export const getBase64Info = (base64Image: string) => {
	const parts = base64Image.split(';base64,');

	const contentType = parts[0].split(':')[1];
	const content = parts[1];
	return {
		content,
		contentType,
	};
};
