import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import s from './DragDropHandle.module.scss';
import cn from 'classnames';
import { ReactComponent as DragDropSVG } from 'src/shared/assets/svg/action/dragdrop.svg';

interface Props extends DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement> {
	variant?: 'vertical' | 'horizontal';
}

export const DragDropHandle: React.FC<Props> = props => {
	const { variant = 'vertical', ...restProps } = props;

	// * Render
	return (
		<DragDropSVG
			className={cn(s.container, variant === 'horizontal' && s.container_horizontal)}
			{...restProps}
		/>
	);
};
