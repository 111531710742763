export const IMAGE_MIMES = {
	'image/jpeg': ['.jpeg'],
	'image/png': ['.png'],
	'image/gif': ['.gif'],
	'video/mpeg': ['.mpeg'],
};

export const VIDEO_MIMES = {
	'video/avi': ['.avi'],
	'video/x-ms-wmv': ['.wmv'],
	'video/mp4': ['.mp4'],
	'video/webm': ['.webm'],
	'video/quicktime': ['.mov'],
};

export const ALL_MIMES = {
	...IMAGE_MIMES,
	...VIDEO_MIMES,
};
