import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ServiceName } from 'src/app/api/api_types';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import {
	PostFileCreateApiArg, //
	GetFileGetApiArg,
	GetFileGetFilesPreviewsApiArg,
	PutFileEditApiArg,
	GetPublicGetApiArg,
} from './types/regTypes';
import {
	PostFileCreateApiResponse, //
	GetFileGetApiResponse,
	GetFileGetFilesPreviewsApiResponse,
	PutFileEditApiResponse,
	GetPublicGetApiResponse,
} from './types/resTypes';

export const reducerPath = `${ServiceName.FILE_SERVICE}` as const;

export const file_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	tagTypes: ['File', 'Public'],
	reducerPath,
	endpoints: build => ({
		postFileCreate: build.mutation<PostFileCreateApiResponse, PostFileCreateApiArg>({
			query: queryArg => ({
				headers: { 'Content-Type': undefined }, // ! Добавлен [ген]
				url: `${reducerPath}/File/create`,
				method: 'POST',
				params: {
					accessType: queryArg.createFilesRequest.accessType,
					entityId: queryArg.createFilesRequest.entityId,
					fileSource: queryArg.createFilesRequest.fileSource,
				},
				body: queryArg.createFilesRequest.uploadedFiles, // ! Изменен [ген]
			}),
			invalidatesTags: ['File'],
		}),
		getFileGet: build.query<GetFileGetApiResponse, GetFileGetApiArg>({
			query: queryArg => ({
				headers: { 'Content-Type': undefined }, // ! Добавлен [ген]
				url: `${reducerPath}/File/get`,
				params: { filesIds: queryArg.filesIds, fileSource: queryArg.fileSource },
			}),
			providesTags: ['File'],
		}),
		getFileGetFilesPreviews: build.query<GetFileGetFilesPreviewsApiResponse, GetFileGetFilesPreviewsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/File/getFilesPreviews`,
				body: queryArg.getFilesPreviewsRequest,
			}),
			providesTags: ['File'],
		}),
		putFileEdit: build.mutation<PutFileEditApiResponse, PutFileEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/File/edit`,
				method: 'PUT',
				params: { entityId: queryArg.entityId, fileId: queryArg.fileId, fileSource: queryArg.fileSource, newName: queryArg.newName },
			}),
			invalidatesTags: ['File'],
		}),
		getPublicGet: build.query<GetPublicGetApiResponse, GetPublicGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Public/get`,
				params: { filesIds: queryArg.filesIds, fileSource: queryArg.fileSource },
			}),
			providesTags: ['Public'],
		}),
	}),
});

export const {
	usePostFileCreateMutation, //
	useGetFileGetQuery,
	useGetFileGetFilesPreviewsQuery,
	usePutFileEditMutation,
	useGetPublicGetQuery,
} = file_serviceAPI;
