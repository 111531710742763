import React, { ReactNode } from 'react';
import { closestCorners, DndContext, DragOverEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SectionProps } from 'src/pages/surveys/pages/NewSurveyPage/_types';

interface Props {
	children?: ReactNode;
	sections: SectionProps[];
	setSections: React.Dispatch<React.SetStateAction<SectionProps[]>>;
}

export const DndContextSurveyForm: React.FC<Props> = props => {
	const { children, sections, setSections } = props;

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const findSectionByQuestionId = (questionId: string) =>
		sections.find(section => {
			return section.questions.find(question => question.id === questionId);
		});

	const findQuestion = (id: string) => {
		const allQuestions = sections.map(section => section.questions).flat();
		return allQuestions.find(question => question.id === id);
	};

	const handleDragOver = (event: DragOverEvent) => {
		const { active, over } = event;

		const activeSection = findSectionByQuestionId(active.id.toString());
		const overSection = over && findSectionByQuestionId(over.id.toString());

		if (activeSection && overSection && activeSection?.id !== overSection?.id) {
			const foundQuestion = findQuestion(active.id.toString());

			if (foundQuestion) {
				setSections(
					sections.map(section => {
						if (section.id === activeSection.id) {
							return {
								...activeSection,
								questions: [...activeSection.questions.filter(question => question.id !== foundQuestion.id)],
							};
						} else if (section.id === overSection.id) {
							return {
								...overSection,
								questions: [foundQuestion, ...overSection.questions],
							};
						} else {
							return section;
						}
					}),
				);
			}
		}
	};

	const handleDragEnd = (event: DragOverEvent) => {
		const { active, over } = event;

		const activeSection = findSectionByQuestionId(active.id.toString());

		if (activeSection && over && active.id !== over?.id) {
			// Same section
			let activeIndex: number;
			let overIndex: number;

			activeSection.questions.forEach((question, index) => {
				if (question.id === active.id) {
					activeIndex = index;
				} else if (question.id === over.id) {
					overIndex = index;
				}
			});

			setSections(
				sections.map(section => {
					if (section.id === activeSection.id) {
						return {
							...section,
							questions: arrayMove(section.questions, activeIndex, overIndex),
						};
					} else {
						return section;
					}
				}),
			);
		}
	};

	// * Render
	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCorners}
			onDragOver={handleDragOver}
			onDragEnd={handleDragEnd}
		>
			{children}
		</DndContext>
	);
};
