// Проверят находиться ли дата в заданном диапазоне
export const isDateInRange = (date: Date, dateRange: [Date | null, Date | null]) => {
	const dateTime = date.getTime();
	const startDateTime = dateRange[0] && dateRange[0].getTime();
	const endDateTime = dateRange[1] && dateRange[1].getTime();

	if (startDateTime && endDateTime) {
		return dateTime >= startDateTime && dateTime <= endDateTime;
	} else if (startDateTime && !endDateTime) {
		return dateTime >= startDateTime;
	} else if (!startDateTime && endDateTime) {
		return dateTime <= endDateTime;
	}
};
