import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RequestStatus } from 'src/app/api/api_types';
import { BookingFilter } from 'src/app/redux/state/office/_states/types';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { FiltersBooking } from 'src/pages/booking/types';
import { BOOKING_RULE_OPTIONS } from 'src/pages/offices/consts/consts';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DateAndTimeRangeField } from 'src/shared/ui/_fields/DateAndTimeRangeField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import * as yup from 'yup';
import s from './BookingFilters.module.scss';

interface Props {
	officeId: string;
	filters: FiltersBooking;
	setFilters: (params: FiltersBooking) => void;
}

export const BookingFilters: React.FC<Props> = props => {
	const { officeId, filters, setFilters } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { findRoomTypes } = officeServiceActions.roomType.async;
	const { findRooms } = officeServiceActions.room.async;
	const { findTags } = officeServiceActions.tag.async;

	// * Selectors
	const roomTypes = useAppSelector(state => state.office_service.roomType.roomTypes);
	const status = useAppSelector(state => state.office_service.room.status);

	// * Initialize
	useEffect(() => {
		officeId &&
			dispatch(
				findRoomTypes({
					params: {
						skipCount: 0,
						takeCount: 1000,
						includeDeactivated: false,
						officeId: officeId,
					},
				}),
			);

		officeId &&
			dispatch(
				findRooms({
					params: {
						skipCount: 0,
						takeCount: 1000,
						officeId: officeId,
					},
				}),
			);
	}, [officeId]);

	useEffect(() => {
		dispatch(
			findTags({
				params: {
					nameIncludeSubstring: '',
					takeCount: 200,
					skipCount: 0,
				},
			}),
		);
	}, []);

	// * Options
	const roomTypeValues = roomTypes?.map(type => {
		const { id, name } = type;

		return {
			id: id,
			name: name,
		};
	});

	// * Form
	const defaultValues: Omit<BookingFilter, 'rule' | 'features'> = {
		type: { id: null, name: null },
		date: '',
		startTime: '',
		endTime: '',
	};

	const schema = yup.object().shape({
		type: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, reset } = formMethods;

	const onSubmit = (data: typeof defaultValues) => {
		if (!officeId) return;

		const dateValue = DateTime.fromJSDate(new Date(data.date)).toISODate();
		const roomTypeId = data.type?.id;
		const params = roomTypeId ? { roomTypeId } : {};

		if (dateValue) {
			const preparedStartTime = `${dateValue}T${data.startTime ? data.startTime : '00:00:00'}`;
			const preparedEndTime = data.endTime ? `${dateValue}T${data.endTime}` : `${DateTime.fromJSDate(new Date(data.date)).plus({ day: 1 }).toISODate()}T00:00:00`;

			Object.assign(params, {
				startTime: preparedStartTime,
				endTime: preparedEndTime,
			});
		}

		// - Если фильтры были и остаются пустыми
		const isEmptyPrevFilters = Object.values(filters).length === 0;
		const isEmptyCurrentFilters = Object.values(params).length === 0;

		if (isEmptyPrevFilters && isEmptyCurrentFilters) return;

		// - set params
		setFilters(params);
	};

	// * Reset filters
	const resetFilters = () => {
		reset(() => defaultValues);
		setFilters({});
	};

	useEffect(() => {
		resetFilters();
	}, [officeId]);

	// * Render
	return (
		<form
			className={s.container}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<h3>Фильтры</h3>
				<div className={s.all_filters}>
					<div className={s.first_row}>
						<SelectSingleField
							name="type"
							label="Тип помещения"
							placeholder="Выберите тип помещения"
							disabled={!officeId}
							options={roomTypeValues}
						/>
					</div>

					<div className={s.second_row}>
						<DateAndTimeRangeField
							dateName="date"
							startTimeName="startTime"
							endTimeName="endTime"
							label="Дата и время бронирования"
							disabled={!officeId}
						/>
					</div>

					<div className={s.buttons}>
						<Button
							fixedWidth
							variant="tertiary"
							onClick={resetFilters}
							isLoading={status === RequestStatus.loading}
							type="button"
						>
							Сбросить фильтры
						</Button>

						<Button
							type="submit"
							fixedWidth
							isLoading={status === RequestStatus.loading}
						>
							Применить
						</Button>
					</div>
				</div>
			</FormProvider>
		</form>
	);
};
