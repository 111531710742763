import React from 'react';
import { RequestStatus } from 'src/app/api/api_types';
import { useGetOfficeFindQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { Office } from 'src/app/redux/state/office/types';
import { useAppSelector } from 'src/app/redux/utils';
import { useRights } from 'src/shared/hooks/useRights';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { OfficeCard } from '../OfficeCard/OfficeCard';
import s from './OfficesLists.module.scss';

export const OfficesLists: React.FC = () => {
	// * Rights
	const isAdmin = useRights();

	// * Selectors
	const status = useAppSelector(state => state.office_service.office.status);

	// * Queries
	const { data: officesList } = useGetOfficeFindQuery({
		includeDeactivated: true,
		takecount: 1000,
		skipcount: 0,
	});

	// * Data conditions
	const activeOffices: Office[] = [];
	const archivedOffices: Office[] = [];

	officesList?.body?.forEach(office => {
		office.isActive ? activeOffices.push(office) : archivedOffices.push(office);
	});

	// * Render
	return (
		<>
			{status === RequestStatus.loading ? (
				<LoaderCircle />
			) : (
				<div className={s.container}>
					{activeOffices.length !== 0 && (
						<div>
							<h3 className={s.header}>Активные</h3>
							<div className={s.list}>
								{activeOffices?.map(office => (
									<OfficeCard
										key={office.id}
										{...office}
									/>
								))}
							</div>
						</div>
					)}

					{isAdmin && archivedOffices.length !== 0 && (
						<div>
							<h3 className={s.header}>Архивированные</h3>
							<div className={s.list}>
								{archivedOffices?.map(office => (
									<OfficeCard
										key={office.id}
										{...office}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};
