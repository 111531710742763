export const StatusOptions = [
	{ id: 'Active' as const, name: 'В работе' },
	{ id: 'Suspend' as const, name: 'Приостановлен' },
	{ id: 'Closed' as const, name: 'Завершен' },
];

export const Status = {
	Active: {
		name: 'В работе',
		color: '#00AF1C',
	},
	Suspend: {
		name: 'Приостановлен',
		color: '#FFBA00',
	},
	Closed: {
		name: 'Завершен',
		color: '#E4342C',
	},
	Archived: {
		name: 'Заархивирован',
		color: '#BDBDBD',
	},
};
