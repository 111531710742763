import cn from 'classnames';
import { CategoryInfo } from 'src/app/redux/queries/survey-service/types';
import { ChipCategory } from 'src/pages/surveys/components/ChipCategory/ChipCategory';
import { DeleteSurveyModal } from 'src/pages/surveys/components/DeleteSurveyModal/DeleteSurveyModal';
import { Survey } from 'src/pages/surveys/pages/NewSurveyPage/_types';
import { useRights } from 'src/shared/hooks/useRights';
import { getIsPastDate } from 'src/shared/lib/date';
import s from './SurveyHeader.module.scss';
import { DateTime } from 'luxon';

interface Props extends Pick<Survey, 'title' | 'description' | 'isAnonymous'> {
	id?: string;
	deadline: string | null;
	category: CategoryInfo | null;
	isTemplate?: boolean;
}

export const SurveyHeader: React.FC<Props> = props => {
	const {
		id, //
		title,
		description,
		isAnonymous,
		deadline,
		category,
		isTemplate = false,
	} = props;

	const isEndedSurvey = getIsPastDate(deadline);

	// * subtitle
	const subtitle = [
		isAnonymous ? 'Анонимный опрос' : '', //
		deadline ? `до ${DateTime.fromISO(deadline).toFormat('dd.LL.yyyy HH:mm')}` : '',
		isEndedSurvey ? '(опрос завершён)' : '',
	]
		.join(' ')
		.trim();

	// * Rights
	const isAdmin = useRights();

	return (
		<div className={s.container}>
			<div>
				<div className={s.header}>
					<div className={cn(s.title, isEndedSurvey && s.title_disabled)}>{title}</div>

					{id && isAdmin && (
						<DeleteSurveyModal
							id={id}
							title={title}
							isEndedSurvey={isEndedSurvey}
							isTemplate={isTemplate}
						/>
					)}
				</div>

				{subtitle && <div className={s.survey_info}>{subtitle}</div>}
			</div>

			{category && <ChipCategory category={category} />}

			{description && <div className={s.description}>{description}</div>}
		</div>
	);
};
