import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	DeleteAnswersByAnsweridApiArg,
	DeleteAnswersByAnsweridApiResponse,
	DeleteCategoriesByCategoryIdApiArg,
	DeleteCategoriesByCategoryIdApiResponse,
	DeleteImagesApiArg,
	DeleteImagesApiResponse,
	DeleteSurveysCategoriesApiArg,
	DeleteSurveysCategoriesApiResponse,
	GetCategoriesApiArg,
	GetCategoriesApiResponse,
	GetQuestionsApiArg,
	GetQuestionsApiResponse,
	GetQuestionsByQuestionidApiArg,
	GetQuestionsByQuestionidApiResponse,
	GetSurveyResultsBySurveyidApiArg,
	GetSurveyResultsBySurveyidApiResponse,
	GetSurveysApiArg,
	GetSurveysApiResponse,
	GetSurveysSurveyidApiArg,
	GetSurveysSurveyidApiResponse,
	PatchCategoriesByCategoryIdApiArg,
	PatchCategoriesByCategoryIdApiResponse,
	PatchGroupsGroupidApiArg,
	PatchGroupsGroupidApiResponse,
	PatchOptionsByQuestionIdApiArg,
	PatchOptionsByQuestionIdApiResponse,
	PatchQuestionsByQuestionIdApiArg,
	PatchQuestionsByQuestionIdApiResponse,
	PatchSurveysSurveyidApiArg,
	PatchSurveysSurveyidApiResponse,
	PostAnswersApiArg,
	PostAnswersApiResponse,
	PostCategoriesApiArg,
	PostCategoriesApiResponse,
	PostGroupsApiArg,
	PostGroupsApiResponse,
	PostImagesApiArg,
	PostImagesApiResponse,
	PostOptionsApiArg,
	PostOptionsApiResponse,
	PostQuestionsApiArg,
	PostQuestionsApiResponse,
	PostSurveysApiArg,
	PostSurveysApiResponse,
	PostSurveysCategoriesApiArg,
	PostSurveysCategoriesApiResponse,
	PostSurveysSurveyIdApiArg,
	PostSurveysSurveyIdApiResponse,
	PutOptionsByQuestionIdApiArg,
	PutOptionsByQuestionIdApiResponse,
} from './types';

/*  ! On generation manual changes.
 - Remove token (types/queries)
 - add - reducerPath
 - patch value: any | null instead of object null.
 - CategoryColor = number.
 - {groupid} / {surveyid} - TODO
 - postAnswers: invalidatesTags: ['Answers', 'Surveys'],
 - deleteAnswersByAnswerid: invalidatesTags: ['Answers', 'Surveys'],
 - postOptions: invalidatesTags: ['Options', 'Surveys'],
 */

const reducerPath = `${ServiceName.SURVEY_SERVICE}` as const;

export const survey_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Answers', 'Categories', 'Groups', 'Images', 'Options', 'Questions', 'SurveyResults', 'Surveys', 'SurveysCategories'] as const,
	endpoints: build => ({
		// Create new answer
		postAnswers: build.mutation<PostAnswersApiResponse, PostAnswersApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Answers`,
				method: 'POST',
				body: queryArg.createAnswerCommand,
			}),
			invalidatesTags: ['Answers', 'Surveys'],
		}),

		// Remove answer
		deleteAnswersByAnswerid: build.mutation<DeleteAnswersByAnsweridApiResponse, DeleteAnswersByAnsweridApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Answers/${queryArg.answerid}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Answers', 'Surveys'],
		}),

		postCategories: build.mutation<PostCategoriesApiResponse, PostCategoriesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories`,
				method: 'POST',
				body: queryArg.createCategoryCommand,
			}),
			invalidatesTags: ['Categories'],
		}),
		getCategories: build.query<GetCategoriesApiResponse, GetCategoriesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories`,
				params: {
					nameIncludeSubstring: queryArg.nameIncludeSubstring,
					color: queryArg.color,
					isAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Categories'],
		}),
		patchCategoriesByCategoryId: build.mutation<PatchCategoriesByCategoryIdApiResponse, PatchCategoriesByCategoryIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories/${queryArg.categoryId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Categories'],
		}),
		deleteCategoriesByCategoryId: build.mutation<DeleteCategoriesByCategoryIdApiResponse, DeleteCategoriesByCategoryIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Categories/${queryArg.categoryId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Categories'],
		}),
		postGroups: build.mutation<PostGroupsApiResponse, PostGroupsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Groups`,
				method: 'POST',
				body: queryArg.createGroupCommand,
			}),
			invalidatesTags: ['Groups'],
		}),
		patchGroupsGroupid: build.mutation<PatchGroupsGroupidApiResponse, PatchGroupsGroupidApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Groups/${queryArg.groupId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Groups'],
		}),

		// create image
		postImages: build.mutation<PostImagesApiResponse, PostImagesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Images`,
				method: 'POST',
				body: queryArg.createImagesCommand,
			}),
			invalidatesTags: ['Surveys'],
		}),

		// delete image
		deleteImages: build.mutation<DeleteImagesApiResponse, DeleteImagesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Images`,
				method: 'DELETE',
				body: queryArg.removeImageCommand,
			}),
			invalidatesTags: ['Surveys'],
		}),

		// Create new option
		postOptions: build.mutation<PostOptionsApiResponse, PostOptionsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Options`,
				method: 'POST',
				body: queryArg.createOptionCommand,
			}),
			invalidatesTags: ['Options', 'Surveys'],
		}),

		// Update options
		putOptionsByQuestionId: build.mutation<PutOptionsByQuestionIdApiResponse, PutOptionsByQuestionIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Options/${queryArg.questionId}`,
				method: 'PUT',
				body: queryArg.updateOptionsRequest,
			}),
			invalidatesTags: ['Options'],
		}),

		//Edit options
		patchOptionsByQuestionId: build.mutation<PatchOptionsByQuestionIdApiResponse, PatchOptionsByQuestionIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Options/${queryArg.questionId}`,
				method: 'PATCH',
				body: queryArg.body,
				params: queryArg.optionsIds.length > 0 && 'optionsIds=' + queryArg.optionsIds.join('&optionsIds='),
			}),
			invalidatesTags: ['Surveys'],
		}),

		postQuestions: build.mutation<PostQuestionsApiResponse, PostQuestionsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Questions`,
				method: 'POST',
				body: queryArg.createSingleQuestionCommand,
			}),
			invalidatesTags: ['Questions'],
		}),
		getQuestions: build.query<GetQuestionsApiResponse, GetQuestionsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Questions`,
				params: {
					isascendingsort: queryArg.isascendingsort,
					isactive: queryArg.isactive,
					includegroup: queryArg.includegroup,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Questions'],
		}),
		getQuestionsByQuestionid: build.query<GetQuestionsByQuestionidApiResponse, GetQuestionsByQuestionidApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Questions/${queryArg.questionid}`,
				params: {
					surveyid: queryArg.surveyid,
					includeoptions: queryArg.includeoptions,
					includeanswers: queryArg.includeanswers,
					includeuserinfo: queryArg.includeuserinfo,
					includeImages: queryArg.includeImages,
				},
			}),
			providesTags: ['Questions'],
		}),
		patchQuestionsByQuestionId: build.mutation<PatchQuestionsByQuestionIdApiResponse, PatchQuestionsByQuestionIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Questions/${queryArg.questionId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Questions'],
		}),
		getSurveyResultsBySurveyid: build.query<GetSurveyResultsBySurveyidApiResponse, GetSurveyResultsBySurveyidApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/SurveyResults/${queryArg.surveyid}`,
			}),
			providesTags: ['SurveyResults'],
		}),

		// Create new survey
		postSurveys: build.mutation<PostSurveysApiResponse, PostSurveysApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Surveys`,
				method: 'POST',
				body: queryArg.createSurveyCommand,
			}),
			invalidatesTags: ['Surveys'],
		}),

		// Find surveys
		getSurveys: build.query<GetSurveysApiResponse, GetSurveysApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Surveys`,
				params: {
					isactive: queryArg.isactive,
					istemplate: queryArg.istemplate,
					nameIncludeSubstring: queryArg.nameIncludeSubstring,
					includecategories: queryArg.includecategories,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Surveys'],
		}),

		// Get survey by id
		getSurveysSurveyid: build.query<GetSurveysSurveyidApiResponse, GetSurveysSurveyidApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Surveys/${queryArg.surveyid}`,
				params: {
					includequestions: queryArg.includequestions,
					includeoptions: queryArg.includeoptions,
					includeuseranswers: queryArg.includeuseranswers,
					userid: queryArg.userid,
					includeuserinfo: queryArg.includeuserinfo,
					includeCategories: queryArg.includeCategories,
					includeImages: queryArg.includeImages,
				},
			}),
			providesTags: ['Surveys'],
		}),

		// Edit survey
		patchSurveysSurveyid: build.mutation<PatchSurveysSurveyidApiResponse, PatchSurveysSurveyidApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Surveys/${queryArg.surveyId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Surveys'],
		}),

		postSurveysSurveyId: build.mutation<PostSurveysSurveyIdApiResponse, PostSurveysSurveyIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Surveys/${queryArg.surveyId}`,
				method: 'POST',
			}),
			invalidatesTags: ['Surveys'],
		}),

		// Add categories to survey
		postSurveysCategories: build.mutation<PostSurveysCategoriesApiResponse, PostSurveysCategoriesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/SurveysCategories`,
				method: 'POST',
				body: queryArg.createSurveyCategoryCommand,
			}),
			invalidatesTags: ['Surveys'],
		}),

		// Remove categories from survey
		deleteSurveysCategories: build.mutation<DeleteSurveysCategoriesApiResponse, DeleteSurveysCategoriesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/SurveysCategories`,
				method: 'DELETE',
				body: queryArg.removeSurveyCategoryCommand,
			}),
			invalidatesTags: ['Surveys'],
		}),
	}),
});

export const {
	usePostAnswersMutation,
	useDeleteAnswersByAnsweridMutation,
	usePostCategoriesMutation,
	useGetCategoriesQuery,
	usePatchCategoriesByCategoryIdMutation,
	useDeleteCategoriesByCategoryIdMutation,
	usePostGroupsMutation,
	usePatchGroupsGroupidMutation,
	usePostImagesMutation,
	useDeleteImagesMutation,
	usePostOptionsMutation,
	usePutOptionsByQuestionIdMutation,
	usePostQuestionsMutation,
	useGetQuestionsQuery,
	useGetQuestionsByQuestionidQuery,
	usePatchQuestionsByQuestionIdMutation,
	useGetSurveyResultsBySurveyidQuery,
	usePostSurveysMutation,
	useGetSurveysQuery,
	useGetSurveysSurveyidQuery,
	usePatchSurveysSurveyidMutation,
	usePostSurveysSurveyIdMutation,
	usePostSurveysCategoriesMutation,
	useDeleteSurveysCategoriesMutation,
} = survey_serviceAPI;
