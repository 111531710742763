import { FieldError } from 'react-hook-form';

export const extractNestedError = (fieldStateError: FieldError | undefined) => {
	if (fieldStateError) {
		if (fieldStateError.message) {
			return fieldStateError.message;
		} else {
			const errorMessages: string[] = [];

			Object.values(fieldStateError).forEach(error => {
				const isFieldError = (errorToCheck: typeof error): errorToCheck is FieldError => {
					return (errorToCheck as FieldError).message !== undefined;
				};

				isFieldError(error) && error.message && errorMessages.push(error.message);
			});

			return errorMessages.join('\n');
		}
	} else {
		return undefined;
	}
};
