import React from 'react';
import s from './EventCreationHeader.module.scss';

export const EventCreationHeader: React.FC<{ title: string }> = ({ title }) => {
	return (
		<div className={s.header}>
			<h2>{title}</h2>
		</div>
	);
};
