import { getApiUrl } from 'src/shared/lib/app';
import { handlePublicResponse, handleResponse } from '../redux/helpers';
import { APIOptions } from './api_types';

const host = getApiUrl();

const getHeaders = (options?: APIOptions) => {
	const accessToken = localStorage.getItem('access_token');

	const token = accessToken ? accessToken : '';

	const headers = {
		Accept: '*/*',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		token: token,
	};

	if (!options?.noContentType) {
		Object.assign(headers, {
			'Content-Type': 'application/json',
		});
	}

	return headers;
};

const getPublicHeaders = () => {
	return {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
	};
};

const getHeadersFormData = () => {
	const accessToken = localStorage.getItem('access_token');

	const token = accessToken ? accessToken : '';

	return {
		Accept: '*/*',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		token: token,
	};
};

export const getRequestUrl = (url: string, params?: string) => {
	return `${host}/${url}${params ? '?' + params : ''}`;
};

export const generateParams = (params?: object) => {
	return params
		? Object.entries(params)
				.map(([key, value]) => `${key}=${value}`)
				.join('&')
		: '';
};

// * Legacy requests
const getLegacy = (url: string, params?: string, signal?: any) =>
	fetch(getRequestUrl(url, params), {
		method: 'GET',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		signal,
	});

const postLegacy = <T>(url: string, payload: T) =>
	fetch(getRequestUrl(url), {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
	});

const postFormDataLegacy = (url: string, params: string, body: BodyInit | null | undefined) =>
	fetch(getRequestUrl(url, params), {
		method: 'POST',
		mode: 'cors',
		headers: getHeadersFormData(),
		referrerPolicy: 'no-referrer',
		body,
	});

const patchLegacy = <T>(url: string, params: string, payload: T) =>
	fetch(getRequestUrl(url, params), {
		method: 'PATCH',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
	});

const putLegacy = <T>(url: string, params: string, payload: T) =>
	fetch(getRequestUrl(url, params), {
		method: 'PUT',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
	});

const delLegacy = <T>(url: string, params?: string, payload?: T) =>
	fetch(getRequestUrl(url, params), {
		method: 'DELETE',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
	});

// * Requests
interface FetchGET {
	url: string;
	params?: object;
	signal?: AbortSignal;
	isPublic?: boolean;
	options?: APIOptions;
}

const GET = async (args: FetchGET) => {
	const { url, params, signal, isPublic = false, options } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'GET',
		headers: isPublic ? getPublicHeaders() : getHeaders(options),
		referrerPolicy: 'no-referrer',
		signal,
	});
};

interface FetchPOST {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

export const POST = async (args: FetchPOST) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchPOST_FormData {
	url: string;
	params?: object;
	payload?: BodyInit | null | undefined;
	signal?: AbortSignal;
}

const POST_FormData = async (args: FetchPOST_FormData) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'POST',
		mode: 'cors',
		headers: getHeadersFormData(),
		referrerPolicy: 'no-referrer',
		body: payload,
		signal,
	});
};

interface FetchPATCH {
	url: string;
	params: object;
	payload: object;
	signal?: AbortSignal;
}

const PATCH = async (args: FetchPATCH) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'PATCH',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchPUT {
	url: string;
	params: object;
	payload: object;
	signal?: AbortSignal;
}

const PUT = async (args: FetchPUT) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'PUT',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchDEL {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

const DEL = async (args: FetchDEL) => {
	const { url, params, payload, signal } = args;

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'DELETE',
		mode: 'cors',
		headers: getHeaders(),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

// * request handlers
const getRequest = async <RES>(args: FetchGET & { action: any; thunkAPI: any; showNotification?: boolean; options?: APIOptions }) => {
	const { thunkAPI, action, showNotification, ...getArgs } = args;

	const response = await GET({ ...getArgs, signal: thunkAPI.signal });

	return await handleResponse<RES>(response, action, thunkAPI, showNotification);
};

const postRequest = async <RES>(args: FetchPOST & { action: any; thunkAPI: any; showNotification?: boolean }) => {
	const { thunkAPI, action, showNotification, ...postArgs } = args;

	const response = await POST(postArgs);

	return await handleResponse<RES>(response, action, thunkAPI, showNotification);
};

const postFormRequest = async <RES>(args: FetchPOST_FormData & { action: any; thunkAPI: any; showNotification?: boolean }) => {
	const { thunkAPI, action, showNotification, ...postFormDataArgs } = args;

	const response = await POST_FormData(postFormDataArgs);

	return await handleResponse<RES>(response, action, thunkAPI, showNotification);
};

const patchRequest = async <RES>(args: FetchPATCH & { action: any; thunkAPI: any; showNotification?: boolean }) => {
	const { thunkAPI, action, showNotification, ...patchArgs } = args;

	const response = await PATCH(patchArgs);

	return await handleResponse<RES>(response, action, thunkAPI, showNotification);
};

const putRequest = async <RES>(args: FetchPUT & { action: any; thunkAPI: any; showNotification?: boolean }) => {
	const { thunkAPI, action, showNotification, ...putArgs } = args;

	const response = await PUT(putArgs);

	return await handleResponse<RES>(response, action, thunkAPI, showNotification);
};

const delRequest = async <RES>(args: FetchDEL & { action: any; thunkAPI: any; showNotification?: boolean }) => {
	const { thunkAPI, action, showNotification, ...delArgs } = args;

	const response = await DEL(delArgs);

	return await handleResponse<RES>(response, action, thunkAPI, showNotification);
};

const getPublicRequest = async <RES>(args: FetchGET & { thunkAPI: any; showNotification?: boolean }) => {
	const { thunkAPI, showNotification, ...getArgs } = args;

	const response = await GET({ ...getArgs, isPublic: true });

	return await handlePublicResponse<RES>(response, thunkAPI, showNotification);
};

export const apiFetch = {
	getLegacy,
	postLegacy,
	postFormDataLegacy,
	patchLegacy,
	putLegacy,
	delLegacy,

	GET,
	POST,
	POST_FormData,
	PATCH,
	PUT,
	DEL,
};

export const apiRequest = {
	getRequest,
	postRequest,
	postFormRequest,
	patchRequest,
	putRequest,
	delRequest,

	getPublicRequest,
};
