import { DateTime } from 'luxon';

export const getMonthNumbers = (date: Date) => {
	const luxonDate = DateTime.fromJSDate(date);

	return {
		currentMonth: luxonDate.month,
		prevMonth: luxonDate.minus({ month: 1 }).month,
		nextMonth: luxonDate.plus({ month: 1 }).month,
	};
};
