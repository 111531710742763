import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import { GalleyFile } from 'src/shared/ui/_galleries/Gallery/_types';
import { contentTypeToExtension } from '../contentTypeToExtension/contentTypeToExtension';
import { galleyFileToBase64 } from '../galleyFileToBase64/galleyFileToBase64';
import { getBase64Info } from '../getBase64Info/getBase64Info';

export const galleyFileToImageContent = async (galleryFile: GalleyFile): Promise<ImageContent> => {
	const base64 = await galleyFileToBase64(galleryFile);

	const info = getBase64Info(base64.base64);

	return {
		name: base64.name,
		content: info.content,
		extension: contentTypeToExtension(info.contentType),
	};
};
