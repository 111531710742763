import React, { useState } from 'react';
import s from './CreateNewUnit.module.scss';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { Button } from 'src/shared/ui/_buttons/Button';
import { SelectSingleCustomValue } from 'src/shared/ui/_inputs/selects/SelectSingleCustomValue';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { EditDepartmentPayloadItem } from 'src/app/redux/state/company-structure/departments/types';
import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { matchItems } from 'src/shared/lib/filter';

interface ValuesProps {
	unitId: string | null;
	name: string;
	description: string;
}

interface Props {
	values: ValuesProps;
	toggleModal: () => void;
}

interface DefaultProps {
	unit: ValuesProps;
}

export const CreateNewUnit: React.FC<Props> = props => {
	const { values, toggleModal } = props;

	const isNewUnit = !values.unitId;

	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const departmentInfo = departmentData?.body || [];

	// * Уже существующие департаменты
	const { data: allDepartmentsData } = company_structure_serviceAPI.useGetAllDepartmentsQuery(true);
	const allExistUnits = allDepartmentsData?.body || [];
	const allIdsExistUnits = allExistUnits.map(info => {
		const { department } = info;
		const { id } = department;

		return id;
	});

	// * Create & edit department
	const [createDepartment] = company_structure_serviceAPI.useCreateDepartmentMutation();
	const [editDepartment] = company_structure_serviceAPI.useEditDepartmentMutation();

	// * Department names wihout exist units
	const departmentsName = departmentInfo
		.filter(item => allIdsExistUnits.find(id => id !== item.id))
		.map(item => {
			return {
				id: item.id,
				name: item.name,
				description: item.description || '',
			};
		});

	const [departmentsNameState, setDepartmentsNameState] = useState(departmentsName);

	// * Form
	const defaultValues: DefaultProps = {
		unit: {
			unitId: values?.unitId || null,
			name: values?.name || '',
			description: values?.description || '',
		},
	};

	const schema = yup.object().shape({
		unit: yup.object().shape({
			name: yup.string().trim().required('Введите название или выберите из списка').max(300, 'Не более 300 символов.'),
			description: yup.string(),
		}),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue, watch } = formMethods;

	const unitId = watch('unit.unitId');
	const unit = watch('unit');

	const onSubmit = (data: DefaultProps) => {
		// new unit
		if (!values.unitId) {
			const departmentPayload = {};

			if (data.unit.unitId) {
				Object.assign(departmentPayload, {
					departmentId: data.unit.unitId,
				});
			}

			if (!data.unit.unitId) {
				Object.assign(departmentPayload, {
					department: {
						name: data.unit.name,
						description: data.unit.description || null,
					},
				});
			}

			createDepartment(departmentPayload)
				.unwrap()
				.then(() => toggleModal())
				.catch(error => console.log('error: ', error));

			return;
		}

		// edit unit
		const preparedPayload: EditDepartmentPayloadItem[] = [];

		if (values.name !== data.unit.name) {
			preparedPayload.push({
				op: 'replace',
				path: '/name',
				value: data.unit.name,
			});
		}

		if (values.description !== data.unit.description) {
			preparedPayload.push({
				op: 'replace',
				path: '/description',
				value: data.unit.description,
			});
		}

		editDepartment({
			departmentId: values.unitId,
			payload: preparedPayload,
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(error => console.log('error: ', error));

		return;
	};

	// * Conditions
	// const isLoading = status === RequestStatus.loading;

	return (
		<div className={s.container}>
			<h2 className={s.title}>{isNewUnit ? 'Создание ' : 'Редактирование '}организационной единицы</h2>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<div className={s.form_components}>
						<Controller
							name="unit.name"
							control={control}
							render={({ field, fieldState }) => (
								<SelectSingleCustomValue
									{...field}
									placeholder="Введите название или выберите из списка"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									searchSubstring={field.value}
									onStringChange={value => {
										const sameValue = departmentsName.find(item => item.name === value);
										setDepartmentsNameState(matchItems(departmentsName, value));
										setValue('unit.name', sameValue ? sameValue.name : value, { shouldValidate: true });
										setValue('unit.unitId', sameValue ? sameValue.id : null, { shouldValidate: true });
									}}
									options={isNewUnit ? departmentsNameState : []}
									selectedOption={{
										id: unitId as string,
										name: unit.name,
										description: unit.description,
									}}
									setSelectedOption={value => {
										setValue('unit.unitId', value.id);
										setValue('unit.name', value.name);
										setValue('unit.description', value.description);
									}}
									errorMessage={fieldState.error?.message}
								/>
							)}
						/>

						<TextAreaField
							name="unit.description"
							placeholder="Описание"
							characterLimit={1000}
							size="small"
							disabled={!!unitId && isNewUnit}
						/>
					</div>
					<div className={s.buttons}>
						<Button
							wide
							variant="tertiary"
							onClick={event => {
								event.preventDefault();
								event.stopPropagation();
								toggleModal();
							}}
						>
							Отменить
						</Button>

						<Button
							type="submit"
							wide
							variant="primary"
							// isLoading={isLoading}
						>
							{isNewUnit ? 'Создать' : 'Сохранить'}
						</Button>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
