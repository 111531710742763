import { asyncActions as departmentsAsync, slice as departmentsSlice } from './departments/slice';
import { asyncActions as contactsAsync, slice as contactsSlice } from './contacts/slice';
import { asyncActions as usersAsync, slice as usersSlice } from './users/slice';

export const companyStructureServiceActions = {
	contact: {
		actions: contactsSlice.actions,
		async: contactsAsync,
	},
	departments: {
		actions: departmentsSlice.actions,
		async: departmentsAsync,
	},
	users: {
		actions: usersSlice.actions,
		async: usersAsync,
	},
};
