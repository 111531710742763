import { FC, useState } from 'react';
import { ModalDelete } from 'src/pages/UserCardPage/ui/DeleteModal/DeleteModal';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { useCommentsContext } from '../../context/useCommentsContext';

interface Props {
	commentId: string;
	editAction: () => void;
}

export const CommentDropDownMenu: FC<Props> = props => {
	const {
		commentId, //
		editAction,
	} = props;

	// * Context
	const { isLoading, deleteAction } = useCommentsContext();

	// * States
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setShowDeleteModal(prevState => !prevState);

	// * Edit menu items
	const menuItems = [
		{
			isVisible: !!editAction,
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => editAction(),
		},
		{
			isVisible: !!deleteAction,
			icon: <ArchiveSVG />,
			title: 'Архивировать',
			action: () => toggleDeleteModal(),
		},
	].filter(item => item.isVisible);

	// * Render
	return (
		<>
			{showDeleteModal && (
				<ModalDelete
					isOpen={showDeleteModal}
					onClose={toggleDeleteModal}
					title="Удаление комментария"
					description="Вы уверены, что хотите удалить комментарий?"
					primaryText="Удалить"
					primaryOnClick={() =>
						deleteAction &&
						deleteAction({
							commentId: commentId,
							successAction: toggleDeleteModal,
						})
					}
					primaryIsLoading={isLoading}
					secondaryOnClick={toggleDeleteModal}
					secondaryIsLoading={isLoading}
				/>
			)}

			<DropDownMenu items={menuItems} />
		</>
	);
};
