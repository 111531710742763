import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { CreateWorkspaceREQ, EditWorkspaceREQ, FindWorkspaceREQ, FindWorkspaceRES, WorkSpaceInfo } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/workspace`;

const createWorkspace = createAppAsyncThunk(`${NAME}/createWorkspace`, async (arg: CreateWorkspaceREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<string>({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createWorkspace(arg),
	});
});

const findWorkspaces = createAppAsyncThunk(`${NAME}/findWorkspaces`, async (arg: FindWorkspaceREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FindWorkspaceRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findWorkspaces(arg),
	});
});

const editWorkspace = createAppAsyncThunk(`${NAME}/editWorkspace`, async (arg: EditWorkspaceREQ, thunkAPI) => {
	const { params, payload } = arg;

	return await apiRequest.patchRequest<EditWorkspaceREQ>({
		url: `${NAME}/edit`,
		params,
		payload,
		thunkAPI,
		action: () => editWorkspace(arg),
	});
});

export interface State {
	workspaces: WorkSpaceInfo[];
	totalCount: number;
	status: RequestStatus;
}

export const initialState: State = {
	workspaces: [],
	totalCount: 0,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createWorkspace.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createWorkspace.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createWorkspace.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findWorkspaces.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findWorkspaces.fulfilled, (state, action) => {
			state.workspaces = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(findWorkspaces.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editWorkspace.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editWorkspace.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(editWorkspace.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createWorkspace,
	findWorkspaces,
	editWorkspace,
};
