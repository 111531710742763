import { createContext, FC, ReactNode, useContext } from 'react';
import { WorkTimeInfo, WorkTimeMonthLimitInfo } from 'src/app/redux/queries/time-service/types/types';
import { LeaveTimeResponse } from 'src/app/redux/queries/time-service/types/resTypes';

interface Context {
	workTimeInfo: WorkTimeInfo[];
	workTimeMonthLimitInfo: WorkTimeMonthLimitInfo[];
	leaveTimesInfo: LeaveTimeResponse[];
}

interface Props extends Context {
	children: ReactNode;
}

const WorkTimesInfoContext = createContext<Context | null>(null);

export const WorkTimesInfoContextProvider: FC<Props> = props => {
	const {
		workTimeInfo,
		workTimeMonthLimitInfo,
		leaveTimesInfo,

		children,
	} = props;

	// * Render
	return (
		<WorkTimesInfoContext.Provider
			value={{
				workTimeInfo,
				workTimeMonthLimitInfo,
				leaveTimesInfo,
			}}
		>
			{children}
		</WorkTimesInfoContext.Provider>
	);
};

export const useWorkTimesInfoContext = () => {
	const context = useContext(WorkTimesInfoContext);

	if (!context) {
		throw new Error('useWorkTimesInfoContext must be used within WorkTimesInfoContext');
	}

	return context;
};
