import cn from 'classnames';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import s from './NavItem.module.scss';

interface Props {
	title: string;
	Icon: ReactElement;
	route: string;
	hasAdaptive: boolean; // для меню с гамбургером
	active?: boolean;
	dot?: boolean;
}

export const NavItem: React.FC<Props> = props => {
	const {
		Icon, //
		title,
		active,
		hasAdaptive,
		route,
		dot,
	} = props;

	// * Render
	return (
		<>
			<div className={cn(s.container, !hasAdaptive && s.container_desktop, active && s.active, !hasAdaptive && s.active_desktop)}>
				<Link
					className={s.link}
					tabIndex={0}
					to={route}
				>
					<div className={s.icon}>
						{dot && <div className={s.dot} />}
						{Icon}
					</div>
					<span className={cn(s.title, hasAdaptive && s.title_adaptive)}>{title}</span>
				</Link>
			</div>
		</>
	);
};
