import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RubricData } from 'src/app/redux/queries/wiki-service/types/types';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import s from './Menu.module.scss';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	wikiTree: RubricData[];
}

export const Menu: React.FC<Props> = props => {
	const {
		className, //
		wikiTree,
	} = props;

	// * Menu
	const nullOption = { name: 'Не выбрано', id: null };

	const { control, setValue, watch } = useFormContext();
	const selectedRubric = watch('rubric');

	// * Render
	return (
		<div className={`${s.container} ${className}`}>
			{wikiTree && (
				<div className={s.content}>
					<Controller
						name="rubric"
						control={control}
						render={() => (
							<SelectSingle
								label="Рубрика"
								keyNames={{
									name: 'name',
									value: 'id',
								}}
								selectedOption={selectedRubric}
								setSelectedOption={option => {
									setValue('rubric', option);
									setValue('subRubric', nullOption);
								}}
								options={[...wikiTree]}
							/>
						)}
					/>

					<SelectSingleField
						name="subRubric"
						label="Подрубрика"
						options={selectedRubric.id ? [nullOption, ...selectedRubric.children] : [nullOption]}
						isNullable
					/>
				</div>
			)}
		</div>
	);
};
