import React, { useState } from 'react';
import s from './MonthPickerTitleButton.module.scss';
import { dateToMonthYearLocaleString } from 'src/shared/lib/date';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FloatingFocusManager } from '@floating-ui/react';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import cn from 'classnames';
import { CollapseArrows } from 'src/shared/ui/CollapseArrows/CollapseArrows';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'showMonthYearPicker' | 'showFullMonthYearPicker'> {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	prefix?: string;
}

export const MonthPickerTitleButton: React.FC<Props> = props => {
	const { selectedDate, setSelectedDate, prefix, className, ...restProps } = props;

	const [isOpen, setIsOpen] = useState(false);

	const onChange = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);
	};

	const dateString = dateToMonthYearLocaleString(selectedDate);

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<h3
				className={s.title}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				{prefix}
				<span className={s.picker_button}>
					{dateString}
					<CollapseArrows
						isOpen={isOpen}
						arrowType="empty"
						color="primary"
					/>
				</span>
			</h3>

			{isOpen && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							{...restProps}
							locale={'ru'}
							selected={selectedDate}
							onChange={onChange}
							showMonthYearPicker
							showFullMonthYearPicker
							inline
						/>
					</div>
				</FloatingFocusManager>
			)}
		</div>
	);
};
