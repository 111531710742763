import React, { InputHTMLAttributes } from 'react';
import s from './RadioColor.module.scss';
import cn from 'classnames';

type DefaultInputPropsType = InputHTMLAttributes<HTMLInputElement>;

type Props = Omit<DefaultInputPropsType, 'type' | 'children'> & {
	label?: string;
	value: number;
};

export const RadioColor: React.FC<Props> = props => {
	const { className, label, value, ...restProps } = props;

	// * Render
	return (
		<label className={cn(s.label, s[`label_${value}`], className)}>
			<input
				readOnly={true}
				type="radio"
				value={value}
				{...restProps}
			/>
			<span /> {/* span is the actual checkbox */}
			{label && <div>{label}</div>}
		</label>
	);
};
