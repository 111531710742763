import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { CreateCategoryRequest } from 'src/app/redux/queries/event-service/types/reqTypes';

export const useGetCategoryActions = () => {
	// * API
	const [createCategory, { isLoading: isCreateLoading }] = event_serviceAPI.usePostCategoryCreateMutation();
	const [editCategoryInfo, { isLoading: isEditLoading }] = event_serviceAPI.usePatchCategoryEditMutation();

	const isLoading = isCreateLoading || isEditLoading;

	// - add
	const addCategory = ({ name, color }: CreateCategoryRequest) => {
		console.log(name, color);
		createCategory({
			createCategoryRequest: {
				name,
				color,
			},
		})
			.unwrap()
			.catch(err => console.log(err));
	};

	// - edit
	const editCategory = ({ id, name, color }: { id: string | null; name: string; color?: number }) => {
		if (!id) return;

		editCategoryInfo({
			categoryId: id,
			body: [
				{
					op: 'replace',
					path: '/Name',
					value: name,
				},
				{
					op: 'replace',
					path: '/Color',
					value: color,
				},
			],
		})
			.unwrap()
			.catch(err => console.log(err));
	};

	// - remove
	const removeCategory = (categoryId: string) => {
		editCategoryInfo({
			categoryId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: false,
				},
			],
		})
			.unwrap()
			.catch(err => console.log(err));
	};

	return { addCategory, editCategory, removeCategory, isLoading };
};
