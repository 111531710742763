import { ColumnDef, ExpandedState, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import s from './TableWithComments.module.scss';

interface Props {
	columns: ColumnDef<any, any>[];
	data: any[]; // subRows - это серые строки.
}

export const TableWithComments: FC<Props> = props => {
	const { columns, data } = props;

	const [expanded, setExpanded] = useState<ExpandedState>({});

	useEffect(() => {
		const initialExpandedState: { [key: number]: boolean } = { ...data.map(() => true) }; // Автоматически расширяет все.
		setExpanded(initialExpandedState);
	}, [data]);

	const table = useReactTable({
		data,
		columns,
		state: {
			expanded,
		},
		getCoreRowModel: getCoreRowModel(),
		getSubRows: row => row.subRows,
		getExpandedRowModel: getExpandedRowModel(),
	});

	// * Render
	return (
		<table className={s.table}>
			<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th
								key={header.id}
								style={header.column.columnDef.meta?.style}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</thead>

			<tbody>
				{table.getRowModel().rows.map(row => (
					<tr key={row.id}>
						{row.getVisibleCells().map(cell => {
							const hasMeta = cell.getContext().cell.column.columnDef.meta;
							return (
								<td
									key={cell.id}
									className={cn(!cell.row.getIsExpanded() && s.grayed)}
									style={cell.column.columnDef.meta?.style}
									{...(hasMeta?.getCellContext && { ...hasMeta.getCellContext(cell.getContext()) })}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
};
