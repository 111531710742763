import { FC } from 'react';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import { useGetBookingInfo } from 'src/pages/booking/ui/BookingPage/MyBookingCard/hooks/useGetBookingInfo';
import { BookingInfoCard } from 'src/pages/booking/ui/BookingInfoCard';
import s from './Booking.module.scss';
import { ConditionalRendering } from 'src/shared/providers';

export const Booking: FC = () => {
	const { isLoading, booking } = useGetBookingInfo();

	// * Render
	return (
		<CardWrapper
			title="Бронирование"
			path="/booking"
			fullHeight
		>
			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={booking.length > 0}
				FailedResult={(
					<div className={s.empty_container}>
						<p>На данный момент у вас нет активных броней ни в одном из офисов.</p>
						<p>Чтобы забронировать рабочее место или переговорную, перейдите в раздел «Бронирование».</p>
					</div>
				)}
				LoadedResult={
					<div className={s.row}>
						{booking.slice(0, 3).map(info => (
							<BookingInfoCard
								key={info.bookingId}
								readonly
								{...info}
							/>
						))}
					</div>
				}
			/>
		</CardWrapper>
	);
};
