import { DateTime } from 'luxon';

export const backendISOUTCToLocalLuxon = (isoString: string) => {
	return DateTime.fromISO(isoString, { zone: 'utc' }).setZone('local');
};

/* ? Пример
	ISO с бэкенда: 2023-12-11T15:31:54.9539224 (Не работает offset)
	ISO который должен бы приходить: 2023-12-11T15:31:54.225Z (Работает offset)
	Офсет с бека не работает.
	С помощью этой функции генерим Luxon объект который корректно работает с офсетом. В данном случае с локальным.
*/
