import { FC } from 'react';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { isVideoExtension } from 'src/shared/lib/file';
import { createGalleyFileFromImageInfo } from 'src/shared/ui/_galleries/Gallery/_utils';
import { MediaItem } from '../../types';
import s from './MediaItem.module.scss';

interface Props {
	mediaItem: MediaItem;
	onDelete: (imageId: string) => void;
}

export const Media: FC<Props> = props => {
	const {
		mediaItem, //
		onDelete,
	} = props;

	const isVideo = isVideoExtension(mediaItem.extension);

	const { preview } = createGalleyFileFromImageInfo(mediaItem);

	// * Render
	return (
		<div
			className={s.container}
			onClick={() => onDelete(mediaItem.id)}
		>
			<div className={s.overlay}>
				<DeleteSVG />
				<span className={s.imageName}>{mediaItem.name}</span>
			</div>

			{isVideo ? (
				<video className={s.image}>
					<source src={preview} />
				</video>
			) : (
				<img
					className={s.image}
					src={preview}
					alt={mediaItem.name}
				/>
			)}
		</div>
	);
};
