import { useState } from 'react';
import { MOCK_USERS } from '../../mock';
import { SortBy } from '../../types';
import { Table } from '../Table/ui/Table/Table';
import { UsersTable } from '../UsersTable';
import s from './VacationTable.module.scss';

export const VacationTable: React.FC = () => {
	const users = MOCK_USERS;

	const [sortBy, setSortBy] = useState<{ ascending: boolean; by: SortBy } | null>(null);

	const sortedUsers = sortBy
		? users.sort((a, b) => {
				if (sortBy.ascending) {
					return a[sortBy.by] > b[sortBy.by] ? 1 : -1;
				} else {
					return a[sortBy.by] < b[sortBy.by] ? 1 : -1;
				}
			})
		: users;

	// * Render
	return (
		<div className={s.container}>
			<UsersTable
				users={sortedUsers}
				sortBy={sortBy}
				setSortBy={setSortBy}
			/>
			<Table users={sortedUsers} />
		</div>
	);
};
