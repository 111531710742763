import { ReactComponent as BalanceSVG } from 'src/shared/assets/svg/social/balance.svg';
import { ReactComponent as OfficeSVG } from 'src/shared/assets/svg/social/office.svg';
import { ReactComponent as SupervisorSVG } from 'src/shared/assets/svg/social/supervisor.svg';
import { ReactComponent as LoyaltySVG } from 'src/shared/assets/svg/social/loyalty.svg';
import { ReactComponent as ClockSVG } from 'src/shared/assets/svg/action/clock.svg';
import { ReactComponent as AccessAlarmsSVG } from 'src/shared/assets/svg/social/access-alarms.svg';
import { ReactComponent as HistorySVG } from 'src/shared/assets/svg/social/history.svg';

export const ProjectInfoFields = [
	{
		key: 'department',
		Icon: BalanceSVG,
		text: 'Департамент',
	},
	{
		key: 'customer',
		Icon: OfficeSVG,
		text: 'Заказчик',
	},
	{
		key: 'startDateUtc',
		Icon: ClockSVG,
		text: 'Дата запуска',
	},
	{
		key: 'usersCount',
		Icon: SupervisorSVG,
		text: 'Сотрудники',
	},

	{
		key: 'endDateUtc',
		Icon: AccessAlarmsSVG,
		text: 'Дата завершения',
	},
	{
		key: 'status',
		Icon: LoyaltySVG,
		text: 'Статус',
	},
	{
		key: 'duration',
		Icon: HistorySVG,
		text: 'Продолжительность',
	},
];
