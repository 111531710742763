import { DateTime } from 'luxon';
import { BookingInfo } from 'src/app/redux/queries/office-service/types/types';
import { isLessThenMax } from 'src/shared/lib/date';

export const getMinDateBooking = (workspaceBookingList: BookingInfo[], selectedDate: Date): Date => {
	const lessSelected = workspaceBookingList
		.filter(item => {
			// Прибавляем день к выбранной дате, т.к. день окончания может совпадать со свободной датой
			const selectedDatePlusOne = DateTime.fromJSDate(new Date(selectedDate)).plus({ day: 1 }).toJSDate();
			// Отсеиваем даты больше выбранной
			const isLess = isLessThenMax(new Date(item.endTime), selectedDatePlusOne);
			return isLess;
		})
		.map(item => item.endTime)
		// Сортируем, чтобы найти ближайшую дату окончания к выбранной дате
		.sort((a, b) => {
			return isLessThenMax(new Date(a), new Date(b)) ? 1 : -1;
		});

	// Если броней до выбранной даты не было, возвращаем минимальную дату бронирования
	if (lessSelected.length > 0) {
		return new Date(lessSelected[0]);
	} else {
		return DateTime.fromJSDate(new Date()).plus({ day: 1 }).toJSDate();
	}
};
