import React, { useState } from 'react';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg/action/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg/action/un-archive.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './ArchiveButton.module.scss';

interface Props {
	id: string;
	name: string;
	isActive: boolean;
	type: 'article' | 'sub-rubric' | 'rubric';
}

export const ArchiveButton: React.FC<Props> = props => {
	const { id, name, isActive, type } = props;

	// * API
	const [editRubric, { isLoading: isRubricLoading }] = wiki_serviceAPI.useEditRubricMutation();
	const [editArticle, { isLoading: isArticleLoading }] = wiki_serviceAPI.useEditArticleMutation();

	const isLoading = isRubricLoading || isArticleLoading;

	// - Archive/un-archive
	const onArchiveClick = () => {
		if (type === 'rubric' || type === 'sub-rubric') {
			editRubric({
				rubricId: id,
				editRubricRequest: [
					{
						op: 'replace',
						path: '/isActive',
						value: !isActive,
					},
				],
			})
				.unwrap()
				.then(() => toggleModal())
				.catch(error => console.log(error));
		}

		if (type === 'article') {
			editArticle({
				articleId: id,
				editArticleRequest: [
					{
						op: 'replace',
						path: '/isActive',
						value: !isActive,
					},
				],
			})
				.unwrap()
				.then(() => toggleModal())
				.catch(error => console.log(error));
		}
	};

	const getLocalType = () => {
		if (type === 'rubric') return 'рубрику';
		if (type === 'sub-rubric') return 'подрубрику';
		if (type === 'article') return 'статью';
	};

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={showModal}
				onClose={toggleModal}
			>
				<div className={s.container}>
					<h3>{isActive ? 'Архивировать' : 'Восстановить'}</h3>
					<p className={s.text}>
						{isActive ? `Вы действительно хотите архивировать ${getLocalType()}` : `Вы действительно хотите восстановить ${getLocalType()}`}
						<br />
						<span className={s.text_highlighted}>{name}</span>?
					</p>

					<ButtonPair
						primaryText={isActive ? 'Архивировать' : 'Восстановить'}
						primaryIsLoading={isLoading}
						primaryOnClick={onArchiveClick}
						secondaryText="Отменить"
						secondaryOnClick={toggleModal}
						secondaryIsLoading={isLoading}
					/>
				</div>
			</ModalNewProto>

			<IconButton
				Icon={isActive ? <ArchiveSVG /> : <UnArchiveSVG />}
				onClick={toggleModal}
			/>
		</>
	);
};
