export const getPasswordRules = (password: string) => {
	const rules = [
		{
			name: 'Не менее 8 символов',
			checked: password.length >= 8,
		},
		{
			name: 'Заглавные буквы',
			checked: /[A-Z]/u.test(password),
		},
		{
			name: 'Строчные буквы',
			checked: /[a-z]/u.test(password),
		},
		{
			name: 'Минимум 1 цифра',
			checked: /\d/.test(password),
		},
		{
			name: 'Минимум 1 специальный символ',
			checked: /[.,:;?!*+%\-<>@[\]{}/\\_$#]/.test(password),
		},
		{
			name: 'Нельзя использовать пробел',
			checked: !!password && !/\s/.test(password),
		},
	];

	return rules;
};
