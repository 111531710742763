import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'src/app/redux/utils';
import { AdminPage } from 'src/pages/AdminPage';
import { AuthPage } from 'src/pages/AuthPage/ui/AuthPage/AuthPage';
import { TimeTrackingPage } from 'src/pages/TimeTrackingPage';
import { UserCardPage } from 'src/pages/UserCardPage/index';
import { UsersPage } from 'src/pages/UsersPage';
import { BookingPage, BookingRoomPage } from 'src/pages/booking';
import { CompanyDetailsPage } from 'src/pages/companyStructure/pages/CompanyDetails/CompanyDetailsPage';
import { CompanyStructurePage } from 'src/pages/companyStructure/pages/CompanyStructurePage';
import { DepartmentPage, DepartmentsPage, TimeListDDPage } from 'src/pages/departments';
import { NewEventPage } from 'src/pages/event_calendar';
import { EventCalendarPage } from 'src/pages/event_calendar';
import { EventDetailsPage } from 'src/pages/event_calendar/ui/EventDetailsPage';
import { FeedbackPage } from 'src/pages/feedback';
import { SingleFeedbackPage } from 'src/pages/feedback/SingleFeedbackPage/SingleFeedbackPage';
import { NotFoundPage } from 'src/pages/notFound/NotFoundPage';
import { OfferMainPage } from 'src/pages/offer/pages/OfferMainPage';
import { OfficesPage, RoomFormPage, SingleOfficePage } from 'src/pages/offices';
import { ProjectDetailPage, ProjectPage, TimeListPage } from 'src/pages/project_service_pages';
import { DisplaySurveyPage } from 'src/pages/surveys/pages/DisplaySurveyPage/DisplaySurveyPage';
import { EditSurveyPage } from 'src/pages/surveys/pages/EditSurvey/EditSurveyPage';
import { NewSurveyPage } from 'src/pages/surveys/pages/NewSurveyPage/NewSurveyPage';
import { NewTemplatePage } from 'src/pages/surveys/pages/NewTemplatePage/NewTemplatePage';
import { ResultsPage } from 'src/pages/surveys/pages/ResultsPage/ResultsPage';
import { SurveyPage } from 'src/pages/surveys/pages/SurveyPage/SurveyPage';
import { TemplatePage } from 'src/pages/surveys/pages/TemplatePage/TemplatePage';
import { CreateArticlePage } from 'src/pages/wiki/createArticlePage/CreateArticlePage';
import { ArticlePage } from 'src/pages/wiki/pages/article/ArticlePage/ArticlePage';
import { ArticlePagePublic } from 'src/pages/wiki/pages/article/ArticlePagePublic/ArticlePagePublic';
import { WikiPage } from 'src/pages/wiki/pages/wiki/WikiPage/WikiPage';
import { WikiPagePublic } from 'src/pages/wiki/pages/wiki/WikiPagePublic/WikiPagePublic';
import { MainPage } from 'src/pages/MainPage';
import { useRights } from 'src/shared/hooks/useRights';
import { isDevEnv } from 'src/shared/lib/app';
import { routerPath } from './paths';
import { createPrivateRoute } from './lib/createBasicRoute';
import { createPublicRoute } from './lib/createPublicRoute';
import { VacationPlannerPage } from 'src/pages/vacation';

export const AppRouter = () => {
	const tokens = useAppSelector(state => state.auth_service.tokens);
	const hasTokens = !!(tokens && tokens.accessToken && tokens.refreshToken);

	const isAdmin = useRights();
	const allowedHR = useRights(18);
	const allowedToEditOffices = useRights(8);

	const startingPage = routerPath.main.page;

	return (
		<BrowserRouter>
			<Routes>
				{/* Redirect unauthorized */}
				<Route
					path="/"
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<Navigate
								to={routerPath.auth.login}
								replace
							/>
						)
					}
				/>

				{/* Redirect authorized */}
				<Route
					path={routerPath.auth.login}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="login" />
						)
					}
				/>

				<Route
					path={routerPath.auth.signup}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="signup" />
						)
					}
				/>

				<Route
					path={routerPath.auth.forgot}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="forgot" />
						)
					}
				/>

				<Route
					path={routerPath.auth.reset}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="reset" />
						)
					}
				/>

				<Route
					path={routerPath.auth.reactivate}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="reactivate" />
						)
					}
				/>

				{/* * Time */}
				{createPrivateRoute({
					path: routerPath.main.page,
					PageComponent: <MainPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Time */}
				{createPrivateRoute({
					path: routerPath.time.page,
					PageComponent: <TimeTrackingPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Projects */}
				{createPrivateRoute({
					path: routerPath.projects.page,
					PageComponent: <ProjectPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.projects.page + '/:projectId',
					PageComponent: <ProjectDetailPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.projects.page + '/:projectId' + routerPath.projects.timelist,
					PageComponent: <TimeListPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Users */}
				{createPrivateRoute({
					path: routerPath.users.page,
					PageComponent: <UsersPage />,
					rights: [],
					hasTokens,
				})}

				{/* * User card */}
				{createPrivateRoute({
					path: routerPath.users.page + '/:userId',
					PageComponent: <UserCardPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Wiki - Private */}
				{createPrivateRoute({
					path: routerPath.wiki.page,
					PageComponent: <WikiPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.wiki.page + '/:articleId',
					PageComponent: <ArticlePage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.wiki.page + routerPath.wiki.newArticles + '/:rubricId/:subRubricId/:articleId',
					PageComponent: <CreateArticlePage />,
					rights: [],
					hasTokens,
				})}

				{/* Vacation */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.vacation.page,
						PageComponent: <VacationPlannerPage />,
						rights: [],
						hasTokens,
					})}

				<Route
					path={routerPath.wiki.page + routerPath.wiki.editArticles + '/:rubricId/:subRubricId/:articleId'}
					element={
						hasTokens ? (
							<CreateArticlePage />
						) : (
							<Navigate
								to="/"
								replace
							/>
						)
					}
				/>

				{/* * Wiki - Public */}
				<Route
					path={routerPath.public.page}
					element={<WikiPagePublic />}
				/>

				<Route
					path={routerPath.public.page + '/:articleId'}
					element={<ArticlePagePublic />}
				/>

				{/* * Offices */}
				{createPrivateRoute({
					path: routerPath.offices.page,
					PageComponent: <OfficesPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.offices.page + routerPath.offices.singleOffice + '/:officeId',
					PageComponent: <SingleOfficePage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.offices.page + routerPath.offices.roomForm + '/:officeId/:roomId/:formStep',
					PageComponent: <RoomFormPage />,
					rights: [allowedToEditOffices],
					hasTokens,
				})}

				{/* * Departments */}
				{createPrivateRoute({
					path: routerPath.departments.page,
					PageComponent: <DepartmentsPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.departments.page + routerPath.departments.singleDepartment + '/:departmentId' + '/:tab',
					PageComponent: <DepartmentPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.departments.page + routerPath.departments.timeList + '/:departmentId',
					PageComponent: <TimeListDDPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.departments.timeList + '/:departmentId',
					PageComponent: <TimeListDDPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Booking */}
				{createPrivateRoute({
					path: routerPath.booking.page,
					PageComponent: <BookingPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.booking.page + '/:officeId' + '/:roomId',
					PageComponent: <BookingRoomPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Company structure */}
				{createPrivateRoute({
					path: routerPath.structure.page,
					PageComponent: <CompanyStructurePage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.structure.details + '/:departmentId',
					PageComponent: <CompanyDetailsPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Event Calendar */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page,
						PageComponent: <EventCalendarPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page + routerPath.calendar.newEvent,
						PageComponent: <NewEventPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page + routerPath.calendar.editEvent + '/:eventId',
						PageComponent: <NewEventPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page + routerPath.calendar.event + '/:eventId',
						PageComponent: <EventDetailsPage />,
						rights: [],
						hasTokens,
					})}

				{/* * HR */}
				{createPrivateRoute({
					path: routerPath.hr.page,
					PageComponent: <OfferMainPage />,
					rights: [allowedHR],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.hr.page + routerPath.hr.offer,
					PageComponent: <OfferMainPage />,
					rights: [allowedHR],
					hasTokens,
				})}

				{/* * Surveys */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page,
						PageComponent: <SurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.newSurvey + '/:surveyId',
						PageComponent: <NewSurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.edit + '/:surveyId',
						PageComponent: <EditSurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.newTemplate + '/:surveyId',
						PageComponent: <NewTemplatePage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + '/:surveyId',
						PageComponent: <DisplaySurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.template + '/:surveyId',
						PageComponent: <TemplatePage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + '/:surveyId' + '/results',
						PageComponent: <ResultsPage />,
						rights: [],
						hasTokens,
					})}

				{/* Admin-service */}
				{createPrivateRoute({
					path: routerPath.admin.page + routerPath.admin.dashboard,
					PageComponent: <AdminPage />,
					rights: [],
					hasTokens,
				})}

				{/* Feedback */}
				{createPrivateRoute({
					path: routerPath.feedback.page,
					PageComponent: <FeedbackPage />,
					rights: [isAdmin],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.feedback.page + '/:feedbackId',
					PageComponent: <SingleFeedbackPage />,
					rights: [isAdmin],
					hasTokens,
				})}

				{/* Wiki - Public */}
				{createPublicRoute({
					path: routerPath.public.page,
					PageComponent: <WikiPagePublic />,
				})}

				{createPublicRoute({
					path: routerPath.public.page + '/:articleId',
					PageComponent: <ArticlePagePublic />,
				})}

				{/* ... */}
				{createPublicRoute({
					path: '*',
					PageComponent: <NotFoundPage />,
				})}
			</Routes>
		</BrowserRouter>
	);
};
