import React from 'react';
import s from './DottedList.module.scss';

export const DottedList: React.FC<{ color: string; text?: string }> = ({ color, text }) => {
	return (
		<div className={s.dottedList}>
			<div
				className={s.dot}
				style={{ backgroundColor: color }}
			/>
			<div className={s.text}>{text}</div>
		</div>
	);
};
