import React from 'react';
import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { Categories } from 'src/widgets/Categories';
import { useGetCategoryActions } from '../../hooks/useGetCategoryActions';
import s from './EventList.module.scss';

export const EventList: React.FC = () => {
	// * API
	const { data: categoriesData } = event_serviceAPI.useGetCategoryFindQuery({
		skipcount: 0,
		takecount: 100,
	});

	const categories = categoriesData?.body || [];

	// - Category actions
	const { addCategory, editCategory, removeCategory, isLoading } = useGetCategoryActions();

	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<Categories
					categories={categories}
					getCategoryId={removeCategory}
					getCategoryInfo={editCategory}
					getNewCategoryInfo={addCategory}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
};
