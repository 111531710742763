import React, { useEffect, useState } from 'react';
import s from './AdministrationContent.module.scss';
import { AdminstrationCard } from './AdminstrationCard/AdminstrationCard';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { CreateNewAdministrator } from './CreateNewAdministrator/CreateNewAdministrator';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { companyStructureServiceActions } from 'src/app/redux/state/company-structure/actions';

interface Props {
	isEdit: boolean;
}

export const AdminstrationContent: React.FC<Props> = props => {
	const { isEdit } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { addReorderedAdministrators } = companyStructureServiceActions.users.actions;

	// * Queries
	const { currentData: usersData } = company_structure_serviceAPI.useGetUsersQuery({
		skipcount: 0,
		takecount: 1000,
		isAdministration: true,
	});

	// * All administrators
	const usersAdministration = usersData?.body || [];
	const [administrators, setAdministrators] = useState(usersAdministration);

	useEffect(() => {
		if (usersData) {
			setAdministrators(usersAdministration);
		}
	}, [usersData]);

	// * save reordered administrators
	useEffect(() => {
		const isChange =
			usersAdministration.filter((admin, index) => {
				if (administrators[index].id === admin.id) return;
				return admin;
			}).length > 0;

		if (!isChange) {
			dispatch(addReorderedAdministrators({}));
			return;
		}

		const length = administrators.length;
		const finishedArray = {};
		for (let i = 0; i <= length; i++) {
			administrators[i] &&
				Object.assign(finishedArray, {
					[administrators[i].id]: i + 1,
				});
		}

		dispatch(addReorderedAdministrators(finishedArray));
	}, [administrators]);

	// * Modal
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => {
		setShowModal(prevState => !prevState);
	};

	// * Drag & drop
	const handleDragDrop = (results: any) => {
		const { source, destination } = results;

		const sourceIndex = source.index;
		const destinationIndex = destination.index;

		// * 1
		if (!destination) return;

		// * 2
		if (source.droppableId === destination.droppableId && source.index === destination.index) return;

		// * 3
		const administratorsArray = [...administrators];

		const [removeUnit] = administratorsArray.splice(sourceIndex, 1);
		administratorsArray.splice(destinationIndex, 0, removeUnit);

		setAdministrators(administratorsArray);
	};

	return (
		<div className={s.container}>
			{isEdit ? (
				<DragDropContext onDragEnd={handleDragDrop}>
					<Droppable
						droppableId="ROOT"
						type="group"
					>
						{provided => (
							<div
								className={s.wrapper}
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								<AddButton
									title="Добавить администратора"
									onClick={toggleModal}
								/>
								{administrators.length > 0 &&
									administrators.map((unit, index) => (
										<Draggable
											draggableId={unit.id}
											key={unit.id}
											index={index}
										>
											{provided => (
												<div
													className={s.unit_card}
													{...provided.dragHandleProps}
													{...provided.draggableProps}
													ref={provided.innerRef}
												>
													<AdminstrationCard
														edit={isEdit && unit.isCustom}
														admin={unit}
													/>
												</div>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<div className={s.wrapper}>
					{administrators.length > 0 &&
						administrators.map(unit => (
							<AdminstrationCard
								key={unit.id}
								admin={unit}
							/>
						))}
				</div>
			)}

			{showModal && (
				<Modal
					show={showModal}
					toggleModal={toggleModal}
					width={800}
					Content={
						<CreateNewAdministrator
							isNewAdministrator
							toggleModal={toggleModal}
						/>
					}
				/>
			)}
		</div>
	);
};
