export const findNewItems = <T extends { id: string }>(prevItems: T[], currentItems: T[]) => {
	const newItems: T[] = [];

	currentItems.forEach(currentItem => {
		const foundInPrevious = prevItems.find(previousItem => previousItem.id === currentItem.id);

		if (!foundInPrevious) {
			newItems.push(currentItem);
		}
	});

	return newItems;
};
