import { DateTime } from 'luxon';

// переводит объект даты в строку по типу дд.мм.гггг (пример: 13.11.2023)
export const dateToFullRuDateString = (date: Date) => {
	return DateTime.fromJSDate(date).toLocaleString(
		{
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		},
		{ locale: 'ru-RU' },
	);
};
