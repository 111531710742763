import { asyncActions as departmentTreeAsync, slice as departmentTreeSlice } from './departmentTree/slice';
import { asyncActions as departmentAsync, slice as departmentSlice } from './department/slice';

export const departmentServiceActions = {
	departmentTree: {
		actions: departmentTreeSlice.actions,
		async: departmentTreeAsync,
	},
	department: {
		action: departmentSlice.actions,
		async: departmentAsync,
	},
};
