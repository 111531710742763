import { useNavigate } from 'react-router-dom';
import { CategoryInfo } from 'src/app/redux/queries/event-service/types/types';
import { getCategoryInfo } from '../../../../lib/getCategoryInfo/getCategoryInfo';
import s from './EventInfoItem.module.scss';
import { ChipCategory } from 'src/shared/ui/_chips/ChipCategory/ChipCategory';

interface Props {
	eventId: string;
	eventName: string;
	time: string;
	category: CategoryInfo[];
}

export const EventInfoItem: React.FC<Props> = props => {
	const { eventId, eventName, time, category } = props;

	const { color, name } = getCategoryInfo(category);

	const navigate = useNavigate();

	return (
		<div
			className={s.container}
			onClick={() => navigate(`event/${eventId}`)}
		>
			<div
				className={s.background}
				style={{ backgroundColor: color }}
			/>

			<div className={s.header}>
				<ChipCategory
					color={color}
					name={name}
				/>

				{time && <div className={s.time}>{time}</div>}
			</div>

			<p className={s.event_name}>{eventName}</p>
		</div>
	);
};
