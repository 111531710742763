import React, { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';
import s from './RoomCard.module.scss';
import { getTimeRangeString } from 'src/shared/lib/date';
import { OfficeRoom } from 'src/app/redux/state/office/office/types';
import { ChipList } from 'src/shared/ui/_chips/ChipList/ChipList';
import { getBookingRule } from 'src/pages/offices/lib/getBookingRule';
import { useRights } from 'src/shared/hooks/useRights';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	room: OfficeRoom;
	DropDownMenu?: ReactElement;
}

export const RoomCard: React.FC<Props> = props => {
	const { className, room, DropDownMenu, ...divProps } = props;

	const {
		room: { id, name, description, workspacesCount, roomType, tags, images, isActive },
		workspaces,
	} = room;

	// * Rights
	const allowedToEditOffices = useRights(8);

	// * Conditions
	const isPartialBooking = roomType.bookingRule === 'PartialBooking';

	// * Render
	return (
		<div
			{...divProps}
			className={`${s.container} ${divProps.onClick && s.clickable} ${className}`}
		>
			<div className={s.info_wrapper}>
				<div className={s.name}>{name}</div>

				<div className={s.room_type}>{roomType.name}</div>

				<div className={s.workspace}>{workspacesCount || ' - '}</div>

				<div className={s.work_time}>{getTimeRangeString(roomType.startTime, roomType.endTime)}</div>
			</div>

			<div className={s.menu}>{allowedToEditOffices && DropDownMenu}</div>
		</div>
	);
};
