import React, { useState } from 'react';
import s from './FullBookingTable.module.scss';
import cn from 'classnames';
import { ReactComponent as LockSVG } from 'src/shared/assets/svg/lock-locked.svg';
import { TimeInterval } from 'src/shared/lib/date/types';
import { injectTimeToDate, isDateTheSame, isoStringToTuple, isPastDate, toWeekDateString, tupleToIsoString } from 'src/shared/lib/date';
import { BookingItem } from 'src/pages/booking/types';
import { useComponentDimensions } from 'src/shared/hooks/useComponentDimensions';
import { FullBookingMC, SelectedCellInfo } from 'src/pages/booking/ui/BookingRoomPage/FullBookingTable/FullBookingMC/FullBookingMC';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { UserInfo } from 'src/app/redux/state/user/user/types';
import { GetOfficeRES } from 'src/app/redux/state/office/office/types';
import { GetRoomRES } from 'src/app/redux/state/office/room/types';
import { CancelBookingInfo, CancelUserBookingMC } from 'src/pages/booking/ui/_modals/CancelUserBookingMC/CancelUserBookingMC';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { ForbidFullBookingMC } from 'src/pages/booking/ui/BookingRoomPage/FullBookingTable/ForbidFullBookingMC/ForbidFullBookingMC';
import { DateTime } from 'luxon';
import { useRights } from 'src/shared/hooks/useRights';
import { createTimeList } from 'src/pages/booking/lib';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	userInfo: UserInfo;
	setSelectedRoomId: (roomId: string) => void;
	officeData: GetOfficeRES;
	roomData: GetRoomRES;
	bookingInterval: TimeInterval;
	selectedIntervals: BookingItem[];
	selectedDate: Date;
	noPrevious?: boolean;
}

export const FullBookingTable: React.FC<Props> = props => {
	const {
		userInfo,
		setSelectedRoomId,
		officeData,
		roomData, // for cancel modal only.
		bookingInterval, // Office interval
		selectedIntervals,
		selectedDate,
		noPrevious = false,
	} = props;

	const isAdmin = useRights();

	// * Styles
	const { dimensions, targetRef } = useComponentDimensions();

	const FIRST_CELL_WIDTH = 200;
	const CELL_GAP_WIDTH = 2;
	const cellWidth = dimensions.width;

	const firstCellStyles = {
		width: FIRST_CELL_WIDTH + 'px',
	};

	const minHour = bookingInterval.start[0];
	const minMinutes = bookingInterval.start[1];
	const maxHour = bookingInterval.end[0];
	const maxMinutes = bookingInterval.end[1];

	const getSelectedIntervalStyles = (interval?: TimeInterval, selectedDateIsAfterStart?: boolean) => {
		if (interval) {
			const { start, end } = interval;

			const selectedStartHour = Number.isNaN(end[0]) && selectedDateIsAfterStart ? minHour : start[0];
			const selectedStartMinutes = Number.isNaN(end[1]) && selectedDateIsAfterStart ? minMinutes : start[1];
			const selectedEndHour = Number.isNaN(end[0]) ? maxHour : end[0];
			const selectedEndMinutes = Number.isNaN(end[1]) ? maxMinutes : end[1];

			const cellsSelected = selectedEndHour - selectedStartHour;
			const gapSelectedWidth = (cellsSelected > 0 ? cellsSelected - 1 : 0) * CELL_GAP_WIDTH;
			const startPoint = selectedStartHour - minHour;

			const isMinHourSelected = selectedStartHour === minHour;
			const isMaxHourSelected = selectedEndHour === maxHour;

			const isMinTimeSelected = isMinHourSelected && selectedStartMinutes === minMinutes;
			const isMaxTimeSelected = isMaxHourSelected && selectedEndMinutes === maxMinutes;

			const shouldDisplayPartialStartCell = selectedStartMinutes !== 0 && !isMinTimeSelected;
			const shouldDisplayPartialEndCell = selectedEndMinutes !== 0 && !isMaxTimeSelected;

			const fullWidth = cellsSelected * cellWidth + gapSelectedWidth;
			const fullCellStartPoint = FIRST_CELL_WIDTH + startPoint * (cellWidth + CELL_GAP_WIDTH) + CELL_GAP_WIDTH;

			const startCellPart = isMinHourSelected ? (selectedStartMinutes - minMinutes) / (60 - minMinutes) : selectedStartMinutes / 60;
			const endCellPart = isMaxHourSelected ? selectedEndMinutes / (60 - (60 - maxMinutes)) : selectedEndMinutes / 60;

			return {
				display: selectedStartHour === selectedEndHour && selectedStartMinutes === selectedEndMinutes ? 'none' : 'block',
				width:
					fullWidth +
					(shouldDisplayPartialStartCell ? -(cellWidth * startCellPart) : 0) +
					(shouldDisplayPartialEndCell ? +(cellWidth * endCellPart) : 0) +
					(isMaxTimeSelected && selectedEndMinutes !== 0 ? +cellWidth : 0) +
					'px',
				left: fullCellStartPoint + (shouldDisplayPartialStartCell ? +(cellWidth * startCellPart) : 0) + 'px',
			};
		} else {
			return {};
		}
	};

	// * Table generation
	const timeList = createTimeList(bookingInterval);

	// * Is cell enabled
	const isCellEnabled = (time: string) => {
		if (!noPrevious && isPastDate(selectedDate)) {
			return false;
		} else if (isDateTheSame(selectedDate, new Date())) {
			const timePlusHour = DateTime.fromJSDate(injectTimeToDate(new Date(), time)).plus({ hour: 1 }).toJSDate();

			return new Date().getTime() <= timePlusHour.getTime();
		} else {
			return true;
		}
	};

	// * Create booking Modal
	// - Booking time select on empty cell click
	const [selectedCellInfo, setSelectedCellInfo] = useState<SelectedCellInfo | null>(null);

	const openCreateBookingModal = (cellInfo: Omit<SelectedCellInfo, 'unavailableIntervals'>) => {
		const { room } = cellInfo;

		const bookedIntervals: TimeInterval[] = selectedIntervals
			?.filter(interval => interval.rowId === room.id)
			.map(interval => {
				interval.selectedInterval;

				const {
					selectedInterval: { start, end },
					selectedDateIsAfterStart,
				} = interval;

				const selectedStartHour = Number.isNaN(end[0]) && selectedDateIsAfterStart ? minHour : start[0];
				const selectedStartMinutes = Number.isNaN(end[1]) && selectedDateIsAfterStart ? minMinutes : start[1];
				const selectedEndHour = Number.isNaN(end[0]) ? maxHour : end[0];
				const selectedEndMinutes = Number.isNaN(end[1]) ? maxMinutes : end[1];

				return {
					start: [selectedStartHour, selectedStartMinutes],
					end: [selectedEndHour, selectedEndMinutes],
				};
			});

		const unavailableStartInterval: TimeInterval = {
			start: isoStringToTuple('00:00:00'),
			end: isoStringToTuple(room.roomType.startTime),
		};

		const unavailableEndInterval: TimeInterval = {
			start: isoStringToTuple(room.roomType.endTime),
			end: isoStringToTuple('23:59:00'),
		};

		const unavailableIntervals = [...bookedIntervals, unavailableStartInterval, unavailableEndInterval];

		setSelectedCellInfo({
			...cellInfo,
			unavailableIntervals,
		});
	};

	const closeTimeSelectModal = () => setSelectedCellInfo(null);

	// * Cancel booking modal
	const [cancelBookingModalInfo, setCancelBookingModalInfo] = useState<CancelBookingInfo | null>(null);
	const onBookingCancelClick = (cancelBookingInfo: CancelBookingInfo) => {
		const bookingUserId = cancelBookingInfo.bookingItem.userId;
		const sameUser = bookingUserId === userInfo.user.id;
		(sameUser || isAdmin) && setCancelBookingModalInfo(cancelBookingInfo);
	};

	const closeBookingModal = () => setCancelBookingModalInfo(null);

	// * Forbid booking modal
	const [forbidBookingModalInfo, setForbidBookingModalInfo] = useState<SelectedCellInfo | null>(null);
	const onForbidBookingClick = (selectedCellInfo: SelectedCellInfo) => setForbidBookingModalInfo(selectedCellInfo);

	const closeForbidModal = () => setForbidBookingModalInfo(null);

	// * Tooltip
	const getTooltipText = (booking: BookingItem) => {
		const { selectedInterval, userName, isBan, startDate, comment } = booking;

		const timeIntervalText = `${tupleToIsoString(selectedInterval.start)} - ${Number.isNaN(selectedInterval.end[0]) ? String.fromCodePoint(8734) : tupleToIsoString(selectedInterval.end)}`;
		if (isBan) {
			return `Запрет бронирования. - ${toWeekDateString(new Date(startDate))} ${timeIntervalText}. ${comment}`;
		} else {
			return `${userName} - ${timeIntervalText}`;
		}
	};

	// * Render
	return (
		<>
			{/* * Modals */}
			{selectedCellInfo && (
				<ModalNewProto
					isOpen={!!selectedCellInfo}
					onClose={closeTimeSelectModal}
					width="s"
				>
					<FullBookingMC
						closeModal={closeTimeSelectModal}
						userInfo={userInfo}
						selectedCellInfo={selectedCellInfo}
						openForbidModal={onForbidBookingClick}
					/>
				</ModalNewProto>
			)}

			{cancelBookingModalInfo && (
				<ModalNewProto
					isOpen={!!cancelBookingModalInfo}
					onClose={closeBookingModal}
					width="s"
				>
					<CancelUserBookingMC
						{...cancelBookingModalInfo}
						closeModal={closeBookingModal}
					/>
				</ModalNewProto>
			)}

			{forbidBookingModalInfo && (
				<ModalNewProto
					isOpen={!!forbidBookingModalInfo}
					onClose={closeForbidModal}
					width="s"
				>
					<ForbidFullBookingMC
						forbidInfo={forbidBookingModalInfo}
						closeModal={closeForbidModal}
						userInfo={userInfo}
					/>
				</ModalNewProto>
			)}

			{/* * Table */}
			<div className={s.container}>
				<div className={s.first_row}>
					<div
						className={s.first_cell}
						style={firstCellStyles}
					>
						Помещение
					</div>
					{timeList.map(time => (
						<div
							key={time}
							className={s.innerCell}
							ref={targetRef}
						>
							{time}
						</div>
					))}
				</div>

				{[...officeData.rooms]
					.sort((a, b) => {
						return a.room.name.toLowerCase().localeCompare(b.room.name.toLowerCase());
					})
					.filter(room => room.room.roomType.bookingRule === 'FullBooking')
					.map(room => (
						<div
							key={room.room.id}
							className={s.row}
						>
							{/* * Selected user intervals */}
							{selectedIntervals
								?.filter(bookingItem => bookingItem.rowId === room.room.id)
								?.map(bookingItem => (
									<div
										key={bookingItem.rowId}
										className={cn(s.selected_interval, bookingItem.userId === userInfo.user.id && s.active_user, bookingItem.isBan && s.lock)}
										style={getSelectedIntervalStyles(bookingItem.selectedInterval, bookingItem.selectedDateIsAfterStart)}
										onClick={() =>
											onBookingCancelClick({
												bookingItem,
												address: officeData.office.address,
												roomName: room.room.name,
											})
										}
									>
										<Tooltip text={getTooltipText(bookingItem)}>
											<span>
												{bookingItem.isBan && <LockSVG />} {bookingItem.isBan ? bookingItem.comment : bookingItem.userName}
											</span>
										</Tooltip>
									</div>
								))}

							<div
								className={cn(s.first_cell, room.room.id === roomData.room.id && s.first_cell_active)}
								onClick={() => setSelectedRoomId(room.room.id)}
								style={firstCellStyles}
							>
								{room.room.name}
							</div>

							<div
								className={s.unavailable_interval}
								style={getSelectedIntervalStyles({
									start: isoStringToTuple(officeData.office.startTime),
									end: isoStringToTuple(room.room.roomType.startTime),
								})}
								onClick={e => e.stopPropagation()}
							/>

							<div
								className={s.unavailable_interval}
								style={getSelectedIntervalStyles({
									start: isoStringToTuple(room.room.roomType.endTime),
									end: isoStringToTuple(officeData.office.endTime),
								})}
								onClick={e => e.stopPropagation()}
							/>

							{timeList.map((time, index, array) => {
								const startTime = index === array.length - 1 ? `${time.slice(0, 2)}:00:00` : `${time}:00`;
								return (
									<div
										key={startTime}
										className={cn(s.innerCell, isCellEnabled(time) && s.innerCell_active)}
										onClick={() =>
											isCellEnabled(time) &&
											openCreateBookingModal({
												room: room.room,
												selectedDate,
												startTime,
											})
										}
									/>
								);
							})}
						</div>
					))}
			</div>
		</>
	);
};
