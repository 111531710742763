import React from 'react';
import { ModalsMenu } from '../ModalsMenu/ModalsMenu';
import s from './PositionCard.module.scss';

interface Props {
	id: string;
	name: string;
	isActive: boolean;
}

export const PositionCard: React.FC<Props> = props => {
	const { id, name, isActive } = props;

	// * Render
	return (
		<div className={s.container}>
			<div className={s.name}>{name}</div>

			<ModalsMenu
				id={id}
				isActive={isActive}
				isRoleMenu={false}
				name={name}
			/>
		</div>
	);
};
