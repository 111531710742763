import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { survey_serviceAPI } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg_icons/action/add.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ModalContent } from '../ModalContent/ModalContent';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	id: string;
	isEndedSurvey: boolean;
	isTemplate?: boolean;
	title?: string;
}

export const DeleteSurveyModal: React.FC<Props> = props => {
	const { id, title = '', isEndedSurvey, isTemplate = false } = props;

	// * Navigate
	const navigate = useNavigate();

	// * API
	const [patchSurveysSurveyid] = survey_serviceAPI.usePatchSurveysSurveyidMutation();

	// * Menu
	const DropDownItems = [
		{
			title: 'Создать шаблон',
			icon: <AddSVG />,
			isVisible: !isTemplate,
			action: () => navigate(`/surveys/template_form/${id}`),
		},
		{
			title: 'Редактировать',
			icon: <EditSVG />,
			isVisible: !isEndedSurvey,
			action: () => navigate(`/surveys/edit/${id}`),
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			isVisible: true,
			action: () => toggleDeleteModal(),
		},
	].filter(item => item.isVisible);

	// * Modal
	const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setIsShowDeleteModal(prevState => !prevState);

	const modalSubtitle = `Вы действительно хотите удалить опрос\n "${title}"?`;

	// * Delete survey
	const changeIsActiveSurvey = () => {
		patchSurveysSurveyid({
			surveyId: id,
			body: [
				{
					op: 'replace',
					value: false,
					path: '/IsActive',
				},
			],
		})
			.unwrap()
			.then(() => {
				toggleDeleteModal();
				navigate('/surveys');
			})
			.catch(error => console.log(error));
	};

	return (
		<>
			{isShowDeleteModal && (
				<ModalNewProto
					isOpen={isShowDeleteModal}
					onClose={toggleDeleteModal}
				>
					<ModalContent
						title="Удалить опрос"
						subtitle={modalSubtitle}
						buttonText="Удалить"
						action={changeIsActiveSurvey}
						onClose={toggleDeleteModal}
					/>
				</ModalNewProto>
			)}

			<DropDownMenu items={DropDownItems} />
		</>
	);
};
