import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import React, { useState } from 'react';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { useRights } from 'src/shared/hooks/useRights';
import { Button } from 'src/shared/ui/_buttons/Button';
import { UsersTabs } from 'src/pages/UsersPage/ui/UsersTabs/UsersTabs';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Form } from '../Form/Form';

export const UsersPage = () => {
	// * Rights
	const isAdmin = useRights();

	// * Modal
	const [isVisible, setIsVisible] = useState(false);
	const toggleVisible = () => setIsVisible(prevState => !prevState);

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Сотрудники"
				buttons={
					isAdmin
						? [
								<React.Fragment key="0">
									<Button
										variant="primary"
										onClick={toggleVisible}
									>
										Добавить сотрудника
									</Button>
								</React.Fragment>,
							]
						: []
				}
			/>

			{isVisible && (
				<ModalNewProto
					isOpen={isVisible}
					onClose={toggleVisible}
				>
					<Form onCloseModal={toggleVisible} />
				</ModalNewProto>
			)}

			<UsersTabs />
		</PageDecorator>
	);
};
