import { FC, ReactNode } from 'react';
import { ReactComponent as RightSVG } from 'src/shared/assets/svg_icons/arrows/right_1.svg';
import { Heading } from 'src/shared/ui/Heading';
import { Button } from 'src/shared/ui/_buttons/Button';
import s from './CardWrapper.module.scss';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

interface Props {
	title: string;
	children: ReactNode;
	path?: string;
	fullHeight?: boolean;
}

export const CardWrapper: FC<Props> = props => {
	const { title, path, fullHeight, children } = props;

	// * Routing
	const navigate = useNavigate();

	// * Render
	return (
		<div className={cn(s.container, !fullHeight && s.full_height)}>
			<div className={s.header}>
				<Heading level={2}>{title}</Heading>

				{path && (
					<Button
						RightIcon={<RightSVG />}
						variant="tertiary"
						onClick={() => navigate(path)}
					>
						Перейти
					</Button>
				)}
			</div>

			{children}
		</div>
	);
};
