import React from 'react';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import { Button } from 'src/shared/ui/_buttons/Button';
import s from './ArchiveModal.module.scss';

interface Props {
	isActive: boolean;
	isRole: boolean;
	id: string;
	name: string;

	onCloseModal: () => void;
}

export const ArchiveModal: React.FC<Props> = props => {
	const {
		isActive,
		isRole,
		id,
		name,

		onCloseModal,
	} = props;

	// * API
	const [editRoleStatus, { isLoading: isLoading1 }] = rights_serviceAPI.useEditRoleStatusMutation();
	const [editPosition, { isLoading: isLoading2 }] = position_serviceAPI.useEditPositionMutation();

	// * Actions
	const onArchive = () => {
		if (isRole) {
			editRoleStatus({
				roleId: id,
				isActive: !isActive,
			})
				.unwrap()
				.then(() => onCloseModal())
				.catch(err => console.log(err));

			return null;
		}

		editPosition({
			positionId: id,
			editPositionRequest: [
				{
					op: 'replace',
					path: '/isactive',
					value: !isActive,
				},
			],
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<div className={s.container}>
			<h2>
				{isActive ? 'Удаление' : 'Восстановление'} {isRole ? 'роли' : 'должности'}
			</h2>

			<div className={s.description}>
				{isActive ? 'Вы действительно хотите удалить ' : 'Вы действительно хотите восстановить '}
				{isRole ? 'роль ' : 'должность '}
				<br />
				{`"${name}"?`}
			</div>

			<div className={s.buttons}>
				<Button
					wide
					variant="tertiary"
					onClick={onCloseModal}
				>
					Отменить
				</Button>
				<Button
					wide
					variant="primary"
					isLoading={isLoading1 || isLoading2}
					onClick={onArchive}
				>
					{isActive ? 'Да, удалить' : 'Да, восстановить'}
				</Button>
			</div>
		</div>
	);
};
