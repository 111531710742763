import { DateTime } from 'luxon';

export const dateToFullRuDataNameString = (date: Date) => {
	return DateTime.fromJSDate(date)
		.toLocaleString(
			{
				year: 'numeric',
				month: 'long',
				day: '2-digit',
			},
			{ locale: 'ru-RU' },
		)
		.slice(0, -2); // ! Убирать .г который почему-то добавляется к году когда месяц short или long.
};
