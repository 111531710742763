import { FC } from 'react';
import { ImageInfo } from 'src/app/redux/queries/user-service/types/types';
import { Avatar } from '../Avatar/Avatar';
import s from './Profile.module.scss';

interface Props {
	userId: string;
	avatar?: ImageInfo;
	userName: string;
}

export const Profile: FC<Props> = props => {
	const {
		userId, //
		avatar,
		userName,
	} = props;

	// * Render
	return (
		<div className={s.container}>
			<Avatar
				userId={userId}
				name={userName}
				avatarId={avatar?.parentId}
				parentAvatarId={avatar?.id}
			/>

			<div className={s.info_name}>
				<h4>{userName}</h4>
			</div>
		</div>
	);
};
