import { useState } from 'react';
import { Users } from 'src/pages/UsersPage/ui/Users/Users';
import { useRights } from 'src/shared/hooks/useRights';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import s from './UsersTabs.module.scss';

export const UsersTabs = () => {
	// * Rights
	const hasUsersRights = useRights(1);

	// * Tabs
	const [activeTabId, setActiveTabId] = useState<'active' | 'inactive' | 'archive'>('active');

	// * Render
	return (
		<div>
			{hasUsersRights && (
				<LineTabs
					className={s.lineTabs}
					tabs={[
						{
							id: 'active',
							name: 'Активные',
						},
						{
							id: 'inactive',
							name: 'Неактивированные',
						},
						{
							id: 'archive',
							name: 'В архиве',
						},
					]}
					width="fit"
					activeTabId={activeTabId}
					setActiveTabId={(id: string) => setActiveTabId(id as 'active' | 'inactive' | 'archive')}
				/>
			)}

			{activeTabId === 'active' && (
				<Users
					isActive
					isPending={false}
				/>
			)}

			{activeTabId === 'inactive' && (
				<Users
					isActive={false}
					isPending
				/>
			)}

			{activeTabId === 'archive' && (
				<Users
					isActive={false}
					isPending={false}
				/>
			)}
		</div>
	);
};
