import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { LeaveTimeTableDataItem } from '../_types';
import s from '../Log.module.scss';

const columnHelper = createColumnHelper<LeaveTimeTableDataItem>();

const getCellContext = (context: CellContext<any, unknown>) => {
	if (context.row.original.isActive === false) {
		return {
			style: { color: 'var(--color-black-3)' },
		};
	}
};

export const COLUMNS_LEAVE_TIME = [
	columnHelper.accessor('leaveType', {
		header: 'ТИП ОТСУТСТВИЯ',
		cell: info => info.getValue(),
		meta: {
			getCellContext,
			style: {
				width: '30%',
			},
		},
	}),
	columnHelper.accessor('userHours', {
		header: 'ВНЕСЕННЫЕ ЧАСЫ  ',
		cell: info => {
			const { current, total } = info.getValue();
			return (
				<>
					<div>
						{current}
						{current !== total && <span className={s.users_hours}>{` (${total})`}</span>}
					</div>
				</>
			);
		},
		meta: {
			getCellContext,
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('leaveDates', {
		header: 'ДАТЫ ОТСУТСТВИЯ',
		cell: info => info.getValue() ?? '-',
		meta: {
			getCellContext,
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('description', {
		header: 'КОММЕНТАРИЙ',
		cell: info => {
			const value = info.getValue();
			return value && value.toString().length > 0 ? info.getValue() : '-';
		},
		meta: {
			getCellContext,
			style: {
				width: '30%',
			},
		},
	}),
	columnHelper.accessor('extra', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),
];
