import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { routerPath } from 'src/app/router/paths';
import { ModalDelete } from 'src/pages/UserCardPage/ui/DeleteModal/DeleteModal';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg_icons/file/unarchive.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';

interface Props {
	eventId: string;
	isActive: boolean;
}

export const EventDropDownMenu: FC<Props> = ({ eventId, isActive }) => {
	// * Routing
	const navigate = useNavigate();

	const [confirmModal, setConfirmModal] = useState(false);
	const toggleConfirmModal = () => setConfirmModal(prevState => !prevState);

	// * API
	const [editEvent, { isLoading }] = event_serviceAPI.usePatchEventEditMutation();

	// * Actions
	const navigateToEditEvent = () => navigate(`${routerPath.calendar.page}${routerPath.calendar.editEvent}/${eventId}`); //TODO: переводить на страницу редактирования

	const archiveEvent = (activeValue: boolean) => {
		editEvent({
			eventId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: !activeValue,
				},
			],
		})
			.unwrap()
			.then(() => {
				toggleConfirmModal();
				navigate('/event-calendar');
			})
			.catch(err => console.log(err));
	};

	// - Items
	const dropDownItems = [
		{
			isVisible: true,
			icon: <EditSVG />,
			title: 'Редактировать',
			action: navigateToEditEvent,
		},
		{
			isVisible: isActive,
			icon: <ArchiveSVG />,
			title: 'Архивировать',
			action: toggleConfirmModal,
		},
		{
			isVisible: !isActive,
			icon: <UnArchiveSVG />,
			title: 'Восстановить',
			action: toggleConfirmModal,
		},
	].filter(item => item.isVisible);

	// * Render
	return (
		<>
			<ModalDelete
				isOpen={confirmModal}
				onClose={toggleConfirmModal}
				title={isActive ? 'Архивация события' : 'Восстановление события'}
				description={isActive ? 'Вы действительно хотите архивировать это событие?' : 'Вы действительно хотите восстановить из архива это событие?'}
				primaryText={isActive ? 'Архивировать' : 'Восстановить'}
				primaryOnClick={() => archiveEvent(isActive)}
				primaryIsLoading={isLoading}
				secondaryOnClick={toggleConfirmModal}
				secondaryIsLoading={isLoading}
			/>

			<DropDownMenu items={dropDownItems} />
		</>
	);
};
