import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostFeedbacksByFeedbackIdFilesMutation, usePostFeedbacksMutation } from 'src/app/redux/queries/gateway-service/gateway_serviceAPI';
import { UserInfo } from 'src/app/redux/state/user/user/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair/ButtonPair';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { IMAGE_MIMES, VIDEO_MIMES } from 'src/shared/consts/mime';
import { getAppEnv } from 'src/shared/lib/app';
import { imageContentToGalleyFile } from 'src/shared/lib/file';
import { galleyFileToBase64 } from 'src/shared/lib/file/galleyFileToBase64/galleyFileToBase64';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { Heading } from 'src/shared/ui/Heading';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { createImageInfoFromFile } from 'src/shared/ui/_galleries/Gallery/_utils';
import { MediaList } from 'src/shared/ui/_galleries/MediaList';
import { MediaItem } from 'src/shared/ui/_galleries/MediaList/types';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { MAX_FILES } from '../../../const';
import { feedbackCategoryOptions } from '../../../meta/reportCategory';
import { actionsBugReportModal } from '../../../slice';
import { FormValues } from '../../../types';
import s from './CreateBugReport.module.scss';

interface Props {
	closeModal: () => void; // ! Так же очищает форму.
	toggleSuccessModal: () => void;
}

export const CreateBugReport: React.FC<Props> = props => {
	const {
		closeModal, //
		toggleSuccessModal,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;
	const { setCategory, setComment, setImages } = actionsBugReportModal;

	// * Selectors
	const userInfo = useAppSelector(state => state.user_service.user.userInfo as UserInfo);
	const category = useAppSelector(state => state.bugReportModal.category);
	const comment = useAppSelector(state => state.bugReportModal.comment);
	const media = useAppSelector(state => state.bugReportModal.media);

	// * API
	const [createFeedback, { isLoading: createFeedbackIL }] = usePostFeedbacksMutation();
	const [createFeedbackFile, { isLoading: createFileIL }] = usePostFeedbacksByFeedbackIdFilesMutation();

	const isLoading = createFeedbackIL || createFileIL;

	// * Form
	const defaultValues: FormValues = {
		category,
		comment,
		media,
	};

	const schema = yup.object().shape({
		category: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable()
			.required('Выберите категорию'),
		comment: yup.string().nullable().max(1000).required('Заполните поле'),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setValue } = formMethods;

	const formCategory = watch('category');
	const formComment = watch('comment');
	const formMedia = watch('media');

	useEffect(() => {
		dispatch(setCategory(formCategory));
		dispatch(setComment(formComment));
		dispatch(setImages(formMedia));
	}, [formCategory, formComment, formMedia.length]);

	const onSubmit = (data: FormValues) => {
		const images: MediaItem[] = [];
		const videos: MediaItem[] = [];

		for (const item of data.media) {
			if (Object.values(IMAGE_MIMES).find(extensions => extensions.includes(item.extension)) ?? false) {
				images.push(item);
			} else {
				videos.push(item);
			}
		}

		const preparedImages = images.map(image => ({
			name: image.name,
			content: image.content,
			extension: image.extension,
		}));

		createFeedback({
			createFeedbackRequest: {
				type: data.category?.id,
				content: data.comment,
				feedbackImages: preparedImages,
				environment: getAppEnv(),
				user: {
					id: userInfo.user.id,
					imageId: userInfo.user.avatar.id ?? undefined,
					firstName: userInfo.user.firstName,
					middleName: userInfo.user.middleName ?? undefined,
					lastName: userInfo.user.lastName,
					isActive: userInfo.user.isActive,
					isPending: Boolean(userInfo.user.pendingInfo),
					email: undefined, // TODO email
				},
			},
		})
			.unwrap()
			.then(res => {
				const feedbackId = res.body;
				if (typeof feedbackId === 'string') {
					const promises: any[] = [];

					if (videos.length > 0) {
						const formData = new FormData();

						for (const video of videos) {
							formData.append(
								'uploadedFiles',
								imageContentToGalleyFile({
									...video,
									name: encodeURI(video.name),
								}),
							);
						}

						promises.push(
							createFeedbackFile({
								feedbackId,
								body: formData as any,
							}).unwrap(),
						);

						Promise.allSettled(promises)
							.then(values => {
								const errors = values.filter(value => value.status !== 'fulfilled');
								const hasErrors = errors.length > 0;

								if (hasErrors) {
									// TODO улучшить сообщение об ошибке.
									alert('Не все файлы были загружены');
								} else {
									toggleSuccessModal();
								}
							})
							.catch(err => console.log('Error: ', err));
					} else {
						toggleSuccessModal();
					}
				}
			})
			.catch(error => console.log(error));
	};

	// * Media
	const existedMedia = watch('media');

	const onDrop = async (acceptedFiles: File[]) => {
		const files = acceptedFiles.map(file =>
			Object.assign(file, {
				id: `new_${v4()}`,
				parentId: null,
				preview: URL.createObjectURL(file),
			}),
		);

		Promise.all(files.map(file => galleyFileToBase64(file)))
			// TODO: Type values
			.then((values: any) => {
				const images = createImageInfoFromFile(values);

				if (existedMedia.length + images.length <= MAX_FILES) {
					setValue('media', [...existedMedia, ...images]);
				} else {
					dispatch(addNotification({ type: 'error', message: `Превышено максимальное количество файлов. Максимум: ${MAX_FILES}` }));
				}
			});
	};

	const onDelete = (id: string) => {
		setValue(
			'media',
			existedMedia.filter(item => item.id !== id),
		);
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading
				level={2}
				marginBottom="m"
			>
				Обратная связь
			</Heading>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<div className={s.form_components}>
						<SelectSingleField
							className={s.category}
							name="category"
							label="Категория запроса"
							placeholder="Выберите категорию"
							options={feedbackCategoryOptions}
						/>

						<TextAreaField
							name="comment"
							label="Комментарий"
							placeholder="Введите комментарий"
							characterLimit={1000}
							size="small"
						/>

						<DropZone
							iconType="media"
							onDrop={onDrop}
							accept={{
								...IMAGE_MIMES,
								...VIDEO_MIMES,
							}}
							maxFiles={MAX_FILES}
						/>

						<MediaList
							className={s.images}
							images={existedMedia}
							onDelete={onDelete}
						/>
					</div>

					<ButtonPair
						primaryText="Отправить"
						primaryIsLoading={isLoading}
						secondaryText="Отменить"
						secondaryOnClick={closeModal}
						secondaryIsLoading={isLoading}
					/>
				</FormProvider>
			</form>
		</div>
	);
};
