export type EventKeys = 'start' | 'end' | 'participants' | 'place';

export const EventInfoFields: Array<{
	key: EventKeys;
	text: string;
}> = [
	{
		key: 'start',
		text: 'Начало',
	},
	{
		key: 'end',
		text: 'Окончание',
	},
	{
		key: 'participants',
		text: 'Участники',
	},
	{
		key: 'place',
		text: 'Место проведения',
	},
];
