import cn from 'classnames';
import React, { HTMLAttributes, useState } from 'react';
import { useSelector } from 'react-redux';
import { image_serviceAPI } from 'src/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { ImageSource } from 'src/app/redux/state/image/image/types';
import { UserAvatar } from 'src/app/redux/state/user/user/types';
import { RootState } from 'src/app/redux/store';
import { ReactComponent as AddImageSVG } from 'src/shared/assets/svg/action/add-image.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { getTwoCharsAcronym } from 'src/shared/lib/string';
import { createGalleyFileFromImageInfo } from 'src/shared/ui/_galleries/Gallery/_utils';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { AvatarUpdate } from '../AvatarUpdate/AvatarUpdate';
import s from './Avatar.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
	userId: string;

	name: string;
	userAvatar?: UserAvatar;

	avatarId?: string | null;
	parentAvatarId?: string | null;
	imageSource?: ImageSource;
}

export const Avatar: React.FC<Props> = props => {
	const { userId, className, name, userAvatar, avatarId, parentAvatarId, imageSource = 'User', ...divProps } = props;

	// * Rights
	const personalInfo = useSelector((state: RootState) => state.user_service.user.userInfo);
	const isPersonalCard = personalInfo?.user.id === userId;

	const hasUsersRights = useRights(1);

	const hasRights = hasUsersRights || isPersonalCard;

	// * API
	const { currentData: avatarInfo } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: avatarId as string,
				source: imageSource,
			},
		},
		{ skip: !avatarId || !!userAvatar },
	);

	const avatar = userAvatar || avatarInfo?.body;

	const avatarImage =
		avatar &&
		createGalleyFileFromImageInfo({
			...avatar,
			name: avatar.name || 'avatar.jpg',
		}).preview;

	// - Hover
	const [isHover, setIsHover] = useState(false);

	// - Modal
	const [showModal, setShowModal] = useState(false);
	const toggleShowModal = () => setShowModal(prevState => !prevState);

	// * Render
	return (
		<>
			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleShowModal}
				>
					<AvatarUpdate
						userId={userId}
						avatarId={avatarId || ''}
						parentAvatarId={parentAvatarId || ''}
						avatar={avatarImage}
						name={name}
						onCloseModal={toggleShowModal}
					/>
				</ModalNewProto>
			)}

			<div
				className={`${s.Avatar_container} ${s.Avatar_size} ${className}`}
				{...divProps}
				onMouseEnter={() => hasRights && setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				{avatarImage ? (
					<>
						<img
							className={s.Avatar_size}
							src={avatarImage}
							alt={name}
						/>
					</>
				) : (
					<span className={s.Avatar_name}>{getTwoCharsAcronym(name)}</span>
				)}

				<label
					className={cn(s.update, isHover && s.show_update)}
					onClick={toggleShowModal}
				>
					<AddImageSVG />
				</label>
			</div>
		</>
	);
};
