import React, { ReactNode } from 'react';
import s from './OfficeInfoItem.module.scss';

type Props = {
	Icon: ReactNode;
	text: string;
	info: string;
};

export const OfficeInfoItem: React.FC<Props> = props => {
	const { Icon, text, info } = props;

	// * Render
	return (
		<div className={s.container}>
			<span className={s.icon}>{Icon}</span>
			<span className={s.text}>{text}</span>
			<span className={s.info}>{info}</span>
		</div>
	);
};
