import { useState, useEffect } from 'react';
import { useLazyGetDepartmentGetQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { DepartmentResponse } from 'src/app/redux/queries/department-service/types/resTypes';

export const useGenerateDepartments = (departmentId: string) => {
	const [getDepartment, { isLoading: isLoadingDepartment }] = useLazyGetDepartmentGetQuery();

	const [availableDepartments, setAvailableDepartments] = useState<DepartmentResponse[]>([]);

	useEffect(() => {
		getDepartment({
			departmentid: departmentId,
		})
			.unwrap()
			.then(res => {
				// ! parentId это доп департамент
				if (res?.body?.parentId) {
					getDepartment({
						departmentid: res?.body?.parentId,
					})
						.unwrap()
						.then(resParent => {
							res?.body && resParent?.body && setAvailableDepartments([res.body, resParent.body]);
						})
						.catch(error => console.log(error));
				} else {
					res?.body && setAvailableDepartments([res.body]);
				}
			})
			.catch(error => console.log(error));
	}, []);

	return { availableDepartments, isLoadingDepartment };
};
