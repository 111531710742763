import { FC } from 'react';
import { DepartmentInfo } from 'src/app/redux/queries/department-service/types/types';
import { DepartmentCard } from '../DepartmentCard/DepartmentCard';
import s from './DepartmentsCardsList.module.scss';
import { getDirectorName } from './lib/getDirectorName';

interface Props {
	departments: DepartmentInfo[];
}

export const DepartmentsCardsList: FC<Props> = props => {
	const { departments } = props;

	// * Render
	return (
		<div className={s.container}>
			{[...departments]
				.sort((a, b) => b.name.toLocaleLowerCase().localeCompare(a.name.toLocaleLowerCase()))
				.sort(x => (x.isActive ? -1 : 1)) // Сортировка по isActive. Сначала Активные
				.map(({ id, name, shortName, director, isActive }) => (
					<DepartmentCard
						key={id}
						id={id}
						name={name}
						shortName={shortName}
						directorsName={getDirectorName(director)}
						isActive={isActive}
					/>
				))}
		</div>
	);
};
