import { FC } from 'react';
import { CommentInfo } from 'src/app/redux/queries/event-service/types/types';
import { CommentContext, CommentsContextProvider } from '../../context/useCommentsContext';
import { CommentCreate } from '../CommentCreate/CommentCreate';
import { CommentList } from '../CommentList/CommentList';
import s from './Comments.module.scss';

interface Props extends CommentContext {
	comments: CommentInfo[];
}

export const Comments: FC<Props> = props => {
	const { currentUser, isLoading, comments, createAction, editAction, deleteAction } = props;

	// * Render
	return (
		<CommentsContextProvider
			currentUser={currentUser}
			isLoading={isLoading}
			createAction={createAction}
			editAction={editAction}
			deleteAction={deleteAction}
		>
			<div className={s.container}>
				<CommentCreate />

				<CommentList comments={comments} />
			</div>
		</CommentsContextProvider>
	);
};
