import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import s from './IconSquareButton.module.scss';

type Props = DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
	Icon: ReactNode;
	size: 'small' | 'large';
};

export const IconSquareButton: React.FC<Props> = props => {
	const {
		children, // Ignore
		className,
		Icon,
		size,
		onClick,
		...buttonProps
	} = props;

	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		if (onClick) {
			onClick(event);
		}
	};

	// * Render
	return (
		<button
			className={`${s.container} ${className}`}
			style={{
				width: size === 'small' ? '2rem' : '3rem',
				height: size === 'small' ? '2rem' : '3rem',
				borderRadius: size === 'small' ? '.75rem' : '1rem',
				padding: size === 'small' ? '.625rem' : '.75rem',
			}}
			{...buttonProps}
			onClick={onClickHandler}
		>
			{Icon}
		</button>
	);
};
