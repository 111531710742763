import { DateTime } from 'luxon';

// Date => [17.07 пн, 18.07 вт, 19.07 ср...]
export const createDayList = (selectedDate: Date) => {
	const firstWeekDay = DateTime.fromJSDate(selectedDate).startOf('week');
	return Array.from(Array(7).keys()).map(index => {
		const day = firstWeekDay.plus({ day: index });
		return {
			name: `${day.toLocaleString({ day: '2-digit', month: '2-digit' })} ${day.weekdayShort}`,
			dateLegacy: day.toJSDate(),
		};
	});
};
