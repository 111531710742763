import React from 'react';
import s from './ChipSelectList.module.scss';
import { InputLabel } from '../../_inputs/_shared/InputLabel/InputLabel';

export interface Option {
	id: string;
	name: string;
}

interface Props {
	label?: string;
	options: Option[];
	selectedOptions: Option[];
	setSelectedOptions: (options: Option[]) => void;
	required?: boolean;
}

export const ChipSelectList: React.FC<Props> = props => {
	const { label, options, selectedOptions, setSelectedOptions, required } = props;

	// * Render
	return (
		<div className={s.container}>
			<InputLabel
				label={label}
				required={required}
			/>

			<div className={s.options}>
				{options.map(option => {
					const selectedOption = selectedOptions.find(selected => selected.id === option.id);

					return (
						<div
							key={option.id}
							className={`${s.chip} ${selectedOption && s.chip_selected}`}
							onClick={() => setSelectedOptions(selectedOption ? selectedOptions.filter(selectedOption => selectedOption.id !== option.id) : [...selectedOptions, option])}
						>
							{option.name}
						</div>
					);
				})}
			</div>
		</div>
	);
};
