import { PublicRubricData, RubricData } from 'src/app/redux/queries/wiki-service/types/types';

export const getFilledRubrics = (rubrics: RubricData[] | PublicRubricData[]) => {
	const filledRubrics = rubrics.filter(rubric => {
		const children = rubric.children.filter(subRubric => subRubric.articles.length > 0);

		return rubric.articles.length > 0 || children.length > 0;
	});

	return filledRubrics;
};
