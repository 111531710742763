import { useState } from 'react';

export const useNameErrors = () => {
	const MAX_CHARS = 100; // так же есть в CreateSubRubricMC

	const nameMissingErrorText = 'Введите название';
	const [nameMissing, setArticleNameMissing] = useState<null | typeof nameMissingErrorText>(null);

	const nameTooLongErrorText = `Название не может быть больше ${MAX_CHARS}`;
	const [nameTooLong, setArticleNameTooLong] = useState<null | typeof nameTooLongErrorText>(null);

	const handleNameErrors = (value: string) => {
		nameMissing && value && setArticleNameMissing(null);
		!value && setArticleNameMissing(nameMissingErrorText);

		nameTooLong && value.length < MAX_CHARS && setArticleNameTooLong(null);
		value.length > MAX_CHARS && setArticleNameTooLong(nameTooLongErrorText);
	};

	const clearErrors = () => {
		setArticleNameMissing(null);
		setArticleNameTooLong(null);
	};

	const nameErrors = nameMissing || nameTooLong;

	return {
		nameErrors,
		handleNameErrors,
		clearErrors,
	};
};
