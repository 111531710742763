import React, { useState } from 'react';
import { PublicRubricData, RubricData } from 'src/app/redux/queries/wiki-service/types/types';
import { CollapseArrows } from 'src/shared/ui/CollapseArrows/CollapseArrows';
import { ArticleTab } from '../../../../_common_components/tabs/ArticleTab/ArticleTab';
import { SubRubricTab } from '../../../../_common_components/tabs/SubRubricTab/SubRubricTab';
import s from '../Menu/Menu.module.scss';

interface Props {
	isPrivate: boolean;
	rubric: RubricData | PublicRubricData;
	hasRights: boolean;
	activeArticleId: string;
	activeRubricId: string;
	activeMainRubric?: string;
}

export const MenuItem: React.FC<Props> = props => {
	const { rubric, activeRubricId, activeArticleId, activeMainRubric, isPrivate, hasRights } = props;

	const articles = rubric.articles;
	const subRubrics = rubric.children.filter(subRubric => subRubric.articles.length > 0);

	// - Set active menu item
	const [collapsed, setCollapsed] = useState(activeMainRubric === rubric.id);
	const toggleMenuContent = () => setCollapsed(prevState => !prevState);

	const isActive = (articleId: string) => {
		return articleId === activeArticleId;
	};

	const isOpen = (subRubricId: string) => {
		return subRubricId === activeRubricId;
	};

	const f = ''.toLowerCase();

	// * Render
	return (
		<>
			<div
				className={s.menu_collapse}
				onClick={toggleMenuContent}
			>
				<CollapseArrows
					isOpen={collapsed}
					arrowType="empty"
					className={s.icon}
				/>

				<span className={s.name}>{rubric.name}</span>
			</div>

			{collapsed && (
				<>
					{subRubrics.length > 0 && (
						<div className={s.heading_wrapper}>
							{subRubrics.map(subRubric => (
								<SubRubricTab
									key={subRubric.position}
									styleType="menu"
									isPrivate={isPrivate}
									isOpen={isOpen(subRubric.id)}
									activeArticleId={activeArticleId}
									{...subRubric}
								/>
							))}
						</div>
					)}

					{articles.length > 0 && (
						<div className={s.heading_wrapper}>
							{articles.map(article => (
								<ArticleTab
									key={article.id}
									styleType="menu"
									active={isActive(article.id)}
									isPrivate={isPrivate}
									{...article}
								/>
							))}
						</div>
					)}
				</>
			)}
		</>
	);
};
