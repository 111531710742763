import React, { useEffect } from 'react';
import s from './CompanyDetailsPage.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { ReactComponent as ArrowLeft } from 'src/shared/assets/svg/navigation/Arrow_left_v3.svg';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DepartmentCardDetail } from './components/DepartmentDescription/DepartmentCardDetail';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';

export const CompanyDetailsPage: React.FC = () => {
	// * Queries
	const { data: allDepartmentsData } = company_structure_serviceAPI.useGetAllDepartmentsQuery(true);
	const allDepartments =
		allDepartmentsData?.body && allDepartmentsData.body.length > 0
			? allDepartmentsData.body
					.map(department => {
						return department.childDepartments ? [department, ...department.childDepartments] : department;
					})
					.flat()
			: [];

	// * All department's name
	const departmentsInfo = allDepartments?.map(departmentInfo => {
		const { department } = departmentInfo;
		return {
			id: department.id,
			name: department.name,
		};
	});

	// * Initialise
	const params: any = useParams();
	const departmentId: string = params.departmentId;

	const isRemovedUnit = !allDepartments.filter(item => item.department.id === departmentId).length;

	useEffect(() => {
		if (allDepartments.length > 0 && isRemovedUnit) {
			navigate(`/structure/details/${departmentsInfo[0].id}`);
		}
	}, [isRemovedUnit]);

	// * Navigation
	const navigate = useNavigate();

	const openActiveDepartment = (departmentId: string) => {
		navigate(`/structure/details/${departmentId}`);
	};

	const navigateBack = () => {
		navigate('/structure');
	};

	return (
		<PageDecorator>
			<div className={s.container}>
				<h1 className={s.title}>Структура компании</h1>

				<div className={s.main_block}>
					<div className={s.navigation}>
						<div className={s.navigation_back}>
							<Button
								variant="tertiary"
								LeftIcon={<ArrowLeft />}
								onClick={navigateBack}
							>
								Назад
							</Button>
						</div>

						<div className={s.company_list}>
							{departmentsInfo &&
								departmentsInfo.map(department => (
									<button
										key={department.name}
										className={`${s.company_name} ${departmentId === department.id ? s.active : ''}`}
										onClick={() => openActiveDepartment(department.id)}
									>
										{department.name}
									</button>
								))}
						</div>
					</div>

					<DepartmentCardDetail departmentId={departmentId} />
				</div>
			</div>
		</PageDecorator>
	);
};
