import React, { HTMLAttributes } from 'react';
import { ArticleData, PublicArticleData } from 'src/app/redux/queries/wiki-service/types/types';
import { useOpenArticle } from 'src/pages/wiki/pages/wiki/_hooks/useOpenArticle';
import { ReactComponent as ArticleSVG } from 'src/shared/assets/svg/articles/article.svg';
import stylesMain from './_ArticleTabInner__main.module.scss';
import stylesMenu from '../ArticleTab/_ArticleTab__menu.module.scss';

type Props = (ArticleData | PublicArticleData) &
	HTMLAttributes<HTMLButtonElement> & {
		styleType: 'main' | 'menu';
		isPrivate: boolean;
		active?: boolean;
	};

export const ArticleTabInner: React.FC<Props> = props => {
	const { className, id, name, styleType, isPrivate, active } = props;

	const { openArticle } = useOpenArticle({ articleId: id, isPrivate });

	const styles = styleType === 'main' ? stylesMain : stylesMenu;

	// * Render
	return (
		<div
			className={`${styles.container_inner} ${active ? styles.active : ''} ${className}`}
			onClick={openArticle}
		>
			<ArticleSVG className={styles.icon} />
			<span className={styles.name}>{name}</span>
		</div>
	);
};
