import React, { HTMLAttributes } from 'react';
import { ReactComponent as ArrowUpEmptySVG } from 'src/shared/assets/svg/navigation/Arrow_up_v1.svg';
import { ReactComponent as ArrowUpFilledSVG } from 'src/shared/assets/svg/navigation/Arrow_up_v2.svg';
import { ReactComponent as ArrowDownEmptySVG } from 'src/shared/assets/svg/navigation/Arrow_down_v1.svg';
import { ReactComponent as ArrowDownFilledSVG } from 'src/shared/assets/svg/navigation/Arrow_down_v2.svg';
import cn from 'classnames';
import s from './CollapseArrows.module.scss';

type Props = HTMLAttributes<HTMLOrSVGElement> & {
	isOpen: boolean;
	arrowType: 'filled' | 'empty';
	color?: 'primary' | 'secondary';
};

export const CollapseArrows: React.FC<Props> = props => {
	const {
		isOpen, //
		arrowType,
		color = 'secondary',
		className,
		...restProps
	} = props;

	// * Styles
	const arrowStyles = cn(s.arrow, s[`arrow__${color}`], className);

	const ArrowDown =
		arrowType === 'filled' ? (
			<ArrowDownFilledSVG
				{...restProps}
				className={arrowStyles}
			/>
		) : (
			<ArrowDownEmptySVG
				{...restProps}
				className={arrowStyles}
			/>
		);

	const ArrowUp =
		arrowType === 'filled' ? (
			<ArrowUpFilledSVG
				{...restProps}
				className={arrowStyles}
			/>
		) : (
			<ArrowUpEmptySVG
				{...restProps}
				className={arrowStyles}
			/>
		);

	return <>{isOpen ? ArrowUp : ArrowDown}</>;
};
