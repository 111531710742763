import { createColumnHelper } from '@tanstack/react-table';
import { ReactNode } from 'react';

export interface TableDataItem {
	avatar: ReactNode;
	fullName_position: ReactNode;
	role: string;
	extra: ReactNode;
}

const columnHelper = createColumnHelper<TableDataItem>();

export const COLUMNS = [
	columnHelper.accessor('avatar', {
		header: () => null,
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' }, // ! Работает как fit-content.
		},
	}),
	columnHelper.accessor('fullName_position', {
		header: 'ФИО',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('role', {
		header: 'РОЛЬ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('extra', {
		header: () => null,
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' }, // ! Работает как fit-content.
		},
	}),
];
