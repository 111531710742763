import React, { useEffect, useState } from 'react';
import s from './PartialBookingTable.module.scss';
import cn from 'classnames';
import { ReactComponent as LockSVG } from 'src/shared/assets/svg/lock-locked.svg';
import { dateToFullRuDateString, isDateTheSame, isLessThenMax, isoStringToTuple, toWeekDateString } from 'src/shared/lib/date';
import { createDayList } from 'src/pages/booking/lib';
import { UserInfo } from 'src/app/redux/state/user/user/types';
import { GetOfficeRES } from 'src/app/redux/state/office/office/types';
import { GetRoomRES } from 'src/app/redux/state/office/room/types';
import { useComponentDimensions } from 'src/shared/hooks/useComponentDimensions';
import { DateTime } from 'luxon';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { PartialBookingMC, SelectedCellInfoPartial } from 'src/pages/booking/ui/BookingRoomPage/PartialBookingTable/PartialBookingMC/PartialBookingMC';
import { BookingInfo } from 'src/app/redux/state/office/booking/types';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { CancelBookingInfo, CancelUserBookingMC } from 'src/pages/booking/ui/_modals/CancelUserBookingMC/CancelUserBookingMC';
import { ForbidPartialBookingMC } from 'src/pages/booking/ui/BookingRoomPage/PartialBookingTable/ForbidPartialBookingMC/ForbidPartialBookingMC';
import { useRights } from 'src/shared/hooks/useRights';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { office_serviceAPI } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { createFullName } from 'src/shared/lib/string';

interface Props {
	userInfo: UserInfo;
	officeData: GetOfficeRES;
	roomData: GetRoomRES;
	selectedDate: Date;
	allBookingInfo: BookingInfo[];

	selectedWorkSpaceId: string | null;
	setSelectedWorkSpaceId: (value: string | null) => void;
}

export const PartialBookingTable: React.FC<Props> = props => {
	const {
		userInfo,
		officeData,
		roomData,
		selectedDate,
		allBookingInfo,

		selectedWorkSpaceId,
		setSelectedWorkSpaceId,
	} = props;

	// * Rights
	const isAdmin = useRights();

	// * Table generation
	const dayList = createDayList(selectedDate);

	// * Styles
	const { dimensions, targetRef } = useComponentDimensions();

	const FIRST_CELL_WIDTH = 200;
	const CELL_GAP_WIDTH = 2;
	const cellWidth = dimensions.width;

	const firstCellStyles = {
		width: FIRST_CELL_WIDTH + 'px',
	};

	const getSelectedIntervalStyles = (startTime: string, endTime: string | null) => {
		let startIndex = 0;
		let endIndex = 0;

		dayList.forEach((day, index) => {
			const dayString = day.dateLegacy.toLocaleString('sv').split(' ').join('T');

			if (dayString === startTime) startIndex = index;
			if (dayString === endTime) endIndex = index;
		});

		// - Если промежуток входит в диапозон бронирования
		const isFilledPeriod = !!endTime && isLessThenMax(new Date(startTime), dayList[0].dateLegacy) && isLessThenMax(dayList[dayList.length - 1].dateLegacy, new Date(endTime));
		if (isFilledPeriod) {
			startIndex = 0;
			endIndex = dayList.length;
		}

		// - Если дата окончания выходит за видимый промежуток
		if (startIndex > 0 && endIndex === 0) endIndex = dayList.length;

		const isStartDayVisible = dayList.find(day => day.dateLegacy.toLocaleString('sv').split(' ').join('T') === startTime);
		const selectedDateIsAfterStart = !isStartDayVisible && new Date(startTime).getTime() < dayList[0].dateLegacy.getTime();

		const isEndlessBooking = endTime === null;
		const startToNullWidth = (6 - startIndex + 1) * cellWidth + (6 - startIndex) * CELL_GAP_WIDTH;
		const fullRowWidth = 7 * cellWidth + 6 * CELL_GAP_WIDTH;

		const getWidth = () => {
			if (isEndlessBooking) {
				return selectedDateIsAfterStart ? fullRowWidth : startToNullWidth;
			} else {
				const cellWidthWithoutGap = (endIndex - startIndex) * cellWidth;
				const countBetweenCeils = endIndex - startIndex;

				// - Если период бронирования больше 1 ячейки, считаем вместе с gap между ячейками
				return countBetweenCeils > 1 ? cellWidthWithoutGap + (countBetweenCeils - 1) * CELL_GAP_WIDTH : cellWidthWithoutGap;
			}
		};

		const getDisplay = () => {
			if (isEndlessBooking) {
				return selectedDateIsAfterStart || isStartDayVisible ? 'block' : 'none';
			}

			if (startIndex === endIndex) {
				return 'none';
			}

			return 'block';
		};

		return {
			display: getDisplay(),
			width: getWidth() + 'px',
			left: FIRST_CELL_WIDTH + startIndex * (cellWidth + CELL_GAP_WIDTH) + CELL_GAP_WIDTH + 'px',
		};
	};

	// * Clear data
	useEffect(() => {
		return () => {
			setSelectedWorkSpaceId(null);
		};
	}, []);

	// * Is cell enabled
	const isCellEnabled = (date: Date) => {
		const today = DateTime.fromJSDate(new Date()).startOf('day').toJSDate();
		const cellDate = DateTime.fromJSDate(date).startOf('day').toJSDate();
		return today.getTime() < cellDate.getTime();
	};

	// * Create booking modal
	const [selectedCellInfo, setSelectedCellInfo] = useState<SelectedCellInfoPartial | null>(null);

	const openCreateBookingModal = (cellInfo: Omit<SelectedCellInfoPartial, 'workspaceBookingList'>) => {
		const workspaceBookingList = allBookingInfo.filter(booking => booking.parentId === cellInfo.workspace.id);

		setSelectedCellInfo({
			...cellInfo,
			workspaceBookingList,
		});
	};

	const closeTimeSelectModal = () => setSelectedCellInfo(null);

	// * Cancel booking modal
	const [cancelBookingModalInfo, setCancelBookingModalInfo] = useState<CancelBookingInfo | null>(null);
	const onBookingCancelClick = (cancelBookingInfo: CancelBookingInfo) => {
		const bookingUserId = cancelBookingInfo.bookingItem.userId;
		const sameUser = bookingUserId === userInfo.user.id;
		(sameUser || isAdmin) && setCancelBookingModalInfo(cancelBookingInfo);
	};

	const closeBookingModal = () => setCancelBookingModalInfo(null);

	// * Forbid booking modal
	const [forbidBookingModalInfo, setForbidBookingModalInfo] = useState<SelectedCellInfoPartial | null>(null);
	const onForbidBookingClick = (selectedCellInfo: SelectedCellInfoPartial) => setForbidBookingModalInfo(selectedCellInfo);

	const closeForbidModal = () => setForbidBookingModalInfo(null);

	// * Tooltip
	const getTooltipText = (booking: BookingInfo) => {
		const { startTime, endTime, isBan, user, comment } = booking;

		const dateOfStart = new Date(startTime);
		const dateOfEnd = DateTime.fromJSDate(new Date(endTime)).minus({ day: 1 }).toJSDate();
		const isSameDate = isDateTheSame(dateOfStart, dateOfEnd);

		if (isBan) {
			const dateString = isSameDate ? dateToFullRuDateString(dateOfStart) : `${dateToFullRuDateString(dateOfStart)} - ${dateToFullRuDateString(dateOfEnd)}`;
			return `Запрет бронирования - ${endTime === null ? `${dateToFullRuDateString(dateOfStart)} - ${String.fromCodePoint(8734)}` : dateString}. ${comment}`;
		} else if (endTime === null) {
			return 'Рабочее место';
		} else {
			const dateString = isSameDate ? dateToFullRuDateString(dateOfStart) : `${dateToFullRuDateString(dateOfStart)} - ${dateToFullRuDateString(dateOfEnd)}`;
			return `${createFullName({ lastName: booking.user.lastName, firstName: booking.user.firstName, middleName: booking.user.middleName })} - ${dateString}`;
		}
	};

	// * Render
	return (
		<>
			{/* * Modals */}
			{selectedCellInfo && (
				<ModalNewProto
					isOpen={!!selectedCellInfo}
					onClose={closeTimeSelectModal}
					width="s"
				>
					<PartialBookingMC
						closeModal={closeTimeSelectModal}
						userInfo={userInfo}
						selectedCellInfo={selectedCellInfo}
						office={officeData.office}
						room={roomData.room}
						openForbidModal={onForbidBookingClick}
					/>
				</ModalNewProto>
			)}

			{cancelBookingModalInfo && (
				<ModalNewProto
					isOpen={!!cancelBookingModalInfo}
					onClose={closeBookingModal}
					width="s"
				>
					<CancelUserBookingMC
						{...cancelBookingModalInfo}
						closeModal={closeBookingModal}
					/>
				</ModalNewProto>
			)}

			{forbidBookingModalInfo && (
				<ModalNewProto
					isOpen={!!forbidBookingModalInfo}
					onClose={closeForbidModal}
					width="s"
				>
					<ForbidPartialBookingMC
						forbidInfo={forbidBookingModalInfo}
						closeModal={closeForbidModal}
						userInfo={userInfo}
					/>
				</ModalNewProto>
			)}

			{/* * Table */}
			<div className={s.container}>
				<div className={s.first_row}>
					<div
						className={s.first_cell}
						style={firstCellStyles}
					>
						Место
					</div>

					{dayList.map(day => (
						<div
							key={day.name}
							className={s.innerCell}
							ref={targetRef}
						>
							{day.name}
						</div>
					))}
				</div>

				{[...roomData.workspaces]
					.sort((a, b) => {
						return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
					})
					.map(workspace => (
						<div
							key={workspace.id}
							className={s.row}
						>
							{allBookingInfo
								.filter(booking => booking.parentId === workspace.id)
								.map(booking => (
									<div
										key={booking.id}
										className={cn(s.selected_interval, booking.user.id === userInfo.user.id && s.active_user, booking.isBan && s.lock)}
										style={getSelectedIntervalStyles(booking.startTime, booking.endTime)}
										onClick={() =>
											onBookingCancelClick({
												bookingItem: {
													userName: `${booking.user.lastName} ${booking.user.firstName}`,
													userId: booking.user.id,
													bookingId: booking.id,
													startDate: new Date(booking.startTime),
													endDate: new Date(booking.endTime),
													selectedInterval: {
														start: isoStringToTuple(roomData.room.roomType.startTime),
														end: isoStringToTuple(booking.endTime ? roomData.room.roomType.endTime : 'NaN:NaN'),
													},
													isBan: booking.isBan,
												},
												roomName: roomData.room.name,
												address: officeData.office.name,
												workspaceName: workspace.name,
											})
										}
									>
										<Tooltip text={getTooltipText(booking)}>
											<span>
												{booking.isBan && <LockSVG />} {booking.isBan ? booking.comment : createFullName({ lastName: booking.user.lastName, firstName: booking.user.firstName, middleName: booking.user.middleName })}
											</span>
										</Tooltip>
									</div>
								))}

							<div
								className={`${s.first_cell} ${workspace.id === selectedWorkSpaceId && s.first_cell_active}`}
								onClick={() => setSelectedWorkSpaceId(workspace.id)}
								style={firstCellStyles}
							>
								{workspace.name}
							</div>

							{dayList.map(day => (
								<div
									key={day.name}
									className={cn(s.innerCell, isCellEnabled(day.dateLegacy) && s.innerCell_active)}
									onClick={() =>
										isCellEnabled(day.dateLegacy) &&
										openCreateBookingModal({
											workspace,
											selectedDate: day.dateLegacy,
										})
									}
								/>
							))}
						</div>
					))}
			</div>
		</>
	);
};
