import { asyncActions as userAsync, slice as userSlice } from './user/slice';
import { asyncActions as communicationAsync, slice as communicationSlice } from './communication/slice';
import { asyncActions as genderAsync, slice as genderSlice } from './gender/slice';

export const userServiceActions = {
	user: {
		actions: userSlice.actions,
		async: userAsync,
	},
	communication: {
		actions: communicationSlice.actions,
		async: communicationAsync,
	},
	gender: {
		actions: genderSlice.actions,
		async: genderAsync,
	},
};
