import React from 'react';
import { ChipSelectList, Option } from '../_chips/ChipSelectList/ChipSelectList';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label?: string;
	options: Option[];
	required?: boolean;
}

export const ChipsField: React.FC<Props> = props => {
	const { name, label, options, required } = props;

	const { control, setValue } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<ChipSelectList
					label={label}
					options={options}
					selectedOptions={field.value}
					setSelectedOptions={options => setValue(field.name, options)}
					required={required}
				/>
			)}
		/>
	);
};
