import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { FormsCard } from '../FormsCard/ui/FormsCard';
import { InfoCards } from '../InfoCards/InfoCards';
import s from './TimeTrackingPage.module.scss';

export const TimeTrackingPage: FC = () => {
	const initialDate = DateTime.fromJSDate(new Date()).startOf('month').startOf('day').toJSDate();
	const [selectedDate, setSelectedDate] = useState(initialDate);
	const handleSelectedDate = (date: Date) => {
		setSelectedDate(DateTime.fromJSDate(date).toJSDate());
	};

	// * Render
	return (
		<PageDecorator>
			<PageTitle title="Учет времени" />

			<div className={s.container}>
				<FormsCard setSelectedDate={handleSelectedDate} />
				<InfoCards
					selectedDate={selectedDate}
					setSelectedDate={handleSelectedDate}
				/>
			</div>
		</PageDecorator>
	);
};
