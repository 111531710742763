import cn from 'classnames';
import { ReactComponent as WrapSVG } from 'src/shared/assets/svg_icons/action/decrease.svg';
import { ReactComponent as UnwrapSVG } from 'src/shared/assets/svg_icons/action/increase.svg';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { Portal } from 'src/shared/providers';
import { SizeProp } from 'src/shared/types/props';
import s from './ModalNewProto.module.scss';
import { Heading } from '../../Heading';

// TODO: Добавить проп позволяющий увеличить z-index, для модалок в модалках.

const ANIMATION_DELAY = 300;

interface Props {
	className?: string;
	children?: ReactNode;
	isOpen?: boolean;
	onClose?: () => void;
	maxWidth?: string;
	width?: SizeProp;
	collapsibleTitle?: string;
	isCollapsedControlled?: boolean;
	setIsCollapsedControlled?: (isCollapsed: boolean) => void;
	disableCloseOverlay?: boolean;
}

export const ModalNewProto: React.FC<Props> = props => {
	const {
		className, //
		children,
		isOpen,
		onClose,
		maxWidth = '90%',
		width,
		collapsibleTitle,
		isCollapsedControlled,
		setIsCollapsedControlled,
		disableCloseOverlay,
	} = props;

	const [isClosing, setIsClosing] = useState(false);
	const timeRef = useRef<ReturnType<typeof setTimeout>>();

	const closeHandler = useCallback(
		(event?: React.MouseEvent) => {
			event?.stopPropagation();

			if (onClose && disableCloseOverlay) {
				onClose();
				return;
			}

			if (onClose) {
				setIsClosing(true);
				timeRef.current = setTimeout(() => {
					onClose();
					setIsClosing(false);
				}, ANIMATION_DELAY);
			}
		},
		[onClose],
	);

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				closeHandler();
			}
		},
		[closeHandler],
	);

	const onContentClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	useEffect(() => {
		if (isOpen) {
			window.addEventListener('keydown', onKeyDown);
		}

		return () => {
			clearTimeout(timeRef.current);
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [isOpen, onKeyDown]);

	// * Collapse
	const [isCollapsed, setIsCollapsed] = useState(false);

	const collapsed = isCollapsedControlled || isCollapsed;
	const setCollapsed = setIsCollapsedControlled || setIsCollapsed;

	const toggleWrap = () => setCollapsed(!collapsed);

	// * Render
	return (
		<Portal element={document.getElementById('modal-portal') as HTMLElement}>
			<div
				className={cn(
					s.container, //
					collapsed && s.container_collapsed,
					isOpen && s.opened,
					isClosing && s.isClosing,
					className,
				)}
			>
				<div
					className={s.overlay}
					onClick={closeHandler}
				>
					<div
						className={cn(s.content, width && s[`content_${width}`])}
						style={{ maxWidth: collapsed ? 'none' : maxWidth }}
						onClick={onContentClick}
					>
						<div className={s.content__top}>
							{collapsibleTitle && collapsed && <Heading level={4}>{collapsibleTitle}</Heading>}

							<div className={s.content__top__icons}>
								{collapsibleTitle && <div className={s.collapsed__button}>{collapsed ? <UnwrapSVG onClick={toggleWrap} /> : <WrapSVG onClick={toggleWrap} />}</div>}

								{onClose && (
									<div
										className={s.close}
										onClick={onClose}
									>
										<CloseSVG />
									</div>
								)}
							</div>
						</div>

						<div className={s.content__inner}>{children}</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};
