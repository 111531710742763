import React, { useEffect, useState } from 'react';
import s from './CroppableAvatar.module.scss';
import { ReactComponent as PenSVG } from 'src/shared/assets/svg/pen.svg';
import AvatarPlaceholder from 'src/shared/assets/img/AvatarPlaceholder.png';
import { centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import { Modal } from '../_modals/Modal/ui/Modal/Modal';
import { CroppableAvatarModal } from './CroppableAvatarModal/CroppableAvatarModal';

interface CroppableAvatarProps {
	height: number;
	width: number;
	rounded?: boolean;
	getImage?: (src: string) => void;
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: 'px',
				width: 228,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

const aspect = 1;

export const CroppableAvatar: React.FC<CroppableAvatarProps> = ({ height, width, rounded, getImage }) => {
	const [crop, setCrop] = useState<Crop>();
	const [imgSrc, setImgSrc] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

	useEffect(() => {
		if (getImage) {
			getImage(imgSrc);
		}
	}, [imgSrc]);

	const toggleModal = () => setShowModal(prevState => !prevState);

	const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined);

			const reader = new FileReader();

			reader.addEventListener('load', () => {
				setImgSrc(reader.result?.toString() || '');
				setShowModal(true);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onSetCrop = (percent: PercentCrop) => setCrop(percent);

	const updateImage = (url: string) => setImgSrc(url);

	const onCompleteCrop = (pixel: PixelCrop) => setCompletedCrop(pixel);

	const onImageLoad = () => {
		if (aspect) {
			setCrop(centerAspectCrop(100, 100, aspect));
		}
	};

	const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
		const element = event.target as HTMLInputElement;
		element.value = '';
	};

	return (
		<div className={s.avatar__wrapper}>
			<div
				className={s.avatar}
				style={{
					borderRadius: rounded ? '50%' : '0%',
				}}
			>
				<img
					src={imgSrc ? imgSrc : AvatarPlaceholder}
					alt=""
					className={s.image}
				/>

				<Modal
					show={showModal}
					Content={
						<CroppableAvatarModal
							onSetCrop={onSetCrop}
							imageSrc={imgSrc}
							crop={crop}
							onImageLoad={onImageLoad}
							onComplete={onCompleteCrop}
							imageSize={{ height, width }}
							completedCrop={completedCrop}
							updateImage={updateImage}
							closeModal={() => setShowModal(false)}
							deleteImage={() => setImgSrc('')}
						/>
					}
					toggleModal={toggleModal}
				/>
			</div>
			<div className={s.cameraAdd}>
				<PenSVG className={s.camera} />
			</div>
			<label
				htmlFor="avatar"
				className={s.label}
			/>
			<input
				type="file"
				style={{ display: 'none' }}
				id="avatar"
				onChange={onSelectFile}
				onClick={onInputClick}
			/>
		</div>
	);
};
