import React, { useEffect } from 'react';
import { Gallery } from 'src/shared/ui/_galleries/Gallery';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useParams } from 'react-router-dom';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { useRights } from 'src/shared/hooks/useRights';
import { RequestStatus } from 'src/app/api/api_types';

export const GalleryContent: React.FC = () => {
	// * Router
	const params = useParams<{ officeId: string }>();
	const officeId: string = params.officeId as string;

	// * Actions
	const dispatch = useAppDispatch();
	const { getImages, createImage, removeImages } = officeServiceActions.image.async;
	const { clearImages } = officeServiceActions.image.actions;
	const { getImage } = imageServiceActions.image.async;

	const fetchImages = () => {
		dispatch(
			getImages({
				params: {
					entityId: officeId,
				},
				options: { entity: 'office' },
			}),
		);
	};

	// * Selectors
	const images = useAppSelector(state => state.office_service.image.officeImages);
	const status = useAppSelector(state => state.office_service.image.status);

	// * Initialize
	useEffect(() => {
		fetchImages();

		return () => {
			dispatch(clearImages('office'));
		};
	}, []);

	// * Rights
	const allowedToEdit = useRights();

	// * Render
	return (
		<Gallery
			images={images.filter(image => !image.isSchemaImage)}
			allowedToEdit={allowedToEdit}
			createImages={images => {
				if (images.length > 0) {
					images.forEach(image => {
						dispatch(
							createImage({
								payload: {
									entityId: officeId,
									name: image.name,
									content: image.content,
									extension: image.extension,
								},
							}),
						)
							.unwrap()
							.then(() => fetchImages());
					});
				}
			}}
			deleteImages={imagesIds => {
				dispatch(
					removeImages({
						payload: {
							entityId: officeId,
							imagesIds,
						},
					}),
				)
					.unwrap()
					.then(() => fetchImages())
					.catch(error => console.log(error));
			}}
			fetchImage={(imageId: string) =>
				getImage({
					params: {
						imageId,
						source: 'Office',
					},
				})
			}
			isLoading={status === RequestStatus.loading}
		/>
	);
};
