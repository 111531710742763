import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as ArrowLeftSVG } from 'src/shared/assets/svg/navigation/Arrow_left_v3.svg';
import { ReactComponent as ArrowLeftSVG } from 'src/shared/assets/svg/navigation/Arrow_left_v1.svg';
import s from './GoBack.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {
	title: string;
	route: string;
};

export const GoBack: React.FC<Props> = props => {
	const { className, title, route } = props;

	// * Router
	const navigate = useNavigate();

	// * User actions
	const goBack = () => {
		navigate(route);
	};

	// * render
	return (
		<button
			className={`${s.container} ${className}`}
			onClick={goBack}
		>
			<ArrowLeftSVG className={s.icon} />
			<h3 className={s.text}>{title}</h3>
		</button>
	);
};
