import React, { ComponentPropsWithoutRef } from 'react';
import s from 'src/pages/TimeTrackingPage/ui/TimeSelected/TimeSelected.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	hours: number;
}

export const TimeSelected: React.FC<Props> = props => {
	const {
		hours, //
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div
			{...divProps}
			className={className}
		>
			<div className={s.label}>Выбрано</div>

			<div className={s.hours}>
				<span className={s.hours_number}>{hours}</span>
				<span className={s.hours_postfix}>{' ч'}</span>
			</div>
		</div>
	);
};
