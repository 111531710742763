import { useEffect, useState } from 'react';
import { sortAscending } from 'src/pages/surveys/libs/sortAscending/sortAscending';
import { getIsPastDate } from 'src/shared/lib/date';
import { CategoryInfo, GroupInfo } from 'src/app/redux/queries/survey-service/types';
import { Button } from 'src/shared/ui/_buttons/Button';
import { getInitialStateSurveyGroups } from '../../utils/utils';
import s from './FillingOutForm.module.scss';
import { DisplayQuestion } from '../../pages/DisplaySurveyPage/DisplayQuestion/DisplayQuestion';
import { SurveyHeader } from '../../pages/DisplaySurveyPage/SurveyHeader/SurveyHeader';
import { Survey, SectionProps } from '../../pages/NewSurveyPage/_types';

interface Props<T> {
	id?: string;
	deadline?: string | null;
	category?: CategoryInfo | null;
	surveyInfo?: Pick<Survey, 'title' | 'description' | 'isAnonymous'>;
	groups?: T[];
	isTemplate?: boolean;

	notClickable?: boolean;

	errorQuestions?: string[];

	onSubmit?: (info: SectionProps[]) => void;
	isLoading: boolean;
}

export const FillingOutForm = (props: Props<SectionProps | GroupInfo>) => {
	const {
		id,
		deadline,
		category,
		surveyInfo,
		groups,
		isTemplate = false,
		// ? isPreview, не нужен достаточно isCompleted (переименован в notClickable)

		notClickable,

		errorQuestions,

		onSubmit,
		isLoading,
	} = props;

	const formInfo = {
		title: surveyInfo?.title || '',
		description: surveyInfo?.description || '',
		isAnonymous: !!surveyInfo?.isAnonymous,
		deadline: deadline || '',
		category: category || null,
		isActive: true,
		groups: groups || [],
	};

	const isEndedSurvey = getIsPastDate(deadline);

	// * Questions & answers
	const [fillingInfo, setFillingInfo] = useState<Array<SectionProps>>([]);

	useEffect(() => {
		const initialGroups: SectionProps[] = getInitialStateSurveyGroups('surveyId', formInfo.groups);
		setFillingInfo([...initialGroups]);
	}, [groups]);

	const sortedGroup = fillingInfo.sort((grp1, grp2) => sortAscending(grp1.position, grp2.position));

	const changeFillingInfo = (newInfo: Array<SectionProps>) => {
		setFillingInfo(newInfo);
	};

	// * Clear filling info
	const onClear = () => {
		const cleanForm = fillingInfo.map(group => {
			const { questions } = group;

			return {
				...group,
				questions: questions.map(question => {
					const { options, scaleOptions, gridOptions } = question;

					return {
						...question,
						options: options.filter(opt => !opt.isCustom).map(opt => ({ ...opt, usersAnswers: [] })),
						scaleOptions: scaleOptions.map(opt => ({ ...opt, usersAnswers: [] })),
						gridOptions: gridOptions.map(opt => ({ ...opt, usersAnswers: [] })),
					};
				}),
			};
		});

		setFillingInfo(cleanForm);
	};

	return (
		<div className={s.container}>
			{surveyInfo && (
				<SurveyHeader
					id={id}
					title={formInfo.title}
					description={formInfo.description}
					isAnonymous={formInfo.isAnonymous}
					deadline={formInfo.deadline}
					category={formInfo.category}
					isTemplate={isTemplate}
				/>
			)}

			{formInfo.groups &&
				sortedGroup.map((group, indexGroup) => (
					<div
						key={group.id}
						className={s.container}
					>
						<div className={s.wrapper_main}>
							<div className={s.section}>
								<div className={s.section_count}>Раздел {indexGroup + 1}</div>

								<h4>{group.subject}</h4>
							</div>
						</div>

						{group.questions &&
							[...group.questions].map((question, indexQuestion) => (
								<DisplayQuestion
									key={question.id}
									indexGroup={indexGroup}
									indexQuestion={indexQuestion}
									question={question}
									fillingInfo={sortedGroup}
									changeFillingInfo={changeFillingInfo}
									errorQuestions={errorQuestions}
									deadline={formInfo.deadline}
									isCompleted={notClickable || isEndedSurvey}
								/>
							))}
					</div>
				))}

			{!notClickable && !isEndedSurvey && onSubmit && (
				<div className={s.footer_buttons}>
					<Button
						variant="tertiary"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							onClear();
						}}
						isLoading={isLoading}
					>
						Очистить форму
					</Button>

					<Button
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							onSubmit(fillingInfo);
						}}
						isLoading={isLoading}
					>
						Отправить
					</Button>
				</div>
			)}
		</div>
	);
};
