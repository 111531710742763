import { Editor } from '@tinymce/tinymce-react';
import React, { HTMLAttributes, MutableRefObject } from 'react';
import { ArticleResponse } from 'src/app/redux/queries/wiki-service/types/resTypes';
import { StringField } from 'src/shared/ui/_fields/StringField';
import s from './ArticleEditor.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
	article: ArticleResponse;
	editorRef: MutableRefObject<any>;
};

export const ArticleEditor: React.FC<Props> = props => {
	const {
		className,
		article: { content },
		editorRef,
	} = props;

	// const saveContent = () => {
	// 	if (editorRef.current) {
	// 		const contentNew: string = editorRef.current.getContent();

	// 		editArticleContent({
	// 			articleId: id,
	// 			editArticleRequest: [
	// 				{
	// 					op: 'replace',
	// 					path: '/content',
	// 					value: contentNew,
	// 				},
	// 				{
	// 					op: 'replace',
	// 					path: '/name',
	// 					value: articleName,
	// 				},
	// 			],
	// 		})
	// 			.unwrap()
	// 			.catch(err => console.log(err));
	// 	}

	// 	toggleEditMode && toggleEditMode();
	// };

	// ! Caused problems with content disappearance when writing title the first time.
	// useEffect(() => {
	// 	editorRef.current && editorRef.current.setContent(content);
	// }, [editorRef.current]);

	// * Render
	return (
		<div className={`${s.container} ${className}`}>
			<div className={s.header}>
				<StringField
					name="name"
					label="Название статьи"
					placeholder="Введите название"
					required
				/>
			</div>

			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
				onInit={(evt, editor) => (editorRef.current = editor)}
				initialValue={content}
				init={{
					browser_spellcheck: true,
					height: 500,
					menubar: false,
					plugins: [
						'advlist',
						'autolink',
						'lists',
						'link',
						'charmap',
						'emoticons',
						'anchor',
						'searchreplace',
						'visualblocks',
						'code',
						'fullscreen',
						'insertdatetime',
						'media',
						'table',
						'preview',
						'help',
						'wordcount',
					],
					toolbar:
						'undo redo | blocks | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsizeselect | charmap  emoticons | table',
					font_css: '/fontsTinyMCE.css',
					content_css: '/tinyMCE.css',
				}}
			/>
		</div>
	);
};
