import { image_serviceAPI } from 'src/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import { imageContentToGalleyFile } from 'src/shared/lib/file';

export const useGetImage = (id?: string | null, image?: ImageContent | null) => {
	// * API
	const { currentData: imagesData, isLoading } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: id as string,
				source: 'Survey',
			},
		},
		{ skip: !id },
	);

	// * Image
	const imageInfo = id && imagesData?.body ? imageContentToGalleyFile(imagesData.body) : image ? imageContentToGalleyFile(image) : null;

	return { imageInfo, isLoading };
};
