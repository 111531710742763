import React, { useState } from 'react';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { Office } from 'src/app/redux/state/office/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg_icons/file/unarchive.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { OfficeFormMC } from '../../../OfficeFormMC/OfficeFormMC';
import { ArchiveMC } from '../ArchiveMC/ArchiveMC';

interface Props {
	office: Office;
	onArchiveExtra?: () => void;
}

export const OfficeInnerMenu: React.FC<Props> = props => {
	const {
		office, //
		onArchiveExtra,
	} = props;

	const { id, isActive } = office;

	// * Actions
	const dispatch = useAppDispatch();
	const { editOffice } = officeServiceActions.office.async;

	// * Interactions
	const onArchiveClick = () => {
		dispatch(
			editOffice({
				params: {
					officeId: id,
				},
				payload: [
					{
						op: 'replace',
						path: '/isActive',
						value: !isActive,
					},
				],
			}),
		)
			.unwrap()
			.then(() => onArchiveExtra && onArchiveExtra())
			.catch(error => console.log(error));
	};

	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => setEditModal(prevState => !prevState);

	const allowedToEditOffices = useRights(8);

	const [archiveModal, setArchiveModal] = useState(false);
	const toggleArchiveModal = () => setArchiveModal(prevState => !prevState);

	const DropDownItems = [
		{
			icon: isActive ? <ArchiveSVG /> : <UnArchiveSVG />,
			title: isActive ? 'Архивировать' : 'Восстановить',
			action: () => (isActive ? toggleArchiveModal() : onArchiveClick()),
		},
	];

	DropDownItems.unshift({
		icon: <EditSVG />,
		title: 'Редактировать',
		action: () => toggleEditModal(),
	});

	// * Render
	return (
		<>
			{editModal && (
				<ModalNewProto
					isOpen={editModal}
					onClose={toggleEditModal}
					width='m'
				>
					<OfficeFormMC
						toggleModal={toggleEditModal}
						defaultValues={office}
					/>
				</ModalNewProto>
			)}

			<ModalNewProto
				isOpen={archiveModal}
				onClose={toggleArchiveModal}
			>
				<ArchiveMC
					officeName={office.name}
					closeModal={toggleArchiveModal}
					onArchiveClick={onArchiveClick}
				/>
			</ModalNewProto>

			{allowedToEditOffices && <DropDownMenu items={DropDownItems} />}
		</>
	);
};
