import React, { ReactNode } from 'react';
import { TextInput } from '../_inputs/text_Inputs/TextInput';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	Icon?: ReactNode;
	required?: boolean;
}

export const PhoneStringField: React.FC<Props> = props => {
	const { name, label, placeholder, disabled = false, Icon, required } = props;

	const { control, setValue } = useFormContext();

	const maskedValue = (event: any, value: string) => {
		const currentValue = event.target.value;

		if (currentValue.length < value.length) {
			return setValue(name, currentValue);
		}

		const maskStartedWithSeven = new RegExp(/^([+78])$/);
		const maskStartedWithOtherNumber = new RegExp(/^([^+78])$/);
		const maskFirst = new RegExp(/^(\+7|8)[\s(]?(\d{3})[\s)]?$/);
		const maskSecond = new RegExp(/^(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})$/);
		const maskFull = new RegExp(/^(\+7|8)?[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/);

		if (currentValue.match(maskStartedWithSeven)) {
			setValue(name, currentValue.replace(maskStartedWithSeven, '+7('));
			return;
		}

		if (currentValue.match(maskStartedWithOtherNumber)) {
			setValue(name, currentValue.replace(maskStartedWithOtherNumber, '+7($1'));
			return;
		}

		if (currentValue.match(maskFirst)) {
			setValue(name, currentValue.replace(maskFirst, '+7($2)'));
			return;
		}

		if (currentValue.match(maskSecond)) {
			setValue(name, currentValue.replace(maskSecond, '+7($2)$3-'));
			return;
		}

		if (currentValue.match(maskFull)) {
			setValue(name, currentValue.replace(maskFull, '+7($2)$3-$4-$5'));
			return;
		}

		return setValue(name, currentValue);
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<TextInput
					{...field}
					value={field.value}
					onChange={e => maskedValue(e, field.value)}
					RightIcon={Icon}
					label={label}
					placeholder={placeholder}
					errorMessage={fieldState.error?.message}
					disabled={disabled}
					required={required}
				/>
			)}
		/>
	);
};
