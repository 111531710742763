import { DateTime } from 'luxon';
import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import { TimePickerInput } from '../TimePickerInput';
import s from './TimePickerRangeInput.module.scss';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	activeStartTime: Date | null;
	setActiveStartDate: (date: Date | null) => void;
	startRequired?: boolean;

	activeEndDate: Date | null;
	setActiveEndDate: (date: Date | null) => void;
	endRequired?: boolean;

	labels?: string[];
	showIcons?: boolean;
	startDateLimit?: Date | null | undefined;
	endDateLimit?: Date | null | undefined;
	errorMessage?: string;

	disabledStart?: boolean;
	disabledEnd?: boolean;
}

export const TimePickerRangeInput: React.FC<Props> = props => {
	const {
		activeStartTime,
		setActiveStartDate,
		startRequired,

		activeEndDate,
		setActiveEndDate,
		endRequired,

		labels,
		showIcons,
		errorMessage,

		disabledStart,
		disabledEnd,

		minTime = DateTime.fromJSDate(new Date()).startOf('day').toJSDate(),
		maxTime = DateTime.fromJSDate(new Date()).endOf('day').toJSDate(),

		...restProps
	} = props;

	const singleLabel = labels && !labels[1] && labels[0];
	const firstLabel = labels && labels?.length > 1 ? labels[0] : undefined;
	const secondLabel = labels && labels?.length > 1 ? labels[1] : undefined;

	// * Render
	return (
		<div className={s.container}>
			{singleLabel && (
				<InputLabel
					label={singleLabel}
					required={startRequired}
				/>
			)}

			<div className={s.inputs_wrapper}>
				<TimePickerInput
					{...restProps}
					label={firstLabel}
					selectedDate={activeStartTime}
					setSelectedDate={setActiveStartDate}
					showIcon={showIcons}
					minTime={minTime}
					maxTime={activeEndDate ? DateTime.fromJSDate(activeEndDate).minus({ minute: 1 }).toJSDate() : maxTime}
					disabled={disabledStart}
					required={startRequired}
				/>

				<span className={s.separator}>-</span>

				<TimePickerInput
					{...restProps}
					label={secondLabel}
					selectedDate={activeEndDate}
					setSelectedDate={setActiveEndDate}
					showIcon={showIcons}
					minTime={activeStartTime ? DateTime.fromJSDate(activeStartTime).plus({ minute: 1 }).toJSDate() : minTime}
					maxTime={maxTime}
					disabled={disabledEnd}
					required={endRequired}
				/>
			</div>

			{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
		</div>
	);
};
