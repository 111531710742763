import React, { ReactNode } from 'react';
import { closestCorners, DndContext, DragOverEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { GridOption, SectionProps } from '../../../_types';

interface Props {
	children?: ReactNode;
	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;
	indexSection: number;
	indexQuestion: number;
	gridOptions: GridOption[];
}

export const DndChoiceGrid: React.FC<Props> = props => {
	const { children, sections, setSections, indexSection, indexQuestion } = props;

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const handleDragEnd = (event: DragOverEvent) => {
		const { active, over } = event;

		const currentQuestion = sections[indexSection].questions[indexQuestion];

		const activeOption = currentQuestion.gridOptions.find(option => option.id === active.id);
		const overOption = currentQuestion.gridOptions.find(option => option.id === over?.id);

		if (activeOption && overOption && activeOption.id !== overOption.id) {
			let activeIndex: number;
			let overIndex: number;

			currentQuestion.gridOptions.forEach((option, index) => {
				if (option.id === active.id) {
					activeIndex = index;
				} else if (option.id === over?.id) {
					overIndex = index;
				}
			});

			setSections(
				sections.map((section, index) => {
					if (index === indexSection) {
						return {
							...section,
							questions: section.questions.map((question, index) => {
								if (index === indexQuestion) {
									return {
										...question,
										gridOptions: arrayMove(question.gridOptions, activeIndex, overIndex),
									};
								} else {
									return question;
								}
							}),
						};
					} else {
						return section;
					}
				}),
			);
		}
	};

	// * Render
	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCorners}
			onDragEnd={handleDragEnd}
		>
			{children}
		</DndContext>
	);
};
