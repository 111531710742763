import { DependencyList, useEffect } from 'react';

export const useDebounceEffect = (fn: () => void, waitTime: number, deps?: DependencyList) => {
	useEffect(() => {
		const t = setTimeout(() => {
			// @ts-ignore
			// eslint-disable-next-line prefer-spread
			fn.apply(null, deps!);
		}, waitTime);

		return () => {
			clearTimeout(t);
		};
	}, deps);
};
