import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { apiFetch } from 'src/app/api/api';
import { removeDuplicates } from 'src/shared/lib/array';
import { handleResponse } from '../../helpers';
import { imageServiceActions } from '../image/actions';
import { slice as articleSlice } from './article/slice';
import { ArticleData, SearchResults } from './types';
import { RubricData } from './wikiTree/types';

export const contentImagesExport = async (
	content: string,
	activeArticleImages: string[],
	action: any,
	options: { rejectWithValue: any; dispatch: ThunkDispatch<any, any, AnyAction>; getState: any },
) => {
	let contentWithMeta = content;

	const allImagesTags: string[] | null = content.match(/<img src="data\s*(.+?)\s*>/g); // find all images

	if (allImagesTags !== null) {
		for (const tag of allImagesTags) {
			const srcData = tag.match(/"data\s*(.+?)\s*"/g);
			const imageWidth = tag.match(/width="\s*(.+?)\s*"/g);
			const imageHeight = tag.match(/height="\s*(.+?)\s*"/g);

			if (srcData) {
				const base64String = srcData[0].slice(1, -1);

				const splittedBase64 = base64String.split(';base64,');
				const contentType = splittedBase64[0].slice(5);
				const content = splittedBase64[1];
				const extension = `.${contentType.split('/')[1]}`;

				// Assign ids to new images
				const response = await apiFetch.postLegacy('imageservice/wikinews/create', {
					content,
					extension,
					name: 'image',
					enablePreview: false,
					purpose: 'Wiki',
				});

				const responseData = await handleResponse<any>(response, action, options);

				contentWithMeta = contentWithMeta.replace(
					tag,
					`<img src="none" data-image-id="${responseData.body.imageId}" ${imageWidth ? imageWidth[0] : ''} ${imageHeight ? imageHeight[0] : ''}>`,
				);
			}
		}
	}

	if (activeArticleImages.length > 0) {
		for (const imageId of activeArticleImages) {
			options.dispatch(
				imageServiceActions.wikiNews.async.removeWikiNewsImage({
					params: {
						imageId,
						purpose: 'Wiki',
					},
				}),
			);
		}
	}

	return contentWithMeta;
};

export const contentImagesImport = async (
	content: string,
	action: any,
	options: { rejectWithValue: any; dispatch: ThunkDispatch<any, any, AnyAction>; getState: any },
	isPublic?: boolean,
) => {
	let contentWithMeta = content;

	const allImagesTags: string[] | null = content.match(/<img src="none" data-image-id=\s*(.+?)\s*>/g); // find all images

	const imageIds = [];

	if (allImagesTags !== null) {
		for (const tag of allImagesTags) {
			const dataImageId = tag.match(/data-image-id="\s*(.+?)\s*"/g);
			const imageWidth = tag.match(/width="\s*(.+?)\s*"/g);
			const imageHeight = tag.match(/height="\s*(.+?)\s*"/g);

			const imageId = dataImageId && dataImageId[0].slice(15, -1);
			imageId && imageIds.push(imageId);
			const params = `imageId=${imageId}&source=Wiki`;

			const response = isPublic ? await apiFetch.getLegacy('imageservice/public/get', params) : await apiFetch.getLegacy('imageservice/image/get', params);
			try {
				const image = await handleResponse<any>(response, action, options);
				contentWithMeta = contentWithMeta.replace(
					tag,
					`<img src="data:${image.body.name}/${image.body.extension.slice(1)};base64,${image.body.content}" ${imageWidth ? imageWidth[0] : ''} ${imageHeight ? imageHeight[0] : ''}>`,
				);
			} catch (error) {
				// ignore
			}
		}

		options.dispatch(articleSlice.actions.setArticleImages(imageIds));
	}

	return contentWithMeta;
};

export const generateSearchResults = (searchSubstring: string, wikiTree: RubricData[] | null, articlesFoundByContent: ArticleData[]): SearchResults => {
	let rubrics: RubricData[] = [];
	let subRubrics: RubricData[] = [];
	let articles: ArticleData[] = articlesFoundByContent;

	if (searchSubstring.trim() !== '' && wikiTree) {
		for (const rubric of wikiTree) {
			// find common articles with name similar to search.
			rubrics = rubric.name.toLowerCase().includes(searchSubstring.toLowerCase()) ? [...rubrics, rubric] : rubrics;

			// find common articles with name similar to search.
			const foundArticles = rubric.articles.filter(article => article.name.toLowerCase().includes(searchSubstring.toLowerCase()));
			articles = foundArticles.length > 0 ? [...articles, ...foundArticles] : articles;

			for (const subRubric of rubric.children) {
				// find sub-rubrics with name similar to search.
				subRubrics = subRubric.name.toLowerCase().includes(searchSubstring.toLowerCase()) ? [...subRubrics, subRubric] : subRubrics;

				// find articles in sub-rubric with name similar to search.
				const foundArticles = subRubric.articles.filter(article => article.name.toLowerCase().includes(searchSubstring.toLowerCase()));
				articles = foundArticles.length > 0 ? [...articles, ...foundArticles] : articles;
				articles = removeDuplicates(articles, 'id');
			}
		}
	}

	return {
		rubrics,
		subRubrics,
		articles,
	};
};
