export const addSpaceAfterThirdDigitRight = (num: number) => {
	const numStr = num.toString();

	const strArray = numStr.split('');
	const arrayWithSpaces = [];

	for (let i = 0; i <= strArray.length; i++) {
		arrayWithSpaces.unshift(strArray[strArray.length - i]);

		if (i % 3 === 0) {
			arrayWithSpaces.unshift(' ');
		}
	}

	return arrayWithSpaces.join('');
};
