import cn from 'classnames';
import { ComponentPropsWithoutRef, FC } from 'react';
import { MediaItem } from '../../types';
import { Media } from '../MediaItem/MediaItem';
import s from './MediaList.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	images: MediaItem[];
	onDelete: (imageId: string) => void;
}

export const MediaList: FC<Props> = props => {
	const {
		images, //
		onDelete,
		className,
	} = props;

	// * Render
	return (
		<div className={cn(s.container, className)}>
			{images.map(imageInfo => (
				<Media
					key={imageInfo.id}
					mediaItem={imageInfo}
					onDelete={onDelete}
				/>
			))}
		</div>
	);
};
