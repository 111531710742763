import { AsyncThunkAction } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { galleyFileToBase64 } from 'src/shared/lib/file/galleyFileToBase64/galleyFileToBase64';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { GalleyFile } from '../../../_galleries/Gallery/_types';
import { createGalleyFileFromImageInfo, createImageInfoFromFile } from '../../../_galleries/Gallery/_utils';
import { MediaModal } from '../../../_modals/MediaModal/MediaModal';
import s from './AvatarField.module.scss';
import { AvatarDropDown } from '../AvatarDropDown/AvatarDropDown';
import { AvatarItem } from '../AvatarItem/AvatarItem';

interface Props {
	view?: 'circle' | 'default';
	images: ImageInfo[];
	allowedToEdit: boolean;
	saveAllImages?: (images: ImageInfo[]) => void;
	createImages?: (images: ImageInfo[]) => void;
	deleteImages?: (imagesIds: string[]) => void;
	fetchImage: (imageId: string) => AsyncThunkAction<any, any, any>;
	maxFiles?: number;
	isLoading?: boolean;
}

export const AvatarField: React.FC<Props> = props => {
	const { view = 'default', images, allowedToEdit, saveAllImages, createImages, deleteImages, fetchImage, maxFiles = 8, isLoading } = props;

	const [files, setFiles] = useState<GalleyFile[]>(images.map(image => createGalleyFileFromImageInfo(image)));
	const [imageToShow, setImageToShow] = useState<number | null>(null);

	useEffect(() => {
		setFiles(images.map(image => createGalleyFileFromImageInfo(image)));

		return () => setFiles([]);
	}, [images.length]);

	useEffect(() => {
		if (saveAllImages) {
			Promise.all(files.map(file => galleyFileToBase64(file)))
				// TODO: Type values
				.then((values: any) => {
					const images = createImageInfoFromFile(values);
					saveAllImages(images);
				});
		}
	}, [files.length]);

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => files.forEach(file => URL.revokeObjectURL(file.preview));
	}, []);

	return (
		<>
			{imageToShow !== null && (
				<MediaModal
					toggleModal={() => setImageToShow(null)}
					files={files}
					imageToShow={imageToShow}
					setImageToShow={setImageToShow}
					fetchImage={fetchImage}
					zIndex={5}
				/>
			)}
			<div className={s.container}>
				<aside className={`${s.thumbs} ${view === 'circle' ? s.avatar_circle : s.avatar_default}`}>
					{files[0] &&
						files.map((file, index) => (
							<AvatarItem
								key={file.id}
								view={view}
								file={file}
								index={index}
								setImageToShow={setImageToShow}
								files={files}
								setFiles={setFiles}
								deleteImages={deleteImages}
								allowedToEdit={allowedToEdit}
							/>
						))}

					{allowedToEdit && files.length < maxFiles && (
						<AvatarDropDown
							view={view}
							currentFilesCount={files.length}
							setFiles={newFiles => setFiles([...files, ...newFiles])}
							createImages={createImages}
							maxFiles={maxFiles}
							isLoading={isLoading}
						/>
					)}
				</aside>
			</div>
		</>
	);
};
