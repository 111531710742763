import { DateTime } from 'luxon';

/*
	2022-05-18T03:08:36.233201-05:00 => 03:08:36
	2023-12-17T06:08:09.933Z => 06:08:09
	2024-08-21T22:22:00 => 22:22:00
*/

export const extractTimeFromISO = (iso: string): string => {
	return DateTime.fromISO(iso, { setZone: true, zone: 'utc' }).toFormat('HH:mm:ss');
};

// ! .fromISO(iso, { setZone: true, zone: 'utc' }) игнорирует зону заданную в iso.

// console.log('2022-05-18T03:08:36.233201-05:00', extractTimeFromISO('2022-05-18T03:08:36.233201-05:00'));
// console.log('2023-12-17T06:08:09.933Z', extractTimeFromISO('2023-12-17T06:08:09.933Z'));
// console.log('2024-08-21T22:22:00', extractTimeFromISO('2024-08-21T22:22:00'));
