import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useGetEventGetQuery } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { AddUsersToEvent } from 'src/pages/event_calendar/ui/EventForm/ui/AddUsersToEvent';
import { StepCounter } from 'src/pages/event_calendar/ui/EventForm/ui/StepCounter';
import { extractTimeFromISO } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { AddFilesToEvent } from '../AddFilesToEvent';
import { EventMainInfo } from '../EventMainInfo';
import { InitialFormValues } from '../EventMainInfo/types';
import { FORM_NAMES, MAX_STEPS } from './consts';
import s from './EventForm.module.scss';
import { getSubmitButtonText } from './lib/getSubmitButtonText/getSubmitButtonText';

// TODO: Добавить isLoading при редактировании и создании события.
export const EventForm: React.FC = () => {
	// * Navigation
	const navigate = useNavigate();
	const goToPreviousPage = () => navigate(-1);
	const params = useParams() as { eventId?: string };

	const eventId = params.eventId;

	// * Api
	const { data, isLoading: getEventLoading } = useGetEventGetQuery(
		{
			eventId: eventId as string,
			includeFiles: true,
			includeImages: true,
			includeUsers: true,
			includeCategories: true,
		},
		{
			skip: !eventId,
		},
	);

	const eventData = data?.body;

	const isLoading = getEventLoading; // TODO: || isCreateEventLoading || isCreateFileLoading

	// * Form navigation
	const [currentStep, setCurrentStep] = useState<number>(1);

	const goToNextStep = () => {
		setCurrentStep(prevState => prevState + 1);
	};

	const goToPrevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(prevState => prevState - 1);
		}
	};

	// * Info step
	const noCategory = {
		id: '',
		name: '',
	};

	const initialValues: InitialFormValues = {
		name: '',
		address: '',
		category: noCategory,
		startTime: '',
		endTime: '',
	};

	const category = eventData && eventData.eventCategories ? eventData.eventCategories[0] : noCategory;

	const eventValues = eventData && {
		name: eventData.name,
		address: eventData.address,
		category: category,
		startDate: new Date(eventData.date),
		startTime: eventData.date ? extractTimeFromISO(eventData.date) : initialValues.startTime,
		endDate: eventData.endDate && new Date(eventData.endDate),
		endTime: eventData.endDate ? extractTimeFromISO(eventData.endDate) : initialValues.endTime,
		description: eventData.description,
	};

	const defaultValues = eventId && eventValues ? eventValues : initialValues;

	// * Users step
	const initialUsersIds = (eventData?.eventUsers ?? []).map(user => user.userId); // Нужно только при редактировании

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title={eventId ? 'Редактирование события' : 'Новое событие'}
				isBeta
				buttons={[
					<Button
						variant="tertiary"
						onClick={goToPreviousPage}
						key="cancel"
					>
						Отмена
					</Button>,
				]}
			/>

			<div className={s.stepContainer}>
				<div className={s.stepContainer__wrapper}>
					<Button
						style={currentStep > 1 ? undefined : { visibility: 'hidden' }}
						variant="tertiary"
						onClick={goToPrevStep}
					>
						Назад
					</Button>

					<StepCounter
						currentStep={currentStep}
						maxSteps={MAX_STEPS}
					/>

					<Button
						type="submit"
						variant="primary"
						form={FORM_NAMES[currentStep - 1]}
						isLoading={isLoading}
					>
						{getSubmitButtonText(currentStep, eventId)}
					</Button>
				</div>
			</div>

			<div className={s.container}>
				<div
					style={{
						display: currentStep === 1 ? 'block' : 'none',
					}}
				>
					{(!eventId || (eventId && eventData && !!defaultValues.name)) && (
						<EventMainInfo
							formName={FORM_NAMES[0]}
							onSubmitAfterValidation={goToNextStep}
							defaultValues={defaultValues}
						/>
					)}
				</div>

				<div
					style={{
						display: currentStep === 2 ? 'block' : 'none',
					}}
				>
					<AddUsersToEvent
						formName={FORM_NAMES[1]}
						onSubmitAfterValidation={goToNextStep}
						initialUsersIds={initialUsersIds}
					/>
				</div>

				<div
					style={{
						display: currentStep === 3 ? 'block' : 'none',
					}}
				>
					<AddFilesToEvent
						formName={FORM_NAMES[2]}
						eventId={eventId}
					/>
				</div>
			</div>
		</PageDecorator>
	);
};
