import { FC } from 'react';
import { MOCK_USERS } from '../../mock';
import s from './UsersTable.module.scss';
import cn from 'classnames';
import { COLUMNS_META } from '../../consts';
import { SortBy } from '../../types';
import { CollapseArrows } from 'src/shared/ui/CollapseArrows';

const CELL_SIZE = 1.5; // rem
const CELL_GAP = 0.125; // rem

interface Props {
	users: typeof MOCK_USERS;
	sortBy: { ascending: boolean; by: SortBy } | null;
	setSortBy: (sortBy: { ascending: boolean; by: SortBy } | null) => void;
}

export const UsersTable: FC<Props> = props => {
	const {
		users, //
		sortBy,
		setSortBy,
	} = props;

	const onHeaderClick = (columnId: SortBy | 'days') => {
		if (columnId !== 'days') {
			if (sortBy?.by === columnId) {
				setSortBy({ ...sortBy, ascending: !sortBy.ascending });
			} else {
				setSortBy({ by: columnId, ascending: true });
			}
		}
	};

	// * Render
	return (
		<div>
			<div className={s.container}>
				{COLUMNS_META.map(column => (
					<div
						key={column.id}
						className={s.column}
						style={{ width: column.width + 'rem' }}
					>
						<div className={s.row} />
						<div
							className={cn(s.row, s.row__header, column.id !== 'days' && s.row_clickable)}
							onClick={() => onHeaderClick(column.id)}
						>
							{column.name}
							{column.id !== 'days' && (
								<CollapseArrows
									isOpen={sortBy?.by === column.id ? true : false}
									arrowType="empty"
								/>
							)}
						</div>

						{column.id === 'days'
							? users.map(user => (
									<div
										key={user.id}
										className={s.row}
									>
										<div className={cn(s.cell, s.cell__day, s.cell__day_left)}>{user.days.left}</div>
										<div className={cn(s.cell, s.cell__day)}>{user.days.total}</div>
										<div className={cn(s.cell, s.cell__day)}>{user.days.used}</div>
									</div>
								))
							: users.map(user => (
									<div
										key={user.id}
										className={s.cell}
									>
										{/*  @ts-ignore */}
										{user[column.id]}
									</div>
								))}
					</div>
				))}

				<div
					className={s.divider}
					style={{ height: users.length * CELL_SIZE + (users.length - 1) * CELL_GAP + 'rem' }}
				/>
			</div>
		</div>
	);
};
