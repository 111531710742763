import React from 'react';
import s from './Legends.module.scss';
import { BaseData } from '../../types';
import { ColorCircle } from 'src/entities/ColorCircle';
import { color } from 'src/shared/lib/color';
import { COLORS } from '../../const/colors';

interface Props {
	data: Array<BaseData>;
}

export const Legends: React.FC<Props> = props => {
	const { data } = props;

	// * libs
	const { getColors } = color;

	// ? useMemo
	const colorsArray = getColors(data.length, COLORS);

	return (
		<div className={s.container}>
			{data.map((item, index) => (
				<div
					key={item.id}
					className={s.wrapper}
				>
					<ColorCircle
						size="m"
						customColor={colorsArray[index]}
					/>

					<div className={s.label}>{item.label}</div>
				</div>
			))}
		</div>
	);
};
