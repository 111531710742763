import { FloatingFocusManager } from '@floating-ui/react';
import { ChangeEvent, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter_serviceAPI } from 'src/app/redux/queries/filter-service/filter_serviceAPI';
import { useDebounceEffect } from 'src/pages/project_service_pages/hooks/useDebounceEffect';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import { createFullName } from 'src/shared/lib/string';
import { SearchBar } from 'src/shared/ui/_inputs/text_Inputs/SearchBar';
import { OptionsWindow } from 'src/shared/ui/_option_lists/OptionsWindow/OptionsWindow';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import s from './UsersSearch.module.scss';

export const UsersSearch: FC = () => {
	// * API
	const [findUsers, { data, isLoading }] = filter_serviceAPI.useLazyGetUsersQuery();
	const searchResults = data?.body;

	// * Routing
	const navigate = useNavigate();

	// * Search
	const [searchValue, setSearchValue] = useState('');
	const [showOptions, setShowOptions] = useState(false);

	const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.stopPropagation();

		if (event.key === 'Enter' && searchValue) {
			setShowOptions(false);
			navigate('/users', { state: { search: searchValue } });
		}
	};

	const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setSearchValue(value);
		setShowOptions(!!value);
	};

	const clearSearchValue = () => {
		setShowOptions(false);
		setSearchValue('');
	};

	useDebounceEffect(
		() => {
			if (!searchValue && !searchResults) return;

			const promise = findUsers({
				skipcount: 0,
				takecount: 100,
				fullNameIncludeSubstring: searchValue,
			});

			return () => {
				promise.abort();
			};
		},
		300,
		[searchValue],
	);

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(showOptions, (value: any) => setShowOptions(!value));

	// * Render
	return (
		<CardWrapper title="Сотрудники">
			<div
				className={s.search_bar}
				onFocus={() => searchValue && setShowOptions(true)}
				// onBlur={() => setShowOptions(false)}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				<SearchBar
					placeholder="Задайте свой вопрос"
					value={searchValue}
					onChange={onSearchValueChange}
					onKeyDown={onEnter}
					active={!!searchValue}
					onActiveClick={clearSearchValue}
					loading={isLoading}
				/>

				{showOptions && searchResults && (
					<div className={s.search_list}>
						<FloatingFocusManager
							context={context}
							modal={false}
						>
							<div
								className={s.options}
								ref={refs.setFloating}
								style={{
									...floatingStyles,
									zIndex: 'var(--z-index-floating)',
								}}
								aria-labelledby={headingId}
								{...getFloatingProps()}
							>
								<OptionsWindow
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									options={searchResults.map(user => ({
										id: user.id,
										name: createFullName({
											firstName: user.firstName || '',
											lastName: user.lastName || '',
											middleName: user.middleName || '',
										}),
									}))}
									onOptionClick={option => {
										navigate(`/users/${option.id}`);
									}}
								/>
							</div>
						</FloatingFocusManager>
					</div>
				)}
			</div>
		</CardWrapper>
	);
};
