import { DateTime, DayNumbers, MonthNumbers } from 'luxon';
import { WorkTimeMonthLimitInfo } from 'src/app/redux/queries/time-service/types/types';
import { WORKDAY_HOURS } from 'src/shared/consts/consts';

export const getHours = (startTime: Date | null, endTime: Date | null, workTimeMonthLimits: WorkTimeMonthLimitInfo[]) => {
	const start = startTime && DateTime.fromJSDate(startTime);
	const end = endTime && DateTime.fromJSDate(endTime);

	let leaveHours = 0;

	const calculateLeaveHours = (startDay: number, endDay: number, selectedMonth: number) => {
		const monthLimits = workTimeMonthLimits?.find(limits => limits.month === selectedMonth);

		if (monthLimits) {
			const selectedDaysBinaryArray = monthLimits.holidays.slice(startDay - 1, endDay).split('');
			return selectedDaysBinaryArray.reduce((sum, value) => (value === '0' ? sum + WORKDAY_HOURS : sum), 0);
		} else {
			return 0;
		}
	};

	const createArrayWithBetweenNumbers = (start: number, end: number) => {
		const array = [];
		for (let i = start; i < end - 1; i++) {
			array.push(i + 1);
		}
		return array;
	};

	if (start && end) {
		const selectedMonths = Array.from(new Set([start.month, end.month])); // removes duplicates from selected months

		if (selectedMonths.length === 1) {
			leaveHours = calculateLeaveHours(start.day, end.day, selectedMonths[0]);
		} else {
			// More than on month selected.
			const firstMonthLeaveHours = calculateLeaveHours(start.day, start.daysInMonth as number, selectedMonths[0]);
			const lastMonthLeaveHours = calculateLeaveHours(1, end.day, selectedMonths[selectedMonths.length - 1]);

			const betweenMonths = createArrayWithBetweenNumbers(start.month, end.month);
			const betweenMonthsLeaveHours = betweenMonths.reduce((sum, value) => {
				const date = DateTime.fromObject({ month: value, year: start.year });
				return sum + calculateLeaveHours(1, date.daysInMonth as DayNumbers, date.month as MonthNumbers);
			}, 0);

			leaveHours = firstMonthLeaveHours + lastMonthLeaveHours + betweenMonthsLeaveHours;
		}
	}

	return leaveHours;
};
