import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { contentImagesImport, generateSearchResults } from '../_helpers';
import { asyncActions as publicAsync, slice as publicSlice } from '../public/slice';
import { ArticleResponse, FileInfo, FindFilesREQ_PARAMS, FindFilesRES, SearchResults } from '../types';
import { RubricData } from '../wikiTree/types';
import { FindPublicArticlesByContentREQ_PARAMS, FindPublicArticlesByContentRES, GetArticleREQ_PARAMS, GetArticleRES } from './types';

const NAME = `${ServiceName.WIKI_SERVICE}/public`;

const getPublicWiki = createAppAsyncThunk(`${NAME}/getPublicWiki`, async (arg: void, thunkAPI) => {
	return await apiRequest.getPublicRequest<RubricData[]>({
		url: `${NAME}/getWiki`,
		thunkAPI,
	});
});

const findPublicArticlesByContent = createAppAsyncThunk(`${NAME}/findPublicArticlesByContent`, async (arg: { params: FindPublicArticlesByContentREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;
	const { getState, signal } = thunkAPI;
	const state = getState();

	if (params.contentIncludeSubstring !== '') {
		const res = await apiRequest.getPublicRequest<FindPublicArticlesByContentRES>({
			url: `${NAME}/findArticleByContent`,
			params,
			thunkAPI,
			signal,
		});

		return generateSearchResults(
			params.contentIncludeSubstring,
			state.wiki_service.public.wikiTree,
			res.body.map(article => ({
				...article,
				isActive: true,
			})),
		);
	} else {
		return null;
	}
});

const findPublicFiles = createAppAsyncThunk(`${NAME}/findPublicFiles`, async (arg: { params: FindFilesREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getPublicRequest<FindFilesRES>({
		url: `${NAME}/findFiles`,
		params,
		thunkAPI,
	});
});

const getArticle = createAppAsyncThunk(`${NAME}/getArticle`, async (arg: { params: GetArticleREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;
	const { dispatch } = thunkAPI;

	const res = await apiRequest.getPublicRequest<GetArticleRES>({
		url: `${NAME}/getArticle`,
		params,
		thunkAPI,
	});

	dispatch(publicAsync.getPublicWiki());
	dispatch(publicSlice.actions.setArticle(res));

	const contentWithMeta = await contentImagesImport(res.content, () => getArticle(arg), thunkAPI, true);

	return {
		...res,
		content: contentWithMeta,
	};
});

interface State {
	wikiTree: RubricData[] | null;
	searchResults: SearchResults | null;
	activeArticle: GetArticleRES | null;
	activeArticleFiles: FileInfo[];
	status: RequestStatus;
	filesStatus: RequestStatus;
}

export const initialState: State = {
	wikiTree: null,
	searchResults: null,
	activeArticle: null,
	activeArticleFiles: [],
	status: RequestStatus.still,
	filesStatus: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setArticle: (state, action: PayloadAction<ArticleResponse>) => {
			state.activeArticle = action.payload;
		},
		setSearchResultsPublic: (state, action: PayloadAction<SearchResults | null>) => {
			state.searchResults = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getPublicWiki.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getPublicWiki.fulfilled, (state, action) => {
			state.wikiTree = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(getPublicWiki.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findPublicFiles.pending, state => {
			state.filesStatus = RequestStatus.loading;
		});
		builder.addCase(findPublicFiles.fulfilled, (state, action) => {
			state.activeArticleFiles = action.payload.body;
			state.filesStatus = RequestStatus.success;
		});
		builder.addCase(findPublicFiles.rejected, state => {
			state.activeArticleFiles = [];
			state.filesStatus = RequestStatus.failed;
		});

		builder.addCase(getArticle.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getArticle.fulfilled, (state, action) => {
			state.activeArticle = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(getArticle.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.status = RequestStatus.failed;
			}
		});

		builder.addCase(findPublicArticlesByContent.pending, (state, action) => {
			const searchString = action.meta.arg.params.contentIncludeSubstring;
			state.status = searchString === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(findPublicArticlesByContent.fulfilled, (state, action) => {
			state.searchResults = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(findPublicArticlesByContent.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.status = RequestStatus.failed;
			}
		});
	},
});

export const asyncActions = {
	getPublicWiki,
	findPublicFiles,
	getArticle,
	findPublicArticlesByContent,
};
