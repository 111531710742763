import { FC } from 'react';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { UserListItem, UsersList } from 'src/features/_card_lists/UsersList';

interface Props {
	users: UserListItem[];
}

export const UsersListContainer: FC<Props> = props => {
	const { users } = props;

	// * Selector
	const officeId = useAppSelector(state => state.office_service.office.office?.id);

	// * Actions
	const dispatch = useAppDispatch();
	const { getOffice } = officeServiceActions.office.async;
	const { removeOfficeUsers } = officeServiceActions.user.async;

	const onRemoveUser = (userId: string) => {
		officeId &&
			dispatch(
				removeOfficeUsers({
					payload: {
						officeId,
						userIds: [userId],
					},
				}),
			)
				.unwrap()
				.then(() => dispatch(getOffice({ params: { officeId } })))
				.catch(error => console.error(error));
	};

	// * Render
	return (
		<UsersList
			users={users}
			onRemoveUser={onRemoveUser}
		/>
	);
};
