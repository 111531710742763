import { FC } from 'react';
import { EducationInfo } from 'src/app/redux/queries/education-service/types/types';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { Heading } from 'src/shared/ui/Heading';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ALL_EDUCATION_TYPES, COMPLETENESS } from '../../const/type';
import { EducationDropDownMenu } from '../EducationDropDownMenu/EducationDropDownMenu';
import { ImageButton } from '../ImageButton/ImageButton';
import s from './EducationFields.module.scss';
import { ConditionalRendering } from 'src/shared/providers';
import { DateTime } from 'luxon';

interface Props {
	title: string;
	isCertificate?: boolean;
	educationInfo: EducationInfo[];
	hasRights: boolean;
	onClick: () => void;
}

export const EducationFields: FC<Props> = props => {
	const {
		title, //
		isCertificate = false,
		educationInfo,
		hasRights,
		onClick,
	} = props;

	// * Render
	return (
		<ConditionalRendering
			initialLoading={false}
			isSuccessful={educationInfo.length > 0 || hasRights}
			LoadedResult={
				<div className={s.container}>
					<div className={s.header}>
						<Heading
							level={3}
							size={4}
						>
							{title}
						</Heading>

						{hasRights && (
							<IconButton
								Icon={<AddSVG />}
								onClick={onClick}
							/>
						)}
					</div>

					{educationInfo.length > 0 ? (
						educationInfo.map(item => {
							const imageId = item.imagesIds ? item.imagesIds[0] : null;
							return (
								<div
									key={item.id}
									className={s.fields}
								>
									<span>
										{DateTime.fromISO(item.admissionAt).year} - {item.issueAt ? DateTime.fromISO(item.issueAt).year : 'наст. время'}
									</span>
									<span>{item.university.name}</span>

									<div className={s.actions}>
										{imageId && hasRights && <ImageButton id={imageId} />}
										{hasRights && (
											<EducationDropDownMenu
												info={item}
												isCertificate={isCertificate}
											/>
										)}
									</div>

									<span>
										{ALL_EDUCATION_TYPES[item.educationForm.name]}, {COMPLETENESS[item.completeness]}
									</span>
									<span>{item.qualificationName}</span>
								</div>
							);
						})
					) : (
						<span>{`Нет информации ${isCertificate ? 'о сертификатах' : 'об образовании'}.`}</span>
					)}
				</div>
			}
		/>
	);
};
