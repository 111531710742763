import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	CreateImageApiArg,
	CreateProjectApiArg,
	CreateProjectUsersApiArg,
	EditFileApiArg,
	EditProjectApiArg,
	EditProjectDepartmentApiArg,
	EditProjectUsersApiArg,
	FindFilesApiArg,
	FindProjectsApiArg,
	FindUsersApiArg,
	GetProjectApiArg,
	RemoveFileApiArg,
	RemoveImageApiArg,
	RemoveProjectUsersApiArg,
} from './types/regTypes';
import {
	CreateImageApiResponse,
	CreateProjectApiResponse,
	CreateProjectUsersApiResponse,
	EditFileApiResponse,
	EditProjectApiResponse,
	EditProjectDepartmentApiResponse,
	EditProjectUsersApiResponse,
	FindFilesApiResponse,
	FindProjectsApiResponse,
	FindUsersApiResponse,
	GetProjectApiResponse,
	RemoveFileApiResponse,
	RemoveImageApiResponse,
	RemoveProjectUsersApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.PROJECT_SERVICE}` as const;

export const project_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Project', 'File', 'Image', 'User', 'Department'] as const,
	endpoints: build => ({
		findProjects: build.query<FindProjectsApiResponse, FindProjectsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/project/find`,
				params: {
					isascendingsort: queryArg.isascendingsort,
					projectstatus: queryArg.projectstatus,
					nameincludesubstring: queryArg.nameincludesubstring,
					includeNotActiveUsers: queryArg.includeNotActiveUsers,
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
					includedepartment: queryArg.includedepartment,
					userid: queryArg.userid,
					departmentid: queryArg.departmentid,
				},
			}),
			providesTags: ['Project'],
		}),
		getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/project/get`,
				params: {
					projectId: queryArg.projectId,
					includeprojectusers: queryArg.includeprojectusers,
					includedepartment: queryArg.includedepartment,
				},
			}),
			providesTags: ['Project'],
		}),
		createProject: build.mutation<CreateProjectApiResponse, CreateProjectApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/project/create`,
				method: 'POST',
				body: queryArg.createProjectRequest,
			}),
			invalidatesTags: ['Project'],
		}),
		editProject: build.mutation<EditProjectApiResponse, EditProjectApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/project/edit`,
				method: 'PATCH',
				body: queryArg.editProjectRequest,
				params: { projectId: queryArg.projectId },
			}),
			invalidatesTags: ['Project'],
		}),
		findFiles: build.query<FindFilesApiResponse, FindFilesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/file/find`,
				params: {
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
					projectid: queryArg.projectid,
				},
			}),
			providesTags: ['File'],
		}),
		removeFile: build.mutation<RemoveFileApiResponse, RemoveFileApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/file/remove`,
				method: 'DELETE',
				body: queryArg.removeFilesRequest,
			}),
			invalidatesTags: ['File'],
		}),
		editFile: build.mutation<EditFileApiResponse, EditFileApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/file/edit`,
				method: 'PUT',
				params: {
					fileId: queryArg.fileId,
					newAccessType: queryArg.newAccessType,
				},
			}),
			invalidatesTags: ['File'],
		}),
		createImage: build.mutation<CreateImageApiResponse, CreateImageApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/image/create`,
				method: 'POST',
				body: queryArg.createImagesRequest,
			}),
			invalidatesTags: ['Image'],
		}),
		removeImage: build.mutation<RemoveImageApiResponse, RemoveImageApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/image/remove`,
				method: 'DELETE',
				body: queryArg.removeImageRequest,
			}),
			invalidatesTags: ['Image'],
		}),
		createProjectUsers: build.mutation<CreateProjectUsersApiResponse, CreateProjectUsersApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/user/create`,
				method: 'POST',
				body: queryArg.createProjectUsersRequest,
			}),
			invalidatesTags: ['User', 'Project'],
		}),
		findUsers: build.query<FindUsersApiResponse, FindUsersApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/user/find`,
				params: {
					projectId: queryArg.projectId,
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
					isActive: queryArg.isActive,
					isAscendingSort: queryArg.isAscendingSort,
					includePositions: queryArg.includePositions,
					fullNameIncludeSubstring: queryArg.fullNameIncludeSubstring,
					positionId: queryArg.positionId,
				},
			}),
			providesTags: ['User'],
		}),
		editProjectUsers: build.mutation<EditProjectUsersApiResponse, EditProjectUsersApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/user/editrole`,
				method: 'PUT',
				body: queryArg.editProjectUsersRoleRequest,
				params: { projectId: queryArg.projectId },
			}),
			invalidatesTags: ['User'],
		}),
		removeProjectUsers: build.mutation<RemoveProjectUsersApiResponse, RemoveProjectUsersApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/user/remove`,
				method: 'DELETE',
				body: queryArg.body,
				params: { projectId: queryArg.projectId },
			}),
			invalidatesTags: ['User', 'Project'],
		}),
		editProjectDepartment: build.mutation<EditProjectDepartmentApiResponse, EditProjectDepartmentApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/department/edit`,
				method: 'PUT',
				body: queryArg.editProjectDepartmentRequest,
			}),
			invalidatesTags: ['Project'],
		}),
	}),
});

export const {
	useFindProjectsQuery,
	useGetProjectQuery,
	useCreateProjectMutation,
	useEditProjectMutation,
	useFindFilesQuery,
	useRemoveFileMutation,
	useEditFileMutation,
	useCreateImageMutation,
	useRemoveImageMutation,
	useCreateProjectUsersMutation,
	useFindUsersQuery,
	useEditProjectUsersMutation,
	useRemoveProjectUsersMutation,
	useEditProjectDepartmentMutation,
} = project_serviceAPI;
