export const getAllSettledValues = <T>(responses: PromiseSettledResult<T>[]): T[] => {
	const values: T[] = [];

	responses.forEach(response => {
		if (response.status === 'fulfilled') {
			values.push(response.value);
		}
	});

	return values;
};
