import { Hours, Minutes } from '../types';

// Функция генерирует массив дат выбранного часа и интервала минут
export const iterateThrewHour = (selectedDate: Date, hour: Hours, startMinute: Minutes, endMinute: Minutes): Date[] => {
	const excludedDates = [];

	const endMinutes = endMinute === 0 ? 60 : endMinute;
	for (let i = startMinute; i <= endMinutes; i++) {
		excludedDates.push(new Date(selectedDate.setHours(hour, i, 0, 0)));
	}

	return excludedDates;
};

/*
	Примеры
	-------
	const date = new Date() ==> 14.11.2023

	iterateThrewHour(date, 1, 2, 3) ==>
	[Tue Nov 14 2023 01:02:00 GMT+0300 (Москва, стандартное время), Tue Nov 14 2023 01:03:00 GMT+0300 (Москва, стандартное время)]

	iterateThrewHour(date, 0, 2, 3) ==>
	[Tue Nov 14 2023 00:02:00 GMT+0300 (Москва, стандартное время), Tue Nov 14 2023 00:03:00 GMT+0300 (Москва, стандартное время)]

	! hour = 24 выдаст следующий день
	iterateThrewHour(date, 24, 2, 3) ==>
	[Wed Nov 15 2023 00:02:00 GMT+0300 (Москва, стандартное время), Thu Nov 16 2023 00:03:00 GMT+0300 (Москва, стандартное время)]

	! endMinute = 0 выведет следующий час
	iterateThrewHour(date, 2, 59, 0) ==>
	[Tue Nov 14 2023 02:59:00 GMT+0300 (Москва, стандартное время), Tue Nov 14 2023 03:00:00 GMT+0300 (Москва, стандартное время)]
*/
