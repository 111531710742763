import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import { LoginRequestBody, UserData } from '../../state/auth/types';

const reducerPath = `${ServiceName.AUTH_SERVICE}/auth` as const;

export const authApi = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	endpoints: build => ({
		login: build.mutation<UserData, LoginRequestBody>({
			query: body => ({
				url: `${reducerPath}/login`,
				method: 'POST',
				body,
			}),
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
	}),
});

export const { useLoginMutation } = authApi;
