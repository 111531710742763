import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import s from './BookingInfoCard.module.scss';
import cn from 'classnames';
import { isDateTheSame, monthToRuMonth } from 'src/shared/lib/date';
import { getTimeInterval } from 'src/pages/booking/lib';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { DateTime, MonthNumbers } from 'luxon';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { CancelBookingInfo, CancelUserBookingMC } from '../_modals/CancelUserBookingMC/CancelUserBookingMC';
import { BookingInfoCardData } from './types';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & BookingInfoCardData;

export const BookingInfoCard: React.FC<Props> = props => {
	const {
		date, //
		startTime,
		endTime,
		roomName,
		address,
		userName,
		userId,
		workspaceName,
		readonly = false,
		bookingId,
		startDate,
		endDate,
		...divProps
	} = props;

	// * Styles
	const containerClass = cn(s.container, readonly && s.readonly);

	// * Date & time
	const bookingDateLuxon = DateTime.fromJSDate(date);
	const bookingDateString = `${bookingDateLuxon.day} ${monthToRuMonth(bookingDateLuxon.month as MonthNumbers)}`;

	const startBooking = DateTime.fromJSDate(startDate || date);
	const endBooking = endDate ? DateTime.fromJSDate(new Date(endDate)).minus({ day: 1 }) : DateTime.fromJSDate(date);
	const isdateTheSame = isDateTheSame(startDate || date, endBooking.toJSDate() || date);
	const period = isdateTheSame
		? `${startBooking.day} ${monthToRuMonth(startBooking.month as MonthNumbers)}`
		: // ? `${startBooking.day} ${monthToRuMonth(startBooking.month as MonthNumbers)} ${startTime !== '00:00' && startTime} - ${endTime !== '00:00' && (endTime === 'NaN:NaN' || endTime === null ? String.fromCodePoint(8734) : endTime)}`
			`${startBooking.day} ${monthToRuMonth(startBooking.month as MonthNumbers)} - ${endBooking.day} ${monthToRuMonth(endBooking.month as MonthNumbers)}`;

	// * Cancel booking
	const [cancelBookingModalInfo, setCancelBookingModalInfo] = useState<CancelBookingInfo | null>(null);
	const closeBookingModal = () => setCancelBookingModalInfo(null);

	const onBookingCancelClick = (cancelBookingInfo: CancelBookingInfo) => {
		setCancelBookingModalInfo(cancelBookingInfo);
	};

	// * Conditions
	const dontDisplayTime = startTime === '00:00' && endTime === '00:00';
	const isMyWorkspace = workspaceName && (endTime === null || endTime === 'NaN:NaN');

	// * Render
	return (
		<>
			{cancelBookingModalInfo && (
				<ModalNewProto
					isOpen={!!cancelBookingModalInfo}
					onClose={closeBookingModal}
					width="s"
				>
					<CancelUserBookingMC
						{...cancelBookingModalInfo}
						closeModal={closeBookingModal}
					/>
				</ModalNewProto>
			)}

			<div
				{...divProps}
				className={containerClass}
			>
				<div className={s.date_time}>
					{isMyWorkspace ? ( //
						<span className={s.date_time__time}>Мое рабочее место</span>
					) : (
						<span>
							{period}
							{isdateTheSame && (
								<span
									className={s.date_time__time}
								>{` ${startTime !== '00:00' && startTime} - ${endTime !== '00:00' && (endTime === 'NaN:NaN' || endTime === null ? String.fromCodePoint(8734) : endTime)}`}</span>
							)}
						</span>
					)}
				</div>

				{!readonly && (
					<div className={s.delete_icon}>
						<DeleteSVG
							onClick={() =>
								bookingId &&
								userId &&
								onBookingCancelClick({
									bookingItem: {
										userName,
										bookingId,
										userId,
										isBan: false,
										startDate: startDate || date,
										endDate: endDate || date,
										selectedInterval: getTimeInterval(startTime, endTime as string),
									},
									roomName: roomName,
									address: address,
									workspaceName: workspaceName,
								})
							}
						/>
					</div>
				)}

				<div className={s.extra_info}>
					<span className={s.caption}>Офис </span>
					<span>{address}</span>
				</div>

				<div className={s.extra_info}>
					<span className={s.caption}>Помещение </span>
					<div>
						<span>{roomName}</span>
						{workspaceName && <span>, место {workspaceName}</span>}
					</div>
				</div>
			</div>
		</>
	);
};
