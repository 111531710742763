import { useSelector } from 'react-redux';
import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { CommentInfo } from 'src/app/redux/queries/event-service/types/types';
import { RootState } from 'src/app/redux/store';
import { Heading } from 'src/shared/ui/Heading';
import s from './EventComments.module.scss';
import { FC } from 'react';
import { Comments } from 'src/widgets/Comments';
import { CreateCommentAction, DeleteCommentAction, EditCommentAction } from 'src/widgets/Comments/types';

interface Props {
	eventId: string;
	comments: CommentInfo[];
}

export const EventComments: FC<Props> = ({ eventId, comments }) => {
	console.log('**** comments: ', comments);
	// * API
	const [createComment, { isLoading: isCreateLoading }] = event_serviceAPI.usePostEventCommentCreateMutation();
	const [editComment, { isLoading: isEditLoading }] = event_serviceAPI.usePatchEventCommentEditMutation();

	const isLoading = isCreateLoading || isEditLoading;

	// * Selectors
	const userInfo = useSelector((state: RootState) => state.user_service.user.userInfo?.user);

	// * Comment actions
	const createNewComment = (data: CreateCommentAction) => {
		const { comment, images, parentId, userId, successAction } = data;

		if (!userInfo) return;

		createComment({
			createEventCommentRequest: {
				eventId: eventId,
				userId: userId,
				content: comment,
				parentId: parentId,
				commentImages: images,
			},
		})
			.unwrap()
			.then(() => successAction && successAction())
			.catch(err => console.log(err));
	};

	const deleteCommentAction = (data: DeleteCommentAction) => {
		const { commentId, successAction } = data;

		editComment({
			commentId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: false,
				},
			],
		})
			.unwrap()
			.then(() => successAction())
			.catch(err => console.log(err));
	};

	const editCommentAction = (data: EditCommentAction) => {
		const { commentId, content, deletedImages, newImages, successAction } = data;

		const promises: any[] = [];

		if (content) {
			promises.push(
				editComment({
					commentId,
					body: [
						{
							op: 'replace',
							path: '/Content',
							value: content,
						},
					],
				}).unwrap(),
			);
		}

		// TODO: добавить добавление и удаление изображений
		Promise.allSettled(promises)
			.then(res => {
				console.log('**** editAction res: ', res);
				successAction && successAction()
			})
			.catch(err => console.log('Error: ', err));
	};

	return (
		<div className={s.container}>
			<Heading
				level={3}
				marginBottom="s"
				className={s.header}
			>
				Комментарии
			</Heading>

			{userInfo && (
				<Comments
					currentUser={{
						id: userInfo.id,
						avatar: userInfo.avatar,
						firstName: userInfo.firstName,
						lastName: userInfo.lastName,
					}}
					isLoading={isLoading}
					comments={comments}
					createAction={createNewComment}
					editAction={editCommentAction}
					deleteAction={deleteCommentAction}
				/>
			)}
		</div>
	);
};
