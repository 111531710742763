import React, { useMemo } from 'react';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { ReactComponent as StarSVG } from 'src/shared/assets/svg_icons/action/star_stroke.svg';
import { ReactComponent as PersonSVG } from 'src/shared/assets/svg_icons/social/person.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';

interface Props {
	projectId: string;
	id: string;
	role: string;
}

export const UserDropDown: React.FC<Props> = props => {
	const { projectId, id, role } = props;

	// * API
	const [editProjectUsers] = project_serviceAPI.useEditProjectUsersMutation();
	const [removeProjectUsers] = project_serviceAPI.useRemoveProjectUsersMutation();

	const DropDownItems = useMemo(
		() =>
			[
				{
					icon: <PersonSVG />,
					isVisible: role === 'Manager',
					title: 'Присвоить роль «Участник проекта»',
					action: (id?: string) => {
						id &&
							editProjectUsers({
								projectId,
								editProjectUsersRoleRequest: {
									role: 'Employee',
									usersIds: [id],
								},
							});
					},
				},
				{
					icon: <StarSVG />,
					isVisible: role !== 'Manager',
					title: 'Присвоить роль «Менеджер проекта»',
					action: (id?: string) => {
						id &&
							editProjectUsers({
								projectId,
								editProjectUsersRoleRequest: {
									role: 'Manager',
									usersIds: [id],
								},
							});
					},
				},
				{
					icon: <CloseSVG />,
					isVisible: true,
					title: 'Открепить сотрудника от проекта',
					action: (id?: string) => {
						id &&
							removeProjectUsers({
								projectId,
								body: [id],
							});
					},
				},
			].filter(item => item.isVisible),
		[id, role],
	);

	// * Render
	return (
		<DropDownMenu
			items={DropDownItems}
			value={id}
		/>
	);
};
