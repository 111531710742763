import { DateTime } from 'luxon';
import { WORKDAY_HOURS } from 'src/shared/consts/consts';

export const getMonthLeaveHours = (month: number, startDate: Date, endDate: Date, holidays: string = '', rate: number | null | undefined) => {
	const startDateLuxon = DateTime.fromJSDate(startDate);
	const endDateLuxon = DateTime.fromJSDate(endDate);

	const sameMonth = startDateLuxon.month === endDateLuxon.month;
	const isStartMonth = startDateLuxon.month === month && !sameMonth;
	const isEndMonth = endDateLuxon.month === month && !sameMonth;

	let totalDays = holidays;

	if (sameMonth) {
		totalDays = holidays.slice(startDateLuxon.day - 1, endDateLuxon.day);
	} else if (isStartMonth) {
		totalDays = holidays.slice(startDateLuxon.day - 1, startDateLuxon.daysInMonth);
	} else if (isEndMonth) {
		totalDays = holidays.slice(0, endDateLuxon.day);
	} // In between - default

	return totalDays.split('').reduce((acc, value) => acc + (value === '0' ? WORKDAY_HOURS : 0), 0) * (rate ?? 1);
};
