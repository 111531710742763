import ru from 'date-fns/locale/ru';
import React from 'react';
import { NotificationController } from '../features/notifications/NotificationController';
import { AppRouter } from './router/AppRouter';
// react-datepicker
import { registerLocale } from 'react-datepicker';

registerLocale('ru', ru);

export const App: React.FC = () => {
	// * Render
	return (
		<>
			<NotificationController />
			<AppRouter />
		</>
	);
};

export default App;
