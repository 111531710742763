import React from 'react';
import s from './RoomFormHeader.module.scss';

interface Props {
	isNewRoom: boolean;
	officeName: string;
}

export const RoomFormHeader: React.FC<Props> = props => {
	const { isNewRoom, officeName } = props;

	return (
		<h1>
			{isNewRoom ? 'Новое помещение для' : 'Редактировать помещение для'}
			<span className={s.office_name}>{` ${officeName}`}</span>
		</h1>
	);
};
