export const getLoginRules = (login: string) => {
	const rules = [
		{
			name: 'От 5 до 15 символов',
			checked: login.length >= 5 && login.length <= 15,
		},
		{
			name: 'Буквы латинского алфавита',
			checked: /^[A-Za-z0-9]+$/.test(login) && !/^[0-9]+$/.test(login),
		},
		{
			name: 'Нельзя использовать только цифры',
			checked: !/^[0-9]+$/.test(login),
		},
		{
			name: 'Нельзя использовать спецсимволы',
			checked: !/[.,:;?!*+%\-<>@[\]{}/\\_$#]/.test(login),
		},
		{
			name: 'Нельзя использовать пробел',
			checked: !!login && !/\s/.test(login),
		},
	];

	return rules;
};
