import { COMPANY_ID_DEV, COMPANY_ID_PREPROD, COMPANY_ID_PROD } from 'src/shared/consts/consts';
import { getAppEnv } from '../getAppEnv/getAppEnv';

export const getCompanyId = (): string => {
	const IDS = {
		develop: COMPANY_ID_DEV,
		preprod: COMPANY_ID_PREPROD,
		prod: COMPANY_ID_PROD,
		unknown: COMPANY_ID_DEV,
	};

	const appEnv = getAppEnv();

	return IDS[appEnv];
};
