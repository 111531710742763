import { DateTime } from 'luxon';

// 00:00:00 (или любая другая ISO строка) => HH:MM
export const isoRemoveSeconds = (dataString: string): string => {
	return DateTime.fromISO(dataString).toISOTime({
		suppressMilliseconds: true,
		suppressSeconds: true,
		includeOffset: false,
	}) as string;
};
