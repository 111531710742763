import React from 'react';
import s from './SurveysList.module.scss';
import { SurveyCard } from '../SurveyCard/SurveyCard';
import { FindSurveysResultInfo } from 'src/app/redux/queries/survey-service/types';
import { useRights } from 'src/shared/hooks/useRights';

interface Props {
	filteredSurveys: FindSurveysResultInfo[];
	isTemplate?: boolean;
}

export const SurveysList: React.FC<Props> = props => {
	const { filteredSurveys, isTemplate = false } = props;

	// * Rights
	const isAdmin = useRights();

	// * Render
	return (
		<div className={isTemplate ? s.container_template : s.container}>
			{filteredSurveys.length > 0 &&
				filteredSurveys.map(survey => (
					<SurveyCard
						key={survey.id}
						survey={survey}
						isEdit={isAdmin}
						isTemplate={isTemplate}
					/>
				))}
		</div>
	);
};
