import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { isLessThenMax } from 'src/shared/lib/date';
import { InputLabel } from 'src/shared/ui/_inputs/_shared/InputLabel/InputLabel';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { DatePickerInput } from 'src/shared/ui/_inputs/date_pickers/DatePickerInput';
import s from './DateRangeInput.module.scss';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'highlightDates'> {
	startDate: Date | null;
	setStartDate: (date: Date | null) => void;
	startRequired?: boolean;

	endDate: Date | null;
	setEndDate: (date: Date | null) => void;
	endRequired?: boolean;

	labels?: string[];
	errorBorderDate?: boolean;
	errorMessage?: string;
	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	disabledStart?: boolean;
	disabledEnd?: boolean;
	highlightDates?: Date[];
}

export const DateRangeInput: React.FC<Props> = props => {
	const {
		startDate,
		setStartDate,
		startRequired,
		endDate,
		setEndDate,
		endRequired,

		isClearable,

		labels,
		errorMessage,
		startDatePlaceholder = 'с ДД.ММ.ГГ',
		endDatePlaceholder = 'по ДД.ММ.ГГ',
		disabledStart,
		disabledEnd,
		placeholderText,
		highlightDates,
		minDate,
		maxDate,
		disabled,
		...datePickerProps
	} = props;

	const highlightWithRanges = highlightDates && [
		{
			'react-datepicker__day--highlighted-custom-1': highlightDates,
		},
	];

	const getStartMaxDate = (endDate: Date | null, maxDate?: Date | null) => {
		if (endDate && maxDate) {
			return isLessThenMax(endDate, maxDate) ? endDate : maxDate;
		} else if (endDate && !maxDate) {
			return endDate;
		} else {
			return maxDate;
		}
	};

	const singleLabel = labels && !labels[1] && labels[0];
	const firstLabel = labels && labels?.length > 1 ? labels[0] : undefined;
	const secondLabel = labels && labels?.length > 1 ? labels[1] : undefined;

	// * Render
	return (
		<div className={s.container}>
			{singleLabel && (
				<InputLabel
					label={singleLabel}
					required={startRequired}
				/>
			)}
			<div className={s.fields}>
				<DatePickerInput
					{...datePickerProps}
					label={firstLabel}
					placeholderText={startDatePlaceholder || placeholderText}
					selectedDate={startDate}
					setSelectedDate={setStartDate}
					isClearable={isClearable}
					highlightDates={highlightWithRanges}
					disabled={disabledStart || disabled}
					minDate={minDate}
					maxDate={getStartMaxDate(endDate, maxDate)}
					required={startRequired}
				/>

				<span className={s.separator}>—</span>

				<DatePickerInput
					{...datePickerProps}
					label={secondLabel}
					placeholderText={endDatePlaceholder || placeholderText}
					selectedDate={endDate}
					setSelectedDate={setEndDate}
					isClearable={isClearable}
					highlightDates={highlightWithRanges}
					disabled={!startDate || disabledEnd || disabled}
					minDate={startDate || minDate}
					maxDate={maxDate}
					required={endRequired}
				/>
			</div>

			{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
		</div>
	);
};
