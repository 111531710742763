import React from 'react';
import { ProjectInfoFields } from 'src/pages/project_service_pages/consts/project';
import s from './ProjectInfo.module.scss';

interface Props {
	customer: string;
	department: string;
	usersCount: number;
	status: string;
	endDateUtc: string;
	startDateUtc: string;
	duration: string;
}

export const ProjectInfo: React.FC<Props> = props => {
	// * Render
	return (
		<div className={s.container}>
			{ProjectInfoFields.map(field => {
				const { key, text, Icon } = field;
				return (
					<div
						key={field.key}
						className={s.info_field}
					>
						<span className={s.icon}>{<Icon />}</span>
						<span className={s.field}>{text}</span>
						<span className={s.info}>{props[key as keyof Props]}</span>
					</div>
				);
			})}
		</div>
	);
};
