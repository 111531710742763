import { createColumnHelper } from '@tanstack/react-table';
import { ReactNode } from 'react';

export interface ProjectsTableDataItem {
	id: string;
	name: string;
	shortName: string;
	customer: string;
	department: string;
	usersCount: string;
	status: ReactNode;
}

const columnHelper = createColumnHelper<ProjectsTableDataItem>();

export const COLUMNS_PROJECTS = [
	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('shortName', {
		header: 'КРАТКОЕ НАЗВАНИЕ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('customer', {
		header: 'ЗАКАЗЧИК',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('usersCount', {
		header: 'СОТРУДНИКИ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('status', {
		header: 'СТАТУС',
		cell: info => info.getValue(),
	}),
];
