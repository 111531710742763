export const STATUSES_RUS = {
	New: {
		name: 'Новое',
		color: 'var(--color-secondary-2)',
	},
	InProgress: {
		name: 'В работе',
		color: 'var(--color-primary-1)',
	},
	Done: {
		name: 'Выполнено',
		color: 'var(--success-dark)',
	},
	Declined: {
		name: 'Отклонено',
		color: 'var(--error-dark)',
	},
};
