import cn from 'classnames';
import React, { ChangeEvent, DetailedHTMLProps, ReactNode, TextareaHTMLAttributes } from 'react';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import _input from 'src/shared/ui/_inputs/_styles/_input.module.scss';
import s from './TextArea.module.scss';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';

interface Props extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	label?: string;
	placeholder?: string | undefined;
	value?: string | undefined;
	errorMessage?: string;
	characterLimit?: number;
	maxLength?: number;
	height?: number;
	size?: 'small' | 'large' | 'tiny';
	disabled?: boolean;
	defaultValue?: string;
	Icon?: ReactNode;
	onIconClicked?: React.MouseEventHandler<HTMLDivElement>;
	showLimit?: boolean;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
	const {
		className,
		label,
		placeholder,
		value,
		errorMessage,
		characterLimit,
		maxLength,
		size = 'large',
		disabled = false,
		defaultValue,
		Icon,
		onClick,
		onChange,
		onIconClicked,
		required,
		...restInputProps
	} = props;

	const inputWrapperStyle = cn(s.inputWrapper, s[`inputWrapper_${size}`], disabled && s.disabled);
	const inputStyle = cn(s.input, s[`input_${size}`]);
	const borderStyle = cn(s.border, s[`border_${size}`]);

	const onClickHandler = (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => {
		event.stopPropagation();

		if (onClick) {
			onClick(event);
		}
	};

	const onIconClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();

		if (onIconClicked) {
			onIconClicked(event);
		}
	};

	const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
		if (onChange) {
			if (characterLimit) {
				const value = event.currentTarget.value;
				const sumOfCharacters = value.length;
				sumOfCharacters <= characterLimit && onChange(event);
			} else {
				onChange(event);
			}
		}
	};

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<InputLabel
				label={label}
				required={required}
			/>

			<div className={inputWrapperStyle}>
				<textarea
					{...restInputProps}
					className={inputStyle}
					value={value}
					ref={ref}
					onClick={onClickHandler}
					onChange={onChangeHandler}
					disabled={disabled}
					defaultValue={defaultValue}
					maxLength={maxLength}
					style={Icon ? { width: 'calc(100% - 60px)' } : undefined}
				/>

				{Icon && (
					<div
						onClick={onIconClickHandler}
						className={`${_input.icon_right} ${!!onClick && !disabled && _input.icon_right__clickable} ${s.icon}`}
					>
						{Icon}
					</div>
				)}

				<div className={borderStyle} />
				{!value && <span className={s.placeholder}>{placeholder}</span>}
			</div>

			<ErrorWithLimit
				errorMessage={errorMessage}
				characterLimit={characterLimit}
				value={value}
			/>
		</div>
	);
});
