import React, { useState } from 'react';
import { CategoryInfo } from 'src/app/redux/queries/survey-service/types';
import { ColorCircle } from 'src/entities/ColorCircle/ui/ColorCircle';
import { ModalContent } from 'src/pages/surveys/components/ModalContent/ModalContent';
import { FormValues, NewCategoryMC } from 'src/pages/surveys/pages/SurveyPage/Content/TemplateContent/NewCategoryMC/NewCategoryMC';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './Category.module.scss';

interface Props {
	categories: CategoryInfo[];
	category: CategoryInfo;

	getCategoryInfo: (value: FormValues) => void;
	getCategoryId: (id: string) => void;

	isLoading: boolean;
}

export const Category: React.FC<Props> = props => {
	const {
		categories,
		category,

		getCategoryInfo,
		getCategoryId,

		isLoading,
	} = props;

	const { color, name } = category;

	// * User Actions
	const [newCategoryModal, setNewCategoryModal] = useState(false);
	const toggleModal = () => setNewCategoryModal(prevState => !prevState);

	const onEdit = (values: FormValues) => {
		toggleModal();
		getCategoryInfo(values);
	};

	// * Delete
	const subtitle = `Вы действительно хотите удалить категорию\n "${category.name}"?`;

	const [deleteModal, setDeleteModal] = useState(false);
	const toggleDeleteModal = () => setDeleteModal(prevState => !prevState);

	const onDelete = () => {
		getCategoryId(category.id);
	};

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={newCategoryModal}
				onClose={toggleModal}
				width="s"
			>
				<NewCategoryMC
					toggleModal={toggleModal}
					title="Редактирование категории"
					saveButtonTitle="Сохранить"
					onSave={onEdit}
					categories={categories}
					isLoading={isLoading}
					category={category}
				/>
			</ModalNewProto>

			<ModalNewProto
				isOpen={deleteModal}
				onClose={toggleDeleteModal}
				width="s"
			>
				<ModalContent
					title="Удалить категорию"
					subtitle={subtitle}
					buttonText="Удалить"
					isLoading={isLoading}
					action={onDelete}
					onClose={toggleDeleteModal}
				/>
			</ModalNewProto>

			<div className={s.container}>
				<div className={s.name}>
					<ColorCircle color={color} />
					<span>{name}</span>
				</div>

				<div className={s.buttons}>
					<IconButton
						Icon={<EditSVG />}
						onClick={toggleModal}
					/>

					<IconButton
						Icon={<DeleteSVG />}
						onClick={toggleDeleteModal}
					/>
				</div>
			</div>
		</>
	);
};
