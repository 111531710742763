import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	CreateCompanyApiArg,
	CreateCompanyUserApiArg,
	CreateContractSubjectApiArg,
	EditCompanyApiArg,
	EditCompanyUserApiArg,
	EditContractSubjectApiArg,
	FindContractSubjectsApiArg,
	GetCompanyApiArg,
} from './types/regTypes';
import {
	CreateCompanyApiResponse,
	CreateCompanyUserApiResponse,
	CreateContractSubjectApiResponse,
	EditCompanyApiResponse,
	EditCompanyUserApiResponse,
	EditContractSubjectApiResponse,
	FindContractSubjectsApiResponse,
	GetCompanyApiResponse,
} from './types/resTypes';

export const reducerPath = `${ServiceName.COMPANY_SERVICE}` as const;

export const company_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Company', 'CompanyUser', 'ContractSubject'] as const,
	endpoints: build => ({
		createCompany: build.mutation<CreateCompanyApiResponse, CreateCompanyApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/company/create`,
				method: 'POST',
				body: queryArg.createCompanyRequest,
			}),
			invalidatesTags: ['Company'],
		}),
		getCompany: build.query<GetCompanyApiResponse, GetCompanyApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/company/get`,
				params: { includeoffices: queryArg.includeoffices },
			}),
			providesTags: ['Company'],
		}),
		editCompany: build.mutation<EditCompanyApiResponse, EditCompanyApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/company/edit`,
				method: 'PATCH',
				body: queryArg.editCompanyRequest,
				params: { companyId: queryArg.companyId },
			}),
			invalidatesTags: ['Company'],
		}),
		createCompanyUser: build.mutation<CreateCompanyUserApiResponse, CreateCompanyUserApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/companyuser/create`,
				method: 'POST',
				body: queryArg.createCompanyUserRequest,
			}),
			invalidatesTags: ['CompanyUser'],
		}),
		editCompanyUser: build.mutation<EditCompanyUserApiResponse, EditCompanyUserApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/companyuser/edit`,
				method: 'PATCH',
				body: queryArg.editCompanyUserRequest,
				params: { userId: queryArg.userId },
			}),
			invalidatesTags: ['CompanyUser'],
		}),

		// - find contract subject
		findContractSubjects: build.query<FindContractSubjectsApiResponse, FindContractSubjectsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/contractsubject/find`,
				params: {
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
					isactive: queryArg.isactive,
				},
			}),
			providesTags: ['ContractSubject'],
		}),

		createContractSubject: build.mutation<CreateContractSubjectApiResponse, CreateContractSubjectApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/contractsubject/create`,
				method: 'POST',
				body: queryArg.createContractSubjectRequest,
			}),
			invalidatesTags: ['ContractSubject'],
		}),
		editContractSubject: build.mutation<EditContractSubjectApiResponse, EditContractSubjectApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/contractsubject/edit`,
				method: 'PATCH',
				body: queryArg.editContractSubjectRequest,
				params: { contractsubjectId: queryArg.contractsubjectId },
			}),
			invalidatesTags: ['ContractSubject'],
		}),
	}),
});

export const {
	useCreateCompanyMutation,
	useGetCompanyQuery,
	useEditCompanyMutation,
	useCreateCompanyUserMutation,
	useEditCompanyUserMutation,
	useFindContractSubjectsQuery,
	useCreateContractSubjectMutation,
	useEditContractSubjectMutation,
} = company_serviceAPI;
