import { ComponentPropsWithoutRef, FC } from 'react';
import s from './InfoItemSingle.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'div'> {
	caption: string;
	info: string;
	disabled?: boolean;
}

export const InfoItemSingle: FC<Props> = props => {
	const {
		caption, //
		info,
		disabled,
		className,
	} = props;

	return (
		<div className={className}>
			<div className={s.caption}>{caption}</div>
			<div className={cn(disabled && s.disabled)}>{info}</div>
		</div>
	);
};
