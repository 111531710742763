export const findMissingItems = <T extends { id: string }>(prevItems: T[], currentItems: T[]) => {
	const missingItems: T[] = [];

	for (const previousItem of prevItems) {
		const itemToKeep = currentItems.find(currentItem => currentItem.id === previousItem.id);
		if (!itemToKeep) {
			missingItems.push(previousItem);
		}
	}

	return missingItems;
};
