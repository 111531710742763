import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import s from './IconButton.module.scss';
import cn from 'classnames';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	Icon: ReactNode;
	disabled?: boolean;
}

export const IconButton: React.FC<Props> = props => {
	const {
		children, // Ignore
		className,
		Icon,
		disabled,
		onClick,
		...buttonProps
	} = props;

	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		if (onClick) {
			onClick(event);
		}
	};

	// * Render
	return (
		<button
			className={cn(s.container, disabled && s.container_disabled, className)}
			{...buttonProps}
			onClick={onClickHandler}
			type="button"
			disabled={disabled}
		>
			{Icon}
		</button>
	);
};
