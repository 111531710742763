import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { ReactComponent as SendSVG } from 'src/shared/assets/svg_icons/action/send.svg';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg_icons/action/close.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { MediaList } from 'src/shared/ui/_galleries/MediaList';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { useCommentsContext } from '../../context/useCommentsContext';
import { EditCommentAction, UserInfoForReply, UserProps } from '../../types';
import { AttachmentInput } from '../AttachmentInput/AttachmentInput';
import s from './CommentCreate.module.scss';
import { findNewItems } from 'src/shared/lib/filter';

interface Props {
	user?: UserProps;
	parentId?: string; // - ID комментария, на который будет дан ответ. При редактировании: ID комментария, который редактируем
	userForReply?: UserInfoForReply | null; // - ID, Имя и Отчество пользователя, которому предназначен комментарий

	commentContent?: string;
	commentImages?: ImageInfo[];

	onClose?: () => void; // - Для скрытия блока
}

export const CommentCreate: FC<Props> = props => {
	const {
		user, //
		parentId,
		userForReply,

		commentContent = '',
		commentImages = [],

		onClose,
	} = props;

	const isEdit = !!commentContent;
	const { id = '', userName = '' } = userForReply ?? {};

	// * Context
	const { currentUser, createAction, editAction } = useCommentsContext();

	// * Comment
	const [newComment, setNewComment] = useState(commentContent);

	useEffect(() => {
		if (userForReply) setNewComment(`${userName}, ${commentContent}`);
	}, [userForReply]);

	// * Drop
	const [eventImgs, setEventImgs] = useState<ImageInfo[]>(commentImages);
	const addNewImages = (newImgs: ImageInfo[]) => setEventImgs([...eventImgs, ...newImgs]);
	const deleteImages = (imgId: string) => setEventImgs(eventImgs.filter(img => img.id !== imgId));

	// * Comment create/edit
	const getCommentContent = (fullComment: string): string => {
		const content = fullComment.startsWith(`${userName},`) ? fullComment.replace(`${userName},`, '') : fullComment;
		return content.trim();
	};

	const createComment = () => {
		const commentContent = getCommentContent(newComment);

		createAction({
			comment: commentContent,
			parentId,
			userId: id,
			images: eventImgs,
			successAction: () => {
				setNewComment('');
				setEventImgs([]);
				onClose && onClose();
			},
		});
	};

	const editComment = () => {
		if (!parentId || !editAction) return;

		const preparedComment = getCommentContent(newComment);
		const args: Omit<EditCommentAction, 'commentId'> = {};

		// - В новом массиве картинок ищем те, айди которых не было в изначальном массиве
		const newImages = findNewItems(commentImages, eventImgs);

		// - В изначальном массиве картинок ищем те, айди которых отсутствуют в новом массиве
		const deletedImages = findNewItems(eventImgs, commentImages);

		// - Вносим изменения в args
		if (commentContent !== preparedComment) Object.assign(args, { content: preparedComment });
		if (newImages.length > 0) Object.assign(args, { newImages });
		if (deletedImages.length > 0) Object.assign(args, { deletedImages });

		// - Если изменения есть, вызываем editAction
		if (Object.keys(args).length > 0) {
			editAction({
				commentId: parentId,
				successAction: onClose,
				...args,
			});

			return;
		}

		// - Если изменений не было, закрываем редактирование
		onClose && onClose();
	};

	return (
		<div className={s.wrapper}>
			<div className={cn(s.container, userForReply && s.reply_container)}>
				<Avatar
					userAvatar={user ? user.avatar : currentUser.avatar}
					name={`${user ? user.lastName : currentUser.lastName} ${user ? user.firstName : currentUser.firstName}`}
					size="large"
				/>

				<div className={s.comment}>
					<AttachmentInput
						value={newComment}
						autoFocus={true}
						maxLength={300}
						onChange={e => setNewComment(e.currentTarget.value)}
						attachImages={addNewImages}
					/>

					<div className={s.comment__actions}>
						<IconButton
							Icon={<SendSVG />}
							className={s.comment__send}
							disabled={!newComment || (!!userName && !newComment.replace(`${userName},`, '').trim())}
							onClick={isEdit ? editComment : createComment}
						/>

						{onClose && (
							<IconButton
								Icon={<CloseSVG />}
								className={s.comment__send}
								onClick={onClose}
							/>
						)}
					</div>
				</div>
			</div>

			{eventImgs.length > 0 && (
				<MediaList
					className={cn(s.image_list, userForReply && s.reply_image_list)}
					images={eventImgs}
					onDelete={deleteImages}
				/>
			)}
		</div>
	);
};
