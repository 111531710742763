import { ColumnDef } from '@tanstack/react-table';
import { TableProps } from '../types';

export const COLUMNS: ColumnDef<TableProps>[] = [
	{
		accessorKey: 'avatar',
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	},
	{
		accessorKey: 'fullName_position',
		header: 'ФИО',
		id: 'name',
		cell: info => info.getValue(),
	},
	{
		accessorKey: 'role',
		header: 'РОЛЬ',
		cell: info => (info.getValue() === 'Manager' ? 'Менеджер проекта' : 'Участник проекта'),
	},
	{
		accessorKey: 'extra',
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	},
];
