import React from 'react';
import s from './TemplateContent.module.scss';
import { Categories } from 'src/widgets/Categories/ui/Categories/Categories';
import {
	useDeleteCategoriesByCategoryIdMutation,
	useGetCategoriesQuery,
	usePatchCategoriesByCategoryIdMutation,
	usePostCategoriesMutation,
} from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { FormValues } from './NewCategoryMC/NewCategoryMC';
import { SurveysList } from '../../../../components/SurveysList/SurveysList';
import { FindSurveysResultInfo } from 'src/app/redux/queries/survey-service/types';

interface Props {
	templates: FindSurveysResultInfo[];
}

export const TemplateContent: React.FC<Props> = props => {
	const { templates } = props;

	// * API
	const { data, isLoading: isLoading1 } = useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const categories = data?.body;

	const [createCategory, { isLoading: isLoading2 }] = usePostCategoriesMutation();
	const [editCategories, { isLoading: isLoading3 }] = usePatchCategoriesByCategoryIdMutation();
	const [deleteCategoriesByCategoryId, { isLoading: isLoading4 }] = useDeleteCategoriesByCategoryIdMutation();

	const isLoading = isLoading1 || isLoading2 || isLoading3 || isLoading4;

	const getCategoryInfo = (value: FormValues) => {
		value.id &&
			editCategories({
				categoryId: value.id,
				body: [
					{
						value: value.name,
						path: '/Name',
						op: 'replace',
					},
					{
						value: value.color,
						path: '/Color',
						op: 'replace',
					},
				],
			});
	};

	const getNewCategoryInfo = (values: FormValues) => {
		createCategory({
			createCategoryCommand: {
				name: values.name,
				color: values.color,
			},
		})
			.unwrap()
			.then(res => console.log(res))
			.catch(error => console.log(error));
	};

	const getCategoryId = (id: string) => {
		deleteCategoriesByCategoryId({
			categoryId: id,
		})
			.unwrap()
			.then(res => console.log(res))
			.catch(error => console.log(error));
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<Categories
					categories={categories ? categories : []}
					isLoading={isLoading}
					getCategoryId={getCategoryId}
					getCategoryInfo={getCategoryInfo}
					getNewCategoryInfo={getNewCategoryInfo}
				/>

				<SurveysList
					filteredSurveys={templates}
					isTemplate
				/>
			</div>
		</div>
	);
};
