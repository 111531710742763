import React, { ReactNode } from 'react';
import s from './PageDecoratorPublic.module.scss';
import { Nav } from 'src/features/_layout/Nav/ui/Nav/Nav';
import { Header } from 'src/features/_layout/Header/Header';

interface Props {
	children: ReactNode;
}

export const PageDecoratorPublic: React.FC<Props> = props => {
	const { children } = props;

	// * Render
	return (
		<div className={s.container}>
			<Nav
				className={s.menu}
				isPrivate={false}
			/>
			<div>
				<Header isPrivate={false} />
				<div className={s.content}>{children}</div>
			</div>
		</div>
	);
};
