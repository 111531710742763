import { useAppSelector } from 'src/app/redux/utils';

export const useRights = (neededRight?: number) => {
	// * Selector
	const isAdmin = useAppSelector(state => state.user_service.user.userInfo?.user.isAdmin);
	const rightsIds = useAppSelector(state => state.user_service.user.userInfo?.role?.rightsIds);

	return !!((neededRight && rightsIds?.includes(neededRight)) || isAdmin);
};

/* rightIds
	1 - Управление пользователями (Право позволяет добавлять, изменять и удалять пользователя из системы)
	2 - Управление проектами (Право позволяет добавлять, изменять и удалять проекты из системы)
	3 - Управление шаблонами сообщений электронной почты (Право позволяет добавлять, изменять и удалять шаблоны сообщений электронной почты и оповещений внутри портала из системы)
	4 - Управление департаментами (Право позволяет добавлять, изменять и удалять департаменты из системы)
	5 - Управление новостями (Право позволяет добавлять, изменять и удалять новости из системы)
	6 - Управление должностями, позициями сотрудников (Право позволяет добавлять, изменять и удалять должности, позиции сотрудников из системы)
	7 - Изменение отработанного времени в системе учета времени (Право позволяет добавлять, изменять и удалять отработанное время в системе учета времени)
	8 - Управление офисами (Право позволяет создавать, изменять и архивировать офисы в системе, а также привязывать и отвязывать сотрудников от офисов)
	9 - Управление компаниями (Право позволяет создавать, изменять, удалять компании в системе)
	10 - Управление бронированием (Право позволяет отменять любые брони, закрывать и открывать помещения для бронирования, настраивать правила бронирования и время работы помещений)
	11 - Управление данными компании (Право позволяет создавать, редактировать, изменять данные в компании к которой привязан правообладатель)
	12 - Управление ролями пользователей (Право позволяет назначать, удалять роль пользователя в системе)
	13 - Управление Вики (Право позволяет создавать, редактировать и удалять статьи, разделы и подразделы в Вики)
	14 - Управление Внутренней библиотекой (Право позволяет создавать, редактировать и удалять карточки книги, удалять отзывы пользователей с базовым правом)
	15 - Управление структурой компании (Право позволяет добавлять сотрудников из системы или создавать новые карточки для сотрудников, удалять сотрудников из блоков “Организационные единицы” и “Администрация компании”. Создавать подблоки в “Организационных единицах”. Редактировать карточки сотрудников созданных через структуру компании.)
	16 - Управление календарем событий (Право позволяет добавлять, редактировать и архивировать события.)
	17 - Управление сервисом опросов (Право позволяет создавать шаблоны опросов, публиковать опросы, редактировать шаблоны и опросы. Выгружать результаты опросов.)
	18 - Управление автоматизацией HR процессов (Право позволяет создавать шаблон для писем с приветствием нового сотрудника и шаблон для оффера.)
	19 - Управление системой заявок (Право позволяет редактировать все заявки. Указывать департамент заявкам, у которых он не указан. Назначать ответственных. Создавать категории.)
	20 - Управление заявками департамента (Право позволяет редактировать заявки внутри своего департамента.)
	21 - Управление заявками своих сотрудников (Право позволяет отклонять или одобрять заявки, которые приходят от департаментов.)
*/
