export const contractsByTerm = [
	{
		id: 'Perpetual',
		name: 'Бессрочный',
	},
	{
		id: 'FixedTerm',
		name: 'Фиксированный срок',
	},
];

export const visibilityBirthday = [
	{
		id: 'Closed',
		name: 'Скрыть',
	},
	{
		id: 'WithoutYear',
		name: 'День и месяц',
	},
	{
		id: 'Open',
		name: 'Полная дата',
	},
];

export const RATES = [
	{
		name: '0,25',
		id: '0.25',
	},
	{
		name: '0,5',
		id: '0.5',
	},
	{
		name: '0,75',
		id: '0.75',
	},
	{
		name: '1',
		id: '1',
	},
];
