import { CellContext, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<any>();

const getCellContext = (context: CellContext<any, unknown>) => {
	if (context.row.original.checked) {
		return {
			style: { backgroundColor: 'var(--color-bg-hover-50)' },
		};
	}
};

export const COLUMNS = [
	columnHelper.accessor('avatar', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
			getCellContext,
		},
	}),
	columnHelper.accessor('name', {
		header: 'ФИО',
		cell: info => info.getValue(),
		meta: {
			getCellContext,
		},
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		meta: {
			getCellContext,
		},
	}),
];
