import { createSlice } from '@reduxjs/toolkit';
import { Office } from '../types';

interface State {
	selectedOffice: Office | { id: null; name: null };
}

export const initialState: State = {
	selectedOffice: { id: null, name: null },
};

export const slice = createSlice({
	name: 'BOOKING_PAGE',
	initialState,
	reducers: {
		setSelectedAddress: (state, action: { payload: Office | { id: null; name: null } }) => {
			state.selectedOffice = action.payload;
		},
		clearSelectedAddress: state => {
			state.selectedOffice = initialState.selectedOffice;
		},
	},
});

export const bookingPageActions = {
	...slice.actions,
};
