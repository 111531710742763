import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { ConditionalRendering } from 'src/shared/providers';
import { ArticleFiles } from '../../../_common_components/ArticleFiles/ArticleFiles';
import { ArticleText } from '../_components/ArticleText/ArticleText';
import { Menu } from '../_components/Menu/Menu';
import s from './ArticlePage.module.scss';

export const ArticlePage: React.FC = () => {
	// * Router
	const params: any = useParams();
	const articleId: string = params.articleId;
	const navigate = useNavigate();

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * API
	const { data: wikiData } = wiki_serviceAPI.useGetWikiQuery({ includedeactivated: false });
	const { data: activeArticleData, isFetching, isError } = wiki_serviceAPI.useGetArticleQuery({ articleId }, { skip: !articleId });

	const wikiTree = wikiData || [];

	// - find rubricId & subRubricId
	const getRubricIds = () => {
		for (const item of wikiTree) {
			if (item.id === activeArticleData?.rubricId) {
				return {
					rubricId: item.id,
					subRubricId: null,
				};
			} else {
				const subRubric = item.children.find(subItem => subItem.id === activeArticleData?.rubricId);

				if (!subRubric) continue;

				return {
					rubricId: subRubric?.parentId || null,
					subRubricId: subRubric?.id || null,
				};
			}
		}
	};

	// * Archived article or not founded
	useEffect(() => {
		if (isError) {
			navigate('/wiki');
			dispatch(addNotification({ type: 'error', message: 'Статья не найдена.' }));
		}
	}, [isError]);

	useEffect(() => {
		if (activeArticleData && !activeArticleData.isActive) {
			navigate('/wiki');
			dispatch(addNotification({ type: 'error', message: 'Данная статья находится в архиве.' }));
		}
	}, [activeArticleData]);

	// * Render
	return (
		<PageDecorator>
			{activeArticleData && (
				<div className={s.container}>
					{activeArticleData.rubricId && wikiTree && (
						<Menu
							className={s.menu}
							tree={wikiTree}
							isPrivate={true}
							activeRubricId={activeArticleData.rubricId}
							activeArticleId={activeArticleData.id}
						/>
					)}

					<ConditionalRendering
						initialLoading={isFetching}
						isSuccessful={!!activeArticleData}
						LoadedResult={
							<div className={s.content}>
								<ArticleText
									article={activeArticleData}
									isPrivate={true}
									{...getRubricIds()}
								/>

								<ArticleFiles
									existFiles={!!activeArticleData.files && activeArticleData.files.length > 0}
									articleId={articleId}
									isPrivate={true}
								/>
							</div>
						}
					/>
				</div>
			)}
		</PageDecorator>
	);
};
