import cn from 'classnames';
import React from 'react';
import s from './ColorCircle.module.scss';

interface Props {
	size?: 'xs' | 's' | 'm';
	color?: number | 'none'; // color from scss
	customColor?: string; // custom color
}

export const ColorCircle: React.FC<Props> = props => {
	const { size = 's', color, customColor } = props;

	return (
		<div
			style={{ backgroundColor: customColor }}
			className={cn(s.container, s[`container__size_${size}`], s[`container__color_${color !== undefined ? color : 'none'}`])}
		/>
	);
};
