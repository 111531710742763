import React from 'react';
import { dateToTimeString, isoTimeStringToDate } from 'src/shared/lib/date';
import { TimePickerRangeInput } from '../_inputs/date_pickers/TimePickerRangeInput';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactDatePickerProps } from 'react-datepicker';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	firstName: string;
	secondName: string;
	labels?: string[];

	disabledStart?: boolean;
	startRequired?: boolean;
	disabledEnd?: boolean;
	endRequired?: boolean;
}

export const TimeRangeField: React.FC<Props> = props => {
	const {
		firstName,
		secondName,
		labels,

		disabledStart,
		startRequired,
		disabledEnd,
		endRequired,

		...restProps
	} = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={firstName}
			control={control}
			render={({ field: startField, fieldState: startFieldState }) => (
				<Controller
					name={secondName}
					control={control}
					render={({ field: endField, fieldState: endFieldState }) => (
						<TimePickerRangeInput
							{...restProps}
							labels={labels}
							activeStartTime={startField.value ? isoTimeStringToDate(startField.value) : null}
							setActiveStartDate={date => setValue(startField.name, date ? dateToTimeString(date) : null)}
							activeEndDate={endField.value ? isoTimeStringToDate(endField.value) : null}
							setActiveEndDate={date => setValue(endField.name, date ? dateToTimeString(date) : null)}
							errorMessage={startFieldState.error?.message || endFieldState.error?.message}
							disabledStart={disabledStart}
							disabledEnd={disabledEnd}
							startRequired={startRequired}
							endRequired={endRequired}
						/>
					)}
				/>
			)}
		/>
	);
};
