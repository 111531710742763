import { ComponentPropsWithoutRef, FC } from 'react';
import s from './SelectedCount.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'span'> {
	title: string;
	count: number;
}

export const SelectedCount: FC<Props> = props => {
	const {
		title, //
		count,
		className,
		...spanProps
	} = props;

	return (
		<div
			{...spanProps}
			className={cn(s.container, className)}
		>
			{title}: {count}
		</div>
	);
};
