import { FC, useState } from 'react';
import { useGetDepartmentsTreeGetQuery, usePostUserCreateMutation } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { AddUsersMC } from 'src/features/_modal_contents/AddUsersMC';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './AddTeamMember.module.scss';

interface Props {
	departmentId: string;
	departmentUsersIds: string[];
}

export const AddTeamMember: FC<Props> = props => {
	const {
		departmentId, //
		departmentUsersIds,
	} = props;

	// * API
	const { data: usersListData, isLoading: isLoadingUsersList } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 2000,
		includepositions: true,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
	});

	const usersList = usersListData?.body ?? [];

	const { data: departmentTreeData, isLoading: isLoadingDepartmentTree } = useGetDepartmentsTreeGetQuery({});
	const departmentTree = departmentTreeData?.body ?? [];

	// - Add
	const [addUsersToDepartment, { isLoading: isLoadingAddUser }] = usePostUserCreateMutation();

	// - Loading status
	const isLoading = isLoadingUsersList || isLoadingDepartmentTree || isLoadingAddUser;

	const nonDepartmentUsers =
		usersList
			.filter(({ user }) => !departmentUsersIds.includes(user.id)) // Убирает пользователей находящихся в департаменте из списка.
			.map(user => {
				return {
					id: user.user.id,
					name: `${user.user.lastName} ${user.user.firstName}`,
					position: user.positionUser?.name ?? '',
					department: user.departmentUser ? user.departmentUser.department.name : '',
					avatar: user.user?.avatar,
				};
			}) || [];

	const allDepartments =
		departmentTree.length > 0
			? departmentTree
					.map(department => {
						return department.children ? [department, ...department.children] : department;
					})
					.flat()
			: [];

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * Buttons actions
	const addUsers = (usersIds: string[]) =>
		addUsersToDepartment({
			createDepartmentUsersRequest: {
				departmentId,
				users: usersIds.map(userId => ({
					userId,
					role: 'Employee',
					assignment: 'Employee',
				})),
			},
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(e => console.log(e));

	// * Render
	return (
		<>
			{showModal && ( // ! Для очистки выбранных пользователей. Можно воспользоваться useImperativeHandle для доступа к setTempSelectedUsersIds.
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<AddUsersMC
						title="Добавить сотрудника"
						allUsers={nonDepartmentUsers}
						allDepartments={allDepartments}
						setSelectedUsers={addUsers}
						isLoading={isLoading}
						toggleModal={toggleModal}
					/>
				</ModalNewProto>
			)}

			<AddButton
				title="Добавить сотрудника"
				onClick={toggleModal}
				className={s.addButton}
			/>
		</>
	);
};
