import React from 'react';
import s from '../Pie/Pie.module.scss';
import { getColors } from 'src/shared/lib/color/getColors/getColors';
import { ResponsiveBar } from '@nivo/bar';
import { COLORS } from '../../const/colors';

interface Props {
	indexBy: string;
	layout: 'horizontal' | 'vertical';
	data: Array<{ [key: string]: string | number }>;
	keys?: string[];
}

const getTspanGroups = (value: string, maxLineLength: number, maxLines = 2) => {
	const words = value.split(' ');

	type linesAcc = {
		lines: string[];
		currLine: string;
	};

	//reduces the words into lines of maxLineLength
	const assembleLines: linesAcc = words.reduce(
		(acc: linesAcc, word: string) => {
			//if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
			if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
				return {
					lines: acc.lines.concat([acc.currLine]),
					currLine: word,
				};
			}
			//otherwise add the word to the current line
			return {
				...acc,
				currLine: acc.currLine + ' ' + word,
			};
		},
		{ lines: [], currLine: '' },
	);

	//add the ending state of current line (the last line) to lines
	const allLines = assembleLines.lines.concat([assembleLines.currLine]);

	//for now, only take first 2 lines due to tick spacing and possible overflow
	const lines = allLines.slice(0, maxLines);
	const children: JSX.Element[] = [];
	let dy = 0;

	lines.forEach((lineText, i) => {
		children.push(
			<tspan
				x={0}
				dy={dy}
				key={i}
			>
				{
					// if on the second line, and that line's length is within 3 of the max length, add ellipsis
					1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
				}
			</tspan>,
		);
		//increment dy to render next line text below
		dy += 15;
	});

	return children;
};

export const HorizontalBar: React.FC<Props> = props => {
	const { indexBy, layout, keys, data } = props;

	const padding = layout === 'horizontal' || data.length <= 5 || !keys ? 0.5 : 0.2;
	const height = layout === 'horizontal' ? data.length * 60 : 350;
	const left = layout === 'horizontal' ? 170 : 50;
	const answersCount = layout === 'horizontal' && data.reduce((acc, answer) => acc + +answer.value, 0);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(1, 1fr)',
				gridAutoRows: ' minmax(0px, 1fr)',
				minHeight: 250,
				height,
			}}
		>
			<ResponsiveBar
				data={data}
				keys={keys || ['value']}
				indexBy={indexBy}
				margin={{
					top: 24,
					right: 24,
					bottom: layout === 'horizontal' ? 32 : 50,
					left: left,
				}}
				padding={padding}
				innerPadding={0}
				groupMode="grouped"
				layout={layout}
				valueScale={{
					type: 'linear',
				}}
				colors={layout === 'vertical' ? getColors(data.length, COLORS) : { datum: 'data.color' }}
				colorBy={keys ? 'id' : 'indexValue'}
				indexScale={{ type: 'band', round: true }}
				enableLabel={false}
				enableGridY={true}
				enableGridX={true}
				// gridXValues={[0, 1, 2, 3, 4, 5]}
				// gridYValues={[0, 1, 2, 3, 4, 5]}
				labelSkipWidth={0}
				labelSkipHeight={0}
				labelTextColor="#212121"
				layers={[
					'markers',
					'grid',
					'axes',
					'bars',
					'legends',
					'annotations',
					// ({ bars }) => {
					// 	return (
					// 		<g>
					// 			{bars.map(({ width, height, x, y, data }) => {
					// 				return layout === 'vertical' ? (
					// 					<g>
					// 						{keys && <rect fill="#fff" x={x + width / 2 - 5} y={y - 24} width="10" height="20"/>}
					// 						<text
					// 							key={x + y}
					// 							transform={`translate(${x + width / 2 - 5}, ${y - 15})`}
					// 							textAnchor="left"
					// 							dominantBaseline="central"
					// 							fill="#212121"
					// 						>
					// 							{keys || data.value ? data.value : ''}
					// 						</text>
					// 					</g>
					// 				) : (
					// 					<g>
					// 						<rect fill="#fff" x={width + 10} y={y + height / 6 - 4} width="10" height="20"/>
					// 						<text
					// 							key={x + y}
					// 							transform={`translate(${width + 10}, ${y + height / 2})`}
					// 							textAnchor="left"
					// 							dominantBaseline="central"
					// 						>
					// 							{`${data.value ?? ''}`}
					// 						</text>
					// 					</g>
					// 				);
					// 			})}
					// 		</g>
					// 	);
					// },
				]}
				tooltip={({ value, label, color, data }) => {
					const field = keys && `${layout === 'horizontal' ? label.substring(8) : label.split('-')[0].trim()}`;
					return (
						<div className={s.bar_tooltip_container}>
							{(data?.option || field) && (
								<div className={s.bar_tooltip_option}>
									<div
										className={s.dot}
										style={{ background: color }}
									/>
									{data?.option || field}
								</div>
							)}
							<div className={s.bar_tooltip_text}>
								Всего ответов: {value} {answersCount && `( ${((value * 100) / answersCount).toFixed()}% )`}
							</div>
						</div>
					);
				}}
				theme={{
					grid: {
						line: {
							stroke: '#CCCCCC',
							strokeWidth: 1,
							strokeDasharray: '4 2',
						},
					},
					labels: {
						text: {
							fontSize: '1rem',
							lineHeight: '1.45rem',
							fontFamily: 'Lato, Arial, serif',
							fontWeight: '400',
						},
					},
					text: {
						fontSize: 16,
					},
					// fontSize: 16,
				}}
				axisLeft={
					layout === 'vertical'
						? {
								tickPadding: 15,
								tickSize: 0,
								format: e => (Math.floor(e) === e ? e : ''),
							}
						: {
								renderTick: ({ opacity, textAnchor, textX, textY, value, x, y }) => {
									return (
										<g
											transform={`translate(${x},${y})`}
											style={{ opacity }}
										>
											<text
												alignmentBaseline="middle"
												textAnchor={textAnchor}
												transform={`translate(${textX},${value.length > 15 ? textY : textY + 5})`}
												fontSize="1rem"
												fontFamily="Lato, Arial, serif"
												fontWeight="400"
											>
												<title>{value}</title>
												{getTspanGroups(value, 15)}
											</text>
										</g>
									);
								},
							}
				}
				axisBottom={
					layout === 'horizontal'
						? {
								tickSize: 0,
								tickPadding: 5,
								format: e => (Math.floor(e) === e ? e : ''),
							}
						: {
								tickPadding: 15,
								renderTick: ({ opacity, textAnchor, textX, textY, value, x, y }) => {
									return (
										<g
											transform={`translate(${x},${y})`}
											style={{ opacity }}
										>
											<text
												alignmentBaseline="central"
												textAnchor={textAnchor}
												transform={`translate(${textX},${textY})`}
											>
												<title>{value}</title>
												{getTspanGroups(value, 10)}
											</text>
										</g>
									);
								},
							}
				}
			/>
		</div>
	);
};
