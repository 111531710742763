import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { Button } from 'src/shared/ui/_buttons/Button';
import { PasswordField } from 'src/shared/ui/_fields/PasswordField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import * as Yup from 'yup';
import s from '../AuthPage/AuthPage.module.scss';
import { InputTooltip } from '../InputTooltip/InputTooltip';
import { getPasswordRules } from './utils/validate';

interface DefaultValues {
	secret: string;
	newPassword: string;
	confirmPassword: string;
}

export const Reset: FC = () => {
	const navigate = useNavigate();

	// * Params
	const [params] = useSearchParams();
	const userId = params.get('userId');

	// useEffect(() => {
	// 	if (!userId) {
	// 		navigate('/');
	// 	}
	// }, [userId]);

	// * API
	const [changePassword, { isLoading }] = user_serviceAPI.usePostPasswordReconstructMutation();

	// * Form
	const [errorMessage, setErrorMessage] = useState('');

	const schema = Yup.object().shape({
		secret: Yup.string().required(''),
		newPassword: Yup.string().required(''),
		confirmPassword: Yup.string().required(''),
	});

	const formMethods = useForm({
		defaultValues: {
			secret: '',
			newPassword: '',
			confirmPassword: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	// - checking form validation
	const secretValue = watch('secret');
	const passwordValue = watch('newPassword');
	const confirmPassValue = watch('confirmPassword');

	const rules = getPasswordRules(passwordValue);
	const isCorrect =
		Boolean(secretValue) && //
		Boolean(passwordValue) &&
		Boolean(confirmPassValue) &&
		rules.filter(rule => !rule.checked).length === 0 &&
		passwordValue === confirmPassValue;

	useEffect(() => {
		if (passwordValue && confirmPassValue && passwordValue !== confirmPassValue) {
			setErrorMessage('Пароли не совпадают');
		} else {
			setErrorMessage('');
		}
	}, [passwordValue, confirmPassValue]);

	// - Submit
	const onSubmit = (data: DefaultValues) => {
		const { secret, newPassword } = data;

		changePassword({
			reconstructPasswordRequest: {
				secret,
				newPassword: newPassword,
				userId: userId as string,
			},
		})
			.unwrap()
			.then(() => {
				setErrorMessage('');
				setIsReset(true);
			})
			.catch(() => setErrorMessage('Неверный код из письма'));
	};

	// - Show tooltip
	const [show, setShow] = useState(false);

	// - Password is reset
	const [isReset, setIsReset] = useState(false);

	return isReset ? (
		<div className={s.AuthPage_form}>
			<div className={s.AuthPage_recovery_info}>Пароль был успешно изменён.</div>

			<Button
				wide
				onClick={() => navigate('/auth/login')}
			>
				Войти
			</Button>
		</div>
	) : (
		<form
			className={s.AuthPage_form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<StringField
					name="secret"
					placeholder="Введите код из письма"
				/>

				<div className={s.AuthPage_wrapper}>
					<PasswordField
						name="newPassword"
						placeholder="Введите новый пароль"
						maxLength={50}
						autoComplete="new-password"
						errorBorder={!!errorMessage}
						onFocus={() => {
							setShow(true);
						}}
						onBlur={() => setShow(false)}
					/>

					{show && <InputTooltip rules={rules} />}
				</div>

				<PasswordField
					name="confirmPassword"
					placeholder="Повторите новый пароль"
					maxLength={50}
					errorBorder={!!errorMessage}
				/>

				{errorMessage && <div className={s.AuthPage_error}>{errorMessage}</div>}

				<Button
					type="submit"
					isLoading={isLoading}
					wide
					disabled={!isCorrect}
				>
					Сменить пароль
				</Button>
			</FormProvider>
		</form>
	);
};
