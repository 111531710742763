interface Props {
	title: string;
	content: string;
}

export const InfoItem: React.FC<Props> = props => {
	const {
		title, //
		content,
	} = props;

	return (
		<div>
			<b>{title}</b>
			<p>{content}</p>
		</div>
	);
};
