import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'src/app/api/api_types';
import { EventCreation, UserListItem } from './types';

const NAME = `event_calendar`;

export interface State {
	event: EventCreation | null;
	selectedUsersInfo: {
		users: UserListItem[];
	};
	status: RequestStatus;
}

export const initialState: State = {
	event: null,
	selectedUsersInfo: {
		users: [],
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setEventInfo: (state, action) => {
			state.event = { ...state.event, ...action.payload };
		},
		setSelectedUsersInfo: (state, action: PayloadAction<UserListItem[]>) => {
			state.selectedUsersInfo.users = [...action.payload];
		},
	},
});

export const actionsEventCalendar = {
	...slice.actions,
};
