import React, { useState } from 'react';
import { LineTabs, SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';
import { UsersContent } from './UsersContent/UsersContent';
import { RoomsContent } from './RoomsContent/RoomsContent';
import { GalleryContent } from './GalleyContent/GalleryContent';

export const Content: React.FC = () => {
	// * Tabs navigation
	const tabs: SingleTab[] = [
		{
			id: 'users',
			name: 'Сотрудники',
		},
		{
			id: 'rooms',
			name: 'Помещения',
		},
		{
			id: 'gallery',
			name: 'Галерея',
		},
	];

	const [activeTabId, setActiveTabId] = useState<string>('users');

	// * Render
	return (
		<>
			<LineTabs
				tabs={tabs}
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
			/>

			{activeTabId === 'users' && <UsersContent />}

			{activeTabId === 'rooms' && <RoomsContent />}

			{activeTabId === 'gallery' && <GalleryContent />}
		</>
	);
};
