import { DateTime } from 'luxon';

export const generateDatesByIsoInterval = (startDate: string, endDate: string): Date[] => {
	let startDateTime = DateTime.fromISO(startDate).startOf('day');
	const endDateTime = DateTime.fromISO(endDate).startOf('day');

	const dates: Date[] = [];

	while (startDateTime.toMillis() <= endDateTime.toMillis()) {
		dates.push(startDateTime.toJSDate());
		startDateTime = startDateTime.plus({ day: 1 });
	}

	return dates;
};
