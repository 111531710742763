import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { GetRightsListREQ, GetRightsListRES, RightInfo } from './types';

const NAME = `${ServiceName.RIGHTS_SERVICE}/rights`;

const getRightsList = createAppAsyncThunk(`${NAME}/getRightsList`, async (arg: GetRightsListREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetRightsListRES>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getRightsList(arg),
	});
});

// * Slice
export interface State {
	rightsList: RightInfo[] | null;
	status: RequestStatus;
}

export const initialState: State = {
	rightsList: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: 'rights',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getRightsList.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getRightsList.fulfilled, (state, action) => {
			state.rightsList = action.payload.body;
			state.status = RequestStatus.loading;
		});
		builder.addCase(getRightsList.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getRightsList,
};
