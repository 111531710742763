import React, { useState } from 'react';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { PositionForm } from '../PositionForm/PositionForm';
import { RoleForm } from '../RoleForm/RoleForm';

interface Props {
	isRoleContent: boolean;
}

export const CreateButton: React.FC<Props> = props => {
	const { isRoleContent } = props;

	// * Modals
	const [isVisible, setIsVisible] = useState(false);
	const toggleVisible = () => setIsVisible(prevState => !prevState);

	// * Render
	return (
		<>
			<AddButton
				title={`Создать ${isRoleContent ? 'роль' : 'должность'}`}
				onClick={toggleVisible}
			/>

			{isVisible && (
				<ModalNewProto
					isOpen={isVisible}
					onClose={toggleVisible}
				>
					{isRoleContent ? <RoleForm onCloseModal={toggleVisible} /> : <PositionForm onCloseModal={toggleVisible} />}
				</ModalNewProto>
			)}
		</>
	);
};
