import React from 'react';
import { getIsChecked } from 'src/pages/surveys/libs/getIsChecked/getIsChecked';
import { Radio } from 'src/shared/ui/_inputs/Radio';
import { ScaleOption, SectionProps } from '../../../../NewSurveyPage/_types';
import s from './ScaleAnswer.module.scss';

interface Props {
	options: Array<ScaleOption>;

	indexGroup: number;
	indexQuestion: number;

	fillingInfo: Array<SectionProps>;
	changeFillingInfo: (info: Array<SectionProps>) => void;

	isEndedSurvey?: boolean;
	isCompleted?: boolean;
}

export const ScaleAnswer: React.FC<Props> = props => {
	const {
		options,

		indexGroup,
		indexQuestion,

		fillingInfo,
		changeFillingInfo,

		isEndedSurvey,
		isCompleted,
	} = props;

	const leftSignature = options[0]?.leftSignature || '';
	const rightSignature = options[0]?.rightSignature || '';

	// * Change option
	const toSelectItem = (indexOption: number) => {
		const changedGroups = fillingInfo.map((group, i) => {
			if (i !== indexGroup) return group;

			return {
				...group,
				isActive: true,
				questions: group.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						scaleOptions: question.scaleOptions.map((option, index) => {
							const newAnswer = index === indexOption ? [{ id: option.id }] : [];

							return {
								...option,
								usersAnswers: newAnswer,
							};
						}),
					};
				}),
			};
		});

		changeFillingInfo([...changedGroups]);
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.caption}>{leftSignature}</div>

			<div className={s.scale}>
				{options.map((option, index) => (
					<div
						key={option.id}
						className={s.scale_item}
					>
						<div className={s.caption}>{option.content}</div>
						<Radio
							checked={getIsChecked(option)}
							onChange={() => toSelectItem(index)}
							disabled={isCompleted}
							noDisabledStyles={!isEndedSurvey}
						/>
					</div>
				))}
			</div>

			<div className={s.caption}>{rightSignature}</div>
		</div>
	);
};
