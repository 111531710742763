export const findSimilarItems = <T extends { id: string }>(prevItems: T[], currentItems: T[]) => {
	const similarItems: T[] = [];

	for (const previousItem of prevItems) {
		const itemToKeep = currentItems.find(currentItem => currentItem.id === previousItem.id);
		if (itemToKeep) {
			similarItems.push(itemToKeep);
		}
	}

	return similarItems;
};
