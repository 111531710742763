import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { BooleanSwitch } from 'src/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import * as yup from 'yup';
import s from './RubricEditButton.module.scss';

interface Props {
	id: string;
	name: string;
	access:
		| 0 // 'Private'
		| 1; // 'Public'
}

export const RubricEditButton: React.FC<Props> = props => {
	const { id, name, access } = props;

	// * API
	const [editRubric, { isLoading }] = wiki_serviceAPI.useEditRubricMutation();

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * Form
	const schema = yup.object().shape({
		name: yup.string().max(100, 'Название должно быть менее ста символов').required('Введите название'),
	});

	const defaultValues = {
		name,
		access,
	};

	// - reset values
	useEffect(() => {
		reset(() => defaultValues);
	}, [id]);

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue, reset } = formMethods;

	// - submit
	const onSubmit = (values: { name: string; access: 0 | 1 }) => {
		editRubric({
			rubricId: id,
			editRubricRequest: [
				{
					op: 'replace',
					path: '/name',
					value: values.name,
				},
				{
					op: 'replace',
					path: '/access',
					value: values.access,
				},
			],
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<>
			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
					width="s"
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormProvider {...formMethods}>
							<div className={s.container}>
								<h3>Редактирование рубрики</h3>

								<div className={s.fields}>
									<StringField
										name="name"
										label="Название"
										placeholder="Введите название подрубрики"
									/>

									<Controller
										name="access"
										control={control}
										render={({ field }) => (
											<BooleanSwitch
												text="Публичная рубрика"
												checked={field.value === 1}
												onChange={event => setValue(field.name, event.target.checked ? 1 : 0)}
											/>
										)}
									/>
								</div>

								<ButtonPair
									primaryText="Сохранить"
									primaryIsLoading={isLoading}
									secondaryText="Отменить"
									secondaryIsLoading={isLoading}
									secondaryOnClick={toggleModal}
								/>
							</div>
						</FormProvider>
					</form>
				</ModalNewProto>
			)}

			<IconButton
				Icon={<EditSVG />}
				onClick={toggleModal}
			/>
		</>
	);
};
