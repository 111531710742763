import React, { ChangeEvent } from 'react';
import s from './BooleanSwitch.module.scss';
import { Switch } from '../Switch/Switch';

interface Props {
	text: string;
	checked: boolean;
	disabled?: boolean;
	isLeftText?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const BooleanSwitch: React.FC<Props> = props => {
	const { text, checked, disabled, isLeftText, onChange } = props;

	return (
		<div className={s.container}>
			{isLeftText && <span className={disabled ? s.disabled : ''}>{text}</span>}
			<Switch
				checked={checked}
				disabled={disabled}
				onChange={onChange}
			/>
			{!isLeftText && <span className={disabled ? s.disabled : ''}>{text}</span>}
		</div>
	);
};
