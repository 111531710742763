import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import s from './UserInfo.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
	userId: string;
	name: string;
	job: string;
	imageId: string;
}

export const UserInfo: React.FC<Props> = props => {
	const { userId, name, job, imageId } = props;

	const navigate = useNavigate();

	// * Render
	return (
		<div
			className={s.container}
			onClick={() => navigate(`/users/${userId}`)}
		>
			<Avatar
				name={name}
				avatarId={imageId}
			/>

			<div>
				<div className={s.name}>{name}</div>
				<div className={s.job}>{job}</div>
			</div>
		</div>
	);
};
