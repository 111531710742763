import { isoToDateString } from 'src/shared/lib/date/isoToDateString/isoToDateString';

// Два ISO => дд.мм.гг - дд.мм.гг (пример: 29.05.23 - 30.05.23). Если даты одинаковые возвращает дд.мм.гг.
export const isoToDateRangeString = (startDate: string, endDate: string) => {
	const startDateString = isoToDateString(startDate);

	if (startDate === endDate) {
		return startDateString;
	} else {
		return `${startDateString} - ${isoToDateString(endDate)}`;
	}
};
