import parse from 'html-react-parser';
import React, { HTMLAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArticleResponse } from 'src/app/redux/queries/wiki-service/types/resTypes';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ShareSVG } from 'src/shared/assets/svg_icons/action/share.svg';
import { ReactComponent as AttachmentSVG } from 'src/shared/assets/svg_icons/file/attachment.svg';
import { ReactComponent as DetachmentSVG } from 'src/shared/assets/svg_icons/file/detachment.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { backendISOUTCToLocalLuxon, tupleToIsoString } from 'src/shared/lib/date';
import { Hours, Minutes } from 'src/shared/lib/date/types';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { Heading } from 'src/shared/ui/Heading';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ArticleAttachContent } from '../ArticleAttachContent/ArticleAttachContent';
import s from './ArticleText.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
	article: ArticleResponse;
	isPrivate: boolean;
	rubricId?: string | null;
	subRubricId?: string | null;
};

export const ArticleText: React.FC<Props> = props => {
	const {
		isPrivate,
		className,
		article: { content, id, isActive, name, createdAtUtc, isFavorite, modifiedAtUtc },
		rubricId = null,
		subRubricId = null,
	} = props;

	const navigate = useNavigate();

	// * API
	const [editArticle, { isLoading }] = wiki_serviceAPI.useEditArticleMutation();
	const [deleteFavorite] = wiki_serviceAPI.useDeleteFavoritesByArticleIdMutation();
	const [createFavorite, { isLoading: isCreateLoading }] = wiki_serviceAPI.usePostFavoritesMutation();
	const { data: favorites } = wiki_serviceAPI.useGetFavoritesQuery({ filter: {} });

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	const urlToClipboard = () => {
		navigator.clipboard.writeText(window.location.href).then(() =>
			dispatch(
				addNotification({
					type: 'success',

					message: 'Ссылка на статью скопирована в буфер обмена',
				}),
			),
		);
	};

	// - Archive/un-archive
	const onArchiveClick = () => {
		editArticle({
			articleId: id,
			editArticleRequest: [
				{
					op: 'replace',
					path: '/isActive',
					value: !isActive,
				},
			],
		})
			.unwrap()
			.then(() => {
				toggleModal();
				navigate('/wiki');
			})
			.catch(error => console.log(error));
	};

	// * Conditions
	const allowedToEditWiki = useRights(13) && isPrivate;

	const editDateInfo = backendISOUTCToLocalLuxon(modifiedAtUtc ? modifiedAtUtc : createdAtUtc);
	const editString = `${modifiedAtUtc ? 'Ред.:' : 'Создано:'} ${editDateInfo.toLocaleString()} в ${tupleToIsoString([editDateInfo.hour as Hours, editDateInfo.minute as Minutes])}`;

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * Attach / detach article
	const [showAttachModal, setShowAttachModal] = useState(false);
	const toggleAttachModal = () => setShowAttachModal(prevState => !prevState);

	const detachArticle = () => {
		deleteFavorite({
			articleId: id,
		})
			.unwrap()
			.then(() => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Статья успешно откреплена',
					}),
				);
			})
			.catch(err => console.log('Error: ', err));
	};

	const createFavoriteArticle = () => {
		createFavorite({
			createFavoriteArticleRequest: {
				articleId: id,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Статья успешно закреплена',
					}),
				);
			})
			.catch(err => console.log('Error: ', err));
	};

	const attachArticle = () => {
		const countFavoriteArticles = Number(favorites?.totalCount);

		if (countFavoriteArticles > 3) {
			return toggleAttachModal();
		}
		createFavoriteArticle();
	};

	// * Render
	return (
		<>
			{showAttachModal && (
				<ModalNewProto
					width="m"
					isOpen={showAttachModal}
					onClose={toggleAttachModal}
				>
					<ArticleAttachContent
						articleId={id}
						articleName={name}
						favorites={favorites?.body || []}
						isLoading={isCreateLoading}
						action={createFavoriteArticle}
						onClose={toggleAttachModal}
					/>
				</ModalNewProto>
			)}

			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
				>
					<div className={s.modal_container}>
						<h3>{isActive ? 'Архивировать' : 'Восстановить'}</h3>
						<p className={s.text}>
							Вы действительно хотите архивировать статью?
							<br />
							<span className={s.text_highlighted}>{name}</span>?
						</p>

						<ButtonPair
							primaryText="Архивировать"
							primaryIsLoading={isLoading}
							primaryOnClick={onArchiveClick}
							secondaryText="Отменить"
							secondaryOnClick={toggleModal}
							secondaryIsLoading={isLoading}
						/>
					</div>
				</ModalNewProto>
			)}

			<div className={`${s.container} ${className}`}>
				<div className={s.card}>
					<div className={s.header}>
						<Heading
							className={s.name}
							level={3}
						>
							{name}
						</Heading>

						<div className={s.button_wrapper}>
							<IconButton
								Icon={<ShareSVG />}
								onClick={urlToClipboard}
							/>

							{allowedToEditWiki && (
								<DropDownMenu
									items={[
										{
											icon: isFavorite ? <DetachmentSVG /> : <AttachmentSVG />,
											title: isFavorite ? 'Открепить с главной' : 'Закрепить на главной',
											action: isFavorite ? detachArticle : attachArticle,
										},
										{
											icon: <EditSVG />,
											title: 'Редактировать',
											action: () => navigate(`/wiki/edit_article/${rubricId}/${subRubricId}/${id}`),
										},
										{
											icon: <DeleteSVG />,
											title: 'Удалить',
											action: toggleModal,
										},
									]}
								/>
							)}
						</div>
					</div>

					<span className={s.edit_info}>{editString}</span>

					<div className={s.article_text}>{parse(content)}</div>
				</div>
			</div>
		</>
	);
};
