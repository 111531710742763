import React, { FC } from 'react';
import { Heading } from 'src/shared/ui/Heading';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './DeleteModal.module.scss';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair/ButtonPair';

interface Props {
	isOpen: boolean;
	onClose: () => void;

	title: string;
	description?: string;

	primaryText: string;
	primaryOnClick: () => void;
	primaryIsLoading: boolean;
	secondaryText?: string;
	secondaryOnClick: () => void;
	secondaryIsLoading: boolean;
}

export const ModalDelete: FC<Props> = props => {
	const {
		isOpen,
		onClose,

		title,
		description,

		primaryText,
		primaryOnClick,
		primaryIsLoading,
		secondaryText = 'Отменить',
		secondaryOnClick,
		secondaryIsLoading,
	} = props;

	// * Render
	return (
		<ModalNewProto
			isOpen={isOpen}
			onClose={onClose}
			className={s.modal}
		>
			<div className={s.container}>
				<Heading
					level={2}
					marginBottom="l"
					className={s.header}
				>
					{title}
				</Heading>

				<div className={s.description}>{description}</div>

				<ButtonPair
					primaryText={primaryText}
					primaryIsLoading={primaryIsLoading}
					primaryOnClick={primaryOnClick}
					secondaryText={secondaryText}
					secondaryOnClick={secondaryOnClick}
					secondaryIsLoading={secondaryIsLoading}
				/>
			</div>
		</ModalNewProto>
	);
};
