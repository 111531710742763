import * as yup from 'yup';

export const contactSchema = yup.object().shape({
	type: yup
		.object()
		.shape({
			id: yup.string().nullable(),
			name: yup.string().nullable(),
			placeholder: yup.string().nullable(),
		})
		.nullable()
		.required(),
	value: yup
		.string()
		.nullable()
		.when('type.id', {
			is: (val: string) => val === 'Email',
			then: yup
				.string()
				.nullable()
				.required('Обязательное поле')
				.matches(/^$|^[^@\s]+@[^@\s,]*/, 'Некорректная почта'),
		})
		.when('type.id', {
			is: (val: string) => val === 'Phone',
			then: yup
				.string()
				.nullable()
				.matches(/(\+7|8)?[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/, 'Введите корректный номер телефона'),
		})
		.when('type.id', {
			is: (val: string) => val === 'Telegram',
			then: yup.string().nullable().required(''),
		})
		.when('type.id', {
			is: (val: string) => val === 'Skype',
			then: yup.string().nullable().max(32, 'Максимум 32 символа'),
		}),
});
