import React from 'react';
import s from './StepCounter.module.scss';
import cn from 'classnames';

interface StepCounterProps {
	currentStep: number;
	maxSteps: number;
}

export const StepCounter: React.FC<StepCounterProps> = ({ currentStep, maxSteps }) => {
	const renderSteps = () => {
		return Array.from(Array(maxSteps).keys()).map(step => (
			<div
				className={cn(s.circle, Boolean(step + 1 === currentStep) && s.circle__active)}
				key={step}
			>
				{step + 1}
			</div>
		));
	};

	return <div className={s.container}>{renderSteps()}</div>;
};
