import { MonthNumbers } from 'luxon';

// Номер месяца в наименования со склонением. Пример: 1 -> Января, 2 -> Февраля
export const monthToRuMonth = (monthNumber: MonthNumbers) => {
	if (monthNumber === 1) return 'Января';
	if (monthNumber === 2) return 'Февраля';
	if (monthNumber === 3) return 'Марта';
	if (monthNumber === 4) return 'Апреля';
	if (monthNumber === 5) return 'Мая';
	if (monthNumber === 6) return 'Июня';
	if (monthNumber === 7) return 'Июля';
	if (monthNumber === 8) return 'Августа';
	if (monthNumber === 9) return 'Сентября';
	if (monthNumber === 10) return 'Октября';
	if (monthNumber === 11) return 'Ноября';
	if (monthNumber === 12) return 'Декабря';
};
