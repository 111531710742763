import { Notification } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

interface State {
	notifications: Notification[];
}

export const initialState: State = {
	notifications: [],
};

export const slice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		addNotification: (state, action: PayloadAction<Omit<Notification, 'id'>>) => {
			state.notifications = [...state.notifications, { ...action.payload, id: v4() }];
		},
		deleteNotification: (state, action: PayloadAction<{ notificationId: string }>) => {
			const { notificationId } = action.payload;
			state.notifications = state.notifications.filter(note => note.id !== notificationId);
		},
	},
});

export const actionsNotifications = slice.actions;
