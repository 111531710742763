import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RequestStatus } from 'src/app/api/api_types';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { WorkSpaceInfo } from 'src/app/redux/state/office/workspace/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { findMissingItems, findNewItems, findSimilarItems } from 'src/shared/lib/filter';
import { Gallery } from 'src/shared/ui/_galleries/Gallery';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { Button } from 'src/shared/ui/_buttons/Button';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { v4 } from 'uuid';
import { RoomFormHeader } from '../../../../RoomFormHeader/RoomFormHeader';
import { WorkSpaceFormModal } from '../../../../WordSpaceFormModal/WorkSpaceFormModal';
import { Workspace } from '../../../SingleOfficePage/Content/RoomsContent/Workspace/Workspace';
import s from './Second.module.scss';

interface FormValues {
	images: ImageInfo[];
	workspaces: WorkSpaceInfo[];
}

interface Props {
	officeId: string;
	roomId: string;
	isNewRoom: boolean;
}

export const Second: React.FC<Props> = props => {
	const { officeId, roomId, isNewRoom } = props;

	// * Router
	const navigate = useNavigate();

	// * Navigation
	const goBack = () => {
		navigate(`${routerPath.offices.page}${routerPath.offices.roomForm}/${officeId}/${roomId}/1`);
	};

	const goToOfficePage = () => {
		navigate(`${routerPath.offices.page}${routerPath.offices.singleOffice}/${officeId}`);
	};

	// * Selectors
	const office = useAppSelector(state => state.office_service.office.office);
	const activeRoom = useAppSelector(state => state.office_service.room.activeRoom);
	const images = useAppSelector(state => state.office_service.image.roomImages);
	const workspaces = useAppSelector(state => state.office_service.workspace.workspaces);

	const officeStatus = useAppSelector(state => state.office_service.office.status);
	const roomStatus = useAppSelector(state => state.office_service.room.status);
	const imageStatus = useAppSelector(state => state.office_service.image.status);
	const workspaceStatus = useAppSelector(state => state.office_service.workspace.status);

	const isLoading = [officeStatus, roomStatus, imageStatus, workspaceStatus].includes(RequestStatus.loading);

	// * Actions
	const dispatch = useAppDispatch();
	const { getImages, createImage, removeImages } = officeServiceActions.image.async;
	const { clearImages } = officeServiceActions.image.actions;
	const { getImage } = imageServiceActions.image.async;
	const { createWorkspace, editWorkspace, findWorkspaces } = officeServiceActions.workspace.async;
	const { createTags, removeTags } = officeServiceActions.tag.async;

	// * Conditions
	const isPartialBooking = activeRoom && activeRoom.roomType.bookingRule === 'PartialBooking';

	// * Initialize
	useEffect(() => {
		dispatch(
			getImages({
				params: {
					entityId: roomId,
				},
				options: {
					entity: 'room',
				},
			}),
		);

		dispatch(
			findWorkspaces({
				params: {
					officeId,
					roomId,
					takeCount: 200,
					skipCount: 0,
					includeDeactivated: false,
				},
			}),
		);

		return () => {
			dispatch(clearImages('room'));
		};
	}, []);

	// * Form
	const defaultValues: FormValues = {
		images,
		workspaces,
	};

	const { handleSubmit, control, setValue, reset, watch, getValues } = useForm({
		defaultValues,
	});

	useEffect(() => {
		reset(defaultValues);
	}, [images, workspaces]);

	const currentWorkspaces = watch('workspaces');

	const onSubmit = (values: typeof defaultValues) => {
		const promises: any[] = [];

		// * images
		const previousImages = defaultValues.images;
		const currentImages = values.images;

		const newImages = findNewItems(previousImages, currentImages);
		const imagesToDelete = findMissingItems(previousImages, currentImages);

		if (newImages.length > 0) {
			newImages.forEach(newImage => {
				promises.push(
					dispatch(
						createImage({
							payload: {
								entityId: roomId,
								name: newImage.name,
								content: newImage.content,
								extension: newImage.extension,
							},
						}),
					).unwrap(),
				);
			});
		}

		if (imagesToDelete.length > 0) {
			promises.push(
				dispatch(
					removeImages({
						payload: {
							entityId: roomId,
							imagesIds: imagesToDelete.map(image => image.id),
						},
					}),
				).unwrap(),
			);
		}

		// ! Partial booking only...
		if (isPartialBooking) {
			// * workspaces
			const previousWorkspaces = defaultValues.workspaces;
			const currentWorkspaces = values.workspaces;

			const newWorkSpaces = findNewItems(previousWorkspaces, currentWorkspaces);
			const workSpacesToDelete = findMissingItems(previousWorkspaces, currentWorkspaces);
			const workSpaceToEdit = findSimilarItems(previousWorkspaces, currentWorkspaces);

			// * Creating workspaces
			newWorkSpaces.forEach(workspace => {
				promises.push(
					dispatch(
						createWorkspace({
							payload: {
								roomId: roomId,
								name: workspace.name,
								description: workspace.description,
							},
						}),
					)
						.unwrap()
						.then(workspaceId => {
							promises.push(
								dispatch(
									createTags({
										payload: {
											entityId: workspaceId,
											tags: workspace.tags.map(tag => tag.name),
										},
									}),
								).unwrap(),
							);
						})
						.catch(error => console.log(error)),
				);
			});

			// * Edit workspaces
			if (activeRoom) {
				workSpaceToEdit.forEach(workspace => {
					// * Edit name
					promises.push(
						dispatch(
							editWorkspace({
								params: {
									workspaceId: workspace.id,
								},
								payload: [
									{
										path: '/name',
										value: workspace.name,
										op: 'replace',
									},
								],
							}),
						).unwrap(),
					);

					// * Edit tags
					const previousTags = previousWorkspaces.find(prevWorkspace => prevWorkspace.id === workspace.id)!.tags;
					const currentTags = currentWorkspaces.find(prevWorkspace => prevWorkspace.id === workspace.id)!.tags;

					const newTags = findNewItems(previousTags, currentTags);
					const tagsToDelete = findMissingItems(previousTags, currentTags);

					if (newTags.length > 0) {
						promises.push(
							dispatch(
								createTags({
									payload: {
										entityId: workspace.id,
										tags: newTags.map(tag => tag.name),
									},
								}),
							).unwrap(),
						);
					}

					if (tagsToDelete.length > 0) {
						promises.push(
							dispatch(
								removeTags({
									payload: {
										entityId: workspace.id,
										tagsIds: tagsToDelete.map(tag => tag.id),
									},
								}),
							).unwrap(),
						);
					}
				});
			}

			// * Deactivate workspaces
			workSpacesToDelete.forEach(workspace => {
				promises.push(
					dispatch(
						editWorkspace({
							params: {
								workspaceId: workspace.id,
							},
							payload: [
								{
									path: '/isActive',
									value: false,
									op: 'replace',
								},
							],
						}),
					).unwrap(),
				);
			});
		}
		// ! ...Partial booking only

		Promise.allSettled(promises)
			.then(() => {
				goToOfficePage();
			})
			.catch(error => console.log('error', error));
	};

	// * Workspaces
	const [selectedWorkspace, setSelectedWorkspace] = useState<WorkSpaceInfo | null>(null);
	const editWorkSpace = (workspace: WorkSpaceInfo) => setSelectedWorkspace(workspace);
	const closeModal = () => setSelectedWorkspace(null);
	const newWorkspace = {
		roomId,
		name: '',
		id: `new_${v4()}`,
		tags: [],
		description: '',
		isActive: true,
	};

	const setWorkspaces = (workspaces: WorkSpaceInfo[]) => setValue('workspaces', workspaces);

	// * Render
	return (
		<>
			{selectedWorkspace && (
				<Modal
					show={selectedWorkspace !== null}
					toggleModal={closeModal}
					Content={
						<WorkSpaceFormModal
							roomId={roomId}
							workspace={selectedWorkspace}
							currentWorkspaces={currentWorkspaces}
							setWorkspaces={setWorkspaces}
							closeModal={closeModal}
						/>
					}
				/>
			)}

			<div className={s.container}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={s.header}>
						{office && (
							<RoomFormHeader
								officeName={office.name}
								isNewRoom={isNewRoom}
							/>
						)}

						<div className={s.button_wrapper}>
							<Button
								variant="tertiary"
								onClick={goBack}
								isLoading={isLoading}
							>
								Назад
							</Button>

							<Button
								type="submit"
								isLoading={isLoading}
							>
								Сохранить
							</Button>
						</div>
					</div>

					<div className={s.inner}>
						<div className={s.card}>
							<h3>Галерея</h3>
							<Gallery
								images={images}
								allowedToEdit={true} // Пользователь без прав не попадет на данную страницу. Не смысла в доп валидации изображений.
								saveAllImages={images => setValue('images', images)}
								fetchImage={(imageId: string) =>
									getImage({
										params: {
											imageId,
											source: 'Office',
										},
									})
								}
							/>
						</div>

						{activeRoom?.roomType.bookingRule === 'PartialBooking' && (
							<div className={s.card}>
								<h3>Места</h3>
								<AddButton
									title="Добавить место"
									onClick={() => {
										editWorkSpace(newWorkspace);
									}}
								/>

								{isPartialBooking && (
									<div className={s.workspaces}>
										{[...currentWorkspaces]
											.sort((a, b) => a.name.localeCompare(b.name))
											.map(workspace => (
												<div key={workspace.id}>
													<Workspace
														officeId={officeId}
														workspace={workspace}
														editWorkSpace={editWorkSpace}
														setWorkspaces={setWorkspaces}
														currentWorkspaces={currentWorkspaces}
													/>
												</div>
											))}
									</div>
								)}
							</div>
						)}
					</div>
				</form>
			</div>
		</>
	);
};
