import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import {
	CreateCommunicationREQ_PARAMS,
	CreateCommunicationRES,
	EditCommunicationREQ_PARAMS,
	EditCommunicationRES,
	RemoveCommunicationREQ_PARAMS,
	RemoveCommunicationRES,
} from './types';

const NAME = `${ServiceName.USER_SERVICE}/communication`;

// * Async thunks
const createCommunication = createAppAsyncThunk(`${NAME}/createCommunication`, async (payload: CreateCommunicationREQ_PARAMS, thunkAPI) => {
	const res = await apiRequest.postRequest<CreateCommunicationRES>({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createCommunication(payload),
	});

	// dispatch(getUser({
	// 	params: {
	// 		userId: userId || '',
	// 		includeCurrentAvatar: true,
	// 		includeCommunications: true,
	// 		includeCompany: true,
	// 		includeDepartment: true,
	// 		includeRole: true,
	// 		locale: 'ru',
	// 	}
	// }));

	return res.body;
});

const removeCommunication = createAppAsyncThunk(`${NAME}/removeCommunication`, async (arg: RemoveCommunicationREQ_PARAMS, thunkAPI) => {
	const { params } = arg;

	return apiRequest.delRequest<RemoveCommunicationRES>({
		url: `${NAME}/remove`,
		params,
		thunkAPI,
		action: () => removeCommunication(arg),
	});
});

const editCommunication = createAppAsyncThunk(`${NAME}/editCommunication`, async (arg: EditCommunicationREQ_PARAMS, thunkAPI) => {
	const { params, payload } = arg;

	await apiRequest.putRequest<EditCommunicationRES>({
		url: `${NAME}/edit`,
		params,
		payload,
		action: () => editCommunication(arg),
		thunkAPI,
	});
});

// * Slice
export interface State {
	createStatus: RequestStatus;
	removeStatus: RequestStatus;
	editStatus: RequestStatus;
	status: RequestStatus;
}

export const initialState: State = {
	createStatus: RequestStatus.still,
	removeStatus: RequestStatus.still,
	editStatus: RequestStatus.still,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createCommunication.pending, state => {
			state.createStatus = RequestStatus.loading;
		});
		builder.addCase(createCommunication.fulfilled, state => {
			state.createStatus = RequestStatus.success;
		});
		builder.addCase(createCommunication.rejected, state => {
			state.createStatus = RequestStatus.failed;
		});

		builder.addCase(removeCommunication.pending, state => {
			state.removeStatus = RequestStatus.loading;
		});
		builder.addCase(removeCommunication.fulfilled, state => {
			state.removeStatus = RequestStatus.success;
		});
		builder.addCase(removeCommunication.rejected, state => {
			state.removeStatus = RequestStatus.failed;
		});

		builder.addCase(editCommunication.pending, state => {
			state.editStatus = RequestStatus.loading;
		});
		builder.addCase(editCommunication.fulfilled, state => {
			state.editStatus = RequestStatus.success;
		});
		builder.addCase(editCommunication.rejected, state => {
			state.editStatus = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createCommunication,
	removeCommunication,
	editCommunication,
};
