import React, { ButtonHTMLAttributes, DetailedHTMLProps, useState } from 'react';
import s from './Tooltip.module.scss';
import { autoUpdate, flip, FloatingPortal, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react';

interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	text: string;
}

export const Tooltip: React.FC<Props> = props => {
	const { text, children, ...divProps } = props;

	const [isOpen, setIsOpen] = useState(false);

	const { x, y, refs, strategy, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		placement: 'top',
		// Make sure the tooltip stays on the screen
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			flip({
				fallbackAxisSideDirection: 'start',
			}),
			shift(),
		],
	});

	// Event listeners to change the open state
	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	// Role props for screen readers
	const role = useRole(context, { role: 'tooltip' });

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

	return (
		<>
			<div
				{...divProps}
				className={s.container}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				{children}
			</div>

			<FloatingPortal>
				{isOpen && (
					<div
						className={s.tooltip}
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							zIndex: 'var(--z-index-tooltip)',
						}}
						{...getFloatingProps()}
					>
						{text}
					</div>
				)}
			</FloatingPortal>
		</>
	);
};
