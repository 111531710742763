import { DateTime } from 'luxon';
import { MOCK_VACATIONS } from '../../../../mock';
import s from './VacationBar.module.scss';
import { FC } from 'react';

interface Props {
	user: {
		id: string;
		name: string;
	};
	monthIndex: number;
	selectedYear: {
		year: number;
		months: {
			name: string;
			holidays: string;
		}[];
	};
}

const CELL_SIZE = 1.5;
const CELL_GAP = 0.125;
const MONTH_GAP = 0.75;

export const VacationBar: FC<Props> = props => {
	const {
		user, //
		monthIndex,
		selectedYear,
	} = props;

	const vacation = MOCK_VACATIONS.find(vacation => vacation.user.id === user.id)?.vacations.find(
		vacation => DateTime.fromFormat(vacation.startDate, 'yyyy-MM-dd').month === monthIndex + 1,
	);

	if (!vacation) {
		return null;
	}

	const vacationStartDateLuxon = DateTime.fromFormat(vacation.startDate, 'yyyy-MM-dd');
	const vacationEndDateLuxon = DateTime.fromFormat(vacation.endDate, 'yyyy-MM-dd');

	const startDay = vacationStartDateLuxon.day;
	const startMonth = vacationStartDateLuxon.month;

	const endDay = vacationEndDateLuxon.day;
	const endMonth = vacationEndDateLuxon.month;

	const monthDiff = endMonth - startMonth;

	// * Width calculation
	// - Cells calculation
	const monthGapWidth = MONTH_GAP * monthDiff;

	let cellsWidth = 0;

	if (monthDiff === 0) {
		const cellsCount = endDay - startDay + 1;
		cellsWidth = cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP;
	} else {
		const vacationMonths = selectedYear.months.slice(monthIndex, endMonth);
		vacationMonths.forEach((month, index) => {
			if (index === 0) {
				const cellsCount = month.holidays.length - startDay + 1;
				cellsWidth = cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP;
			} else if (index === vacationMonths.length - 1) {
				const cellsCount = endDay;
				cellsWidth = cellsWidth + (cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP);
			} else {
				const cellsCount = month.holidays.length;
				cellsWidth = cellsWidth + (cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP);
			}
		});
	}

	// * Render
	return (
		<div
			className={s.container}
			style={{
				left: (startDay - 1) * CELL_SIZE + CELL_GAP * (startDay - 1) + 'rem',
				width: monthGapWidth + cellsWidth + 'rem',
			}}
		/>
	);
};
