import { FC } from 'react';
import { actionsEventCalendar } from 'src/app/redux/state/event-calendar/slice';
import { useAppDispatch } from 'src/app/redux/utils';
import { UserListItem, UsersList } from 'src/features/_card_lists/UsersList';

interface Props {
	users: UserListItem[]; // TODO: Тип берется аж из SingleOfficePage.
}

export const UsersListContainer: FC<Props> = props => {
	const { users } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo } = actionsEventCalendar;

	const onRemoveUser = (userId: string) => {
		dispatch(setSelectedUsersInfo(users.filter(user => user.id !== userId)));
	};

	// * Render
	return (
		<UsersList
			users={users}
			onRemoveUser={onRemoveUser}
		/>
	);
};
