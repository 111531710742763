import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { CommunicationInfo, UserAvatar } from 'src/app/redux/state/user/user/types';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { UserDropDown } from 'src/pages/UsersPage/ui/UserDropDown/UserDropDown';
import s from '../Users.module.scss';

export interface UsersTableDataItem {
	id: string;
	avatar?: UserAvatar;
	avatarId: string;
	name: string;
	department: string;
	departmentId: string;
	position: string;
	role: string;
	isActive: boolean;
	isPending: boolean;
	contacts: CommunicationInfo[];
}

const columnHelper = createColumnHelper<UsersTableDataItem>();

export const getColumnsUsers = (includeContact: boolean, includeRole: boolean) => {
	const prepared = [
		{
			isVisible: true,
			element: columnHelper.accessor('avatarId', {
				header: '',
				cell: ({ row, getValue }) => (
					<React.Fragment>
						<Avatar
							name={row.original.name}
							avatarId={getValue<string>()}
						/>
					</React.Fragment>
				),
			}),
		},
		{
			isVisible: true,
			element: columnHelper.accessor('name', {
				header: 'ФИО',
				cell: ({ row, getValue }) => (
					<React.Fragment>
						<h5>{getValue<string>()}</h5>
						<div className={s.role}>{row.original.position}</div>
					</React.Fragment>
				),
			}),
		},
		{
			isVisible: true,
			element: columnHelper.accessor('department', {
				header: 'ДЕПАРТАМЕНТ',
				cell: info => info.getValue(),
			}),
		},
		{
			isVisible: includeRole,
			element: columnHelper.accessor('role', {
				header: 'РОЛЬ',
				cell: info => info.getValue(),
			}),
		},
		{
			isVisible: includeContact,
			element: columnHelper.accessor('contacts', {
				header: 'ПОЧТА',
				cell: ({ getValue }) => (
					<React.Fragment>
						{getValue().map(item => (
							<div key={item.id}>{item.value}</div>
						))}
					</React.Fragment>
				),
			}),
		},
		{
			isVisible: includeRole,
			element: columnHelper.accessor('id', {
				header: '',
				cell: ({ row, getValue }) => (
					<React.Fragment>
						<UserDropDown
							id={getValue<string>()}
							isActive={row.original.isActive}
							isPending={row.original.isPending}
							contacts={row.original.contacts}
						/>
					</React.Fragment>
				),
				meta: {
					style: { width: '1%' }, // ! Работает как fit-content.
				},
			}),
		},
	];

	const result = prepared.filter(item => item.isVisible).map(item => item.element);

	return result;
};
