import React, { useEffect } from 'react';
import s from './SingleOfficePage.module.scss';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { Content } from './Content/Content';
import { OfficeCardDetailed } from './OfficeCardDetailed/OfficeCardDetailed';
import { officeServiceActions } from 'src/app/redux/state/office/actions';
import { companyServiceActions } from 'src/app/redux/state/company/actions';

export const SingleOfficePage: React.FC = () => {
	// * Actions
	const dispatch = useAppDispatch();
	const { getCompany } = companyServiceActions.company.async;
	const { getOffice } = officeServiceActions.office.async;
	const { clearOfficeInfo } = officeServiceActions.office.actions;

	// * Selectors
	const company = useAppSelector(state => state.company_service.company.company);
	const office = useAppSelector(state => state.office_service.office.office);
	const rooms = useAppSelector(state => state.office_service.office.rooms);
	const userIds = useAppSelector(state => state.office_service.office.userIds);

	// * Initialize
	const params: any = useParams();
	const officeId: string = params.officeId;

	useEffect(() => {
		const promiseGetCompany = dispatch(
			getCompany({
				params: {
					includeOffices: false,
				},
			}),
		);

		const promiseGetOffice = dispatch(
			getOffice({
				params: { officeId },
			}),
		);

		return () => {
			promiseGetCompany.abort();
			promiseGetOffice.abort();
			dispatch(clearOfficeInfo());
		};
	}, []);

	// * Conditions
	const officeLoaded = company && office && rooms && userIds;

	// * Render
	return (
		<PageDecorator>
			<div className={s.container}>
				{officeLoaded && (
					<OfficeCardDetailed
						office={office}
						companyName={company.name}
						peopleAmount={userIds.length}
						roomsAmount={rooms.length}
					/>
				)}

				<Content />
			</div>
		</PageDecorator>
	);
};
