import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { getFilledRubrics } from 'src/pages/wiki/lib/getFilledRubrics';
import { useRights } from 'src/shared/hooks/useRights';
import { ContentProps } from '../_types';
import s from './ContentMode.module.scss';
import { Articles } from './components/Articles/Articles';
import { EditButtons } from './components/EditButtons/EditButtons';
import { RubricList } from './components/RubricList/RubricList';
import { useGetWiki } from './hooks/useGetWiki';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ModalContentCreateNew } from 'src/pages/wiki/_common_components/modals/ModalCreateNew/ModalContentCreateNew';
import cn from 'classnames';
import { getIsActive } from 'src/pages/wiki/lib/getIsActive';
import { ReactComponent as LockSVG } from 'src/shared/assets/svg_icons/action/lock.svg';
import { ReactComponent as UnlockSVG } from 'src/shared/assets/svg_icons/action/unlock.svg';

interface Props extends ContentProps {
	isPrivate: boolean;
}

export const Content: React.FC<Props> = props => {
	const { isPrivate } = props;

	// * Selectors
	const searchMode = useAppSelector(state => state.wiki.searchMode);
	const editMode = useAppSelector(state => state.wiki.editModeWiki);
	const archiveMode = useAppSelector(state => state.wiki.archiveMode);

	// * API
	const wikiTree = useGetWiki(isPrivate, archiveMode);

	// - active rubric
	const [activeRubricId, setActiveRubricId] = useState<string | null>(null);
	const activeRubric = wikiTree?.find(wikiItem => wikiItem.id === activeRubricId);

	// * Initial
	useEffect(() => {
		if (!activeRubricId && wikiTree) {
			setActiveRubricId(wikiTree[0].id);
		}
	}, [activeRubricId, wikiTree]);

	// * Conditions
	const allowedToEditWiki = useRights(13) && isPrivate;

	// * Create sub-rubric
	const [showCreateModal, setCreateModal] = useState(false);
	const toggleCreateModal = () => setCreateModal(prevState => !prevState);

	// * Render
	return (
		<>
			{showCreateModal && (
				<ModalNewProto
					width="s"
					isOpen={showCreateModal}
					onClose={toggleCreateModal}
				>
					<ModalContentCreateNew
						toggleModal={toggleCreateModal}
						initialRubricId={activeRubric?.id}
						createType="sub-rubric"
					/>
				</ModalNewProto>
			)}

			<div className={s.container}>
				{/* ! Displayed only outside of search mode */}
				{!searchMode && wikiTree && (
					<RubricList
						className={s.rubric_list}
						rubrics={allowedToEditWiki ? wikiTree : getFilledRubrics(wikiTree)}
						activeRubricId={activeRubricId}
						setActiveRubric={setActiveRubricId}
					/>
				)}

				{!searchMode && activeRubric && (
					<div className={s.sections_container}>
						<div className={s.edit_wrapper}>
							<div className={cn(s.rubric_title, getIsActive(activeRubric, true) && s.disabled)}>
								{activeRubric.name}

								{editMode && <>{'access' in activeRubric && !activeRubric.access ? <LockSVG /> : <UnlockSVG />}</>}
							</div>

							{editMode && (
								<EditButtons
									id={activeRubric.id}
									addAction={getIsActive(activeRubric) ? toggleCreateModal : undefined}
									hasEditAction={getIsActive(activeRubric)}
									isPublic={'access' in activeRubric && !!activeRubric.access}
									name={activeRubric.name}
									isActive={getIsActive(activeRubric)}
									type="rubric"
								/>
							)}
						</div>

						<div className={s.articles}>
							{activeRubric.children
								.filter(subRubric => subRubric.articles.length > 0 || allowedToEditWiki)
								.map(subRubric => (
									<Articles
										key={subRubric.id}
										title={subRubric.name}
										articles={subRubric.articles}
										isPrivate={isPrivate}
										archived={getIsActive(subRubric, true)}
										archivedParent={getIsActive(activeRubric, true)}
										rubricId={activeRubric.id}
										subRubricId={subRubric.id}
									/>
								))}

							{activeRubric.articles.length > 0 && (
								<Articles
									articles={activeRubric.articles}
									archivedParent={getIsActive(activeRubric, true)}
									rubricId={activeRubric.id}
									isPrivate={isPrivate}
								/>
							)}

							{getFilledRubrics([activeRubric]).length === 0 && (
								<Articles
									articles={[]}
									isPrivate={isPrivate}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
