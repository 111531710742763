import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { Radio } from 'src/shared/ui/_inputs/Radio';
import { Heading } from 'src/shared/ui/Heading';
import * as yup from 'yup';
import s from './ArticleAttachContent.module.scss';
import { ArticleRadioBtn } from './ArticleRadioBtn';

interface FavoritesInfo {
	id: string;
	name: string;
}

interface Props {
	articleId: string;
	articleName: string;
	favorites: string[];
	isLoading: boolean;
	action: () => void;
	onClose: () => void;
}

export const ArticleAttachContent: FC<Props> = props => {
	const { articleId, articleName, favorites, isLoading, action, onClose } = props;

	// * API
	const [deleteFavorite, { isLoading: isDeleteLoading }] = wiki_serviceAPI.useDeleteFavoritesByArticleIdMutation();

	// * Form
	const defaultValues = {
		article: favorites[0] || '',
	};

	const schema = yup.object().shape({
		article: yup.string().required(''),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { control, setValue, handleSubmit } = formMethods;

	// * Submit
	const onSubmit = (data: typeof defaultValues) => {
		deleteFavorite({ articleId: data.article })
			.unwrap()
			.then(() => {
				action();
				onClose();
			})
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={s.form}
			>
				<Heading
					level={2}
					className={s.wrapper}
				>
					Закрепить статью
				</Heading>

				<div className={s.wrapper}>
					<p>Достигнуто максимальное количество закреплённых статей.</p>
					<p>Выберите статью, вместо которой будет отображаться статья</p>
					<p className={s.wrapper_name}>{articleName}</p>
				</div>

				<div className={s.list}>
					{favorites.map((id: string) => (
						<Controller
							key={id}
							name="article"
							control={control}
							render={({ field }) => (
								<ArticleRadioBtn
									{...field}
									className={s.radio}
									id={id}
									checked={field.value === id}
									onChange={() => setValue('article', id)}
								/>
							)}
						/>
					))}
				</div>

				<ButtonPair
					primaryText="Закрепить"
					primaryIsLoading={isLoading || isDeleteLoading}
					secondaryText="Отмена"
					secondaryOnClick={onClose}
					secondaryIsLoading={isLoading || isDeleteLoading}
				/>
			</form>
		</FormProvider>
	);
};
