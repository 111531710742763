import React, { useMemo, useState } from 'react';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg/action/archive.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg/action/un-archive.svg';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ArchiveModal } from '../ArchiveModal/ArchiveModal';
import { PositionForm } from '../PositionForm/PositionForm';
import { RoleForm } from '../RoleForm/RoleForm';
import s from './ModalsMenu.module.scss';

interface Props {
	id: string;
	isActive: boolean;
	name?: string;

	localizationId?: string;
	description?: string;
	rights?: string[];

	isRoleMenu: boolean;
}

export const ModalsMenu: React.FC<Props> = props => {
	const {
		id,
		isActive,
		name = '',

		localizationId = '',
		description = '',
		rights,

		isRoleMenu,
	} = props;

	// * Modals
	const [isArchiveModal, setIsArchiveModal] = useState(false);
	const toggleArchiveModal = () => setIsArchiveModal(prevState => !prevState);

	const [isEditRole, setIsEditRole] = useState(false);
	const toggleEditRole = () => setIsEditRole(prevState => !prevState);

	const [isEditPosition, setIsEditPosition] = useState(false);
	const toggleEditPosition = () => setIsEditPosition(prevState => !prevState);

	// * Menu
	const DropDownItems = useMemo(
		() =>
			[
				{
					icon: <EditSVG />,
					isVisible: true,
					title: 'Редактировать',
					action: () => (isRoleMenu ? toggleEditRole() : toggleEditPosition()),
				},
				{
					icon: <ArchiveSVG />,
					isVisible: isActive,
					title: 'Архивировать',
					action: () => toggleArchiveModal(),
				},
				{
					icon: <UnArchiveSVG />,
					isVisible: !isActive,
					title: 'Восстановить',
					action: () => toggleArchiveModal(),
				},
			].filter(item => item.isVisible),
		[id, isActive],
	);

	// * Render
	return (
		<div className={s.container}>
			<DropDownMenu
				items={DropDownItems}
				value={id}
			/>

			{isEditRole && (
				<ModalNewProto
					isOpen={isEditRole}
					onClose={toggleEditRole}
				>
					<RoleForm
						roleId={id}
						localizationId={localizationId}
						name={name}
						description={description}
						roleRights={rights}
						onCloseModal={toggleEditRole}
					/>
				</ModalNewProto>
			)}

			{isEditPosition && (
				<ModalNewProto
					isOpen={isEditPosition}
					onClose={toggleEditPosition}
				>
					<PositionForm
						id={id}
						name={name}
						onCloseModal={toggleEditPosition}
					/>
				</ModalNewProto>
			)}

			{isArchiveModal && (
				<ModalNewProto
					isOpen={isArchiveModal}
					onClose={toggleArchiveModal}
				>
					<ArchiveModal
						isActive={isActive}
						isRole={isRoleMenu}
						id={id}
						name={name}
						onCloseModal={toggleArchiveModal}
					/>
				</ModalNewProto>
			)}
		</div>
	);
};
