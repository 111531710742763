import { FC, useState } from 'react';
import { useLoginMutation } from 'src/app/redux/queries/auth-service/authApi';
import { actionsAuth } from 'src/app/redux/state/auth/slice';
import { LoginRequestBody } from 'src/app/redux/state/auth/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { AuthForm } from '../AuthForm/AuthForm';

export const Login: FC = () => {
	// * SHARE - Set data when login happens on another domain.
	const dispatch = useAppDispatch();
	const { setTokens } = actionsAuth;

	// * Error
	const [errorMessage, setErrorMessage] = useState('');

	// * Async actions
	const [login, { isLoading }] = useLoginMutation();

	// - Log in
	const onSubmit = (values: LoginRequestBody) => {
		login(values)
			.unwrap()
			.then(res => {
				setErrorMessage('');
				dispatch(setTokens(res));
			})
			.catch(error => {
				const message = error?.data?.Message;
				message && setErrorMessage('Неверный логин или пароль');
			});
	};

	return (
		<AuthForm
			isLogin
			isLoading={isLoading}
			onSubmit={onSubmit}
			errorMessage={errorMessage}
		/>
	);
};
