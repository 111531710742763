import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/app/router/paths';

export const useCreateArticle = (rubricId: string | null, subRubricId: string | null, articleId: string | null) => {
	// * Router
	const navigate = useNavigate();

	// * User actions
	const toNewArticlePage = () => {
		navigate(`${routerPath.wiki.page + routerPath.wiki.newArticles}/${rubricId}/${subRubricId}/${articleId}`);
	};

	return { toNewArticlePage };
};
