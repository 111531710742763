import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { survey_serviceAPI } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { getSortGroupByUser, getUsers } from '../../../../utils/utils';
import { FillingOutForm } from '../../../../components/FillingOutForm/FillingOutForm';
import s from './UsersContent.module.scss';

interface Props {
	isAnonymous: boolean;
}

export const UsersContent: React.FC<Props> = ({ isAnonymous }) => {
	// * Router
	const { surveyId } = useParams<'surveyId'>();

	// * API
	const { currentData: surveyInfo } = survey_serviceAPI.useGetSurveysSurveyidQuery(
		{
			surveyid: surveyId as string,
			includeuseranswers: true,
			includeuserinfo: true,
		},
		{ skip: !surveyId },
	);

	// * Users
	const users = getUsers(isAnonymous, surveyInfo?.groups);

	const usersOptions = users.map((user, index) => {
		const name = isAnonymous ? `Сотрудник ${index + 1}` : `${user.lastName} ${user.firstName}`;

		return {
			id: isAnonymous ? user.firstName : user.id,
			name,
		};
	});

	const [selectedUser, setSelectesUser] = useState(usersOptions.length > 0 ? usersOptions[0] : { name: 'name', id: '' });

	// * API
	const { currentData: surveyIdData, isLoading } = survey_serviceAPI.useGetSurveysSurveyidQuery(
		{
			surveyid: surveyId as string,
			includeuseranswers: true,
			includeImages: true,
			userid: selectedUser.id || '',
		},
		{ skip: !selectedUser.id || !!isAnonymous },
	);

	const sortedGroup = isAnonymous ? getSortGroupByUser(selectedUser.id, surveyInfo?.groups) : surveyIdData?.groups;

	// * Render
	return (
		<div className={s.container}>
			<SelectSingle
				placeholder="Выберите сотрудника"
				keyNames={{ name: 'name', value: 'id' }}
				selectedOption={selectedUser}
				setSelectedOption={option => setSelectesUser(option)}
				options={usersOptions}
			/>

			<FillingOutForm
				groups={sortedGroup || []}
				notClickable
				isLoading={isLoading}
			/>
		</div>
	);
};
