import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { getApiUrl } from 'src/shared/lib/app';
import { actionsNotifications } from '../../features/notifications/_BLL/slice';
import { actionsAuth } from '../redux/state/auth/slice';
import { ServiceName } from './api_types';
import { getErrorMessage } from '../errors/getErrorMessage';

const { setTokens, logout } = actionsAuth;
const mutex = new Mutex();

const baseUrl = getApiUrl();

const refreshToken = localStorage.getItem('refresh_token');

const baseQuery: BaseQueryFn = fetchBaseQuery({
	baseUrl,
	credentials: 'same-origin',
	headers: {
		Accept: '*/*',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
	},
	prepareHeaders: headers => {
		const accessToken = localStorage.getItem('access_token');
		const token = accessToken ? accessToken : '';
		if (token) {
			headers.set('token', token);
		}
		return headers;
	},
});

type BaseQueryFnReAuth = BaseQueryFn<any, unknown, unknown, { ignoreErrorNotification?: boolean }, {}>;

export const baseQueryWithReAuth: BaseQueryFnReAuth = async (args, api, extraOptions) => {
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);
	// @ts-ignore
	const status = result?.error?.status;

	if (status === 401 && refreshToken) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			try {
				const accessToken = localStorage.getItem('access_token');
				const token = accessToken ? accessToken : '';

				const refreshResult = await fetch(`${baseUrl}/${ServiceName.AUTH_SERVICE}/auth/refresh`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Request-Headers': '*',
						token: token,
					},
					referrerPolicy: 'no-referrer',
					body: JSON.stringify({
						refreshToken,
					}),
				});

				const parsedResponse = await refreshResult.json();

				if (parsedResponse.accessToken) {
					// Store new tokens
					api.dispatch(
						setTokens({
							refreshToken: parsedResponse.refreshToken,
							accessToken: parsedResponse.accessToken,
						}),
					);
					// Retry original query with refresh tokens
					result = await baseQuery(args, api, extraOptions);
				} else {
					api.dispatch(logout());
				}
			} finally {
				release();
			}
		} else {
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}

	// TODO: Обрабатывать массив с ошибками... Второй вариант развития событий на бэке.
	if ((status < 200 || status >= 300) && status !== 401) {
		if (extraOptions?.ignoreErrorNotification === true) {
			return result;
		} else {
			// @ts-ignore
			if ('error' in result && 'data' in result.error && 'errors' in result.error.data && Array.isArray(result.error.data.errors)) {
				result.error.data.errors.forEach(message => {
					api.dispatch(
						actionsNotifications.addNotification({
							type: 'error',
							message: getErrorMessage(message),
						}),
					);
				});
			} else {
				api.dispatch(
					actionsNotifications.addNotification({
						type: 'error',
						// @ts-ignore
						message: result.error?.data?.Message ? getErrorMessage(result.error.data.Message) : 'Произошла ошибка',
					}),
				);
			}
		}
	}

	return result;
};
