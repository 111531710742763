import React from 'react';
import s from './CardTitle.module.scss';
import { ReactComponent as Logo } from 'src/shared/assets/svg/tercom-logo.svg';
import { ReactComponent as Trapezoid } from 'src/shared/assets/svg/trapezoid.svg';

export const CardTitle: React.FC<{ title: string }> = ({ title }) => {
	return (
		<div className={s.cardTitle}>
			<h2
				className={s.cardTitle__title}
				dangerouslySetInnerHTML={{ __html: title }}
			/>
			<Logo className={s.cardTitle__img} />
			<Trapezoid className={s.cardTitle__border} />
		</div>
	);
};
