import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	GetFeedbackEnvironmentsApiArg,
	GetFeedbackFindApiArg,
	GetFeedbackGetApiArg,
	GetFilesByFileIdApiArg,
	PostFeedbackCreateApiArg,
	PostFeedbackRepliesApiArg,
	PostFeedbackRepliesByFeedbackReplyIdFilesApiArg,
	PostFeedbacksByFeedbackIdFilesApiArg,
	PutFeedbackEditstatusApiArg,
} from './types/reqTypes';
import {
	GetFeedbackEnvironmentsApiResponse,
	GetFeedbackFindApiResponse,
	GetFeedbackGetApiResponse,
	GetFilesByFileIdApiResponse,
	PostFeedbackCreateApiResponse,
	PostFeedbackRepliesApiResponse,
	PostFeedbackRepliesByFeedbackReplyIdFilesApiResponse,
	PostFeedbacksByFeedbackIdFilesApiResponse,
	PutFeedbackEditstatusApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.FEEDBACK_SERVICE}` as const;

export const feedback_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['FeedbackEnvironments', 'FeedbackReplies', 'Feedbacks', 'Files'] as const,
	endpoints: build => ({
		getFeedbackEnvironments: build.query<GetFeedbackEnvironmentsApiResponse, GetFeedbackEnvironmentsApiArg>({
			query: () => ({ url: `${reducerPath}/feedback-environments` }),
			providesTags: ['FeedbackEnvironments'],
		}),
		postFeedbackReplies: build.mutation<PostFeedbackRepliesApiResponse, PostFeedbackRepliesApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback-replies`, method: 'POST', body: queryArg.createFeedbackReplyRequest }),
			invalidatesTags: ['FeedbackReplies'],
		}),
		getFeedbackGet: build.query<GetFeedbackGetApiResponse, GetFeedbackGetApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback/get`, params: { feedbackId: queryArg.feedbackId } }),
			providesTags: ['Feedbacks'],
		}),
		getFeedbackFind: build.query<GetFeedbackFindApiResponse, GetFeedbackFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/feedback/find`,
				body: queryArg.userData,
				params: {
					ownmessagesonly: queryArg.ownmessagesonly,
					feedbackstatus: queryArg.feedbackstatus,
					feedbackenvironment: queryArg.feedbackenvironment,
					feedbacktype: queryArg.feedbacktype,
					orderbydescending: queryArg.orderbydescending,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Feedbacks'],
		}),
		postFeedbackCreate: build.mutation<PostFeedbackCreateApiResponse, PostFeedbackCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback/create`, method: 'POST', body: queryArg.createFeedbackRequest }),
			invalidatesTags: ['Feedbacks'],
		}),
		putFeedbackEditstatus: build.mutation<PutFeedbackEditstatusApiResponse, PutFeedbackEditstatusApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback/editstatus`, method: 'PUT', body: queryArg.editFeedbackStatusesRequest }),
			invalidatesTags: ['Feedbacks'],
		}),
		postFeedbacksByFeedbackIdFiles: build.mutation<PostFeedbacksByFeedbackIdFilesApiResponse, PostFeedbacksByFeedbackIdFilesApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedbacks/${queryArg.feedbackId}/files`, method: 'POST', body: queryArg.body }),
			invalidatesTags: ['Files'],
		}),
		postFeedbackRepliesByFeedbackReplyIdFiles: build.mutation<PostFeedbackRepliesByFeedbackReplyIdFilesApiResponse, PostFeedbackRepliesByFeedbackReplyIdFilesApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback-replies/${queryArg.feedbackReplyId}/files`, method: 'POST', body: queryArg.body }),
			invalidatesTags: ['Files'],
		}),
		getFilesByFileId: build.query<GetFilesByFileIdApiResponse, GetFilesByFileIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/files/${queryArg.fileId}`,
				responseHandler: (response: Response) => {
					const header = response.headers.get('Content-Disposition'); // TODO: Добавить на Бэке 'Access-Control-Expose-Headers': 'Content-Disposition'.
					const parts = header!.split(';');
					const filename = parts[1].split('=')[1];

					return response.blob().then(res => ({
						name: decodeURI(filename),
						blob: res, // ! Парсинг в Blob
					}));
				},
			}),
			providesTags: ['Files'],
		}),
	}),
});

export const {
	useGetFeedbackEnvironmentsQuery,
	usePostFeedbackRepliesMutation,
	useGetFeedbackGetQuery,
	useGetFeedbackFindQuery,
	usePostFeedbackCreateMutation,
	usePutFeedbackEditstatusMutation,
	usePostFeedbacksByFeedbackIdFilesMutation,
	usePostFeedbackRepliesByFeedbackReplyIdFilesMutation,
	useGetFilesByFileIdQuery,
	useLazyGetFilesByFileIdQuery,
} = feedback_serviceAPI;
