import { ImageContent as MediaContent } from 'src/app/redux/queries/survey-service/types';
import { GalleyFile } from 'src/shared/ui/_galleries/Gallery/_types';
import { v4 } from 'uuid';
import { base64InfoToString } from '../base64InfoToString/base64InfoToString';
import { base64ToBlob } from '../base64ToBlob/base64ToBlob';
import { extensionToContentType } from '../extensionToContentType/extensionToContentType';
import { isValidBase64DataString } from '..';

// TODO: Почти дубликат createGalleyFileFromImageInfo
export const imageContentToGalleyFile = (mediaContent: MediaContent): GalleyFile => {
	const contentType = extensionToContentType(mediaContent.extension);
	const base64String = isValidBase64DataString(mediaContent.content) ? mediaContent.content : base64InfoToString(contentType, mediaContent.content);

	const blob = base64ToBlob(base64String);
	const file = new File([blob], mediaContent.name ? mediaContent.name : 'unnamed', { type: contentType });

	return Object.assign(file, {
		id: `new_${v4()}`,
		parentId: null,
		preview: URL.createObjectURL(blob),
	});
};
