import ReactDOM from 'react-dom';
import React from 'react';
import s from './Backdrop.module.scss';

interface Props {
	isActive: boolean;
	onClick: any;
	children?: React.ReactNode;
	zIndex: 1 | 2 | 3 | 4 | 5;
}

export const Backdrop: React.FC<Props> = props => {
	const { isActive, onClick, children, zIndex = 1 } = props;

	const content = (
		<div
			className={s.container}
			style={{
				display: `${isActive ? 'block' : 'none'}`,
				zIndex: `calc(${zIndex} * var(--z-index-modal))`,
			}}
			onClick={onClick}
		>
			{children}
		</div>
	);

	const container = document.getElementById('backdrop-portal') as HTMLElement;

	return ReactDOM.createPortal(content, container);
};
