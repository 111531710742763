import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/query';
import { createContext, FC, ReactNode, useContext } from 'react';
import { GetUsersApiArg } from 'src/app/redux/queries/filter-service/types/regTypes';
import { UserInfoFindResultResponse } from 'src/app/redux/queries/filter-service/types/resTypes';

interface Context {
	originalArgs?: GetUsersApiArg;
	getUserFilter: LazyQueryTrigger<QueryDefinition<GetUsersApiArg, BaseQueryFn, 'Users', UserInfoFindResultResponse, 'filterservice'>>;
}

interface Props extends Context {
	children: ReactNode;
}

const GetUsersContext = createContext<Context | null>(null);

export const GetUsersContextProvider: FC<Props> = props => {
	const {
		originalArgs,
		getUserFilter,

		children,
	} = props;

	// * Render
	return (
		<GetUsersContext.Provider
			value={{
				originalArgs,
				getUserFilter,
			}}
		>
			{children}
		</GetUsersContext.Provider>
	);
};

export const useGetUsersContext = () => {
	const context = useContext(GetUsersContext);

	if (!context) {
		throw new Error('useGetUsersContext must be used within GetUsersContext');
	}

	return context;
};
