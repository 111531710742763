import React from 'react';
import s from './ChipCategory.module.scss';

interface Props {
	color?: string;
	name?: string;
}

export const ChipCategory: React.FC<Props> = props => {
	const { color, name } = props;

	// * Render
	return (
		<div
			className={s.container}
			style={{ backgroundColor: color }}
		>
			{name}
		</div>
	);
};
