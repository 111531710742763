import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	GetActionCountsApiArg,
	GetAdminFindApiArg,
	GetGraphicalUserInterfaceGetApiArg,
	PatchGraphicalUserInterfaceEditApiArg,
	PostAdminInstallApiArg,
	PutActionCountsApiArg,
	PutAdminEditApiArg,
} from './types/regTypes';
import {
	GetActionCountsApiResponse,
	GetAdminFindApiResponse,
	GetGraphicalUserInterfaceGetApiResponse,
	PatchGraphicalUserInterfaceEditApiResponse,
	PostAdminInstallApiResponse,
	PutActionCountsApiResponse,
	PutAdminEditApiResponse,
} from './types/resTypes';

export const reducerPath = `${ServiceName.ADMIN_SERVICE}` as const;

export const admin_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['ActionCounts', 'Admin', 'GraphicalUserInterface'] as const,
	endpoints: build => ({
		// The method allows to increase the action counter.
		putActionCounts: build.mutation<PutActionCountsApiResponse, PutActionCountsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/ActionCounts`,
				method: 'PUT',
				params: { actionType: queryArg.actionType },
			}),
			invalidatesTags: ['ActionCounts'],
		}),

		// The method that displays statistics on the action counter
		getActionCounts: build.query<GetActionCountsApiResponse, GetActionCountsApiArg>({
			query: () => ({
				url: `${reducerPath}/ActionCounts`,
			}),
			providesTags: ['ActionCounts'],
		}),

		getAdminFind: build.query<GetAdminFindApiResponse, GetAdminFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Admin/find`,
				params: {
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Admin'],
		}),
		putAdminEdit: build.mutation<PutAdminEditApiResponse, PutAdminEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Admin/edit`,
				method: 'PUT',
				body: queryArg.body,
			}),
			invalidatesTags: ['Admin'],
		}),
		postAdminInstall: build.mutation<PostAdminInstallApiResponse, PostAdminInstallApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Admin/install`,
				method: 'POST',
				body: queryArg.installAppRequest,
			}),
			invalidatesTags: ['Admin'],
		}),
		getGraphicalUserInterfaceGet: build.query<GetGraphicalUserInterfaceGetApiResponse, GetGraphicalUserInterfaceGetApiArg>({
			query: () => ({ url: `${reducerPath}/GraphicalUserInterface/get` }),
			providesTags: ['GraphicalUserInterface'],
		}),
		patchGraphicalUserInterfaceEdit: build.mutation<PatchGraphicalUserInterfaceEditApiResponse, PatchGraphicalUserInterfaceEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/GraphicalUserInterface/edit`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['GraphicalUserInterface'],
		}),
	}),
});

export const {
	usePutActionCountsMutation,
	useGetActionCountsQuery,
	useGetAdminFindQuery,
	usePutAdminEditMutation,
	usePostAdminInstallMutation,
	useGetGraphicalUserInterfaceGetQuery,
	usePatchGraphicalUserInterfaceEditMutation,
} = admin_serviceAPI;
