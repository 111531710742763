import React from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { ReportBug } from 'src/features/_layout/Header/ReportBug';
import { HeaderAvatar } from '../Avatar/HeaderAvatar';
import s from './HeaderDesktop.module.scss';

interface Props {
	isPrivate: boolean;
}

export const HeaderDesktop: React.FC<Props> = props => {
	const { isPrivate } = props;

	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Render
	return (
		<div className={s.container}>
			<div className={s.left}>
				<a
					className={s.title}
					href={`/users/${userId}`}
				>
					ЦИФРОВОЙ ОФИС
				</a>

				{isPrivate && <ReportBug />}
			</div>

			<HeaderAvatar isPrivate={isPrivate} />
		</div>
	);
};
