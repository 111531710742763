import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { GetWikiREQ, RubricData } from './types';

const NAME = `${ServiceName.WIKI_SERVICE}/wikiTree`;

const getWiki = createAppAsyncThunk(`${NAME}/getWiki`, async (arg: GetWikiREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<RubricData[]>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getWiki(arg),
	});
});

interface State {
	wikiTree: RubricData[] | null;
	showArchived: boolean;
	status: RequestStatus;
}

export const initialState: State = {
	wikiTree: null,
	showArchived: false,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setShowArchived: (state, action: PayloadAction<boolean>) => {
			state.showArchived = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getWiki.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getWiki.fulfilled, (state, action) => {
			state.wikiTree = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getWiki.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

// export const actionsWikiServiceWikiTree = slice.actions

export const asyncActions = {
	getWiki,
};
