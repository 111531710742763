import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { GetImageREQ, GetImageRES, ImageInfo } from './types';

const NAME = `${ServiceName.IMAGE_SERVICE}/image`;

const getImage = createAppAsyncThunk(`${NAME}/getImage`, async (arg: GetImageREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetImageRES>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getImage(arg),
		showNotification: false,
	});
});

interface State {
	image: ImageInfo | null;
	status: RequestStatus;
}

const initialState: State = {
	image: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		clearImage: state => {
			state.image = null;
		},
	},
	extraReducers: builder => {
		builder.addCase(getImage.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getImage.fulfilled, (state, action) => {
			state.image = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(getImage.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getImage,
};
