import { DateTime } from 'luxon';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { DefaultValuesForm, EditProjectArg } from 'src/pages/project_service_pages/types';
import { isDateTheSame } from 'src/shared/lib/date';

export const useEditProject = (projectId: string, defaultValues: DefaultValuesForm, onCloseModal: () => void) => {
	const promises: any[] = [];

	// * API
	const [editProject, { isLoading: isLoading1 }] = project_serviceAPI.useEditProjectMutation();
	const [editProjectDepartment, { isLoading: isLoading2 }] = project_serviceAPI.useEditProjectDepartmentMutation();

	const isEditLoading = isLoading1 || isLoading2;

	// * Submit
	const onEditSubmit = (data: DefaultValuesForm) => {
		const { status, department, endDateUtc, startDateUtc, ...restParams } = data;

		const preparedArgs: EditProjectArg[] = [];

		// - restParams
		(Object.keys(restParams) as Array<keyof typeof restParams>).forEach(key => {
			if (restParams[key] === defaultValues[key]) return;

			preparedArgs.push({
				op: 'replace',
				path: `/${key}`,
				value: restParams[key],
			});
		});

		// - status
		if (status.id !== defaultValues.status.id) {
			preparedArgs.push({
				op: 'replace',
				path: `/status`,
				value: status.id,
			});
		}

		// - startDate
		if (startDateUtc && defaultValues.startDateUtc && !isDateTheSame(startDateUtc, defaultValues.startDateUtc)) {
			const startDate = startDateUtc ? DateTime.fromJSDate(startDateUtc).set({ hour: 0 }).toISO({ includeOffset: false }) : startDateUtc;

			preparedArgs.push({
				op: 'replace',
				path: `/startDateUtc`,
				value: startDate,
			});
		}

		// - endDate
		const prevEndDate = defaultValues.endDateUtc;
		if (endDateUtc !== prevEndDate) {
			const endDate = endDateUtc ? DateTime.fromJSDate(endDateUtc).set({ hour: 0 }).toISO({ includeOffset: false }) : endDateUtc;

			if ((endDateUtc && !prevEndDate) || (!endDateUtc && prevEndDate)) {
				preparedArgs.push({
					op: 'replace',
					path: `/endDateUtc`,
					value: endDate,
				});
			}

			if (endDateUtc && defaultValues.endDateUtc && !isDateTheSame(endDateUtc, defaultValues.endDateUtc)) {
				preparedArgs.push({
					op: 'replace',
					path: `/endDateUtc`,
					value: endDate,
				});
			}
		}

		preparedArgs.length > 0 &&
			promises.push(
				editProject({
					projectId: projectId,
					editProjectRequest: preparedArgs,
				}).unwrap(),
			);

		// - department
		if (department.id !== defaultValues.department.id) {
			promises.push(
				editProjectDepartment({
					editProjectDepartmentRequest: {
						projectId: projectId,
						departmentId: department.id || undefined,
					},
				}).unwrap(),
			);
		}

		Promise.allSettled(promises)
			.then(values => {
				const errors = values.filter(value => value.status !== 'fulfilled');
				const hasErrors = errors.length > 0;

				if (!hasErrors) onCloseModal();
			})
			.catch(err => console.log('Error: ', err));
	};

	return { onEditSubmit, isEditLoading };
};
