import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useGetStatFindQuery, useGetWorkTimeMonthLimitFindQuery } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import { backendISOUTCToLocalLuxon, getMonthLeaveHours, isoToDateRangeString } from '../lib/date';
import { createFullName } from '../lib/string';

export const useGenerateTimeListUser = (luxonDate: DateTime, sourceIds: { projectId?: string; departmentId?: string }) => {
	const { projectId, departmentId } = sourceIds;

	// * API
	const {
		data: statData,
		isLoading,
		refetch,
	} = useGetStatFindQuery(
		{
			projectid: projectId ? [projectId] : undefined,
			departmentid: departmentId ? [departmentId] : undefined,
			month: luxonDate.month,
			year: luxonDate.year,
			takecount: 1000,
			skipcount: 0,
			ascendingsort: true,
		},
		{ skip: projectId === undefined && departmentId === undefined },
	);

	// - Get holidays
	const { data: workTimeMonthLimitsRes } = useGetWorkTimeMonthLimitFindQuery({
		month: luxonDate.month,
		year: luxonDate.year,
		takecount: 1000, // ! HARDCODE
		skipcount: 0, // ! HARDCODE
	});

	const workTimeMonthLimitInfo = workTimeMonthLimitsRes?.body ? workTimeMonthLimitsRes.body : [];
	const holidays = workTimeMonthLimitInfo[0]?.holidays ?? '1';

	const users = useMemo(() => {
		const prepared =
			statData?.body?.map(item => {
				const { user, companyUser, limitInfo, workTimes, leaveTimes, position } = item;

				let totalHours = 0;

				const { id: userId, firstName, lastName, middleName, imageId, isActive } = user;
				const rate = companyUser?.rate ?? 1;
				const contract = companyUser?.contractSubject?.name || '-';
				const projectsCount = workTimes.length + '';

				// - все часы отсутствия
				const totalLeaveHours = leaveTimes
					.filter(leaveTime => leaveTime.endTime)
					.reduce((sum, object) => {
						const startAt = object.managerLeaveTime ? object.managerLeaveTime.startTime : object.startTime;
						const endAt = object.managerLeaveTime ? object.managerLeaveTime.endTime : object.endTime;

						return sum + getMonthLeaveHours(luxonDate.month, new Date(startAt), new Date(endAt), holidays, rate);
					}, 0);

				const projects = workTimes.map(workTime => {
					const {
						id, //
						project,
						userHours,
						description,
						modifiedByUserAtUtc,
						managerDescription,
						managerHours,
						modifiedByManagerAtUtc,
						manager,
					} = workTime;

					totalHours += managerHours || userHours || 0;

					const dateAtUtc = modifiedByManagerAtUtc || modifiedByUserAtUtc;

					return {
						id: project.id,
						workTimeId: id,
						name: project.name ?? 'Другое',
						usersHours: managerHours || userHours || 0,
						dateEntered: dateAtUtc ? backendISOUTCToLocalLuxon(dateAtUtc).toFormat('dd.LL.yy') : '-',
						comment: managerDescription || description || '-',
						userId,
						selectedDate: luxonDate.toJSDate(),
						manager: manager ? manager.firstName + ' ' + manager.lastName : null,
						subRows: modifiedByManagerAtUtc
							? [
									{
										id: null,
										workTimeId: id,
										name: null,
										usersHours: userHours ?? 0,
										dateEntered: modifiedByUserAtUtc ? backendISOUTCToLocalLuxon(modifiedByUserAtUtc).toFormat('dd.LL.yy') : '-',
										comment: description ?? '-',
										userId,
										selectedDate: luxonDate.toJSDate(),
									},
								]
							: undefined,
					};
				});

				const leave = leaveTimes.map(leaveTime => {
					const { id, minutes, startTime, endTime, comment, leaveType, managerLeaveTime, managerInfo } = leaveTime;

					const startTimeLeave = managerLeaveTime?.startTime || startTime;
					const endTimeLeave = managerLeaveTime?.endTime || endTime;
					const minutesLeave = managerLeaveTime?.minutes || minutes;
					const commentLeave = managerLeaveTime ? managerLeaveTime.comment : comment;

					const monthLeaveByUser = getMonthLeaveHours(luxonDate.month, new Date(startTime), new Date(endTime), holidays, rate);
					const monthLeave = managerLeaveTime //
						? getMonthLeaveHours(luxonDate.month, new Date(managerLeaveTime.startTime), new Date(managerLeaveTime.endTime), holidays, rate)
						: monthLeaveByUser;

					return {
						id,
						leaveType: leaveType ?? '',
						usersHours: {
							current: monthLeave,
							total: minutesLeave / 60,
						},
						leaveDates: isoToDateRangeString(startTimeLeave, endTimeLeave),
						startTime: startTimeLeave,
						endTime: endTimeLeave,
						comment: commentLeave || '-',
						userId,
						manager: managerInfo ? managerInfo.firstName + ' ' + managerInfo.lastName : null,
						subRows: managerLeaveTime
							? [
									{
										id: null,
										usersHours: {
											current: monthLeaveByUser,
											total: minutes / 60,
										},
										leaveType: null,
										leaveDates: isoToDateRangeString(startTime, endTime),
										startTime,
										endTime,
										comment: comment || '-',
										userId,
									},
								]
							: undefined,
					};
				});

				return {
					id: userId,
					avatar: (
						<Avatar
							name={`${firstName ?? ''} ${lastName ?? ''}`.trim()}
							avatarId={imageId}
						/>
					),
					fullName: createFullName({ firstName, middleName, lastName }),
					position: position?.name ?? '',
					normHours: limitInfo?.normHours ? `${totalHours + totalLeaveHours} / ${limitInfo.normHours * rate}` : '-',
					contract,
					projectsCount,
					projects,
					leave,
					isActive,
				};
			}) || [];

		return prepared;
	}, [statData]);

	return { users, isLoading, refetch };
};
