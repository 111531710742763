import { useMemo, useState } from 'react';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import s from 'src/pages/AdminPage/style/TabContent.module.scss';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { ConditionalRendering } from 'src/shared/providers';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { STATUS_OPTIONS } from '../../consts/consts';
import { CreateButton } from '../CreateButton/CreateButton';
import { COLUMNS } from './const';

export const RoleContent = () => {
	// * API
	const { data: rolesData, isLoading } = rights_serviceAPI.useFindRolesQuery({
		skipCount: 0,
		takeCount: 1000,
		locale: 'ru',
		includeDeactivated: true,
	});

	// * Filter
	// - Status
	const [status, setStatus] = useState(STATUS_OPTIONS[0]);

	// - Name
	const [name, setName] = useState('');

	// * Roles
	const roles = useMemo(() => {
		let preparedRoles = rolesData?.body
			? rolesData.body.map(item => {
					const { localizations, rights, id, isActive } = item;
					const { name, description, id: localizationId } = localizations[0];

					const rightsIds = rights.map(right => `${right.rightId}`);

					return {
						id,
						localizationId,
						name,
						description: description || '',
						rights: rightsIds,
						isActive,
					};
				})
			: [];

		if (status.id === 'Active') preparedRoles = preparedRoles.filter(role => role.isActive);
		if (status.id === 'Archive') preparedRoles = preparedRoles.filter(role => !role.isActive);
		if (name) preparedRoles = preparedRoles?.filter(role => role.name.toLocaleLowerCase().includes(name.toLocaleLowerCase().trim()));

		return preparedRoles;
	}, [rolesData, status.id, name]);

	// * Render
	return (
		<div className={s.container}>
			<div className={s.filters}>
				<SelectSingle
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					selectedOption={status}
					setSelectedOption={setStatus}
					options={STATUS_OPTIONS}
				/>

				<TextInput
					placeholder="Поиск по названию"
					value={name}
					onChange={event => setName(event.currentTarget.value)}
					RightIcon={<SearchSVG />}
				/>
			</div>

			<CreateButton isRoleContent />

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={roles.length > 0}
				FailedResult={<EmptyData />}
				LoadedResult={
					<CardsTable
						data={roles}
						columns={COLUMNS}
					/>
				}
			/>
		</div>
	);
};
