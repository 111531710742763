import { asyncActions as officeAsync, slice as officeSlice } from './office/slice';
import { asyncActions as roomAsync, slice as roomSlice } from './room/slice';
import { asyncActions as roomTypeAsync, slice as roomTypeSlice } from './roomType/slice';
import { asyncActions as workspaceAsync, slice as workspaceSlice } from './workspace/slice';
import { asyncActions as userAsync, slice as userSlice } from './user/slice';
import { asyncActions as bookingAsync, slice as bookingSlice } from './booking/slice';
import { asyncActions as imageAsync, slice as imageSlice } from './image/slice';
import { asyncActions as tagAsync, slice as tagSlice } from './tag/slice';

export const officeServiceActions = {
	office: {
		actions: officeSlice.actions,
		async: officeAsync,
	},
	room: {
		actions: roomSlice.actions,
		async: roomAsync,
	},
	roomType: {
		actions: roomTypeSlice.actions,
		async: roomTypeAsync,
	},
	workspace: {
		actions: workspaceSlice.actions,
		async: workspaceAsync,
	},
	user: {
		actions: userSlice.actions,
		async: userAsync,
	},
	booking: {
		actions: bookingSlice.actions,
		async: bookingAsync,
	},
	image: {
		actions: imageSlice.actions,
		async: imageAsync,
	},
	tag: {
		actions: tagSlice.actions,
		async: tagAsync,
	},
};
