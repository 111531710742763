import { FC, InputHTMLAttributes } from 'react';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { Radio } from 'src/shared/ui/_inputs/Radio';

type DefaultInputPropsType = InputHTMLAttributes<HTMLInputElement>;

type Props = Omit<DefaultInputPropsType, 'type' | 'children'> & {
	id: string;
	checked: boolean;
	onChange: () => void;
};

export const ArticleRadioBtn: FC<Props> = props => {
	const { id, onChange, checked, ...rest } = props;

	// * API
	const { data: article } = wiki_serviceAPI.useGetArticleQuery({ articleId: id });

	// * Render
	return (
		<Radio
			{...rest}
			label={article?.name || ''}
			checked={checked}
			onChange={onChange}
		/>
	);
};
