import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFindProjectsQuery } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { SelectStringSearchFilter } from 'src/entities/_filters/SelectStringSearchFilter/SelectStringSearchFilter';
import { routerPath } from 'src/app/router/paths';
import { PROJECT_STATUS_OPTIONS } from 'src/shared/consts/options';
import { ConditionalRendering } from 'src/shared/providers';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { COLUMNS } from './const';
import s from './projects.module.scss';

interface Props {
	departmentId: string;
}

export const Projects: FC<Props> = props => {
	const { departmentId } = props;

	// * Filters
	const [filterStatus, setFilterStatus] = useState<any>({
		id: null,
		name: '',
	});

	const [filterName, setFilterName] = useState<string>('');

	// * API
	const { data, isLoading } = useFindProjectsQuery({
		takeCount: 100,
		skipCount: 0,
		departmentid: departmentId,
		projectstatus: filterStatus.id === null ? undefined : filterStatus.id,
		nameincludesubstring: filterName !== '' ? filterName : undefined,
	});

	const projects = data?.body ?? [];

	// * Navigation
	const navigate = useNavigate();

	const onProjectClick = (projectId: string) => {
		navigate(`${routerPath.projects.page}/${projectId}`);
	};

	// * Render
	return (
		<>
			<SelectStringSearchFilter
				className={s.filters}
				selectValue={filterStatus}
				setSelect={setFilterStatus as (value: { id: null | string; name: string | null }) => void}
				selectPlaceholder="Статус"
				selectOptions={PROJECT_STATUS_OPTIONS}
				searchStringValue={filterName}
				setSearchString={setFilterName}
				searchPlaceholder="Поиск по названию"
			/>

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!projects}
				LoadedResult={
					<>
						{projects.length > 0 && (
							<CardsTable
								data={projects}
								columns={COLUMNS}
								onRowClick={rowId => onProjectClick(projects[rowId].id)}
							/>
						)}
					</>
				}
			/>
		</>
	);
};
