import React, { HTMLAttributes } from 'react';
import { PublicRubricData, RubricData } from 'src/app/redux/queries/wiki-service/types/types';
import { routerPath } from 'src/app/router/paths';
import { getFilledRubrics } from 'src/pages/wiki/lib/getFilledRubrics';
import { useRights } from 'src/shared/hooks/useRights';
import { ConditionalRendering } from 'src/shared/providers';
import { GoBack } from '../../../../_common_components/GoBack/GoBack';
import { MenuItem } from '../MenuItem/MenuItem';
import s from './Menu.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
	isPrivate: boolean;
	tree: RubricData[] | PublicRubricData[];
	activeRubricId: string;
	activeArticleId: string;
}

export const Menu: React.FC<Props> = props => {
	const { className, tree, isPrivate, activeRubricId, activeArticleId } = props;

	// * Conditions
	const allowedToEditWiki = useRights(13) && isPrivate;

	// - main rubric id
	const findMainRubricId = () => {
		for (const coreRubric of tree) {
			if (coreRubric.id === activeRubricId) {
				return activeRubricId;
			}

			for (const childRubric of coreRubric.children) {
				if (childRubric.id === activeRubricId) {
					return childRubric.parentId;
				}
			}
		}
	};

	// * Render
	return (
		<ConditionalRendering
			initialLoading={false}
			isSuccessful={!!activeRubricId}
			LoadedResult={
				<div className={`${s.container} ${className}`}>
					<GoBack
						className={s.go_back}
						title="База знаний"
						route={isPrivate ? routerPath.wiki.page : routerPath.public.page}
					/>

					<div className={s.tree_wrapper}>
						{tree
							.filter(treeItem => getFilledRubrics([treeItem]).length > 0)
							.map(treeItem => (
								<MenuItem
									key={treeItem.id}
									rubric={treeItem}
									isPrivate={isPrivate}
									hasRights={allowedToEditWiki}
									activeArticleId={activeArticleId}
									activeRubricId={activeRubricId}
									activeMainRubric={findMainRubricId()}
								/>
							))}
					</div>
				</div>
			}
		/>
	);
};
