import React from 'react';
import { EventResponse } from 'src/app/redux/queries/event-service/types/resTypes';
import { Heading } from 'src/shared/ui/Heading';
import s from './EventInfoCard.module.scss';
import { ReactComponent as LockSVG } from 'src/shared/assets/svg_icons/action/lock.svg';
import { ReactComponent as UnlockSVG } from 'src/shared/assets/svg_icons/action/unlock.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg_icons/action/edit.svg';
import { ReactComponent as ArchiveSVG } from 'src/shared/assets/svg_icons/file/archive.svg';
import { ReactComponent as UnArchiveSVG } from 'src/shared/assets/svg_icons/file/unarchive.svg';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';
import { EventDescription } from '../EventDescription';
import { ChipCategory } from 'src/shared/ui/_chips/ChipCategory/ChipCategory';
import { getCategoryInfo } from 'src/pages/event_calendar/ui/EventCalendarPage/lib/getCategoryInfo/getCategoryInfo';
import { EventFiles } from '../EventFiles/EventFiles';
import { EventDropDownMenu } from '../EventDropDownMenu/EventDropDownMenu';
import { dateToFullRuDateString, isoRemoveSeconds } from 'src/shared/lib/date';

interface Props {
	eventInfo: EventResponse;
}

export const EventInfoCard: React.FC<Props> = props => {
	const { eventInfo } = props;

	// * Render
	return (
		<div className={s.container}>
			<div>
				<div className={s.header}>
					<div>
						{!eventInfo.access ? <UnlockSVG /> : <LockSVG />}

						<Heading
							level={3}
							className={s.header__title}
						>
							{eventInfo.name}
						</Heading>
					</div>

					<div>
						<Button>Присоединиться</Button>
						<EventDropDownMenu
							eventId={eventInfo.id}
							isActive={true}
						/>
					</div>
				</div>

				{eventInfo.eventCategories && <ChipCategory {...getCategoryInfo(eventInfo.eventCategories)} />}
			</div>

			<EventDescription
				start={`${dateToFullRuDateString(new Date(eventInfo.date))} ${isoRemoveSeconds(eventInfo.date)}`}
				end={eventInfo.endDate ? `${dateToFullRuDateString(new Date(eventInfo.endDate))} ${isoRemoveSeconds(eventInfo.endDate)}` : '-'}
				participants={eventInfo.eventUsers?.length || 0}
				place={eventInfo.address}
				description={eventInfo.description}
			/>

			{
				<EventFiles
					images={eventInfo.eventImages || []}
					files={eventInfo.eventFiles || []}
				/>
			}
		</div>
	);
};
