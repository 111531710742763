import React from 'react';
import { useGetImageGetQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { Room } from 'src/app/redux/state/office/room/types';
import { ChipList } from 'src/shared/ui/_chips/ChipList/ChipList';
import { Gallery } from 'src/shared/ui/_galleries/Gallery';
import s from './RoomInfoCard.module.scss';

interface Props {
	room: Room;
}

export const RoomInfoCard: React.FC<Props> = props => {
	const {
		room: { id, description, tags },
	} = props;

	// * Actions
	const { getImage } = imageServiceActions.image.async;

	// * Queries
	const { currentData: images } = useGetImageGetQuery({ entityId: id });

	// * Render
	return (
		<>
			{(description || tags.length > 0 || images) && (
				<div className={s.container}>
					{(description || tags.length > 0) && (
						<>
							<div className={s.info}>
								<h4>Описание</h4>
								{description && <span>{description}</span>}
								{tags.length > 0 && <ChipList chips={tags} />}
							</div>
						</>
					)}

					{images && (
						<div>
							<h4>Галерея</h4>
							<Gallery
								images={images}
								allowedToEdit={false}
								fetchImage={(imageId: string) =>
									getImage({
										params: {
											imageId,
											source: 'Office',
										},
									})
								}
							/>
						</div>
					)}
				</div>
			)}
		</>
	);
};
