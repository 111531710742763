import React from 'react';
import { SurveysList } from '../../../../components/SurveysList/SurveysList';
import s from './SurveysContent.module.scss';
import { FindSurveysResultInfo } from 'src/app/redux/queries/survey-service/types';

interface Props {
	surveys: FindSurveysResultInfo[];
}

export const SurveysContent: React.FC<Props> = props => {
	const { surveys } = props;

	// * Render
	return (
		<div className={s.container}>
			<SurveysList filteredSurveys={surveys} />
		</div>
	);
};
