import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { ContactsDefaultValues } from '../types';

export const useEditContact = (
	prevValue: string, //
	onCloseModal: () => void,
	contactId?: string,
) => {
	// * API
	const [editContact, { isLoading: isEditLoading }] = user_serviceAPI.usePutCommunicationEditMutation();

	// * Submit
	const onEditSubmit = (data: ContactsDefaultValues) => {
		const { type, value } = data;

		if (!contactId) return null;

		const regExp = /\D/g;
		const contactValue = type.id === 'Phone' ? value.replace(regExp, '') : value;

		if (value !== prevValue) {
			editContact({
				communicationId: contactId,
				editCommunicationRequest: {
					value: type.id === 'Telegram' ? `@${value}` : contactValue,
				},
			})
				.unwrap()
				.then(() => onCloseModal())
				.catch(err => console.log('Error: ', err));
		}
	};

	return { onEditSubmit, isEditLoading };
};
