import cn from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';
import s from './LoaderCircle.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	variant?: 'primary' | 'secondary' | 'light';
	size?: 'small' | 'large';
}

export const LoaderCircle: React.FC<Props> = props => {
	const {
		variant = 'secondary', //
		size = 'small',
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, s[`container_${variant}`], s[`container_${size}`], className)}
		/>
	);
};
