import React from 'react';
import s from './ContactItem.module.scss';
import { ReactComponent as EmailSVG } from 'src/shared/assets/svg/social/email.svg';
import { ReactComponent as PhoneSVG } from 'src/shared/assets/svg/social/phone.svg';
import { ReactComponent as SkypeSVG } from 'src/shared/assets/svg/social/skype.svg';
import { ReactComponent as TelegramSVG } from 'src/shared/assets/svg/social/telegram.svg';
import { UserContact } from 'src/entities/UserContact/UserContact';

interface Props {
	name: string;
	value: string;
}

export const ContactItem: React.FC<Props> = props => {
	const { name, value } = props;

	const getIcon = () => {
		switch (name) {
			case 'Email':
				return <EmailSVG />;
			case 'Telegram':
				return <TelegramSVG />;
			case 'Phone':
				return <PhoneSVG />;
			case 'Skype':
				return <SkypeSVG />;
			default:
				return <EmailSVG />;
		}
	};

	return (
		<div className={s.container}>
			<div className={s.icon}>{getIcon()}</div>

			<UserContact
				type={name}
				value={value}
				font="body2"
			/>
		</div>
	);
};
