import React, { InputHTMLAttributes } from 'react';
import s from './Radio.module.scss';
import cn from 'classnames';

type DefaultInputPropsType = InputHTMLAttributes<HTMLInputElement>;

type Props = Omit<DefaultInputPropsType, 'type' | 'children'> & {
	checked?: boolean;
	noDisabledStyles?: boolean;
	label?: string;
};

export const Radio: React.FC<Props> = props => {
	const {
		className, //
		checked = true,
		label,
		noDisabledStyles,
		...restProps
	} = props;

	const disabledStyle = noDisabledStyles ? s.label_another_disabled : s.label_disabled;

	// * Render
	return (
		<label className={cn(s.label, restProps.disabled && disabledStyle, className)}>
			<input
				readOnly={true}
				type="radio"
				checked={checked}
				{...restProps}
			/>

			<svg
				className={cn(s.radio)}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				{checked && (
					<circle
						className={s.circle}
						cx="12"
						cy="12"
						r="9"
					/>
				)}
				<rect
					className={s.rect}
					x="0.5"
					y="0.5"
					width="23"
					height="23"
					rx="11.5"
				/>
			</svg>

			{label && <div>{label}</div>}
		</label>
	);
};
