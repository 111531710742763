import { createContext, FC, ReactNode, useContext } from 'react';
import { CreateCommentAction, DeleteCommentAction, EditCommentAction, UserProps } from '../types';

export interface CommentContext {
	currentUser: UserProps;
	isLoading: boolean;
	createAction: (data: CreateCommentAction) => void; // - Create comment
	editAction?: (data: EditCommentAction) => void; // - Edit content comment
	deleteAction?: (data: DeleteCommentAction) => void; // - Delete comment
}

interface Props extends CommentContext {
	children: ReactNode;
}

const CommentsContext = createContext<CommentContext | null>(null);

export const CommentsContextProvider: FC<Props> = props => {
	const {
		currentUser,
		isLoading,
		createAction,
		editAction,
		deleteAction,

		children,
	} = props;

	// * Render
	return (
		<CommentsContext.Provider
			value={{
				currentUser,
				isLoading,
				createAction,
				editAction,
				deleteAction,
			}}
		>
			{children}
		</CommentsContext.Provider>
	);
};

export const useCommentsContext = () => {
	const context = useContext(CommentsContext);

	if (!context) {
		throw new Error('useCommentsContext must be used within CommentsContext');
	}

	return context;
};
