import cn from 'classnames';
import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { useOpenArticle } from 'src/pages/wiki/pages/wiki/_hooks/useOpenArticle';
import s from '../Articles/Articles.module.scss';
import { EditButtons } from '../EditButtons/EditButtons';
import { useCreateArticle } from 'src/pages/wiki/pages/wiki/_hooks/useCreateArticle';

interface Props {
	id: string;
	name: string;
	isPrivate: boolean;
	archived?: boolean;
	archivedParent?: boolean;
	rubricId?: string;
	subRubricId?: string;
}

export const Article: FC<Props> = props => {
	const { id, name, isPrivate, archived, archivedParent, rubricId, subRubricId } = props;

	const { openArticle } = useOpenArticle({ articleId: id, isPrivate });

	// * Selectors
	const editMode = useAppSelector(state => state.wiki.editModeWiki);

	// - edit article
	const { toNewArticlePage } = useCreateArticle(rubricId || null, subRubricId || null, id);

	// * Render
	return (
		<div className={s.edit_wrapper}>
			<div
				className={cn(s.article_name, archived && s.archived, !editMode && s.hover)}
				onClick={!editMode ? openArticle : undefined}
			>
				{name}
			</div>

			{editMode && (
				<EditButtons
					id={id}
					type={archivedParent ? undefined : 'article'}
					isActive={!archived}
					name={name}
					editAction={toNewArticlePage}
				/>
			)}
		</div>
	);
};
