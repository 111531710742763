import { UserInfo } from 'src/app/redux/queries/department-service/types/types';

export const getDirectorName = (director?: UserInfo) => {
	const getCapital = (name: string) => {
		return name.slice(0, 1).toLocaleUpperCase() + '.';
	};

	if (director) {
		const { lastName, middleName, firstName } = director;
		return `${lastName ?? ''} ${firstName ? getCapital(firstName) : ''}${middleName ? getCapital(middleName) : ''}`;
	} else {
		return '-';
	}
};
