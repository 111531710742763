import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { galleyFileToBase64 } from 'src/shared/lib/file/galleyFileToBase64/galleyFileToBase64';
import { formatBytes } from 'src/shared/lib/string';
import { v4 } from 'uuid';
import { ImageInfo } from 'src/app/redux/state/image/image/types';
import { ReactComponent as AddImageSVG } from 'src/shared/assets/svg/action/add-image.svg';
import { GalleyFile } from '../../../_galleries/Gallery/_types';
import { createImageInfoFromFile } from '../../../_galleries/Gallery/_utils';
import { LoaderCircle } from '../../../_loaders/LoaderCircle/LoaderCircle';
import s from './AvatarDropDown.module.scss';

interface Props {
	view?: 'circle' | 'default';
	currentFilesCount: number;
	setFiles: (newFiles: GalleyFile[]) => void;
	maxFiles: number;
	createImages?: (images: ImageInfo[]) => void;
	isLoading?: boolean;
}

export const AvatarDropDown: React.FC<Props> = props => {
	const { view = 'default', currentFilesCount, setFiles, maxFiles, createImages, isLoading } = props;

	const MAX_IMAGE_SIZE = 30000000; // bytes

	const [error, setError] = useState<string | null>(null);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': [],
		},
		// maxSize: 30000000, // 30mb
		maxSize: MAX_IMAGE_SIZE,
		onDrop: (acceptedFiles, fileRejections) => {
			error && setError(null);

			const files = acceptedFiles.map(file =>
				Object.assign(file, {
					id: `new_${v4()}`,
					parentId: null,
					preview: URL.createObjectURL(file),
				}),
			);

			if (currentFilesCount + files.length <= maxFiles) {
				!isLoading && setFiles(files);

				createImages &&
					Promise.all(files.map(file => galleyFileToBase64(file)))
						// TODO: Type values
						.then((values: any) => {
							const images = createImageInfoFromFile(values);
							createImages(images);
						});
			} else {
				setError(`Максимальное количество изображений ${maxFiles}`);
			}

			fileRejections.length > 0 &&
				fileRejections.forEach(file => {
					const error = file.errors.find(error => error.code === 'file-too-large');
					error && setError(`Файл ${file.file.name} превышает ${formatBytes(MAX_IMAGE_SIZE)}`);
				});
		},
	});

	// * Render
	return (
		<div
			className={`${s.container} ${view === 'circle' ? s.avatar_circle : s.avatar_default}`}
			aria-disabled={isLoading}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<div className={s.main_text}>{isLoading ? <LoaderCircle /> : <AddImageSVG />}</div>
			{error && <span className={s.error}>{error}</span>}
		</div>
	);
};
