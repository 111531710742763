import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import s from './LineTabs.module.scss';

export interface SingleTab<T = string> {
	id: T;
	name: string;
	width?: number; // individual tab width in percent
}

interface Props<T> extends ComponentPropsWithoutRef<'div'> {
	tabs: SingleTab<T>[];
	activeTabId: T;
	setActiveTabId: (activeTabId: T) => void;
	width?: 'fit' | 'full';
	tabWidth?: number; // Set tab width in percent, tabs will be aligned left when width lesser than 100%. By default, set to 100%.
}

export const LineTabs = <T extends string>(props: PropsWithChildren<Props<T>>) => {
	const {
		tabs, //
		activeTabId,
		setActiveTabId,
		width = 'fit',
		tabWidth = 100,
		className,
		...divProps
	} = props;

	const window = useWindowDimensions();
	const isDesktop = window.width >= 1024;

	// * Dynamic styles
	const tabWidthStyle = width === 'fit' && isDesktop ? { width: 'fit-content' } : { width: `${tabWidth}%` };
	const tabsListGapStyle = width === 'fit' && isDesktop ? { gap: '1.5rem' } : { gap: '0' };

	// * Render
	return (
		<div
			{...divProps}
			className={className}
		>
			<ul
				className={s.tabsList}
				style={tabsListGapStyle}
			>
				{tabs &&
					tabs.map(option => (
						<li
							className={`${s.tab} ${option.id === activeTabId && s.active}`}
							style={tabWidthStyle}
							key={option.id}
							onClick={() => setActiveTabId(option.id)}
						>
							{option.name}
						</li>
					))}
			</ul>

			<hr className={s.underline} />
		</div>
	);
};
