import { DateTime } from 'luxon';

// Проверяет являться ли дата, датой будущих недель. Если дата этой недели или прошлых недель возвращает false.
export const isAfterThisWeek = (date: Date) => {
	const thisWeekStart = DateTime.now().startOf('week');
	const prevWeekStart = DateTime.fromJSDate(date).minus({ day: 7 }).startOf('week');

	const prevWeekStartTime = prevWeekStart.toJSDate().getTime();
	const thisWeekStartTime = thisWeekStart.toJSDate().getTime();

	return prevWeekStartTime > thisWeekStartTime || prevWeekStartTime === thisWeekStartTime;
};
