import { FC } from 'react';
import { usePutUserEditAssignmentMutation, usePutUserEditRoleMutation } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { DepartmentUserAssignment } from 'src/app/redux/queries/department-service/types/types';
import { DepartmentUserRole } from 'src/app/redux/queries/user-service/types/types';
import { ReactComponent as ManagerSVG } from 'src/shared/assets/svg_icons/action/star_stroke.svg';
import { ReactComponent as DirectorSVG } from 'src/shared/assets/svg_icons/action/star_filled.svg';
import { ReactComponent as PersonSVG } from 'src/shared/assets/svg_icons/social/person.svg';
import { DropDownItem, DropDownMenu } from 'src/shared/ui/DropDownMenu/DropDownMenu';

interface Props {
	departmentId: string;
	userId: string;
	assignment: DepartmentUserAssignment;
	role: DepartmentUserRole;
}

export const RoleAssignButton: FC<Props> = props => {
	const { departmentId, userId, assignment, role } = props;

	const isDirector = assignment === 'Director';
	const isManager = role === 'Manager' && assignment === 'Employee';

	const [editRole, { isLoading: isLoadingRole }] = usePutUserEditRoleMutation();
	const [editAssignment, { isLoading: isLoadingAssignment }] = usePutUserEditAssignmentMutation();

	const DirectorAssignmentItem: DropDownItem = {
		icon: <DirectorSVG />,
		title: 'Присвоить роль «Директор департамента»',
		action: () => {
			editAssignment({
				departmentId,
				editDepartmentUserAssignmentRequest: {
					assignment: 'Director',
					usersIds: [userId],
				},
			});
		},
	};

	const ManagerAssignmentItem: DropDownItem = {
		icon: <ManagerSVG />,
		title: 'Присвоить роль «Менеджер департамента»',
		action: () => {
			if (isDirector) {
				editAssignment({
					departmentId,
					editDepartmentUserAssignmentRequest: {
						assignment: 'Employee',
						usersIds: [userId],
					},
				}).then(() => {
					editRole({
						departmentId,
						editDepartmentUsersRoleRequest: {
							role: 'Manager',
							usersIds: [userId],
						},
					});
				});
			} else {
				editRole({
					departmentId,
					editDepartmentUsersRoleRequest: {
						role: 'Manager',
						usersIds: [userId],
					},
				});
			}
		},
	};

	const EmployeeAssignmentItem: DropDownItem = {
		icon: <PersonSVG />,
		title: 'Присвоить роль «Сотрудник департамента»',
		action: () => {
			isDirector &&
				editAssignment({
					departmentId,
					editDepartmentUserAssignmentRequest: {
						assignment: 'Employee',
						usersIds: [userId],
					},
				});

			editRole({
				departmentId,
				editDepartmentUsersRoleRequest: {
					role: 'Employee',
					usersIds: [userId],
				},
			});
		},
	};

	const isLoading = isLoadingAssignment || isLoadingRole;

	const getItems = (): DropDownItem[] => {
		if (isDirector) {
			return [ManagerAssignmentItem, EmployeeAssignmentItem];
		} else if (isManager) {
			return [DirectorAssignmentItem, EmployeeAssignmentItem];
		} else {
			return [DirectorAssignmentItem, ManagerAssignmentItem];
		}
	};

	return (
		<DropDownMenu
			items={getItems()}
			isLoading={isLoading}
		/>
	);
};
