import { createColumnHelper } from '@tanstack/react-table';
import { ModalsMenu } from '../ModalsMenu/ModalsMenu';

export interface TableDataItem {
	id: string;
	isActive: boolean;
	localizationId: string;
	name: string;
	description: string;
	rights: string[];
}

const columnHelper = createColumnHelper<TableDataItem>();

export const COLUMNS = [
	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('description', {
		header: 'ОПИСАНИЕ',
		cell: info => info.getValue() || '-',
	}),
	columnHelper.accessor('id', {
		header: '',
		cell: ({ row, getValue }) => (
			<ModalsMenu
				id={getValue()}
				isActive={row.original.isActive}
				isRoleMenu
				name={row.original.name}
				description={row.original.description}
				localizationId={row.original.localizationId}
				rights={row.original.rights}
			/>
		),
	}),
];
