import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/app/router/paths';
import { useAppSelector } from 'src/app/redux/utils';

export const useOpenArticle = (arg: { articleId: string; isPrivate: boolean }) => {
	const { articleId, isPrivate } = arg;

	// * Selectors
	const editModeArticle = useAppSelector(state => state.wiki.editModeArticle);

	// * Router
	const navigate = useNavigate();

	// * User actions
	const openArticle = () => {
		window.scrollTo(0, 0);
		if (isPrivate) {
			!editModeArticle && navigate(`${routerPath.wiki.page}/${articleId}`);
		} else {
			navigate(`${routerPath.public.page}/${articleId}`);
		}
	};

	return { openArticle };
};
