import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { Provider } from 'react-redux';
import { store } from './app/redux/store';
import './index.scss';
import './fonts/Montserrat-Bold.ttf';
import './fonts/Lato-Regular.ttf';
import './fonts/Lato-Medium.ttf';
import './fonts/Lato-Semibold.ttf';
import './fonts/gilroy-bold.ttf';
import './fonts/gilroy-medium.ttf';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);
