import { asyncActions as imageAsync, slice as imageSlice } from './image/slice';
import { asyncActions as wikiNewsAsync, slice as wikiNewsSlice } from './wikiNews/slice';

export const imageServiceActions = {
	image: {
		actions: imageSlice.actions,
		async: imageAsync,
	},
	wikiNews: {
		actions: wikiNewsSlice.actions,
		async: wikiNewsAsync,
	},
};
