import React from 'react';
import s from './ChipList.module.scss';

interface Props {
	chips: Array<{ id: string; name: string }>;
}

export const ChipList: React.FC<Props> = props => {
	const { chips } = props;

	// * Render
	return (
		<div className={s.container}>
			{chips.map(tag => (
				<div
					key={tag.id}
					className={s.chip}
				>
					{tag.name}
				</div>
			))}
		</div>
	);
};
