import React, { HTMLAttributes } from 'react';
import s from './Rubric.module.scss';
import cn from 'classnames';

interface Props extends HTMLAttributes<HTMLButtonElement> {
	tabId: string;
	title: string;
	disabled?: boolean;
	active?: boolean;
	archived?: boolean;
	setActive: (id: string) => void;
}

export const Rubric: React.FC<Props> = props => {
	const { tabId, title, disabled, active, archived, setActive, ...buttonProps } = props;

	const containerStyle = cn(s.container, active && !disabled && s.active, disabled && s.disabled, archived && s.archived);

	// * Render
	return (
		<button
			{...buttonProps}
			className={containerStyle}
			onClick={() => setActive(tabId)}
			disabled={disabled}
		>
			<span>{title}</span>
		</button>
	);
};
