import { Avatar } from 'src/shared/ui/Avatar/Avatar';
import s from './ImageListShort.module.scss';

interface ListProps {
	image: string;
	name: string;
}

interface Props {
	list: ListProps[];
}

export const ImageListShort: React.FC<Props> = ({ list }) => {
	const renderImages = (list: ListProps[]) => {
		let visibleImages: ListProps[];
		let unvisibleImages: ListProps[] = [];

		if (list.length >= 3) {
			visibleImages = [list[0], list[1], list[2]];
			unvisibleImages = list.slice(3);
		} else {
			visibleImages = list;
		}

		return (
			<div className={s.imageRow}>
				{visibleImages.map(item => (
					<div
						key={item.image}
						className={s.image}
					>
						<Avatar
							name={item.name}
							avatarId={item.image}
							size="extra-small"
						/>
					</div>
				))}

				{unvisibleImages.length > 0 && (
					<div className={s.image}>
						<div className={s.unvisibleImages}>+{unvisibleImages.length}</div>
					</div>
				)}
			</div>
		);
	};

	// * Render
	return <div className={s.container}>{renderImages(list)}</div>;
};
