import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { ContactInfo, EditUserPayloadItem } from 'src/app/redux/state/company-structure/users/types';
import { imageServiceActions } from 'src/app/redux/state/image/actions';
import { UserAvatar } from 'src/app/redux/state/user/user/types';
import { useRights } from 'src/shared/hooks/useRights';
import { ReactComponent as EmailSVG } from 'src/shared/assets/svg/social/email.svg';
import { ReactComponent as PhoneSVG } from 'src/shared/assets/svg/social/phone.svg';
import { ReactComponent as SkypeSVG } from 'src/shared/assets/svg/social/skype.svg';
import { ReactComponent as TelegramSVG } from 'src/shared/assets/svg/social/telegram.svg';
import { matchItems } from 'src/shared/lib/filter';
import { AvatarField } from 'src/shared/ui/AvatarField';
import { Button } from 'src/shared/ui/_buttons/Button';
import { PhoneStringField } from 'src/shared/ui/_fields/PhoneStringField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { SelectSingleCustomValue } from 'src/shared/ui/_inputs/selects/SelectSingleCustomValue';
import * as yup from 'yup';
import s from './CreateNewUser.module.scss';
import { createFullName } from 'src/shared/lib/string';

interface DefaultProps {
	avatar?: UserAvatar | null;
	lastName: string;
	firstName: string;
	middleName: string;
	position: string;
	positionId: string;
	contacts: {
		email: string;
		skype: string;
		telegram: string;
		phone: string;
	};
}

interface ContactsIds {
	email?: string;
	skype?: string;
	telegram?: string;
	phone?: string;
}

interface Props {
	values?: DefaultProps;
	contactsIds?: ContactsIds;
	userId?: string;
	isNewUser?: boolean;
	positionId?: string;
	toggleCreateModal: () => void;
}

export const CreateNewUser: React.FC<Props> = props => {
	const { isNewUser, contactsIds, userId, values, positionId, toggleCreateModal } = props;

	// * Actions
	const { getImage } = imageServiceActions.image.async;

	// * ?
	const [createAvatar] = company_structure_serviceAPI.useCreateAvatarMutation();
	const [createUser] = company_structure_serviceAPI.useCreateUserMutation();
	const [createPosition] = company_structure_serviceAPI.useCreatePositionMutation();
	const [editUser] = company_structure_serviceAPI.useEditUserMutation();
	const [deleteContact] = company_structure_serviceAPI.useDeleteContactMutation();
	const [addNewContact] = company_structure_serviceAPI.useAddNewContactMutation();

	// * Queries
	const { currentData: positionsData } = company_structure_serviceAPI.useGetPositionsQuery({
		params: {
			skipcount: 0,
			takecount: 1000,
		},
	});
	const customPositions = positionsData?.body || [];

	const [positionsNameState, setPositionsNameState] = useState(customPositions);

	// * Form
	const defaultValues = isNewUser
		? {
				lastName: '',
				firstName: '',
				middleName: '',
				position: '',
				positionId: '',
				contacts: {
					email: '',
					skype: '',
					telegram: '',
					phone: '',
				},
			}
		: values;

	const schema = yup.object().shape({
		lastName: yup.string().required('Поле не должно быть пустым').nullable(),
		firstName: yup.string().required('Поле не должно быть пустым').nullable(),
		middleName: yup.string(),
		position: yup.string().required('Поле не должно быть пустым').nullable(),
		contacts: yup.object().shape({
			email: yup
				.string()
				.nullable()
				.matches(/^$|^[^@\s]+@[^@\s,]*/, 'Некорректная почта'),
			skype: yup.string().nullable().max(32, 'Максимум 32 символа'),
			telegram: yup
				.string()
				.nullable()
				.matches(/^$|^@\w{5,}$/, 'Введите корректный ник: @nickname'),
			phone: yup
				.string()
				.nullable()
				.matches(/(\+7|8)?[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/, 'Введите корректный номер телефона'),
		}),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue } = formMethods;

	const isAdmin = useRights();

	const onSubmit = (data: any) => {
		const { contacts } = data;
		const contactsPayload: ContactInfo[] = [];
		for (const key in contacts) {
			if (contacts[key]) {
				const keyName = key[0].toUpperCase() + key.slice(1);
				contactsPayload.push({
					name: keyName,
					value: contacts[key],
				});
			}
		}

		const userFullName = createFullName(data);

		if (isNewUser && data?.positionId) {
			createUser({
				params: {
					user: {
						fullName: userFullName,
						positionId: data.positionId,
						contacts: contactsPayload,
					},
				},
			})
				.unwrap()
				.then(userId => {
					if (data?.avatar) {
						createAvatar({
							params: {
								userId: userId,
							},
							payload: {
								name: data?.avatar?.name || '',
								content: data?.avatar?.content || '',
								extension: data?.avatar?.extension || '',
							},
						})
							.unwrap()
							.catch(err => console.log(err));
					}
					toggleCreateModal();
				})
				.catch(err => console.log(err));
		}

		if (isNewUser && !data?.positionId) {
			createPosition({
				params: {
					name: data.position,
				},
			})
				.unwrap()
				.then(posId => {
					createUser({
						params: {
							user: {
								fullName: userFullName,
								positionId: posId,
								contacts: contactsPayload,
							},
						},
					})
						.unwrap()
						.then(userId => {
							if (data?.avatar) {
								createAvatar({
									params: {
										userId: userId,
									},
									payload: {
										name: data?.avatar?.name || '',
										content: data?.avatar?.content || '',
										extension: data?.avatar?.extension || '',
									},
								});
							}
							toggleCreateModal();
						})
						.catch(err => console.log(err));
				})
				.catch(err => console.log(err));
			return;
		}

		const fullNameChanged = values?.firstName !== data.firstName || values?.lastName !== data.lastName || values?.middleName !== data.middleName;
		const positionChanged = values?.position !== data.position;
		const avatarChanged = values?.avatar?.id !== data?.avatar?.id;
		const emailChanged = values?.contacts.email !== data.contacts?.email;
		const skypeChanged = values?.contacts.skype !== data.contacts?.skype;
		const telegramChanged = values?.contacts.telegram !== data.contacts?.telegram;
		const phoneChanged = values?.contacts.phone !== data.contacts?.phone;

		if (!userId) return;

		// delete changed contacts
		if (emailChanged && contactsIds?.email) {
			deleteContact({
				params: {
					contactId: contactsIds.email,
				},
			});
		}

		if (skypeChanged && contactsIds?.skype) {
			deleteContact({
				params: {
					contactId: contactsIds.skype,
				},
			});
		}

		if (telegramChanged && contactsIds?.telegram) {
			deleteContact({
				params: {
					contactId: contactsIds.telegram,
				},
			});
		}

		if (phoneChanged && contactsIds?.phone) {
			deleteContact({
				params: {
					contactId: contactsIds.phone,
				},
			});
		}

		// create new changed contact
		if (emailChanged || skypeChanged || telegramChanged || phoneChanged) {
			const preparedContacts = [];

			emailChanged &&
				data.contacts.email &&
				preparedContacts.push({
					name: 'Email',
					value: data.contacts.email,
				});

			skypeChanged &&
				data.contacts.skype &&
				preparedContacts.push({
					name: 'Skype',
					value: data.contacts.skype,
				});

			telegramChanged &&
				data.contacts.telegram &&
				preparedContacts.push({
					name: 'Telegram',
					value: data.contacts.telegram,
				});

			phoneChanged &&
				data.contacts.phone &&
				preparedContacts.push({
					name: 'Phone',
					value: data.contacts.phone,
				});

			preparedContacts.length > 0 &&
				addNewContact({
					userId: userId,
					contacts: preparedContacts,
				}).unwrap();
		}

		if (!fullNameChanged && !positionChanged && !avatarChanged) {
			toggleCreateModal();
			return;
		}

		// prepare changed fullname
		const preparedPayload: EditUserPayloadItem[] = [];

		if (fullNameChanged) {
			preparedPayload.push({
				op: 'replace',
				path: '/fullname',
				value: userFullName,
			});
		}

		// prepare changed position to custom position
		if (positionChanged && data?.positionId) {
			preparedPayload.push({
				op: 'replace',
				path: '/positionId',
				value: data.positionId,
			});
		}

		// change to exist position & change avatar
		if (positionChanged && !data?.positionId) {
			createPosition({
				params: {
					name: data.position,
				},
			})
				.unwrap()
				.then(posId => {
					editUser({
						userId: userId,
						payload: [
							...preparedPayload,
							{
								op: 'replace',
								path: '/positionId',
								value: posId,
							},
						],
					})
						.unwrap()
						.then(() => {
							if (data?.avatar && avatarChanged) {
								createAvatar({
									params: {
										userId: userId,
									},
									payload: {
										name: data?.avatar?.name || '',
										content: data?.avatar?.content || '',
										extension: data?.avatar?.extension || '',
									},
								})
									.unwrap()
									.then(() => toggleCreateModal())
									.catch(err => console.log(err));
							}
						})
						.catch(err => console.log(err));
				})
				.catch(err => console.log(err));
			return;
		}

		// changed only avatar
		if (data?.avatar && avatarChanged && preparedPayload.length === 0) {
			createAvatar({
				params: {
					userId: userId,
				},
				payload: {
					name: data?.avatar?.name || '',
					content: data?.avatar?.content || '',
					extension: data?.avatar?.extension || '',
				},
			})
				.unwrap()
				.then(() => toggleCreateModal())
				.catch(err => console.log(err));
			return;
		}

		// changed others
		if (preparedPayload.length !== 0) {
			editUser({
				userId: userId,
				payload: preparedPayload,
			})
				.unwrap()
				.then(() => {
					if (data?.avatar && avatarChanged) {
						createAvatar({
							params: {
								userId: userId,
							},
							payload: {
								name: data?.avatar?.name || '',
								content: data?.avatar?.content || '',
								extension: data?.avatar?.extension || '',
							},
						}).unwrap();
					}
					toggleCreateModal();
				})
				.catch(err => console.log(err));
		}
	};

	return (
		<div className={s.container}>
			<h2 className={s.title}>{isNewUser ? 'Создание сотрудника' : 'Редактирование сотрудника'}</h2>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<div className={s.form_components}>
						<div className={s.avatar_wrapper}>
							<div className={s.subtitle}>Фотография сотрудника</div>
							<Controller
								name="avatar"
								control={control}
								render={({ field }) => (
									<AvatarField
										view="circle"
										createImages={newFile => setValue('avatar', newFile[0])}
										deleteImages={() => setValue('avatar', null)}
										images={field.value ? [{ ...field.value, name: field.value.name || '' }] : []}
										maxFiles={1}
										allowedToEdit={isAdmin}
										fetchImage={(imageId: string) =>
											getImage({
												params: {
													imageId,
													source: 'CompanyStructure',
												},
											})
										}
									/>
								)}
							/>
						</div>

						<div className={s.about_wrapper}>
							<div className={s.subtitle}>Основная информация</div>
							<div className={s.form_wrapper}>
								<StringField
									name="lastName"
									placeholder="Фамилия"
								/>
								<StringField
									name="firstName"
									placeholder="Имя"
								/>
								<StringField
									name="middleName"
									placeholder="Отчество"
								/>
								<Controller
									name="position"
									control={control}
									render={({ field, fieldState }) => (
										<SelectSingleCustomValue
											{...field}
											placeholder="Должность"
											keyNames={{
												name: 'name',
												value: 'id',
											}}
											searchSubstring={field.value}
											onStringChange={value => {
												const sameValue = customPositions.find(item => item.name === value);
												setPositionsNameState(matchItems(customPositions, value));
												setValue('positionId', sameValue ? sameValue.id : '', { shouldValidate: true });
												setValue('position', sameValue ? sameValue.name : value, { shouldValidate: true });
											}}
											options={positionsNameState}
											selectedOption={{
												id: positionId as string,
												name: values?.position || positionId,
											}}
											setSelectedOption={value => {
												setValue('positionId', value.id);
												setValue('position', value.name || '');
											}}
											errorMessage={!field.value ? fieldState.error?.message : ''}
										/>
									)}
								/>
							</div>
						</div>

						<div className={s.contacts_wrapper}>
							<div className={s.subtitle}>Контакты</div>
							<div className={s.form_wrapper}>
								<StringField
									name="contacts.email"
									placeholder="Почта"
									Icon={<EmailSVG />}
								/>
								<PhoneStringField
									name="contacts.phone"
									placeholder="Телефон"
									Icon={<PhoneSVG />}
								/>
								<StringField
									name="contacts.skype"
									placeholder="Скайп"
									Icon={<SkypeSVG />}
								/>
								<StringField
									name="contacts.telegram"
									placeholder="Телеграм"
									Icon={<TelegramSVG />}
								/>
							</div>
						</div>

						<div className={s.form_buttons}>
							<Button
								wide
								variant="tertiary"
								onClick={event => {
									event.preventDefault();
									event.stopPropagation();
									toggleCreateModal();
								}}
							>
								Отменить
							</Button>

							<Button
								type="submit"
								wide
								variant="primary"
								// isLoading={isLoading}
							>
								{isNewUser ? 'Создать' : 'Сохранить'}
							</Button>
						</div>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
