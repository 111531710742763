import { BookingRule } from 'src/app/redux/state/office/types';

export const BOOKING_RULE_OPTIONS: Array<{ name: string; value: BookingRule }> = [
	// {
	// 	name: 'Любой',
	// 	value: 'AnyBooking'
	// },
	{
		name: 'Частичный',
		value: 'PartialBooking', //  Только Место
	},
	{
		name: 'Полный',
		value: 'FullBooking', // Все помещение
	},
];
