import { createSlice } from '@reduxjs/toolkit';

interface State {
	activeTab: string;
	remoteSurveyName: string;
}

export const initialState: State = {
	activeTab: 'surveys',
	remoteSurveyName: '',
};

export const slice = createSlice({
	name: 'SURVEY_PAGE',
	initialState,
	reducers: {
		setActiveTab: (state, action: { payload: string }) => {
			state.activeTab = action.payload;
		},
		setRemoteSurveyName: (state, action: { payload: string }) => {
			state.remoteSurveyName = action.payload;
		},
	},
});

export const surveyPageActions = {
	...slice.actions,
};
