import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import { BaseData } from '../../types';
import s from './Pie.module.scss';
import { Legends } from '../Legends/Legends';
import { color } from 'src/shared/lib/color';
import { COLORS } from '../../const/colors';

interface Props {
	data: Array<BaseData>;
}

export const Pie: React.FC<Props> = props => {
	const { data } = props;

	// * libs
	const { getColors } = color;

	const dataWithValue = data.filter((item: { id: string; value: number; label: string }) => item.value !== 0);

	const isOneValue = dataWithValue.length === 1;

	const countValues = data.length;

	return (
		<div className={s.container}>
			<div className={s.pie_wrapper}>
				<ResponsivePie
					data={data}
					colors={getColors(countValues, COLORS)}
					margin={{ top: 8, right: 48, bottom: 8, left: 0 }}
					innerRadius={0}
					startAngle={0}
					padAngle={0}
					cornerRadius={0}
					activeOuterRadiusOffset={6}
					borderWidth={isOneValue ? 0 : 2}
					borderColor="#FDFEFF"
					arcLabelsTextColor="#FAFAFA"
					enableArcLinkLabels={false}
					theme={{
						labels: {
							text: {
								fontSize: '1rem',
								lineHeight: '1.45rem',
								fontFamily: 'Lato, Arial, serif',
								fontWeight: '400',
							},
						},
					}}
					valueFormat={value => (value > 0 ? `${value}%` : '')}
					tooltip={({ datum: { label, color, data } }) => (
						<div className={s.pie_tooltip_container}>
							<div className={s.pie_tooltip_wrapper}>
								<div
									className={s.dot}
									style={{ background: color }}
								/>
								<div>{label}</div>
							</div>

							<div className={s.pie_tooltip_text}>Всего ответов: {data.answersCount}</div>
						</div>
					)}
				/>
			</div>

			<div className={s.legends_wrapper}>
				<Legends data={data} />
			</div>
		</div>
	);
};
