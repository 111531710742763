import { ReactNode } from 'react';
import { Navigate, Route } from 'react-router-dom';

export const createPrivateRoute = (args: { path: string; PageComponent: ReactNode; hasTokens: boolean; rights?: boolean[] }) => {
	const {
		path,
		PageComponent,
		rights = [], // Пустой массив rights будет равен true, так как проверка every на пустой массив всегда вернет true.
		hasTokens,
	} = args;

	const hasRights = rights.every(right => right === true);

	return (
		<Route
			path={path}
			element={
				hasTokens && hasRights ? (
					<>{PageComponent}</>
				) : (
					<Navigate
						to="/"
						replace
					/>
				)
			}
		/>
	);
};
