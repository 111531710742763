import { CategoryInfo } from 'src/app/redux/queries/event-service/types/types';
import s from './CalendarEvent.module.scss';
import React from 'react';
import { COLORS } from 'src/shared/consts/consts';
import { getCategoryInfo } from '../../../../lib/getCategoryInfo/getCategoryInfo';

interface Props {
	category: CategoryInfo[];
}

export const CalendarEvent: React.FC<Props> = ({ category }) => {
	const { color, name } = getCategoryInfo(category);

	return (
		<div
			className={s.event}
			style={{ backgroundColor: color }}
		>
			{name}
		</div>
	);
};
