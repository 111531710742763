import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { EditCompanyUserREQ_PARAMS, EditCompanyUserRES } from './types';

const NAME = `${ServiceName.COMPANY_SERVICE}/contractsubject`;

// * Async thunks
const editCompanyUser = createAppAsyncThunk(`${NAME}/editCompanyUser`, async (arg: EditCompanyUserREQ_PARAMS, thunkAPI) => {
	const { params, payload } = arg;

	const res = await apiRequest.patchRequest<EditCompanyUserRES>({
		url: `${NAME}/find`,
		params,
		payload,
		thunkAPI,
		action: () => editCompanyUser(arg),
	});

	return res.body;
});

// * Slice
export interface State {
	status: RequestStatus;
}

export const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(editCompanyUser.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editCompanyUser.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(editCompanyUser.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	editCompanyUser,
};
