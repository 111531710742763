import { QuestionTypeInfo } from '../_types';

export const questionCategories: QuestionTypeInfo[] = [
	{
		id: 1,
		name: 'Несколько из списка',
		type: 'Base',
		hasMultipleChoice: true,
	},
	{
		id: 2,
		name: 'Один из списка',
		type: 'Base',
		hasMultipleChoice: false,
	},
	{
		id: 3,
		name: 'Шкала',
		type: 'Scale',
		hasMultipleChoice: false,
	},
	{
		id: 4,
		name: 'Сетка (один)',
		type: 'Grid',
		hasMultipleChoice: false,
	},
	{
		id: 5,
		name: 'Сетка (несколько)',
		type: 'Grid',
		hasMultipleChoice: true,
	},
];
