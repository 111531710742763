import React from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import s from './EventDetailsPage.module.scss';
import { EventInfoCard } from '../EventInfoCard';
import { useParams } from 'react-router-dom';
import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { ConditionalRendering } from 'src/shared/providers';
import { EventComments } from '../EventComments';

export const EventDetailsPage: React.FC = () => {
	// * Routing
	const { eventId } = useParams() as { eventId: string };

	// * API
	const { data: eventData, isLoading } = event_serviceAPI.useGetEventGetQuery({
		eventId,
		includeUsers: true,
		includeCategories: true,
		includeImages: true,
		includeFiles: true,
		includeComments: true,
	});

	const eventInfo = eventData?.body;

	// * Render
	return (
		<PageDecorator>
			<PageTitle title="Календарь событий" />

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!eventInfo}
				LoadedResult={
					eventInfo && (
						<>
							<EventInfoCard eventInfo={eventInfo} />
							<EventComments
								eventId={eventId}
								comments={eventInfo.eventComments || []}
							/>
						</>
					)
				}
			/>
		</PageDecorator>
	);
};
