import { TimeInterval } from 'src/shared/lib/date/types';
import { iterateThrewHour } from 'src/shared/lib/date';

// * Функция генерирует уже забронированные или недоступные по причинам ограничений помещения даты.
export const generateExcludedDates = (selectedDate: Date, unavailableIntervals: TimeInterval[]) => {
	let excludedDates: Date[] = [];
	const excludeDatesIntervals: Array<Date[]> = [];

	unavailableIntervals.forEach(interval => {
		let excludedDatesInterval: Date[] = [];

		const startHour = interval.start[0];
		const startMinute = interval.start[1];
		const endHour = interval.end[0];
		const endMinute = interval.end[1];

		// Создаем массив дат из интервала
		if (startHour === endHour && startMinute === endMinute) {
			// Ничего не надо делать. Так как интервал абсолютно равен.
		} else if (startHour === endHour && startMinute !== endMinute) {
			excludedDatesInterval = excludedDatesInterval.concat(iterateThrewHour(selectedDate, startHour, startMinute, endMinute));
		} else {
			for (let i = startHour; i <= endHour; i++) {
				if (i === startHour) {
					excludedDatesInterval = excludedDatesInterval.concat(iterateThrewHour(selectedDate, i, startMinute, 0));
				} else if (i === endHour && endMinute !== 0) {
					excludedDatesInterval = excludedDatesInterval.concat(iterateThrewHour(selectedDate, i, 0, endMinute));
				} else if (i !== startHour && i !== endHour) {
					excludedDatesInterval = excludedDatesInterval.concat(iterateThrewHour(selectedDate, i, 0, endHour - 1 === i ? 0 : 59));
				}
			}
		}
		if (excludedDatesInterval.length > 0) {
			excludeDatesIntervals.push(excludedDatesInterval);
		}
	});

	excludeDatesIntervals.forEach((interval, index, allIntervals) => {
		const restIntervals = [...allIntervals];
		restIntervals.splice(index, 1);
		const firstTimeDuplicate = restIntervals.flat().find(item => item.getTime() === interval[0].getTime());
		const lastTimeDuplicate = restIntervals.flat().find(item => item.getTime() === interval[interval.length - 1].getTime());

		/*
			Если к примеру интервал с 15:00 до 17:00 уже забронирован, то пользователь все равно может выбрать время с 17:00 или до 15:00.
			Чтобы дать такую возможность надо, вырезать из запрещенных интервалов начло или конец в зависимости от типа интервала.
		*/
		if (interval[0].toString().includes('00:00:00')) {
			excludedDates = [...excludedDates, ...interval.slice(0, -1)];
		} else if (interval[interval.length - 1].toString().includes('23:59:00')) {
			excludedDates = [...excludedDates, ...interval.slice(1)];
		} else if (firstTimeDuplicate && lastTimeDuplicate) {
			excludedDates = [...excludedDates, ...interval.slice(1, -1)];
		} else if (firstTimeDuplicate) {
			excludedDates = [...excludedDates, ...interval.slice(0, -1)];
		} else if (lastTimeDuplicate) {
			excludedDates = [...excludedDates, ...interval.slice(1)];
		} else {
			excludedDates = [...excludedDates, ...interval.slice(1, -1)];
		}
	});

	return excludedDates;
};
