import React from 'react';
import s from './EmptyData.module.scss';

export const EmptyData = () => {
	return (
		<div className={s.container}>
			<span>По вашему запросу ничего не найдено.</span>
			<span>Попробуйте изменить ваш запрос.</span>
		</div>
	);
};
