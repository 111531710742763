import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { contentImagesExport, contentImagesImport, generateSearchResults } from '../_helpers';
import { wikiServiceActions } from '../actions';
import { ArticleResponse, SearchResults } from '../types';
import { asyncActions as wikiTreeAsync } from '../wikiTree/slice';
import { slice as articleSlice } from '../article/slice';
import { CreateArticleREQ_PAYLOAD, EditArticleREQ, FindArticleByContentREQ, FindArticleByContentRES, GetArticleREQ_PARAMS, GetArticleRES } from './types';

const NAME = `${ServiceName.WIKI_SERVICE}/articles`;

const getArticle = createAppAsyncThunk(`${NAME}/getArticle`, async (arg: { params: GetArticleREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;
	const { dispatch, getState } = thunkAPI;

	const res = await apiRequest.getRequest<GetArticleRES>({
		url: `${NAME}/${params.articleId}`,
		// params,
		thunkAPI,
		action: () => getArticle(arg),
	});

	dispatch(wikiTreeAsync.getWiki({ params: { includeDeactivated: getState().wiki_service.wikiTree.showArchived } }));
	dispatch(articleSlice.actions.setArticle(res));

	const contentWithMeta = await contentImagesImport(res.content, () => getArticle(arg), thunkAPI);

	return {
		...res,
		content: contentWithMeta,
	};
});

const createArticle = createAppAsyncThunk(`${NAME}/createArticle`, async (arg: { payload: CreateArticleREQ_PAYLOAD }, thunkAPI) => {
	const { payload } = arg;
	const { dispatch, getState } = thunkAPI;

	const currentState = getState();
	const activeArticleImages = currentState.wiki_service.article.activeArticleImages;

	const contentWithMeta = await contentImagesExport(payload.content, activeArticleImages, () => createArticle(arg), thunkAPI);

	const res = await apiRequest.postRequest<string>({
		url: `${NAME}/create`,
		payload: {
			...payload,
			content: contentWithMeta,
		},
		thunkAPI,
		action: () => createArticle(arg),
	});

	await dispatch(wikiTreeAsync.getWiki({ params: { includeDeactivated: getState().wiki_service.wikiTree.showArchived } }));

	return res;
});

const editArticle = createAppAsyncThunk(`${NAME}/editArticle`, async (arg: EditArticleREQ, thunkAPI) => {
	const { params, payload } = arg;
	const { dispatch, getState } = thunkAPI;

	await apiRequest.patchRequest({
		url: `${NAME}/edit`,
		params,
		payload,
		thunkAPI,
		action: () => editArticle(arg),
	});

	await dispatch(wikiTreeAsync.getWiki({ params: { includeDeactivated: getState().wiki_service.wikiTree.showArchived } }));
});

const findArticleByContent = createAppAsyncThunk(`${NAME}/findArticleByContent`, async (arg: FindArticleByContentREQ, thunkAPI) => {
	const { params } = arg;
	const { getState, signal } = thunkAPI;
	const state = getState();

	if (params.contentIncludeSubstring !== '') {
		const res = await apiRequest.getRequest<FindArticleByContentRES>({
			url: `${NAME}/findbycontent`,
			params,
			thunkAPI,
			action: () => findArticleByContent(arg),
			signal,
		});

		return generateSearchResults(params.contentIncludeSubstring, state.wiki_service.wikiTree.wikiTree, res.body);
	} else {
		return null;
	}
});

// * Custom requests
export const editArticleContent = createAppAsyncThunk(`${NAME}/editArticleContent`, async (arg: { articleId: string; name: string; content: string }, thunkAPI) => {
	const { articleId, name, content } = arg;
	const { dispatch, getState } = thunkAPI;

	const currentState = getState();
	const activeArticleImages = currentState.wiki_service.article.activeArticleImages;

	const contentWithMeta = await contentImagesExport(content, activeArticleImages, () => editArticleContent(arg), thunkAPI);

	await dispatch(
		editArticle({
			params: { articleId },
			payload: [
				{
					op: 'replace',
					path: '/name',
					value: name,
				},
				{
					op: 'replace',
					path: '/content',
					value: contentWithMeta,
				},
			],
		}),
	);

	dispatch(getArticle({ params: { articleId } }));
});

interface State {
	activeArticleData: ArticleResponse | null;
	activeArticleImages: string[];
	searchResults: SearchResults | null;
	status: RequestStatus;
}

export const initialState: State = {
	activeArticleData: null,
	activeArticleImages: [],
	searchResults: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setArticle: (state, action: PayloadAction<ArticleResponse>) => {
			state.activeArticleData = action.payload;
		},
		setArticleImages: (state, action: PayloadAction<string[]>) => {
			state.activeArticleImages = action.payload;
		},
		setSearchResultsPrivate: (state, action: PayloadAction<SearchResults | null>) => {
			state.searchResults = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getArticle.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getArticle.fulfilled, (state, action) => {
			state.activeArticleData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getArticle.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.status = RequestStatus.failed;
			}
		});

		builder.addCase(createArticle.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createArticle.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createArticle.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editArticle.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editArticle.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(editArticle.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findArticleByContent.pending, (state, action) => {
			const searchString = action.meta.arg.params.contentIncludeSubstring;
			state.status = searchString === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(findArticleByContent.fulfilled, (state, action) => {
			state.searchResults = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(findArticleByContent.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.status = RequestStatus.failed;
			}
		});
	},
});

export const asyncActions = {
	getArticle,
	createArticle,
	editArticle,
	findArticleByContent,
	editArticleContent,
};
