export const isValidTimeUnit = (str: string, type: 'hour' | 'min-sec'): boolean => {
	const number = Number(str);

	if (isNaN(number)) {
		return false;
	}

	switch (type) {
		case 'hour':
			return number >= 0 && number <= 23;
		case 'min-sec':
			return number >= 0 && number <= 59;
	}
};
