import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectSingleCustomValue } from '../_inputs/selects/SelectSingleCustomValue/SelectSingleCustomValue';
import { matchItems } from 'src/shared/lib/filter';
import { extractNestedError } from 'src/shared/lib/fields';

export interface SelectValue {
	id: string;
	name: string;
}

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	options: SelectValue[];
	required?: boolean;
	disabled?: boolean;
	isNullable?: boolean;
}

export const SelectSingleCustomValueField: FC<Props> = props => {
	const {
		name, //
		label,
		placeholder,
		options,
		required,
		disabled,
		isNullable,
	} = props;

	const { control, setValue } = useFormContext();

	const [searchSubstring, setSearchSubstring] = useState('');
	const [filteredOptions, setFilteredOptions] = useState<SelectValue[] | { name: string; id: string }[]>([]);

	useEffect(() => {
		setFilteredOptions(options);
	}, [options]);

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<SelectSingleCustomValue
					label={label}
					placeholder={placeholder}
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					searchSubstring={searchSubstring}
					onStringChange={value => {
						const sameValue = options.find(item => item.name === value);
						setFilteredOptions(matchItems(options, value));
						setSearchSubstring(value);
						field.onChange(sameValue ? { name: sameValue.name, id: sameValue.id } : { name: value, id: null });
					}}
					selectedOption={field.value}
					setSelectedOption={value => setValue(name, value)}
					options={filteredOptions}
					isNullable={isNullable}
					disabled={disabled}
					errorMessage={extractNestedError(fieldState.error)}
					required={required}
				/>
			)}
		/>
	);
};
