import React, { useState } from 'react';
import { useGetImage } from 'src/pages/surveys/libs/useGetImage/useGetImage';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { galleyFileToImageContent } from 'src/shared/lib/file';
import { v4 } from 'uuid';
import { ImageContent } from 'src/app/redux/queries/survey-service/types';
import { DropZone } from 'src/shared/ui/DropZone/DropZone';
import { GalleyFile } from 'src/shared/ui/_galleries/Gallery/_types';
import { Button } from 'src/shared/ui/_buttons/Button';
import { IconSquareButton } from 'src/shared/ui/_buttons/IconSquareButton';
import { ModalHeader } from '../../../../../../shared/ui/_modals/ModalHeader/ModalHeader';
import s from './ImageModal.module.scss';

interface Props {
	toggleModal: () => void;
	questionImage: ImageContent | null;
	setQuestionImage: (image: ImageContent | null) => void;
	id?: string | null;
}

export const ImageModal: React.FC<Props> = props => {
	const { toggleModal, questionImage, setQuestionImage, id } = props;

	const { imageInfo } = useGetImage(id, questionImage);

	const [currentImage, setCurrentImage] = useState<GalleyFile | null>(imageInfo);
	const [showDeleteIcon, setShowDeleteIcon] = useState(false);

	const onDrop = async (acceptedFiles: any) => {
		const files = acceptedFiles.map((file: any) =>
			Object.assign(file, {
				id: `new_${v4()}`,
				preview: URL.createObjectURL(file),
			}),
		);

		setCurrentImage(files[0]);
	};

	const removeFile = () => setCurrentImage(null);

	const onSaveClick = () => {
		if (currentImage?.id === imageInfo?.id) {
			toggleModal();
			return;
		}

		if (currentImage) {
			galleyFileToImageContent(currentImage).then(res => {
				setQuestionImage(res);
				toggleModal();
			});
		} else {
			setQuestionImage(null);
			toggleModal();
		}
	};

	// * Render
	return (
		<>
			<ModalHeader title="Добавление фотографии" />

			<div className={s.inner}>
				{currentImage ? (
					<div
						className={s.image}
						onMouseEnter={() => setShowDeleteIcon(true)}
						onMouseLeave={() => setShowDeleteIcon(false)}
					>
						<img
							src={currentImage.preview}
							alt={currentImage.name}
						/>

						{showDeleteIcon && (
							<IconSquareButton
								size="small"
								Icon={<DeleteSVG />}
								onClick={() => removeFile()}
								className={s.delete}
							/>
						)}
					</div>
				) : (
					<DropZone
						iconType="media"
						onDrop={onDrop}
					/>
				)}
			</div>

			<div className={s.button}>
				<Button
					variant="tertiary"
					onClick={toggleModal}
				>
					Отменить
				</Button>
				<Button onClick={onSaveClick}>Сохранить</Button>
			</div>
		</>
	);
};
