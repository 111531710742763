import { UseFormSetValue } from 'react-hook-form';
import { WorkTimeResponse } from 'src/app/redux/queries/time-service/types/resTypes';
import { ProjectInfo } from '../types';
import { FormValues } from '../types';

export const setDescriptionAndHours = (setValue: UseFormSetValue<FormValues>, selectedProject: ProjectInfo, workTimesInfo: WorkTimeResponse[]) => {
	const projectsWorkTimeInfo = workTimesInfo.find(workTime => workTime.workTime.project.id === selectedProject.id)?.workTime;

	setValue('description', (projectsWorkTimeInfo?.managerDescription || projectsWorkTimeInfo?.description) ?? '');
	setValue('hours', (projectsWorkTimeInfo?.managerHours || projectsWorkTimeInfo?.userHours) ?? null);
};
