import React, { useEffect, useState } from 'react';
import s from './UnitsContent.module.scss';
import { UnitCard } from './UnitCard/UnitCard';
import { useNavigate } from 'react-router-dom';
import { CreateNewUnit } from './CreateNewUnit/CreateNewUnit';
import { Modal } from 'src/shared/ui/_modals/Modal/ui/Modal/Modal';
import { DragDropContext } from 'react-beautiful-dnd';
import { DragDropContent } from './DragDropContent/DragDropContent';
import { company_structure_serviceAPI } from 'src/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { companyStructureServiceActions } from 'src/app/redux/state/company-structure/actions';
import { useAppDispatch } from 'src/app/redux/utils';

interface Props {
	isEdit: boolean;
}

export interface UnitProps {
	id: string;
	name: string;
	fullname: string;
	isCustom: boolean;
	description: string | null;
	usersCount: number;
}

export const UnitsContent: React.FC<Props> = props => {
	const { isEdit } = props;

	const navigate = useNavigate();

	const openDetails = (departmentId: string) => {
		navigate(`/structure/details/${departmentId}`);
	};

	// * Actions
	const dispatch = useAppDispatch();
	const { addReorderedUnits } = companyStructureServiceActions.departments.actions;

	// * Queries
	const { currentData: allDepartmentsData } = company_structure_serviceAPI.useGetAllDepartmentsQuery(true);
	const allDepartments =
		allDepartmentsData?.body && allDepartmentsData.body.length > 0
			? allDepartmentsData.body
					.map(department => {
						return department.childDepartments && !isEdit ? [department, ...department.childDepartments] : department;
					})
					.flat()
			: [];

	const preparedUnits = allDepartments.map(departmentInfo => {
		const { department, director, usersCount } = departmentInfo;
		const { name, id, isCustom, description } = department;

		return {
			id,
			name,
			fullname: director ? `${director.fullName}` : '',
			description,
			isCustom,
			usersCount,
		};
	});

	// * State
	const [units, setUnits] = useState<UnitProps[]>([]);

	const [unitsFirstColumn, setUnitsFirstColumn] = useState<UnitProps[]>([]);
	const [unitsSecondColumn, setUnitsSecondColumn] = useState<UnitProps[]>([]);
	const [unitsThirdColumn, setUnitsThirdColumn] = useState<UnitProps[]>([]);

	useEffect(() => {
		const length = Math.max(unitsFirstColumn.length, unitsSecondColumn.length, unitsThirdColumn.length);

		// * Проверка, изменилось ли расположение карточек
		const finishedArray = [];
		for (let i = 0; i <= length; i++) {
			unitsSecondColumn[i] && finishedArray.push(unitsSecondColumn[i].id);
			unitsThirdColumn[i] && finishedArray.push(unitsThirdColumn[i].id);
			unitsFirstColumn[i] && finishedArray.push(unitsFirstColumn[i].id);
		}

		const isChangeUnits =
			finishedArray.filter((item, index) => {
				if (units[index]?.id === item) return;
				return item;
			}).length > 0;

		if (!isChangeUnits) {
			dispatch(addReorderedUnits({}));
			return;
		}

		// * Все колонки в один объект с порядковым номером
		const finishedObject = {};
		let count = 1;
		for (let i = 0; i <= length; i++) {
			if (unitsSecondColumn[i]) {
				Object.assign(finishedObject, {
					[unitsSecondColumn[i].id]: count,
				});
				count++;
			}
			if (unitsThirdColumn[i]) {
				Object.assign(finishedObject, {
					[unitsThirdColumn[i].id]: count,
				});
				count++;
			}
			if (unitsFirstColumn[i]) {
				Object.assign(finishedObject, {
					[unitsFirstColumn[i].id]: count,
				});
				count++;
			}
		}

		dispatch(addReorderedUnits(finishedObject));
	}, [unitsFirstColumn, unitsSecondColumn, unitsThirdColumn]);

	useEffect(() => {
		preparedUnits.length > 0 && setUnits([...preparedUnits]);
	}, [allDepartments.length]);

	useEffect(() => {
		if (units) {
			setUnitsFirstColumn([...units.filter((_unit, index) => index === 2 || index % 3 === 2)]);
			setUnitsSecondColumn([...units.filter((_unit, index) => index === 0 || index % 3 === 0)]);
			setUnitsThirdColumn([...units.filter((_unit, index) => index === 1 || index % 3 === 1)]);
		}
	}, [units]);

	// * Modal
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => {
		setShowModal(prevState => !prevState);
	};

	const handleDragDrop = (results: any) => {
		const { source, destination } = results;

		const sourceIndex = source.index;
		const destinationIndex = destination.index;

		// * 1
		if (!destination) return;

		// * 2
		if (source.droppableId === destination.droppableId && source.index === destination.index) return;

		// * 3
		if (source.droppableId === destination.droppableId) {
			const reorderedUnits = source.droppableId === 'UNIT1' ? [...unitsFirstColumn] : source.droppableId === 'UNIT2' ? [...unitsSecondColumn] : [...unitsThirdColumn];

			const [removeUnit] = reorderedUnits.splice(sourceIndex, 1);
			reorderedUnits.splice(destinationIndex, 0, removeUnit);

			if (source.droppableId === 'UNIT1') {
				setUnitsFirstColumn([...reorderedUnits]);
			} else if (source.droppableId === 'UNIT2') {
				setUnitsSecondColumn([...reorderedUnits]);
			} else {
				setUnitsThirdColumn([...reorderedUnits]);
			}

			return;
		}

		// * 4
		const reordereSourcedUnits = source.droppableId === 'UNIT1' ? [...unitsFirstColumn] : source.droppableId === 'UNIT2' ? [...unitsSecondColumn] : [...unitsThirdColumn];
		const reordereDestinationUnits =
			destination.droppableId === 'UNIT1' ? [...unitsFirstColumn] : destination.droppableId === 'UNIT2' ? [...unitsSecondColumn] : [...unitsThirdColumn];

		const [removeUnit] = reordereSourcedUnits.splice(sourceIndex, 1);
		reordereDestinationUnits.splice(destinationIndex, 0, removeUnit);

		if (source.droppableId === 'UNIT1') {
			setUnitsFirstColumn([...reordereSourcedUnits]);
		} else if (source.droppableId === 'UNIT2') {
			setUnitsSecondColumn([...reordereSourcedUnits]);
		} else {
			setUnitsThirdColumn([...reordereSourcedUnits]);
		}

		if (destination.droppableId === 'UNIT1') {
			setUnitsFirstColumn([...reordereDestinationUnits]);
		} else if (destination.droppableId === 'UNIT2') {
			setUnitsSecondColumn([...reordereDestinationUnits]);
		} else {
			setUnitsThirdColumn([...reordereDestinationUnits]);
		}

		return;
	};

	return (
		<div className={s.container}>
			{isEdit && (
				<div className={s.edit_wrapper}>
					<div className={s.columns}>
						<DragDropContext onDragEnd={handleDragDrop}>
							<DragDropContent
								addButton={toggleModal}
								arrayUnits={unitsFirstColumn}
								droppableId="UNIT1"
							/>

							<DragDropContent
								arrayUnits={unitsSecondColumn}
								droppableId="UNIT2"
							/>

							<DragDropContent
								arrayUnits={unitsThirdColumn}
								droppableId="UNIT3"
							/>
						</DragDropContext>
					</div>
				</div>
			)}

			{!isEdit && (
				<div className={s.wrapper}>
					{preparedUnits.map(unit => (
						<UnitCard
							key={unit.id}
							id={unit.id}
							name={unit.name}
							fullname={unit.fullname}
							usersCount={unit.usersCount}
							isCustom={unit.isCustom}
							onClick={() => openDetails(unit.id)}
						/>
					))}
				</div>
			)}

			{showModal && (
				<Modal
					show={showModal}
					toggleModal={toggleModal}
					width={800}
					overflowX="visible"
					Content={
						<CreateNewUnit
							values={{
								unitId: null,
								name: '',
								description: '',
							}}
							toggleModal={toggleModal}
						/>
					}
				/>
			)}
		</div>
	);
};
