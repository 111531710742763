import { DateTime } from 'luxon';

// ISO => дд.мм.гг (пример: 29.05.23)
export const isoToDateString = (isoString: string) => {
	return DateTime.fromISO(isoString).toLocaleString(
		{
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		},
		{ locale: 'ru-RU' },
	);
};
