import { DateTime } from 'luxon';
import { BirthType } from 'src/app/redux/queries/user-service/types/types';

// ISO => дд.мм.гг (пример: 29.05.23) / дд.мм (пример: 29.05) / скрыть дату, если type === 'Closed'
export const isoToDateByType = (isoString: string, type?: BirthType) => {
	switch (type) {
		case 'WithoutYear':
			return DateTime.fromISO(isoString).toLocaleString(
				{
					month: '2-digit',
					day: '2-digit',
				},
				{ locale: 'ru-RU' },
			);

		case 'Closed':
		case 'Open':
			return DateTime.fromISO(isoString).toLocaleString(
				{
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				},
				{ locale: 'ru-RU' },
			);

		default:
			return '';
	}
};
