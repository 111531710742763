import cn from 'classnames';
import React from 'react';
import { FormValues } from '../../pages/SurveyPage/Content/TemplateContent/NewCategoryMC/NewCategoryMC';
import s from './ChipCategory.module.scss';

interface Props {
	category: FormValues;
}

export const ChipCategory: React.FC<Props> = props => {
	const { category } = props;

	const { name, color } = category;

	// * Render
	return (
		<div className={cn(s.container, s[`background_${color}`])}>
			<span>{name}</span>
		</div>
	);
};
