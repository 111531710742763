import { FC } from 'react';
import { DepartmentUserAssignment, DepartmentUserRole } from 'src/app/redux/queries/department-service/types/types';
import { ReactComponent as ManagerSVG } from 'src/shared/assets/svg/action/star.svg';
import { ReactComponent as DirectorSVG } from 'src/shared/assets/svg/action/star_filled.svg';
import s from './RoleStar.module.scss';

interface Props {
	role: DepartmentUserRole;
	assignment?: DepartmentUserAssignment;
}

export const RoleStar: FC<Props> = props => {
	const { role, assignment = 'Employee' } = props;

	const renderSVG = () => {
		if (assignment === 'Director') {
			return <DirectorSVG className={s.container} />;
		} else if (role === 'Manager') {
			return <ManagerSVG className={s.container} />;
		} else {
			return null;
		}
	};

	return <>{renderSVG()}</>;
};
