import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { CreateWikiNewsImageREQ, RemoveWikiNewsImageREQ } from './types';

const NAME = `${ServiceName.IMAGE_SERVICE}/wikiNews`;

const createWikiNewsImage = createAppAsyncThunk(`${NAME}/createWikiNewsImage`, async (arg: CreateWikiNewsImageREQ, thunkAPI) => {
	const { payload } = arg;

	await apiRequest.postRequest({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createWikiNewsImage(arg),
	});
});

const removeWikiNewsImage = createAppAsyncThunk(`${NAME}/removeWikiNewsImage`, async (arg: RemoveWikiNewsImageREQ, thunkAPI) => {
	const { params } = arg;

	await apiRequest.delRequest({
		url: `${NAME}/remove`,
		params,
		thunkAPI,
		action: () => removeWikiNewsImage(arg),
	});
});

interface State {
	status: RequestStatus;
}

const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createWikiNewsImage.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createWikiNewsImage.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createWikiNewsImage.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeWikiNewsImage.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeWikiNewsImage.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(removeWikiNewsImage.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createWikiNewsImage,
	removeWikiNewsImage,
};
