import { education_serviceAPI } from 'src/app/redux/queries/education-service/education_serviceAPI';
import { dateToBackendISO, injectYearToDate } from 'src/shared/lib/date';
import { DefaultValues } from '../types';

export const useEditEducation = (
	educationId: string, //
	prevValues: DefaultValues,
	onCloseModal: () => void,
) => {
	const promises: any[] = [];

	// * API
	const [editEducation, { isLoading: isEditEducationLoading }] = education_serviceAPI.usePatchEducationEditMutation();
	const [createUniversity, { isLoading: isCreateUniversityLoading }] = education_serviceAPI.usePostUniversityCreateMutation();
	const [deleteImage, { isLoading: isDeleteImageLoading }] = education_serviceAPI.useDeleteImageRemoveMutation();
	const [createImage, { isLoading: isCreateImageLoading }] = education_serviceAPI.usePostImageCreateMutation();

	const isEditLoading = isEditEducationLoading || isCreateUniversityLoading || isDeleteImageLoading || isCreateImageLoading;

	// * Submit
	const onEditSubmit = async (data: DefaultValues) => {
		const {
			institution, //
			specialization,
			form,
			startDate,
			endDate,
			images,
			completeness,
		} = data;

		const preparedArgs: Array<{
			op: 'replace';
			value: any;
			path:
				| '/UniversityId' //
				| '/QualificationName'
				| '/EducationFormId'
				| '/EducationTypeId'
				| '/Completeness'
				| '/AdmissionAt'
				| '/IssueAt'
				| '/IsActive';
		}> = [];

		// - diff
		if (institution.name !== prevValues.institution.name) {
			if (institution.id) {
				preparedArgs.push({
					op: 'replace',
					path: '/UniversityId',
					value: institution.id,
				});
			} else {
				await createUniversity({
					createUniversityRequest: {
						name: institution.name,
					},
				})
					.unwrap()
					.then(res => {
						preparedArgs.push({
							op: 'replace',
							path: '/UniversityId',
							value: res.body || '',
						});
					})
					.catch(err => console.log(err));
			}
		}

		if (specialization !== prevValues.specialization) {
			preparedArgs.push({
				op: 'replace',
				path: '/QualificationName',
				value: specialization,
			});
		}

		if (form.id !== prevValues.form.id) {
			preparedArgs.push({
				op: 'replace',
				path: '/EducationFormId',
				value: form.id,
			});
		}

		if (startDate !== prevValues.startDate) {
			preparedArgs.push({
				op: 'replace',
				path: '/AdmissionAt',
				value: dateToBackendISO(injectYearToDate(new Date(), startDate)),
			});
		}

		if (endDate !== prevValues.endDate) {
			preparedArgs.push({
				op: 'replace',
				path: '/IssueAt',
				value: endDate ? dateToBackendISO(injectYearToDate(new Date(), endDate)) : null,
			});
		}

		if (completeness !== prevValues.completeness) {
			preparedArgs.push({
				op: 'replace',
				path: '/Completeness',
				value: completeness ? 'Completed' : 'Uncompleted',
			});
		}

		const currentImageId = images[0]?.id;
		const prevImageId = prevValues.images[0]?.id;
		if (currentImageId !== prevImageId) {
			if (prevImageId) {
				promises.push(
					deleteImage({
						removeImagesRequest: {
							educationId,
							imagesIds: [prevImageId],
						},
					}).unwrap(),
				);
			}

			if (currentImageId) {
				promises.push(
					createImage({
						createImagesRequest: {
							educationId,
							images,
						},
					}).unwrap(),
				);
			}
		}

		// - promises
		preparedArgs.length > 0 &&
			promises.push(
				editEducation({
					educationId,
					body: preparedArgs,
				}).unwrap(),
			);

		Promise.allSettled(promises)
			.then(() => onCloseModal())
			.catch(err => console.log('Error: ', err));
	};

	return { onEditSubmit, isEditLoading };
};
