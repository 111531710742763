import { QuestionItem } from 'src/pages/surveys/pages/NewSurveyPage/_types';
import { getGridColumns } from 'src/pages/surveys/libs/getGridColumns/getGridColumns';
import { getAnswers } from 'src/pages/surveys/libs/getAnswers/getAnswers';
import { BaseData, Data, GridData, MultiBaseData } from 'src/pages/surveys/pages/ResultsPage/Content/SummaryContent/types';

// ? forEach
export const getSummaryData = (questions: QuestionItem[]): Data[] => {
	return questions.map(question => {
		const { type, hasMultipleChoice, images } = question;
		const imageId = (images ? images[0]?.parentId : null) ?? null;

		const typeQuestion = hasMultipleChoice ? 'Multi' + type.type : type.type;

		const prepared: Omit<Data, 'data'> = {
			id: question.id,
			content: question.content,
			isObligatory: question.isObligatory,
			type: typeQuestion,
			imageId,
		};

		const noAnswers = [...getAnswers(question.options), ...getAnswers(question.scaleOptions), ...getAnswers(question.gridOptions)].length === 0;

		if (noAnswers)
			return {
				id: question.id,
				content: question.content,
				isObligatory: question.isObligatory,
				type: typeQuestion,
				imageId,
				data: [],
			};

		switch (typeQuestion) {
			case 'Base': {
				let total = 0;

				question.options.map(opt => {
					const count = opt.usersAnswers ? opt.usersAnswers.length : 0;
					total += count;
				});

				const data: BaseData[] = [];

				// other answers
				question.options.map(opt => {
					if (opt.isCustom) return;

					const count = opt.usersAnswers ? opt.usersAnswers.length : 0;
					const value = total ? +((count / total) * 100).toFixed() : 0;

					data.push({
						id: opt.id,
						value: value,
						label: opt.content,
						answersCount: count,
					});
				});

				// custom answer
				const customsWithAnswers = question.options.filter(opt => opt.isCustom && opt.usersAnswers && opt.usersAnswers.length > 0);

				const custom = question.options.reduce((acc: BaseData[], opt) => {
					const { id, content, isCustom } = opt;
					if (!isCustom) return acc;

					const isExist = acc.find(item => item.label === content);

					if (isExist) return acc;

					const countCustomAnswer = customsWithAnswers.filter(item => item.content === content).length;

					// TODO: убрать условие и customsWithAnswers, когда бэк почистят пустые кастомные ответы customsWithAnswers
					if (countCustomAnswer === 0) return acc;

					return [
						...acc,
						{
							id: opt.id,
							value: total ? +((countCustomAnswer / total) * 100).toFixed() : 0,
							label: content,
							answersCount: countCustomAnswer,
						},
					];
				}, []);

				if (custom.length > 0) data.push(...custom);

				Object.assign(prepared, {
					data: data,
				});
				break;
			}

			case 'MultiBase': {
				const answers: MultiBaseData[] = question.options
					.filter(opt => !opt.isCustom)
					.map(opt => {
						const count = opt.usersAnswers?.length || 0;

						return {
							value: count,
							option: opt.content,
							color: '#3F98F5',
						};
					});

				const customsWithAnswers = question.options.filter(opt => opt.isCustom && opt.usersAnswers && opt.usersAnswers.length > 0);

				const custom = question.options.reduce((acc: MultiBaseData[], opt) => {
					const isExist = acc.find(item => item.option === opt.content);

					if (isExist) return acc;

					const countCustomAnswer = customsWithAnswers.filter(item => item.isCustom && item.content === opt.content).length;

					// TODO: убрать условие, когда бэк почистят пустые кастомные ответы
					if (countCustomAnswer === 0) return acc;

					return [
						...acc,
						{
							value: countCustomAnswer,
							option: opt.content,
							color: '#3F98F5',
						},
					];
				}, []);

				if (custom.length > 0) answers.unshift(...custom);

				Object.assign(prepared, {
					data: [...answers],
				});

				break;
			}

			case 'Scale': {
				Object.assign(prepared, {
					data: question.scaleOptions.map(opt => {
						const count = opt.usersAnswers?.length || 0;

						return {
							value: count,
							scale: opt.content,
						};
					}),
				});
				break;
			}

			case 'Grid':
			case 'MultiGrid': {
				const keys: string[] = [];
				// Все варианты ответа (столбцы)
				const columns = getGridColumns(question.gridOptions);

				const data = question.gridOptions.reduce((acc: GridData[], option) => {
					// Если такая строка уже была
					const isExistRow = acc.find(item => item.row === option.rowSignature);
					if (isExistRow) return acc;

					// Находим все варианты ответов в этой строке
					const row = option.rowSignature;
					const allRows = question.gridOptions.filter(opt => row === opt.rowSignature);

					const object = {};

					// Добавляем все варианты ответа на строку с количеством ответивших
					columns.map(column => {
						const withColumn = allRows.find(row => row.columnSignature === column.columnSignature);

						withColumn &&
							Object.assign(object, {
								[`${column.columnSignature}`]: withColumn.usersAnswers?.length || 0,
							});
					});

					// Ключи для диаграммы
					if (keys.length < 1) keys.push(...Object.keys(object));

					return [
						...acc,
						{
							row: option.rowSignature,
							...object,
						},
					];
				}, []);

				Object.assign(prepared, {
					data: data,
					keys: keys,
				});

				break;
			}
		}

		return prepared as Data;
	});
};
