import { ComponentPropsWithoutRef } from 'react';
import s from './IconWrapper.module.scss';

export const IconWrapper: React.FC<ComponentPropsWithoutRef<'div'>> = ({ children, ...divProps }) => {
	return (
		<div
			className={s.container}
			{...divProps}
		>
			{children}
		</div>
	);
};
