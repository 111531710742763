export const ALL_EDUCATION_TYPES: { [key: string]: string } = {
	'Full-time': 'Очное',
	'Part-time': 'Заочное',
	Online: 'Онлайн',
	Offline: 'Оффлайн',
};

export const EDUCATION_TYPE = ['Full-time', 'Part-time'];
export const CERTIFICATE_TYPE = ['Online', 'Offline'];

export const COMPLETENESS: { [key: string]: string } = {
	Uncompleted: 'Неоконченное',
	Completed: 'Оконченное',
};
