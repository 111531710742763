import { useLayoutEffect, useRef, useState } from 'react';

export const useComponentDimensions = () => {
	const targetRef = useRef<any>();
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	let movementTimer: any = null;

	const RESET_TIMEOUT = 10;

	const testDimensions = () => {
		if (targetRef.current) {
			setDimensions({
				width: targetRef.current.getBoundingClientRect().width,
				height: targetRef.current.getBoundingClientRect().height,
			});
		}
	};

	useLayoutEffect(() => {
		testDimensions();

		window.addEventListener('resize', () => {
			clearTimeout(movementTimer);
			movementTimer = setTimeout(testDimensions, RESET_TIMEOUT);
		});
	}, []);

	return { targetRef, dimensions };
};
