import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { EditPositionREQ, EditPositionRES, FindPositionREQ, FindPositionRES, PositionInfo } from './types';

const NAME = `${ServiceName.POSITION_SERVICE}/position`;

// * Async thunks
const findPosition = createAppAsyncThunk(`${NAME}/findPosition`, async (arg: FindPositionREQ, thunkAPI) => {
	const { params } = arg;

	const res = await apiRequest.getRequest<FindPositionRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findPosition(arg),
	});

	return res.body;
});

const editPosition = createAppAsyncThunk(`${NAME}/editPosition`, async (arg: EditPositionREQ, thunkAPI) => {
	const { params, payload } = arg;

	await apiRequest.patchRequest<EditPositionRES>({
		url: `${NAME}/edit`,
		params,
		payload,
		action: () => editPosition(arg),
		thunkAPI,
	});
});

// * Slice
export interface State {
	positionInfo: PositionInfo[] | null;
	editStatus: RequestStatus;
	status: RequestStatus;
}

export const initialState: State = {
	positionInfo: null,
	editStatus: RequestStatus.still,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(findPosition.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findPosition.fulfilled, (state, action) => {
			state.positionInfo = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(findPosition.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editPosition.pending, state => {
			state.editStatus = RequestStatus.loading;
		});
		builder.addCase(editPosition.fulfilled, state => {
			state.editStatus = RequestStatus.success;
		});
		builder.addCase(editPosition.rejected, state => {
			state.editStatus = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	findPosition,
	editPosition,
};
