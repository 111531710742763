import React, { FormEvent, ReactNode } from 'react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { TextInput } from '../_inputs/text_Inputs/TextInput';

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	maxLength?: number;
	characterLimit?: number;
	limitPosition?: 'start' | 'end';
	disabled?: boolean;
	LeftIcon?: ReactNode;
	Icon?: ReactNode;
	defaultValue?: string;
	required?: boolean;
	errorBorder?: boolean;
	onChangeWrapper?: (value: string | number | null) => string | number | null;
	onFocus?: () => void;
	onBlur?: () => void;
}

export const StringField: React.FC<Props> = props => {
	const {
		name, //
		label,
		placeholder,
		maxLength,
		characterLimit,
		disabled = false,
		LeftIcon,
		Icon,
		defaultValue,
		required,
		errorBorder,
		onChangeWrapper,
		onFocus,
		onBlur,
	} = props;

	const { control, setValue } = useFormContext();

	const onChange = (event: FormEvent<HTMLInputElement>, field: ControllerRenderProps<FieldValues, string>) => {
		return onChangeWrapper ? setValue(field.name, onChangeWrapper(event.currentTarget.value)) : field.onChange(event);
	};

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => (
				<TextInput
					{...field}
					onChange={event => onChange(event, field)}
					onFocus={onFocus}
					onBlur={onBlur}
					LeftIcon={LeftIcon}
					RightIcon={Icon}
					label={label}
					placeholder={placeholder}
					maxLength={maxLength}
					characterLimit={characterLimit}
					errorMessage={fieldState.error?.message}
					disabled={disabled}
					required={required}
					errorBorder={errorBorder}
				/>
			)}
		/>
	);
};
