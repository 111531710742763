import { ComponentPropsWithoutRef, FC } from 'react';
import s from './ErrorWithLimit.module.scss';
import cn from 'classnames';

interface Props extends ComponentPropsWithoutRef<'span'> {
	errorMessage?: string | null;
	characterLimit?: number;
	value?: string | number;
}

export const ErrorWithLimit: FC<Props> = props => {
	const {
		errorMessage, //
		characterLimit,
		value,
		className,
		...rest
	} = props;

	// * Render
	return (
		<span
			{...rest}
			className={cn(s.bottom_text_wrapper, className)}
		>
			{(errorMessage || characterLimit) && <span className={s.error}>{errorMessage}</span>}
			{characterLimit && (
				<span
					className={cn(s.bottom_text, value !== undefined && String(value)?.length === characterLimit ? s.bottom_text_max : '')}
				>{`${value !== undefined ? String(value).length : 0}/${characterLimit}`}</span>
			)}
		</span>
	);
};
