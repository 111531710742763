import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import s from './SurveyForm.module.scss';

import { survey_serviceAPI, useGetCategoriesQuery } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { CategoryInfo } from 'src/app/redux/queries/survey-service/types';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { FillingOutForm } from 'src/pages/surveys/components/FillingOutForm/FillingOutForm';
import { NewSurveySettings } from 'src/pages/surveys/components/NewSurveySettings/NewSurveySettings';
import { DndContextSurveyForm } from 'src/pages/surveys/components/SurveyForm/lib/dNd/DndContextSurveyForm';
import { useCreateSurvey } from 'src/pages/surveys/libs/useCreateSurvey/useCreateSurvey';
import { useEditSurvey } from 'src/pages/surveys/libs/useEditSurvey/useEditSurvey';
import { AddButtons } from 'src/pages/surveys/pages/NewSurveyPage/Section/AddButtons/AddButtons';
import { Section } from 'src/pages/surveys/pages/NewSurveyPage/Section/Section';
import { AllSurveyInfo, SectionProps, Survey } from 'src/pages/surveys/pages/NewSurveyPage/_types';
import { getInitialStateSurveyGroups } from 'src/pages/surveys/utils/utils';
import { ReactComponent as PreviewSVG } from 'src/shared/assets/svg/action/preview.svg';
import { ReactComponent as UnpreviewSVG } from 'src/shared/assets/svg/action/un_preview.svg';
import { dateAndTimeToString } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';

interface Props {
	isEdit?: boolean;
	isTemplate: boolean;
	surveyId: string | null;
}

export const SurveyForm: React.FC<Props> = props => {
	const { isEdit, isTemplate, surveyId } = props;

	// * Navigate
	const navigate = useNavigate();

	const toCancel = () => {
		navigate('/surveys');
	};

	// * API
	const { data: categoriesRES } = useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const categories = categoriesRES?.body;

	const { currentData: surveyIdData } = survey_serviceAPI.useGetSurveysSurveyidQuery(
		{
			surveyid: surveyId as string,
			includeCategories: true,
			includeImages: true,
			includeoptions: true,
		},
		{ skip: surveyId === null },
	);

	// * Survey info
	const [surveyInfo, setSurveyInfo] = useState<Survey>({
		title: '',
		description: '',
		isAnonymous: false,
		limited: false,
		cancelVoice: false,
		allRequiredFields: false,
		dateEnd: null,
		timeEnd: null,
		categoryId: null,
	});

	const [prevState, setPrevState] = useState<AllSurveyInfo>({ ...surveyInfo, groups: [] });

	useEffect(() => {
		const surveyAbout = {
			title: (surveyId ? surveyIdData?.subject : '') ?? '',
			description: (surveyId ? surveyIdData?.description : '') ?? '',
			isAnonymous: surveyId ? !!surveyIdData?.isAnonymous : false,
			limited: surveyId ? !!surveyIdData?.deadline : false,
			cancelVoice: surveyId ? !!surveyIdData?.isRevoteAvailable : false,
			allRequiredFields: surveyId ? !!surveyIdData?.isObligatory : false,
			dateEnd: surveyId && !!surveyIdData?.deadline ? new Date(surveyIdData?.deadline || '') : null,
			timeEnd: surveyId && !!surveyIdData?.deadline ? new Date(surveyIdData?.deadline || '') : null,
			categoryId: surveyId ? (surveyIdData?.surveyCategories ? surveyIdData.surveyCategories[0]?.id : null) : null,
		};

		setSurveyInfo(surveyAbout);

		const initialGroups: SectionProps[] = getInitialStateSurveyGroups(surveyId, surveyIdData?.groups);
		setSections([...initialGroups]);

		setPrevState({ ...surveyAbout, groups: initialGroups });
	}, [surveyIdData, surveyId]);

	// * Groups
	const [sections, setSections] = useState<SectionProps[]>([]);

	const { editSurveySubmit, isEditLoading } = useEditSurvey(prevState, { ...surveyInfo, groups: sections }, surveyId || '');
	const { createSurveySubmit, isCreateLoading } = useCreateSurvey(surveyInfo, sections, isTemplate);
	const isLoading = isEditLoading || isCreateLoading;

	// * Error state
	const [errors, setErrors] = useState<string[]>([]);

	const getSelectedCategory = (): CategoryInfo | { id: ''; name: '' } => {
		const selectedCategory = categories?.find(category => category.id === surveyInfo.categoryId);
		return selectedCategory ? selectedCategory : { id: '', name: '' };
	};

	// * Submit
	const schema = yup.object().shape({
		title: yup.string().max(150, 'title').required('title').nullable(),
		// description: yup.string().max(500, 'description').nullable(),
		dateEnd: yup
			.date()
			.nullable()
			.notRequired()
			.when('limited', {
				is: true,
				then: yup.date().nullable().required('dateEnd'),
			}),
	});

	const validateSurvey = () => {
		const isError = schema
			.validate(surveyInfo, { abortEarly: false })
			.then(() => setErrors([]))
			.catch(error => {
				setErrors(error.errors);
				return true;
			});

		return isError;
	};

	const onSubmit = async () => {
		// Находим пустые поля
		const isError = await validateSurvey();

		if (isError) return;

		createSurveySubmit();
	};

	const editSurvey = async () => {
		const isError = await validateSurvey();

		if (isError || !surveyId) return;

		editSurveySubmit();
	};

	// * Preview
	const [isPreview, setIsPreview] = useState(false);

	const toShowSurvey = async () => {
		const isError = await validateSurvey();

		if (!isError) setIsPreview(prevState => !prevState);
	};

	// * Render
	return (
		<div className={s.container}>
			<PageTitle
				title="Опросы"
				isBeta
				buttons={[
					<Button
						key="0"
						variant="tertiary"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							toShowSurvey();
						}}
						LeftIcon={isPreview ? <UnpreviewSVG /> : <PreviewSVG />}
						isLoading={isLoading}
					>
						{isPreview ? 'Назад' : 'Предпросмотр'}
					</Button>,
					<React.Fragment key="1">
						{!isPreview && (
							<Button
								onClick={toCancel}
								variant="secondary"
							>
								Отменить
							</Button>
						)}
					</React.Fragment>,
					<Button
						key="2"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							if (isEdit) {
								editSurvey();
							} else {
								onSubmit();
							}
						}}
						isLoading={isLoading}
					>
						{isPreview && !isEdit ? 'Создать' : 'Сохранить'}
					</Button>,
				]}
			/>

			{!isPreview && (
				<div className={s.survey_wrapper}>
					<NewSurveySettings
						isEdit={isEdit}
						surveyInfo={surveyInfo}
						setSurveyInfo={setSurveyInfo}
						prevIsAnonymous={prevState.isAnonymous}
						isTemplate={isTemplate || !!surveyIdData?.isTemplate}
						errors={errors}
						getSelectedCategory={getSelectedCategory}
						categories={categories}
					/>

					{sections.length < 1 && (
						<AddButtons
							sections={sections}
							indexSection={0}
							setSections={setSections}
							allRequiredFields={surveyInfo.allRequiredFields}
						/>
					)}

					<DndContextSurveyForm
						sections={sections}
						setSections={setSections}
					>
						{sections.map((section, indexSection) => (
							<Section
								key={section.id}
								isEdit={isEdit}
								section={section}
								sections={sections}
								indexSection={indexSection}
								setSections={setSections}
								allRequiredFields={surveyInfo.allRequiredFields}
							/>
						))}
					</DndContextSurveyForm>
				</div>
			)}

			{isPreview && (
				<FillingOutForm
					surveyInfo={{
						title: surveyInfo.title,
						description: surveyInfo.description,
						isAnonymous: surveyInfo.isAnonymous,
					}}
					category={getSelectedCategory().id ? getSelectedCategory() : null}
					deadline={surveyInfo.dateEnd ? dateAndTimeToString(surveyInfo.timeEnd, surveyInfo.dateEnd) : null}
					groups={sections}
					notClickable
					isLoading={false}
				/>
			)}
		</div>
	);
};
