import React, { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import s from './styles/_local_common.module.scss';
import cn from 'classnames';
import bs_primary from './styles/Button__primary.module.scss';
import bs_secondary from './styles/Button__secondary.module.scss';
import bs_tertiary from './styles/Button__tertiary.module.scss';
import { LoaderCircle } from '../../_loaders/LoaderCircle/LoaderCircle';
import { ButtonVariant } from '.';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
	variant?: ButtonVariant;
	isLoading?: boolean;
	LeftIcon?: ReactNode;
	RightIcon?: ReactNode;
	wide?: boolean;
	fixedWidth?: boolean;
};

export const Button: React.FC<Props> = props => {
	const {
		variant = 'primary', //
		isLoading,
		LeftIcon,
		RightIcon,
		wide,
		disabled = false,
		children,
		fixedWidth,
		onClick,
		type,
		className,
		...buttonProps
	} = props;

	// * Styles
	const bs = variant === 'primary' ? bs_primary : variant === 'secondary' ? bs_secondary : bs_tertiary;

	// * User actions
	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		type === 'button' && event.preventDefault(); // Отмена отправки формы. Если кнопка типа 'button'.
		type === 'submit' && isDisabled && event.preventDefault();
		!isDisabled && !isLoading && onClick && onClick(event);
	};

	// * Conditions
	const isDisabled = disabled || isLoading;

	// * Render
	return (
		<button
			className={cn(bs.container, isDisabled && bs.disabled, className)}
			style={{
				minWidth: fixedWidth ? '12rem' : 'fit-content',
				width: wide ? '100%' : 'fit-content',
			}}
			{...buttonProps}
			type={type}
			onClick={onClickHandler}
		>
			{LeftIcon && !isLoading && <div className={s.leftIcon}>{LeftIcon}</div>}

			{children}
			{isLoading && (
				<LoaderCircle
					className={s.loader}
					variant={variant === 'primary' ? 'light' : 'primary'}
				/>
			)}

			{RightIcon && !isLoading && <div className={s.rightIcon}>{RightIcon}</div>}
		</button>
	);
};
