import { DateTime } from 'luxon';
import { isValidTimeUnit } from '../isValidTimeUnit/isValidTimeUnit';

// (date, 00:00:00) => ISO string (2024-08-15T11:11:00)
export const dateTimeStringToISO = (date: Date, time: string): string | undefined => {
	const luxonDate = DateTime.fromJSDate(date);
	const [hour, minute, second] = time.split(':');

	const isValidHour = isValidTimeUnit(hour, 'hour');
	const isValidMinute = isValidTimeUnit(minute, 'min-sec');
	const isValidSecond = isValidTimeUnit(second, 'min-sec');

	const stringIsValidTime = isValidHour && isValidMinute && isValidSecond;

	if (luxonDate.isValid && stringIsValidTime) {
		return luxonDate
			.set({
				hour: +hour, //
				minute: +minute,
				second: +second,
			})
			.toISO({ includeOffset: false, suppressMilliseconds: true });
	} else {
		console.log('Invalid data or time in dateTimeStringToISO');
	}
};
