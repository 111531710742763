export const REGISTRATION_FORM_OPTIONS = [
	{
		name: 'Трудовой договор',
		value: 'Трудовой договор',
	},
	{
		name: 'ИП',
		value: 'ИП',
	},
	{
		name: 'Самозанятый',
		value: 'Самозанятый',
	},
];

export const JOB_FORMAT = [
	{
		name: 'Офисный',
		value: 'Офисный',
	},
	{
		name: 'Гибрид',
		value: 'Гибрид',
	},
	{
		name: 'Удалённый',
		value: 'Удалённый',
	},
];

export const WORK_ADDRESS = [
	{
		name: 'Офис в Санкт-Петербурге по адресу: ул. Чайковского, дом 79',
		value: 'Офис в Санкт-Петербурге по адресу: ул. Чайковского, дом 79',
	},
	{
		name: 'Офис в Санкт-Петербурге по адресу: Чичеринская ул., 2, литера А',
		value: 'Офис в Санкт-Петербурге по адресу: Чичеринская ул., 2, литера А',
	},
];

export const LABELS = {
	jobTitle: 'Должность',
	startDate: 'Предполагаемая дата выхода',
	registrationType: 'Форма оформления',
	registration: 'Оформление',
	probationPayment: 'Заработная плата на период испытательного срока',
	payment: 'Заработная плата после испытательного срока',
	jobFormat: 'Формат работы',
	probation: 'Испытательный срок',
	schedule: 'График работы',
	mainAddress: 'Основное место работы',
};
