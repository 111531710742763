import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import { GetUsersApiArg } from './types/regTypes';
import { GetUsersApiResponse } from './types/resTypes';

export const reducerPath = `${ServiceName.FILTER_SERVICE}` as const;

export const filter_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Users'] as const,
	endpoints: build => ({
		getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Users`,
				params: {
					includeDepartment: queryArg.includeDepartment,
					departmentsIds: queryArg.departmentsIds,
					includePosition: queryArg.includePosition,
					positionsIds: queryArg.positionsIds,
					includeOffice: queryArg.includeOffice,
					officesIds: queryArg.officesIds,
					includeProject: queryArg.includeProject,
					projectsIds: queryArg.projectsIds,
					rolesIds: queryArg.rolesIds,
					includeCommunications: queryArg.includeCommunications,
					isAscendingSort: queryArg.isAscendingSort,
					fullNameIncludeSubstring: queryArg.fullNameIncludeSubstring,
					rate: queryArg.rate,
					genderId: queryArg.genderId,
					isActive: queryArg.isActive,
					isPending: queryArg.isPending,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Users'],
		}),
	}),
});
