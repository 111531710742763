import React from 'react';
import s from './CompanyUsersList.module.scss';
import { CompanyUserCard } from '../CompanyUserCard/CompanyUserCard';
import { User } from 'src/app/redux/state/company-structure/users/types';

interface Props {
	users: User[];
	departmentName?: boolean;
	edit?: boolean;
	isCustomUnit?: boolean;
}

export const CompanyUsersList: React.FC<Props> = props => {
	const { users, departmentName, edit = false, isCustomUnit } = props;

	const sortedUsers = [...users].sort((firstUser, _) => {
		if (firstUser.assignment === 'Director') return -1;
		return 1;
	});

	const directorId = [...users].find(user => user.assignment === 'Director')?.id || '';

	// * Render
	return (
		<div>
			{users.length > 0 && (
				<div className={s.header}>
					<span>ФИО</span>
					<span className={s.department_name}>
						{departmentName && <span>Структурная единица</span>}
						<span>Контакты</span>
					</span>
				</div>
			)}

			<div className={s.users_list}>
				{sortedUsers.map(user => {
					return (
						<CompanyUserCard
							key={user.id}
							directorId={directorId}
							user={user}
							departmentName={departmentName ? user.departmentName || '-' : ''}
							isCustomUnit={isCustomUnit}
							RightComponent={edit && user.isCustom}
						/>
					);
				})}
			</div>
		</div>
	);
};
