import { routerPath } from 'src/app/router/paths';
import { ReactComponent as KnowledgeBaseSVG } from 'src/shared/assets/svg/book-mark.svg';
import { ReactComponent as BookingSVG } from 'src/shared/assets/svg/booking.svg';
import { ReactComponent as FeedbackSVG } from 'src/shared/assets/svg/bug.svg';
import { ReactComponent as OfficesSVG } from 'src/shared/assets/svg/building.svg';
import { ReactComponent as ClockSVG } from 'src/shared/assets/svg/clock.svg';
import { ReactComponent as AdminSVG } from 'src/shared/assets/svg/gear.svg';
import { ReactComponent as MagnifierSVG } from 'src/shared/assets/svg/magnifier.svg';
import { ReactComponent as ProjectorScreenSVG } from 'src/shared/assets/svg/projector-screen.svg';
import { ReactComponent as CompanyStructureSVG } from 'src/shared/assets/svg/structure.svg';
import { ReactComponent as TeamSVG } from 'src/shared/assets/svg/team.svg';
import { ReactComponent as UsersSVG } from 'src/shared/assets/svg/users.svg';

const MENU_ITEMS_PRIVATE_PROD = [
	{
		title: 'Учет времени' as const,
		Icon: <ClockSVG />,
		route: routerPath.time.page,
	},
	{
		title: 'Проекты' as const,
		Icon: <ProjectorScreenSVG />,
		route: routerPath.projects.page,
	},
	{
		title: 'Сотрудники' as const,
		Icon: <UsersSVG />,
		route: routerPath.users.page,
	},
	{
		title: 'Департаменты' as const,
		Icon: <TeamSVG />,
		route: routerPath.departments.page,
	},
	{
		title: 'Бронирование' as const,
		Icon: <BookingSVG />,
		route: routerPath.booking.page,
	},
	{
		title: 'Офисы' as const,
		Icon: <OfficesSVG />,
		route: routerPath.offices.page,
	},
	{
		title: 'База знаний' as const,
		Icon: <KnowledgeBaseSVG />,
		route: routerPath.wiki.page,
	},
	{
		title: 'Структура компании' as const,
		Icon: <CompanyStructureSVG />,
		route: routerPath.structure.page,
	},
	// {
	// 	title: 'Опросы' as const,
	// 	Icon: <SurveysSVG />,
	// 	route: routerPath.surveys.page,
	// },
	// {
	// 	title: 'Календарь событий' as const,
	// 	Icon: <EventSVG/>,
	// 	route: routerPath.calendar.page
	// },
	{
		title: 'Администрирование' as const,
		Icon: <AdminSVG />,
		route: routerPath.admin.page + routerPath.admin.dashboard,
	},
	{
		title: 'Обратная связь' as const,
		Icon: <FeedbackSVG />,
		route: routerPath.feedback.page,
	},
	{
		title: 'HR' as const,
		Icon: <MagnifierSVG />,
		route: routerPath.hr.page,
	},
];

const MENU_ITEMS_PUBLIC_PROD = [
	{
		title: 'База знаний',
		Icon: <KnowledgeBaseSVG />,
		route: routerPath.public.page,
	},
];

export { MENU_ITEMS_PRIVATE_PROD, MENU_ITEMS_PUBLIC_PROD };
