import React, { HTMLAttributes, memo } from 'react';
import s from './Switch.module.scss';
import { v4 } from 'uuid';
import cn from 'classnames';

// TODO: Переписать пропсы...
type Props = HTMLAttributes<HTMLInputElement> & {
	checked: boolean;
	disabled?: boolean;
};

export const Switch: React.FC<Props> = memo(props => {
	const {
		checked, //
		disabled,
		onChange,
	} = props;

	const checkboxId = v4();

	const labelStyle = cn(s.label, checked ? s.on : s.off, disabled && s.disabled);

	// * Render
	return (
		<div className={s.container}>
			<input
				disabled={disabled}
				className={s.checkbox}
				checked={checked}
				onChange={onChange}
				id={checkboxId}
				type="checkbox"
			/>
			<label
				className={labelStyle}
				htmlFor={checkboxId}
			>
				<span className={s.button} />
			</label>
		</div>
	);
});
