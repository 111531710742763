import { createApi } from '@reduxjs/toolkit/query/react';
import { UserAvatar } from '../../state/user/user/types';
import { CreateAvatarREQ, GetAvatarREQ } from '../../state/company-structure/avatars/types';
import { AddNewContactREQ, AddNewContactRES, DeleteContactREQ, DeleteContactRES } from '../../state/company-structure/contacts/types';
import {
	ChangePrioritiesREQ,
	CreateDepartmentREQ,
	CreateDepartmentRES,
	EditDepartmentREQ,
	GetAllDepartmentsRES,
	GetDepartmentREQ,
	GetDepartmentRES,
} from '../../state/company-structure/departments/types';
import { DeletePositionREQ, EditPositionREQ, GetPositionsREQ, GetPositionsRES, СreatePositionREQ } from '../../state/company-structure/positions/types';
import {
	GetSurveyStatisticsParametersREQ,
	GetSurveyStatisticsREQ,
	GetUsersWithSurveyInfoREQ,
	PutSurveyStatisticsParametersREQ,
	SurveyStatistic,
	UserWithSurveyInfo,
} from '../../state/company-structure/surveysStatistics/types';
import { ChangeAdministratorPrioritiesREQ, CreateUserREQ, EditUserREQ, GetUsersREQ, GetUsersRES } from '../../state/company-structure/users/types';
import { ServiceName } from 'src/app/api/api_types';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';

const reducerPath = `${ServiceName.COMPANY_STRUCTURE_SERVICE}` as const;

export const company_structure_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Users', 'Departments', 'Positions', 'SurveysStatistics'],
	endpoints: build => ({
		// * Users
		getUsers: build.query<GetUsersRES, GetUsersREQ>({
			query: arg => ({
				url: `${reducerPath}/Users`,
				params: arg,
			}),
			providesTags: ['Users'],
		}),

		createUser: build.mutation<string, CreateUserREQ>({
			query: arg => ({
				url: `${reducerPath}/Users`,
				method: 'POST',
				body: arg.params,
			}),
			invalidatesTags: ['Users'],
		}),

		editUser: build.mutation<boolean, EditUserREQ>({
			query: arg => ({
				url: `${reducerPath}/Users/${arg.userId}`,
				method: 'PATCH',
				body: arg.payload,
			}),
			invalidatesTags: ['Users'],
		}),

		changeAdministratorPriority: build.mutation<boolean, ChangeAdministratorPrioritiesREQ>({
			query: arg => ({
				url: `${reducerPath}/Users/changepriority`,
				method: 'POST',
				body: arg.payload,
			}),
			invalidatesTags: ['Users'],
		}),

		// * Departments
		getAllDepartments: build.query<GetAllDepartmentsRES, boolean>({
			query: () => ({
				url: `${reducerPath}/Departments`,
			}),
			providesTags: ['Departments'],
		}),

		getDepartment: build.query<GetDepartmentRES, GetDepartmentREQ>({
			query: arg => ({
				url: `${reducerPath}/Departments/${arg.departmentId}`,
			}),
			providesTags: ['Departments'],
		}),

		createDepartment: build.mutation<CreateDepartmentRES, CreateDepartmentREQ>({
			query: arg => ({
				url: `${reducerPath}/Departments`,
				method: 'POST',
				body: arg,
			}),
			invalidatesTags: ['Departments'],
		}),

		editDepartment: build.mutation<boolean, EditDepartmentREQ>({
			query: arg => ({
				url: `${reducerPath}/Departments/${arg.departmentId}`,
				method: 'PATCH',
				body: arg.payload,
			}),
			invalidatesTags: ['Departments'],
		}),

		changePriority: build.mutation<boolean, ChangePrioritiesREQ>({
			query: arg => ({
				url: `${reducerPath}/Departments/changepriority`,
				method: 'POST',
				body: arg.payload,
			}),
			invalidatesTags: ['Departments'],
		}),

		// * Positions
		getPositions: build.query<GetPositionsRES, GetPositionsREQ>({
			query: arg => ({
				url: `${reducerPath}/Positions`,
				params: arg.params,
			}),
			providesTags: ['Positions'],
		}),

		createPosition: build.mutation<string, СreatePositionREQ>({
			query: arg => ({
				url: `${reducerPath}/Positions`,
				method: 'POST',
				body: arg.params,
			}),
			extraOptions: {
				ignoreErrorNotification: true,
			},
			invalidatesTags: ['Positions'],
		}),

		deletePosition: build.mutation<boolean, DeletePositionREQ>({
			query: arg => ({
				url: `${reducerPath}/Positions/${arg.positionId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Positions'],
		}),

		editPosition: build.mutation<boolean, EditPositionREQ>({
			query: arg => ({
				url: `${reducerPath}/Positions/${arg.params.positionId}`,
				method: 'PATCH',
				body: arg.payload,
			}),
			invalidatesTags: ['Positions'],
		}),

		// * Avatars
		createAvatar: build.mutation<string, CreateAvatarREQ>({
			query: arg => ({
				url: `${reducerPath}/Avatars/${arg.params.userId}`,
				method: 'POST',
				body: arg.payload,
			}),
			invalidatesTags: ['Users'],
		}),

		getAvatar: build.query<UserAvatar, GetAvatarREQ>({
			query: arg => ({
				url: `${reducerPath}/Avatars/${arg.params.userId}`,
			}),
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),

		// * Contacts
		deleteContact: build.mutation<DeleteContactRES, DeleteContactREQ>({
			query: arg => ({
				url: `${reducerPath}/Contacts/${arg.params.contactId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Users'],
		}),

		addNewContact: build.mutation<AddNewContactRES, AddNewContactREQ>({
			query: arg => ({
				url: `${reducerPath}/Contacts`,
				method: 'POST',
				body: arg,
			}),
			invalidatesTags: ['Users'],
		}),

		// * SurveysStatistics
		getSurveyStatistics: build.query<SurveyStatistic[], GetSurveyStatisticsREQ>({
			query: arg => ({
				url: `${reducerPath}/SurveysStatistics/${arg.surveyid}`,
			}),
			providesTags: ['SurveysStatistics'],
		}),

		getUsersWithSurveyInfo: build.query<UserWithSurveyInfo[], GetUsersWithSurveyInfoREQ>({
			query: arg => ({
				url: `${reducerPath}/SurveysStatistics/Surveys/${arg.surveyid}/Departments/${arg.departmentid}`,
			}),
			providesTags: ['SurveysStatistics'],
		}),

		getSurveyStatisticsParameters: build.query<string, GetSurveyStatisticsParametersREQ>({
			query: arg => ({
				url: `${reducerPath}/SurveysStatistics/Parameters`,
				params: {
					ParameterName: arg.ParameterName,
				},
			}),
			providesTags: ['SurveysStatistics'],
		}),

		putSurveyStatisticsParameters: build.mutation<string, PutSurveyStatisticsParametersREQ>({
			query: arg => ({
				url: `${reducerPath}/SurveysStatistics/Parameters/${arg.parameterName}`,
				method: 'PUT',
				body: `"${arg.body}"`,
			}),
			invalidatesTags: ['SurveysStatistics'],
		}),
	}),
});
