import { ReactNode } from 'react';
import { Route } from 'react-router-dom';

export const createPublicRoute = (args: { path: string; PageComponent: ReactNode }) => {
	const { path, PageComponent } = args;

	return (
		<Route
			path={path}
			element={PageComponent}
		/>
	);
};
