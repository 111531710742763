import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { slice as auth } from './state/auth/slice';
import { slice as notifications } from '../../features/notifications/_BLL/slice';

import { slice as department_departmentTree } from './state/department/departmentTree/slice';
import { slice as department_department } from './state/department/department/slice';

import { slice as file_file } from './state/file/file/slice';
import { slice as file_public } from './state/file/public/slice';

import { slice as wiki } from './state/wiki/slice';

import { slice as position } from './state/position/position/slice';

import { slice as rights_rights } from './state/rights/rights/slice';
import { slice as rights_role } from './state/rights/roles/slice';

import { slice as company } from './state/company/company/slice';
import { slice as company_contractSubject } from './state/company/contractSubject/slice';

import { slice as event_form } from './state/event-calendar/slice';

import { slice as user_user } from './state/user/user/slice';
import { slice as user_gender } from './state/user/gender/slice';

import { slice as wiki_wikiTree } from './state/wiki/wikiTree/slice';
import { slice as wiki_public } from './state/wiki/public/slice';
import { slice as wiki_rubric } from './state/wiki/rubric/slice';
import { slice as wiki_article } from './state/wiki/article/slice';
import { slice as wiki_file } from './state/wiki/file/slice';

import { slice as office_office } from './state/office/office/slice';
import { slice as office_user } from './state/office/user/slice';
import { slice as office_room } from './state/office/room/slice';
import { slice as office_roomType } from './state/office/roomType/slice';
import { slice as office_workspace } from './state/office/workspace/slice';
import { slice as office_booking } from './state/office/booking/slice';
import { slice as office_image } from './state/office/image/slice';
import { slice as office_tag } from './state/office/tag/slice';

// * Components
import { slice as bugReportModal } from 'src/features/_layout/Header/ReportBug/slice';

// * Queries
import { authApi } from './queries/auth-service/authApi';
import { office_serviceAPI } from './queries/office-service/office_serviceAPI';
import { user_serviceAPI } from './queries/user-service/user_serviceAPI';
import { image_serviceAPI } from './queries/image-serviceAPI/image_serviceAPI';
import { company_structure_serviceAPI } from './queries/company-structure-service/company_structure_serviceAPI';
import { position_serviceAPI } from './queries/position-service/position_serviceAPI';
import { department_serviceAPI } from './queries/department-service/department_serviceAPI';
import { survey_serviceAPI } from './queries/survey-service/survey_serviceAPI';
import { time_serviceAPI } from './queries/time-service/time_serviceAPI';
import { project_serviceAPI } from './queries/project-service/project_serviceAPI';
import { rights_serviceAPI } from './queries/rights-service/rights_serviceAPI';
import { filter_serviceAPI } from './queries/filter-service/filter_serviceAPI';
import { company_serviceAPI } from './queries/company-service/company_serviceAPI';
import { admin_serviceAPI } from './queries/admin-service/admin_serviceAPI';
import { education_serviceAPI } from './queries/education-service/education_serviceAPI';
import { family_serviceAPI } from './queries/family-service/family_serviceAPI';
import { wiki_serviceAPI } from './queries/wiki-service/wiki_serviceAPI';
import { file_serviceAPI } from './queries/file-service/file_serviceAPI';

import { slice as company_structure_contacts } from './state/company-structure/contacts/slice';
import { slice as company_structure_departments } from './state/company-structure/departments/slice';
import { slice as company_structure_users } from './state/company-structure/users/slice';
import { slice as bookingPage } from './state/office/_states/booking_page_state';
import { slice as surveyPage } from './state/survey/slice';
import { event_serviceAPI } from './queries/event-service/event_serviceAPI';
import { gateway_serviceAPI } from './queries/gateway-service/gateway_serviceAPI';
import { feedback_serviceAPI } from './queries/feedback-service/feedback_serviceAPI';

// !NOTE: all the combined reducers must be set outside of configure store. https://github.com/reduxjs/redux-toolkit/issues/1831
const user_service = combineReducers({
	user: user_user.reducer,
	gender: user_gender.reducer,
});

const event_calendar = combineReducers({
	event_form: event_form.reducer,
});

const rights_service = combineReducers({
	rights: rights_rights.reducer,
	role: rights_role.reducer,
});

const company_service = combineReducers({
	company: company.reducer,
	contractSubject: company_contractSubject.reducer,
});

const department_service = combineReducers({
	department: department_department.reducer,
	departmentTree: department_departmentTree.reducer,
});

const position_service = combineReducers({
	position: position.reducer,
});

const files_service = combineReducers({
	file: file_file.reducer,
	public: file_public.reducer,
});

const wiki_service = combineReducers({
	rubric: wiki_rubric.reducer,
	article: wiki_article.reducer,
	file: wiki_file.reducer,
	wikiTree: wiki_wikiTree.reducer,
	public: wiki_public.reducer,
});

const office_service = combineReducers({
	office: office_office.reducer,
	room: office_room.reducer,
	roomType: office_roomType.reducer,
	workspace: office_workspace.reducer,
	user: office_user.reducer,
	booking: office_booking.reducer,
	image: office_image.reducer,
	tag: office_tag.reducer,
});

const company_structure_service = combineReducers({
	contacts: company_structure_contacts.reducer,
	departments: company_structure_departments.reducer,
	users: company_structure_users.reducer,
});

export const store = configureStore({
	reducer: {
		notifications: notifications.reducer,
		auth_service: auth.reducer,
		user_service,
		rights_service,
		company_service,
		department_service,
		position_service,
		files_service,
		event_calendar,
		wiki: wiki.reducer,
		wiki_service,
		office_service,
		company_structure_service,
		[authApi.reducerPath]: authApi.reducer,
		[user_serviceAPI.reducerPath]: user_serviceAPI.reducer,
		[office_serviceAPI.reducerPath]: office_serviceAPI.reducer,
		[image_serviceAPI.reducerPath]: image_serviceAPI.reducer,
		[company_structure_serviceAPI.reducerPath]: company_structure_serviceAPI.reducer,
		[position_serviceAPI.reducerPath]: position_serviceAPI.reducer,
		[department_serviceAPI.reducerPath]: department_serviceAPI.reducer,
		[survey_serviceAPI.reducerPath]: survey_serviceAPI.reducer,
		[filter_serviceAPI.reducerPath]: filter_serviceAPI.reducer,
		[project_serviceAPI.reducerPath]: project_serviceAPI.reducer,
		[rights_serviceAPI.reducerPath]: rights_serviceAPI.reducer,
		[time_serviceAPI.reducerPath]: time_serviceAPI.reducer,
		[company_serviceAPI.reducerPath]: company_serviceAPI.reducer,
		[event_serviceAPI.reducerPath]: event_serviceAPI.reducer,
		[admin_serviceAPI.reducerPath]: admin_serviceAPI.reducer,
		[education_serviceAPI.reducerPath]: education_serviceAPI.reducer,
		[family_serviceAPI.reducerPath]: family_serviceAPI.reducer,
		[wiki_serviceAPI.reducerPath]: wiki_serviceAPI.reducer,
		[file_serviceAPI.reducerPath]: file_serviceAPI.reducer,
		[gateway_serviceAPI.reducerPath]: gateway_serviceAPI.reducer,
		[feedback_serviceAPI.reducerPath]: feedback_serviceAPI.reducer,
		bookingPage: bookingPage.reducer,
		surveyPage: surveyPage.reducer,
		// * Components
		bugReportModal: bugReportModal.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware()
			.concat(authApi.middleware)
			.concat(user_serviceAPI.middleware)
			.concat(office_serviceAPI.middleware)
			.concat(image_serviceAPI.middleware)
			.concat(company_structure_serviceAPI.middleware)
			.concat(position_serviceAPI.middleware)
			.concat(department_serviceAPI.middleware)
			.concat(survey_serviceAPI.middleware)
			.concat(time_serviceAPI.middleware)
			.concat(project_serviceAPI.middleware)
			.concat(rights_serviceAPI.middleware)
			.concat(filter_serviceAPI.middleware)
			.concat(company_serviceAPI.middleware)
			.concat(event_serviceAPI.middleware)
			.concat(admin_serviceAPI.middleware)
			.concat(education_serviceAPI.middleware)
			.concat(family_serviceAPI.middleware)
			.concat(wiki_serviceAPI.middleware)
			.concat(file_serviceAPI.middleware)
			.concat(gateway_serviceAPI.middleware)
			.concat(feedback_serviceAPI.middleware),
	devTools: {
		trace: true,
	},
	// preloadedState: loadState()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
