import { FC } from 'react';
import { UserInfo } from 'src/app/redux/queries/user-service/types/types';
import s from './ContactsCard.module.scss';
import { Contacts } from '../Contacts';
import { Profile } from '../Profile';

interface Props {
	user: UserInfo;
}

export const ContactsCard: FC<Props> = props => {
	const { user } = props;

	const { firstName = '', lastName = '', middleName = '', avatar, communications } = user;

	// * Render
	return (
		<div className={s.container}>
			<Profile
				userId={user.id}
				avatar={avatar}
				userName={[lastName, firstName, middleName].join(' ')}
			/>
			<hr />
			<Contacts
				userId={user.id}
				contacts={communications || []}
			/>
		</div>
	);
};
