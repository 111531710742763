import { tupleToIsoString } from 'src/shared/lib/date';
import { TimeInterval } from 'src/shared/lib/date/types';

// TimeInterval => [06:00, 07:00, 08,00...]
export const createTimeList = (bookingInterval: TimeInterval) => {
	const { start, end } = bookingInterval;
	const startHour = start[0];
	const startMinutes = start[1];
	const endHour = end[0];
	const endMinutes = end[1];

	const timeList: string[] = [];

	for (let i = startHour; i <= endHour; i++) {
		if (i === startHour) {
			timeList.push(tupleToIsoString([i, startMinutes]));
		} else if (i === endHour) {
			endMinutes !== 0 && timeList.push(tupleToIsoString([i, endMinutes]));
		} else {
			timeList.push(tupleToIsoString([i, 0]));
		}
	}

	return timeList;
};
