import React from 'react';
import s from './EventDescription.module.scss';
import { EventInfoFields } from './consts';
import cn from 'classnames';
import { useAppDispatch } from 'src/app/redux/utils';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { ReactComponent as CopySVG } from 'src/shared/assets/svg/action/copy.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { Heading } from 'src/shared/ui/Heading';

interface Props {
	start: string;
	end: string;
	participants: number;
	place: string;
	description: string;
}

export const EventDescription: React.FC<Props> = props => {
	const { description } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * Copy
	const urlToClipboard = (value: string) => {
		navigator.clipboard.writeText(value).then(() =>
			dispatch(
				addNotification({
					type: 'success',
					message: 'Место проведения скопировано в буфер обмена',
				}),
			),
		);
	};

	// * Render
	return (
		<>
			<div className={s.container}>
				{EventInfoFields.map(field => {
					const { key, text } = field;

					return (
						<div
							key={field.key}
							className={s.wrapper}
						>
							<span className={s.field}>{text}</span>
							<span className={cn(s.value, key === 'participants' && s.clickable)}>
								{props[key as keyof Props]}

								{key === 'place' && (
									<IconButton
										Icon={<CopySVG />}
										onClick={() => urlToClipboard(props[key])}
									/>
								)}
							</span>
						</div>
					);
				})}
			</div>

			{description && (
				<div>
					<Heading
						level={4}
						marginBottom="s"
					>
						Описание
					</Heading>

					<p>{description}</p>
				</div>
			)}
		</>
	);
};
