export const STATUS_OPTIONS = [
	{
		name: 'Активные',
		id: 'Active',
	},
	{
		name: 'Архивированные',
		id: 'Archive',
	},
];
