import { ContactType } from '../types';

export const ContactsTypes: ContactType[] = [
	{
		id: 'BaseEmail',
		name: 'Электронная почта',
		placeholder: 'Введите электронную почту',
	},
	{
		id: 'Email',
		name: 'Электронная почта',
		placeholder: 'Введите электронную почту',
	},
	{
		id: 'Phone',
		name: 'Рабочий номер',
		placeholder: 'Введите рабочий номер',
	},
	{
		id: 'Telegram',
		name: 'Telegram',
		placeholder: 'Введите Telegram',
	},
	{
		id: 'Skype',
		name: 'Skype',
		placeholder: 'Введите Skype',
	},
];

export const CONTACTS = {
	BaseEmail: 'Электронная почта',
	Email: 'Электронная почта',
	Phone: 'Рабочий номер',
	Telegram: 'Telegram',
	Skype: 'Skype',
	Twitter: 'Twitter',
};
