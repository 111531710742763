import React from 'react';
import { ReactComponent as LogoSVG } from 'src/shared/assets/svg/logo.svg';
import { ReactComponent as MenuSVG } from 'src/shared/assets/svg_icons/editor/bulleted_list.svg';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { HeaderAvatar } from '../Avatar/HeaderAvatar';
import s from './HeaderWithHamburger.module.scss';

interface Props {
	toggleMenu: () => void;
}

export const HeaderWithHamburger: React.FC<Props> = props => {
	const { toggleMenu } = props;

	// * Menu\

	// * Render
	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<IconButton
					Icon={<MenuSVG />}
					onClick={toggleMenu}
				/>

				<a
					className={s.logoWrapper}
					href={`/main`}
				>
					<LogoSVG className={s.logo} />
					<span className={s.title}>ЦИФРОВОЙ ОФИС</span>
				</a>

				<HeaderAvatar isPrivate={true} />
			</div>
		</div>
	);
};
