export const LIST_OF_MONTHS_GENITIVE = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
export const DAY_OF_THE_WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const COLORS = [
	'rgb(189, 189, 189)',
	'rgb(95, 184, 93)',
	'rgb(156, 185, 75)',
	'rgb(236, 189, 25)',
	'rgb(255, 152, 0)',
	'rgb(246, 101, 90)',
	'rgb(141, 110, 99)',
	'rgb(240, 98, 146)',
	'rgb(186, 104, 200)',
	'rgb(82, 174, 180)',
	'rgb(101, 128, 176)',
	'rgb(115, 119, 221)',
	'rgb(63, 152, 245)',
]; // TODO: Составить список из var или из глобального const если там есть.

export const EMPTY_CATEGORY = {
	id: '',
	name: 'Без категории',
	color: 0,
};
