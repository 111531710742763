import { Option, GridOption, ScaleOption } from 'src/pages/surveys/pages/NewSurveyPage/_types';

export const getAnswers = (options: Array<Option | GridOption | ScaleOption>): string[] => {
	const answers: string[] = [];

	options.forEach(opt => {
		if (opt.usersAnswers && opt.usersAnswers.length > 0) {
			opt.usersAnswers.forEach(answ => answers.push(answ.id));
		}

		return;
	});

	return answers;
};
