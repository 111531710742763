import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { actionsAuth } from 'src/app/redux/state/auth/slice';
import { LoginRequestBody } from 'src/app/redux/state/auth/types';
import { useAppDispatch } from 'src/app/redux/utils';
import { AuthForm } from '../AuthForm/AuthForm';

export const Signup = () => {
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const { setTokens } = actionsAuth;

	// * Params
	const [params] = useSearchParams();
	const userId = params.get('userId');

	// * API
	const {
		currentData: pendingInfo,
		isSuccess,
		isError,
	} = user_serviceAPI.useGetPendingCheckQuery(
		{
			userId: userId as string,
		},
		{ skip: !userId },
	);

	const [createCredentials, { isLoading }] = user_serviceAPI.usePostCredentialsCreateMutation();

	// - check pending
	useEffect(() => {
		if (!userId || (isSuccess && !pendingInfo?.body) || isError) {
			navigate('/');
		}
	}, [userId, isSuccess, isError]);

	// * Error
	const [errorMessage, setErrorMessage] = useState('');

	// - sign up
	const onSubmit = (values: LoginRequestBody) => {
		createCredentials({
			createCredentialsRequest: {
				login: values.loginData,
				password: values.password,
				userId: userId as string,
			},
		})
			.unwrap()
			.then(res => {
				setErrorMessage('');
				res?.body && dispatch(setTokens(res.body));
			})
			.catch(error => {
				const message = error.data.errors[0];
				message && setErrorMessage(message);
			});
	};

	return (
		<AuthForm
			isLogin={false}
			isLoading={isLoading}
			onSubmit={onSubmit}
			errorMessage={errorMessage}
		/>
	);
};
