import React from 'react';
import s from './InputLabel.module.scss';

interface Props {
	label?: string;
	required?: boolean;
}

export const InputLabel: React.FC<Props> = props => {
	const { label, required } = props;

	// * Render
	return (
		<>
			{label && (
				<label className={s.container}>
					{label} {required && <span className={s.required}>*</span>}
				</label>
			)}
		</>
	);
};
