import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { BookingInfo, CreateBookingREQ, FindBookingREQ, FindBookingRES, RemoveBookingREQ } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/booking`;

export const createBooking = createAppAsyncThunk(`${NAME}/createBooking`, async (arg: CreateBookingREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<boolean>({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createBooking(arg),
	});
});

export const removeBooking = createAppAsyncThunk(`${NAME}/removeBooking`, async (arg: RemoveBookingREQ, thunkAPI) => {
	const { params } = arg;

	// ? Is it really post or delete
	return await apiRequest.postRequest<boolean>({
		url: `${NAME}/remove`,
		params,
		thunkAPI,
		action: () => removeBooking(arg),
	});
});

export const findBooking = createAppAsyncThunk(`${NAME}/findBooking`, async (arg: FindBookingREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FindBookingRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findBooking(arg),
	});
});

export interface State {
	bookingList: BookingInfo[];
	totalCount: number;
	status: RequestStatus;
}

export const initialState: State = {
	bookingList: [],
	totalCount: 0,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createBooking.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createBooking.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createBooking.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeBooking.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeBooking.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(removeBooking.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findBooking.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findBooking.fulfilled, (state, action) => {
			state.bookingList = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(findBooking.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createBooking,
	removeBooking,
	findBooking,
};
