import { DateTime } from 'luxon';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { DefaultValuesForm } from 'src/pages/project_service_pages/types';

export const useCreateProject = (onCloseModal: () => void) => {
	// * API
	const [createProject, { isLoading: isCreateLoading }] = project_serviceAPI.useCreateProjectMutation();

	// * Submit
	const onCreateSubmit = (data: DefaultValuesForm) => {
		const { name, shortName, status, department, customer, startDateUtc, endDateUtc } = data;

		const startDate = startDateUtc ? DateTime.fromJSDate(startDateUtc).set({ hour: 0 }).toISO({ includeOffset: false }) : startDateUtc;

		const endDate = endDateUtc ? DateTime.fromJSDate(endDateUtc).set({ hour: 0 }).toISO({ includeOffset: false }) : endDateUtc;

		createProject({
			createProjectRequest: {
				name,
				shortName,
				status: status.id,
				departmentId: department.id || undefined,
				customer,
				startDateUtc: startDate,
				endDateUtc: endDate,
				users: [],
				projectImages: [],
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log('Error: ', err));
	};

	return { onCreateSubmit, isCreateLoading };
};
