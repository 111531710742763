import React from 'react';
import { Gallery } from 'src/shared/ui/_galleries/Gallery';
import { FileInfo, ImageInfo } from 'src/app/redux/queries/event-service/types/types';
import { Heading } from 'src/shared/ui/Heading';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { COLUMNS_EVENT_DOCS } from './consts';
import { image_serviceAPI } from 'src/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { imageServiceActions } from 'src/app/redux/state/image/actions';

interface Props {
	images: ImageInfo[];
	files: FileInfo[];
}

export const EventFiles: React.FC<Props> = ({ images, files }) => {
	// * Actions
	const { getImage } = imageServiceActions.image.async;

	// * Render
	return (
		<>
			{images.length > 0 && (
				<div>
					<Heading
						level={4}
						marginBottom="s"
					>
						Галерея
					</Heading>
					<Gallery
						allowedToEdit={false}
						images={images}
						fetchImage={(imageId: string) =>
							getImage({
								params: {
									imageId,
									source: 'Event',
								},
							})
						}
					/>
				</div>
			)}

			{files.length > 0 && (
				<div>
					<Heading
						level={4}
						marginBottom="s"
					>
						Документы
					</Heading>

					<CardsTable
						columns={COLUMNS_EVENT_DOCS}
						data={files}
					/>
				</div>
			)}
		</>
	);
};
