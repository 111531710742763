import cn from 'classnames';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ArticleData, PublicArticleData } from 'src/app/redux/queries/wiki-service/types/types';
import { useOpenArticle } from 'src/pages/wiki/pages/wiki/_hooks/useOpenArticle';
import { ReactComponent as ArticleSVG } from 'src/shared/assets/svg/articles/article.svg';
import s_main from './_ArticleTab__main.module.scss';
import s_menu from './_ArticleTab__menu.module.scss';

type Props = (ArticleData | PublicArticleData) &
	DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
		styleType: 'main' | 'menu';
		isPrivate: boolean;
		active?: boolean;
	};

export const ArticleTab: React.FC<Props> = props => {
	const { className, id, name, styleType, isPrivate, active } = props;

	// * Styles
	const s = styleType === 'main' ? s_main : s_menu;
	const containerStyle = cn(s.container, active && s.active, className);

	// * User actions
	const { openArticle } = useOpenArticle({ articleId: id, isPrivate });

	// * Render
	return (
		<div
			className={containerStyle}
			onClick={openArticle}
		>
			<ArticleSVG className={s.icon} />
			<span className={s.name}>{name}</span>
		</div>
	);
};
