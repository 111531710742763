import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoginRequestBody } from 'src/app/redux/state/auth/types';
import { Button } from 'src/shared/ui/_buttons/Button';
import { PasswordField } from 'src/shared/ui/_fields/PasswordField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import * as Yup from 'yup';
import s from '../AuthPage/AuthPage.module.scss';
import { InputTooltip } from '../InputTooltip/InputTooltip';
import { getLoginRules } from '../Signup/utils/validate';

interface Props {
	isLogin: boolean;
	isLoading: boolean;
	errorMessage: string;
	onSubmit: (values: LoginRequestBody) => void;
}

export const AuthForm: FC<Props> = props => {
	const { isLogin, isLoading, errorMessage, onSubmit } = props;

	const navigate = useNavigate();

	// * Form
	const schema = Yup.object().shape({
		loginData: Yup.string().required(''),
		password: Yup.string().required(''),
	});

	const formMethods = useForm({
		defaultValues: {
			loginData: '',
			password: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	const login = watch('loginData');
	const password = watch('password');

	// - Show tooltip
	const [show, setShow] = useState(false);

	return (
		<form
			className={s.AuthPage_form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<div className={s.AuthPage_wrapper}>
					<StringField
						name="loginData"
						maxLength={isLogin ? undefined : 15}
						placeholder={isLogin ? 'Логин или e-mail' : 'Придумайте логин'}
						onFocus={() => {
							!isLogin && setShow(true);
						}}
						onBlur={() => !isLogin && setShow(false)}
					/>

					{show && <InputTooltip rules={getLoginRules(login)} />}
				</div>

				<PasswordField
					name="password"
					placeholder={isLogin ? 'Пароль' : 'Введите пароль из письма'}
					autoComplete={isLogin ? 'current-password' : 'new-password'}
				/>

				{errorMessage && <div className={s.AuthPage_error}>{errorMessage}</div>}

				<Button
					type="submit"
					isLoading={isLoading}
					wide
					disabled={!login || !password || (!isLogin && getLoginRules(login).filter(rule => !rule.checked).length > 0)}
				>
					Войти
				</Button>

				{isLogin && (
					<Button
						variant="tertiary"
						wide
						className={s.AuthPage_btn_forgot}
						onClick={e => {
							e.preventDefault();
							navigate('/auth/forgot');
						}}
					>
						Забыли пароль?
					</Button>
				)}
			</FormProvider>
		</form>
	);
};
