import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { FindGenderREQ_PARAMS, FindGenderRES, GenderInfo } from './types';

const NAME = `${ServiceName.USER_SERVICE}/gender`;

// * Async thunks
const findGender = createAppAsyncThunk(`${NAME}/findGender`, async (arg: { params: FindGenderREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;

	const res = await apiRequest.getRequest<FindGenderRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findGender(arg),
	});

	return res.body;
});

// * Slice
export interface State {
	genderInfo: GenderInfo[] | null;
	status: RequestStatus;
}

export const initialState: State = {
	genderInfo: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(findGender.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findGender.fulfilled, (state, action) => {
			state.genderInfo = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(findGender.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	findGender,
};
