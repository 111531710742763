export const COLORS = [
	'var(--color-1)',
	'var(--color-2)',
	'var(--color-5)',
	'var(--color-9)',
	'var(--color-11)',
	'var(--color-3)',
	'var(--color-8)',
	'var(--color-7)',
	'var(--color-4)',
	'var(--color-10)',
	'var(--color-6)',
	'var(--color-12)',
];
