import React from 'react';
import { Notification } from '../_BLL/types';
import { NotificationSingle } from '../NotificationSingle/NotificationSingle';
import s from './NotificationProvider.module.scss';

interface Props {
	notifications: Notification[];
}

export const NotificationProvider: React.FC<Props> = props => {
	const { notifications } = props;

	return (
		<div className={s.container}>
			{notifications.map(note => (
				<NotificationSingle
					key={note.id}
					{...note}
				/>
			))}
		</div>
	);
};
