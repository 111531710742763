import { BaseQueryFn, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetStatFindApiArg } from 'src/app/redux/queries/time-service/types/regTypes';
import { UserStatInfoFindResultResponse } from 'src/app/redux/queries/time-service/types/resTypes';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { ReactComponent as AlertSVG } from 'src/shared/assets/svg/alerts/info.svg';
import { EMPTY_ID } from 'src/shared/consts/consts';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { TimeEnter } from 'src/widgets/TimeEnter';
import s from '../styles/columns.module.scss';

export interface ProjectsInfoTableDataItem {
	id: string | null; // Project id
	workTimeId: string;
	name: string | null;
	usersHours: string | number | null;
	dateEntered: string;
	comment: string | null;
	userId: string;
	selectedDate: Date;
	manager?: string | null;
	subRows?: ProjectsInfoTableDataItem[];
}

const columnHelper = createColumnHelper<ProjectsInfoTableDataItem>();

export const generateColumnsProjectsInfo = (
	refetch: () => QueryActionCreatorResult<
		QueryDefinition<
			GetStatFindApiArg,
			BaseQueryFn,
			'Import' | 'LeaveTime' | 'Stat' | 'WorkTime' | 'WorkTimeDayJob' | 'WorkTimeMonthLimit',
			UserStatInfoFindResultResponse,
			'timeservice'
		>
	>,
) => [
	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ ПРОЕКТА',
		cell: ({ getValue, row }) => {
			const navigate = useNavigate();
			const isProject = EMPTY_ID !== row.original.id;

			return (
				<div
					className={isProject ? s.link_wrapper : s.project_title}
					onClick={() => isProject && navigate(`/projects/${row.original.id}`)}
				>
					{getValue()}
				</div>
			);
		},
		meta: {
			style: {
				width: '424px',
			},
		},
	}),
	columnHelper.accessor('usersHours', {
		header: 'ВНЕСЕННЫЕ ЧАСЫ',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('dateEntered', {
		header: 'ДАТА ВНЕСЕНИЯ',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('comment', {
		header: 'КОММЕНТАРИЙ',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '30%',
			},
		},
	}),
	columnHelper.accessor('id', {
		header: () => null,
		footer: props => props.column.id,
		cell: ({ row }) => {
			const [isVisibleModal, setIsVisibleModal] = useState(false);

			return (
				<div className={s.icon_wrapper}>
					{row.original.manager && (
						<Tooltip text={`Отредактировано: ${row.original.manager}`}>
							<IconButton Icon={<AlertSVG />} />
						</Tooltip>
					)}
					{row.original.id && (
						<IconButton
							Icon={<EditSVG />}
							onClick={() => setIsVisibleModal(!isVisibleModal)}
						/>
					)}

					{isVisibleModal && (
						<ModalNewProto
							isOpen={isVisibleModal}
							onClose={() => setIsVisibleModal(false)}
						>
							<div className={s.modal_content}>
								<h2>Редактирование часов</h2>

								<TimeEnter
									userId={row.original.userId}
									edit={{
										initialDate: row.original.selectedDate,
										initialProject: { id: row.original.id, name: row.original.name },
										onCancel: () => {
											setIsVisibleModal(!isVisibleModal);
											refetch();
										},
										hours: row.original.usersHours ? Number(row.original.usersHours) : null,
										description: row.original.comment,
										workTimeId: row.original.workTimeId,
									}}
								/>
							</div>
						</ModalNewProto>
					)}
				</div>
			);
		},
		meta: {
			style: {
				width: '10%',
			},
		},
	}),
];
