import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { file_serviceAPI } from 'src/app/redux/queries/file-service/file_serviceAPI';
import { ArticleResponse } from 'src/app/redux/queries/wiki-service/types/resTypes';
import { PatchArticleDocument, RubricData } from 'src/app/redux/queries/wiki-service/types/types';
import { wiki_serviceAPI } from 'src/app/redux/queries/wiki-service/wiki_serviceAPI';
import { routerPath } from 'src/app/router/paths';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { Button } from 'src/shared/ui/_buttons/Button';
import * as Yup from 'yup';
import { ArticleEditor } from '../_common_components/ArticleEditor/ArticleEditor';
import { ArticleFiles } from '../_common_components/ArticleFiles/ArticleFiles';
import s from './CreateArticlePage.module.scss';
import { Menu } from './components/Menu/Menu';

interface DefaultProps {
	name: string;
	rubric:
		| RubricData
		| {
				name: string;
				id: null;
		  };
	subRubric:
		| RubricData
		| {
				name: string;
				id: null;
		  };
	files: Array<any>;
	initialFiles: Array<any>;
}

export const CreateArticlePage: React.FC = () => {
	// * Router
	const params: any = useParams();
	const rubricId: string | null = params.rubricId;
	const subRubricId: string | null = params.subRubricId;
	const articleId: string = params.articleId;

	const isEdit = articleId && articleId !== 'null';

	// * Ref
	const editorRef = useRef<any>(null);

	// * Navigate
	const navigate = useNavigate();

	// * API
	// - Wiki service
	const { data: wikiTree } = wiki_serviceAPI.useGetWikiQuery({
		includedeactivated: false,
	});
	const { data: articleData } = wiki_serviceAPI.useGetArticleQuery({ articleId }, { skip: !articleId || articleId === 'null' });
	const [editArticle, { isLoading: isEditLoading }] = wiki_serviceAPI.useEditArticleMutation();
	const [createArticle, { isLoading: isCreateLoading }] = wiki_serviceAPI.useCreateArticleMutation();
	const [removeFiles, { isLoading: isRemoveFileLoading }] = wiki_serviceAPI.useRemoveFilesMutation();
	const [createNewFile, { isLoading: isCreateFileLoading }] = file_serviceAPI.usePostFileCreateMutation();

	const isLoading = isEditLoading || isCreateLoading || isRemoveFileLoading || isCreateFileLoading;

	// * Menu options
	const nullOption = { name: 'Не выбрано', id: null };

	const [selectedRubric, setSelectedRubric] = useState<RubricData | typeof nullOption>(nullOption);
	const [selectedSubRubric, setSelectedSubRubric] = useState<RubricData | typeof nullOption>(nullOption);

	useEffect(() => {
		wikiTree && setSelectedRubric(wikiTree[0]);
	}, [wikiTree]);

	// * Form
	const schema = Yup.object().shape({
		name: Yup.string().required('Введите название').max(100, 'Название не может быть больше 100'),
		files: Yup.array(),
		initialFiles: Yup.array(),
	});

	const defaultValues: DefaultProps = {
		name: '',
		rubric: nullOption,
		subRubric: nullOption,
		files: [],
		initialFiles: [],
	};

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, setValue } = formMethods;

	useEffect(() => {
		if (articleData) setValue('name', articleData.name);
	}, [articleData]);

	// - initial
	const initialArticle: ArticleResponse = articleData ?? {
		content: '',
		files: [],
		id: articleId || '',
		isActive: true,
		isFavorite: false,
		name: '',
		access: 'Public',
		rubricId: (function () {
			if (selectedSubRubric.id) {
				return selectedSubRubric.id;
			} else if (selectedRubric.id) {
				return selectedRubric.id;
			} else {
				return '';
			}
		})(), // id рубрики или подрубрики. Подрубрика в приоритете.
		createdAtUtc: '',
		createdBy: '',
	};

	useEffect(() => {
		if (wikiTree) {
			const rubricFound = wikiTree.find(rubric => rubric.id === rubricId);
			if (rubricFound) {
				setValue('rubric', rubricFound);
				setSelectedRubric(rubricFound);
				const subRubricFound = rubricFound.children.find(subRubric => subRubric.id === subRubricId);
				if (subRubricFound) {
					setValue('subRubric', subRubricFound);
					setSelectedSubRubric(subRubricFound);
				}
			}
		}
	}, [wikiTree]);

	// * Create / edit article
	const onCreateArticle = (data: typeof defaultValues) => {
		const { name, rubric, subRubric, files } = data;

		// - Create article
		if (editorRef.current) {
			const contentNew: string = editorRef.current.getContent();

			createArticle({
				createArticleRequest: {
					rubricId: subRubric.id || rubric.id,
					name,
					content: contentNew,
				},
			})
				.unwrap()
				.then(res => {
					if (res && files.length > 0) {
						const formData = new FormData();

						for (const newFile of files) {
							formData.append('uploadedFiles', newFile);
						}

						createNewFile({
							createFilesRequest: {
								accessType: 'Public',
								fileSource: 'Wiki',
								entityId: res,
								uploadedFiles: formData,
							},
						})
							.unwrap()
							.then(() => navigate(`${routerPath.wiki.page}/${res}`))
							.catch(error => console.log(error));
					} else {
						navigate(`${routerPath.wiki.page}/${res}`);
					}

					//
				})
				.catch(err => console.log(err));
		}
	};

	const onEditArticle = (data: typeof defaultValues) => {
		const { name, rubric, subRubric, initialFiles, files } = data;

		const promises: any[] = [];
		const args: PatchArticleDocument[] = [];
		const initialSubRubric = subRubricId === 'null' ? '' : subRubricId;
		const selectedSubRubric = subRubric.id === null ? '' : subRubric.id;

		if (initialSubRubric !== selectedSubRubric || rubricId !== rubric.id) {
			args.push({
				op: 'replace',
				path: '/rubricId',
				value: selectedSubRubric || rubric.id,
			});
		}

		// * files
		const deletedFiles = initialFiles.filter(item => !files.find(file => file?.id === item?.id));
		const newFiles = files.filter(item => !item?.id);

		if (editorRef.current) {
			const contentNew: string = editorRef.current.getContent();

			promises.push(
				editArticle({
					articleId,
					editArticleRequest: [
						...args,
						{
							op: 'replace',
							path: '/content',
							value: contentNew,
						},
						{
							op: 'replace',
							path: '/name',
							value: name,
						},
					],
				}).unwrap(),
			);
		}

		// * promises
		if (newFiles.length > 0) {
			const formData = new FormData();

			for (const newFile of newFiles) {
				formData.append('uploadedFiles', newFile);
			}

			promises.push(
				createNewFile({
					createFilesRequest: {
						accessType: 'Public',
						fileSource: 'Wiki',
						entityId: articleId,
						uploadedFiles: formData,
					},
				}).unwrap(),
			);
		}

		if (deletedFiles.length > 0) {
			const deletedIds = deletedFiles.map(item => item.id);

			promises.push(
				removeFiles({
					removeFilesRequest: {
						articleId,
						filesIds: deletedIds,
					},
				}).unwrap(),
			);
		}

		Promise.allSettled(promises)
			.then(() => navigate(`${routerPath.wiki.page}/${articleId}`))
			.catch(err => console.log('Error: ', err));
	};

	// * Render
	return (
		<>
			<PageDecorator>
				<FormProvider {...formMethods}>
					<form onSubmit={handleSubmit(isEdit ? onEditArticle : onCreateArticle)}>
						<PageTitle
							title={isEdit ? 'Редактирование статьи' : 'Новая статья'}
							buttons={[
								<Button
									key="0"
									variant="secondary"
									isLoading={isLoading}
									onClick={e => {
										e.preventDefault();
										navigate(-1);
									}}
								>
									Отменить
								</Button>,

								<Button
									key="1"
									type="submit"
									isLoading={isLoading}
								>
									{isEdit ? 'Сохранить' : 'Опубликовать'}
								</Button>,
							]}
						/>

						<div className={s.container}>
							{wikiTree && (
								<Menu
									className={s.menu}
									wikiTree={wikiTree}
								/>
							)}

							<div className={s.content}>
								<ArticleEditor
									article={initialArticle}
									editorRef={editorRef}
								/>

								<ArticleFiles
									existFiles={!!articleData?.files && articleData.files.length > 0}
									isEdit={articleId !== 'null'}
									articleId={articleId || ''}
									isPrivate={true}
								/>
							</div>
						</div>
					</form>
				</FormProvider>
			</PageDecorator>
		</>
	);
};
