import { FC, InputHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '../_inputs/Checkbox/Checkbox';

type DefaultInputPropsType = InputHTMLAttributes<HTMLInputElement>;

type Props = Omit<DefaultInputPropsType, 'type' | 'children'> & {
	name: string;
	label?: string;
	noDisabledStyles?: boolean;
};

export const CheckboxField: FC<Props> = props => {
	const { name, label, noDisabledStyles, ...restProps } = props;

	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<Checkbox
					{...field}
					checked={field.value}
					label={label}
					noDisabledStyles={noDisabledStyles}
					{...restProps}
				/>
			)}
		/>
	);
};
