import React, { FormEvent } from 'react';
import s from '../../../../../components/SurveyForm/SurveyForm.module.scss';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { Button } from 'src/shared/ui/_buttons/Button';
import { Option, QuestionItem, SectionProps } from '../../../_types';
import { Radio } from 'src/shared/ui/_inputs/Radio';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { MultiChoiceOption } from './MultiChoiceOption';
import { DndMultiChoice } from '../../lib/dNd/DndMultiChoice';

interface Props {
	indexQuestion: number;
	question: QuestionItem;

	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;
	indexSection: number;

	isEdit?: boolean;

	defaultOption: Option;
}

export const MultiChoice: React.FC<Props> = props => {
	const {
		indexQuestion,
		question,

		sections,
		setSections,
		indexSection,

		isEdit,

		defaultOption,
	} = props;

	const hasMultipleChoice = question.hasMultipleChoice;
	const showCloseIcon = (question.hasCustomOptions && question.options.length > 0) || question.options.length > 1;

	const onOptionContentChange = (event: FormEvent<HTMLInputElement>, indexSection: number, indexQuestion: number, indexOption: number) => {
		const isTypeBlur = event.type === 'blur';
		const value = event.currentTarget.value;
		const newValue = isTypeBlur ? value.trim() : value;

		const changedSection = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						options: question.options.map((option, index) => {
							if (index !== indexOption) return option;

							return {
								...option,
								content: isTypeBlur && !newValue ? `Вариант ${indexOption + 1}` : newValue,
							};
						}),
					};
				}),
			};
		});

		setSections([...changedSection]);
	};

	const addNewOption = (indexSection: number, indexQuestion: number, isCustom = false) => {
		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					if (isCustom) {
						return {
							...question,
							hasCustomOptions: isCustom,
						};
					}

					const numberNewOption = question.options.length;

					return {
						...question,
						options: [
							...question.options,
							{
								...defaultOption,
								content: `Вариант ${numberNewOption + 1}`,
							},
						],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	const removeOption = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, indexSection: number, indexQuestion: number, indexOption: number) => {
		event.preventDefault();
		event.stopPropagation();

		const preparedSections = sections.map((section, i) => {
			if (i !== indexSection) return section;

			const { questions } = section;
			return {
				...section,
				questions: questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					if (indexOption < 0)
						return {
							...question,
							hasCustomOptions: false,
						};

					const { options } = question;

					const newOptions = options
						.filter((_opt, index) => index !== indexOption)
						.map((opt, index) => ({
							...opt,
							content: opt.content.startsWith('Вариант') ? `Вариант ${index + 1}` : opt.content,
						}));

					return {
						...question,
						options: [...newOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	// * Render
	return (
		<DndMultiChoice
			sections={sections}
			setSections={setSections}
			indexSection={indexSection}
			indexQuestion={indexQuestion}
		>
			<div className={s.options}>
				<SortableContext
					items={question.options}
					strategy={verticalListSortingStrategy}
				>
					{[...question.options]
						.sort((a, b) => Number(a.isCustom) - Number(b.isCustom))
						.map((option, indexOption) => (
							<MultiChoiceOption
								key={option.id}
								option={option}
								hasMultipleChoice={hasMultipleChoice}
								indexes={{
									indexSection,
									indexQuestion,
									indexOption,
								}}
								onOptionContentChange={onOptionContentChange}
								removeOption={removeOption}
								showCloseIcon={showCloseIcon}
								isEdit={isEdit}
							/>
						))}
				</SortableContext>

				{question.hasCustomOptions && (
					<div className={s.option_field}>
						{hasMultipleChoice && (
							<Checkbox
								disabled
								noDisabledStyles
							/>
						)}

						{!hasMultipleChoice && (
							<Radio
								checked={false}
								disabled
								noDisabledStyles
							/>
						)}

						<TextInput
							placeholder="Другое"
							disabled
						/>

						{showCloseIcon && !isEdit && (
							<IconButton
								Icon={<CloseSVG />}
								onClick={e => removeOption(e, indexSection, indexQuestion, -1)}
							/>
						)}
					</div>
				)}
			</div>

			{!isEdit && (
				<div className={s.add_option}>
					<Button
						variant="secondary"
						onClick={() => addNewOption(indexSection, indexQuestion)}
					>
						Добавить вариант
					</Button>

					{!question.hasCustomOptions && (
						<Button
							variant="tertiary"
							onClick={() => addNewOption(indexSection, indexQuestion, true)}
						>
							Добавить вариант “Другое”
						</Button>
					)}
				</div>
			)}
		</DndMultiChoice>
	);
};
