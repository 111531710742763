import { asyncActions as companyAsync, slice as companySlice } from './company/slice';
import { asyncActions as companyUserAsync, slice as companyUserSlice } from './companyUser/slice';
import { asyncActions as contractSubjectAsync, slice as contractSubjectSlice } from './contractSubject/slice';

export const companyServiceActions = {
	company: {
		actions: companySlice.actions,
		async: companyAsync,
	},
	companyUser: {
		actions: companyUserSlice.actions,
		async: companyUserAsync,
	},
	contractSubject: {
		actions: contractSubjectSlice,
		async: contractSubjectAsync,
	},
};
