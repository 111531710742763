import React from 'react';
import s from './DateAndTimePickerInput.module.scss';
import { DatePickerInput } from 'src/shared/ui/_inputs/date_pickers/DatePickerInput';
import { TimePickerInput } from '../TimePickerInput';
import { ReactDatePickerProps } from 'react-datepicker';
import { DateTime } from 'luxon';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	selectedDate: Date | null;
	setSelectedDate: (date: Date | null) => void;

	activeTime: Date | null;
	setActiveTime: (date: Date | null) => void;

	label?: string;
	errorBorderDate?: boolean;
	errorBorderTime?: boolean;
	errorMessage?: string;
	required?: boolean;
}

// TODO: Disabled, width
export const DateAndTimePickerInput: React.FC<Props> = props => {
	const {
		selectedDate,
		setSelectedDate,

		activeTime,
		setActiveTime,

		label,
		errorBorderDate,
		errorBorderTime,
		errorMessage,

		excludeTimes,
		minDate,
		minTime = DateTime.fromJSDate(new Date()).startOf('day').toJSDate(),
		maxTime = DateTime.fromJSDate(new Date()).endOf('day').toJSDate(),
		disabled,
		required,
	} = props;

	// * Render
	return (
		<>
			<div className={s.container}>
				<InputLabel
					label={label}
					required={required}
				/>

				<div className={s.inputs_wrapper}>
					<DatePickerInput
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						minDate={minDate}
						disabled={disabled}
						errorBorderDate={errorBorderDate}
					/>

					<TimePickerInput
						selectedDate={activeTime}
						setSelectedDate={setActiveTime}
						excludeTimes={excludeTimes}
						minTime={minTime}
						maxTime={maxTime}
						disabled={disabled}
						errorBorderTime={errorBorderTime}
					/>
				</div>

				{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
			</div>
		</>
	);
};
