import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { useGetFeedbacksQuery } from 'src/app/redux/queries/gateway-service/gateway_serviceAPI';
import { FeedbackStatusType, FeedbackType } from 'src/app/redux/queries/gateway-service/types/types';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { routerPath } from 'src/app/router/paths';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { SelectStringSearchFilter } from 'src/entities/_filters/SelectStringSearchFilter';
import { actionsBugReportModal } from 'src/features/_layout/Header/ReportBug/slice';
import { ReactComponent as AddSVG } from 'src/shared/assets/svg/action/add.svg';
import { ReactComponent as ClipSVG } from 'src/shared/assets/svg/files/clip.svg';
import { backendISOUTCToLocalLuxon, dateToFullRuDateString, dateToTimeString } from 'src/shared/lib/date';
import { ConditionalRendering } from 'src/shared/providers';
import { Button } from 'src/shared/ui/_buttons/Button';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox';
import { BooleanSwitch } from 'src/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import { CardsTable } from 'src/shared/ui/_tables/CardsTable';
import { LineTabs } from 'src/shared/ui/_tabs/lineTabs';
import { IconWrapper } from 'src/shared/ui/_wrappers/IconWrapper';
import s from './FeedbackPage.module.scss';
import { CATEGORIES_RUS, COLUMNS, TABS } from './consts';
import { MultiAssign } from './ui/MultiAssign/MultiAssign';

export const FeedbackPage = () => {
	// * Routing
	const navigate = useNavigate();

	// * Actions
	const dispatch = useAppDispatch();
	const { setShowModal } = actionsBugReportModal;

	// * Selectors
	const showModal = useAppSelector(state => state.bugReportModal.showModal);

	const toggleModal = () => {
		dispatch(setShowModal(!showModal));
	};

	// * Filters
	// - Type
	const EMPTY_OPTION = { id: null, name: '' };
	const selectOptions = [...Object.entries(CATEGORIES_RUS).map(([key, value]) => ({ id: key as FeedbackType, name: value }))];
	const [selectedCategory, setSelectedCategory] = useState<{ id: null | FeedbackType; name: string | null }>(EMPTY_OPTION);

	const [ticketNumber, setTicketNumber] = useState<string>('');
	const [own, setOwn] = useState<boolean>(false);

	// * Tabs
	const [activeTabId, setActiveTabId] = useState<FeedbackStatusType>(TABS[0].id); //

	// * API
	const { data, isFetching } = useGetFeedbacksQuery({
		ownmessagesonly: own,
		skipcount: 0,
		takecount: 1000,

		feedbackstatus: activeTabId,
		feedbacktype: selectedCategory.id ?? undefined,
	});

	// * Table
	const [selected, setSelected] = useState<string[]>([]);

	const tableData = (data?.body ?? [])
		.filter(feedback => String(feedback.number).includes(ticketNumber))
		.sort((a, b) => (a.createdAtUtc < b.createdAtUtc ? 1 : -1))
		.map(feedback => {
			const checked = selected.includes(feedback.id);
			const createDate = backendISOUTCToLocalLuxon(feedback.createdAtUtc).toJSDate();

			return {
				id: feedback.id,
				number: feedback.number,
				Checkbox: (
					<Checkbox
						checked={checked}
						onChange={event => {
							event.stopPropagation();
							setSelected(prevState => (checked ? prevState.filter(id => id !== feedback.id) : [...prevState, feedback.id]));
						}}
					/>
				),
				senderFullName: feedback.senderFullName,
				type: CATEGORIES_RUS[feedback.type],
				createdAtUtc: (() => `${dateToFullRuDateString(createDate)} ${dateToTimeString(createDate, true)}`)(),
				content: feedback.content,
				Files: (
					<div className={s.files}>
						{feedback.imagesCount + feedback.filesCount} <ClipSVG />
					</div>
				),
			};
		});

	const toFeedback = (id: number) => navigate(`${routerPath.feedback.page}/${tableData[id].id}`);

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Обратная связь"
				buttons={[
					<Button
						key={0}
						onClick={toggleModal}
						variant="secondary"
					>
						<IconWrapper>
							<AddSVG />
						</IconWrapper>
					</Button>,
				]}
			/>

			<div className={s.filters}>
				<SelectStringSearchFilter
					selectPlaceholder="Все категории"
					selectValue={selectedCategory}
					selectOptions={selectOptions}
					setSelect={setSelectedCategory}
					searchPlaceholder="Поиск по номеру"
					searchStringValue={ticketNumber}
					setSearchString={setTicketNumber}
				/>

				<BooleanSwitch
					text="Мои обращения"
					checked={own}
					onChange={() => setOwn(prevState => !prevState)}
				/>
			</div>

			<LineTabs
				className={s.tabs}
				tabs={TABS}
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
			/>

			<ConditionalRendering
				initialLoading={isFetching}
				isSuccessful={!!tableData && tableData.length > 0}
				FailedResult="Ничего не найдено"
				LoadedResult={
					<>
						{selected.length > 0 && (
							<MultiAssign
								className={s.multiAssign}
								selected={selected}
								setSelected={setSelected}
							/>
						)}

						{!!tableData && (
							<CardsTable
								className={s.table}
								columns={COLUMNS}
								data={tableData}
								onRowClick={toFeedback}
							/>
						)}
					</>
				}
			/>
		</PageDecorator>
	);
};
