import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';

export const useCreatePosition = (onCloseModal: () => void) => {
	const [createPosition, { isLoading: isCreateLoading }] = position_serviceAPI.useCreatePositionMutation();

	const onCreateSubmit = (data: { name: string }) => {
		const { name } = data;

		createPosition({
			createPositionRequest: {
				name,
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log('Error: ', err));
	};

	return { onCreateSubmit, isCreateLoading };
};
