import cn from 'classnames';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/app/router/paths';
import { InfoItemSingle } from 'src/shared/ui/InfoItemSingle';
import s from './DepartmentCard.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	id: string;
	name: string;
	shortName: string;
	directorsName: string;
	isActive?: boolean;
}

export const DepartmentCard: FC<Props> = props => {
	const {
		id, //
		name,
		shortName,
		directorsName,
		className,
		isActive,
		...divProps
	} = props;

	const disabled = !isActive;

	const navigate = useNavigate();
	const toDepartment = () => navigate(`${routerPath.departments.page}${routerPath.departments.singleDepartment}/${id}/team`);

	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
			onClick={toDepartment}
		>
			<div className={cn(s.name, disabled && s.name_disabled)}>{name}</div>

			<div className={s.info}>
				<InfoItemSingle
					caption="Краткое название"
					info={shortName}
					disabled={disabled}
				/>

				<InfoItemSingle
					caption="Директор департамента"
					info={directorsName}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};
