import { UserResponse } from 'src/app/redux/queries/user-service/types/resTypes';
import { isPastDate, isoRemoveSeconds, isoToDateString } from 'src/shared/lib/date';
import { isoToDateByType } from './getDateOfBirth';

interface Field {
	// [key: string]: string;
	name: string;
	value: string;
	isHidden?: boolean;
}

export const getInfo = (userInfo: UserResponse, hasRights: boolean) => {
	const {
		userAddition, //
		departmentUser,
		position,
		offices,
		companyUser,
		role,
	} = userInfo;

	const result: Array<{
		title: string;
		fields: Field[];
	}> = [];

	// - Personal
	const personalInfo = [
		{
			name: 'Пол',
			value: userAddition?.gender?.name,
		},
		{
			name: 'День рождения',
			value:
				userAddition?.dateOfBirth && (hasRights || userAddition.birthType !== 'Closed')
					? isoToDateByType(userAddition.dateOfBirth, userAddition.birthType) //
					: null,
			isHidden: userAddition.birthType === 'Closed',
		},
	].filter(item => !!item.value) as Field[];

	if (personalInfo.length > 0)
		result.push({
			title: 'Личное',
			fields: personalInfo,
		});

	// - Work
	const hoursFrom = userAddition?.businessHoursFromUtc ? isoRemoveSeconds(userAddition.businessHoursFromUtc) : null;
	const hoursTo = userAddition?.businessHoursToUtc ? isoRemoveSeconds(userAddition.businessHoursToUtc) : null;

	// - Если исп. срок прошел, не отображаем информацию
	const probation =
		companyUser?.probation && !isPastDate(new Date(companyUser.probation)) //
			? `до ${isoToDateString(companyUser.probation)}`
			: null;

	const workInfo = [
		{
			name: 'Департамент',
			value: departmentUser?.department.name,
		},
		{
			name: 'Должность',
			value: position?.name,
		},
		{
			name: 'Время работы',
			value: getWorkTime(hoursFrom, hoursTo),
		},
		{
			name: 'Офис',
			value: offices?.map(office => `${office.city}, ${office.address}`).join('\n'),
		},
		{
			name: 'В компании с',
			value: hasRights && companyUser?.startWorkingAt ? isoToDateString(companyUser.startWorkingAt) : null,
		},
		{
			name: 'Тип договора',
			value: hasRights ? companyUser?.contractSubject?.name : null,
		},
		{
			name: 'Ставка',
			value: hasRights ? companyUser?.rate : null,
		},
		{
			name: 'Испытательный срок',
			value: hasRights ? probation : null,
		},
		{
			name: 'Роль',
			value: hasRights ? role?.name : null,
		},
	].filter(item => !!item.value) as Field[];

	if (workInfo.length > 0)
		result.push({
			title: 'Рабочее',
			fields: workInfo,
		});

	return result;
};

const getWorkTime = (hoursFrom: string | null, hoursTo: string | null) => {
	let workTime = '';

	if (hoursFrom && hoursTo) workTime = `${hoursFrom} - ${hoursTo}`;
	if (hoursFrom && !hoursTo) workTime = `С ${hoursFrom}`;
	if (!hoursFrom && hoursTo) workTime = `До ${hoursTo}`;

	return workTime;
};
