import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { ContactsDefaultValues } from '../types';

export const useCreateContact = (
	userId: string, //
	onCloseModal: () => void,
) => {
	// * API
	const [createContact, { isLoading: isCreateLoading }] = user_serviceAPI.usePostCommunicationCreateMutation();

	// * Submit
	const onCreateSubmit = (data: ContactsDefaultValues) => {
		const { type, value } = data;

		const regExp = /\D/g;
		const contactValue = type.id === 'Phone' ? value.replace(regExp, '') : value;

		createContact({
			createCommunicationRequest: {
				type: type.id,
				value: type.id === 'Telegram' ? `@${value}` : contactValue,
				userId,
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log(err));
	};

	return { onCreateSubmit, isCreateLoading };
};
