import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, ServiceName } from 'src/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { Company } from '../types';
import { GetCompanyREQ_PARAMS, GetCompanyRES_BODY } from './types';

const NAME = `${ServiceName.COMPANY_SERVICE}/company`;

const getCompany = createAppAsyncThunk(`${NAME}/getCompany`, async (arg: GetCompanyREQ_PARAMS, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetCompanyRES_BODY>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getCompany(arg),
	});
});

export interface State {
	company: Company | null;
	status: RequestStatus;
}

export const initialState: State = {
	company: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getCompany.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompany.fulfilled, (state, action) => {
			state.company = action.payload.body;
			state.status = RequestStatus.success;
		});
		builder.addCase(getCompany.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getCompany,
};
