import { OfficeInfo } from 'src/app/redux/queries/office-service/types/types';
import { OfficeInfo as UserOfficeInfo } from 'src/app/redux/queries/user-service/types/types';
import { PRIORITY_OFFICE_NAME } from 'src/shared/consts/consts';

export const getPriorityOfficeId = (offices: UserOfficeInfo[], allOffices: OfficeInfo[]): OfficeInfo | undefined => {
	let officeInfo = undefined;

	// Если пользователь не прикреплен к офису, то берем офис по приоритетности
	if (offices.length === 0) {
		const office = allOffices.find(office => office.name === PRIORITY_OFFICE_NAME);

		if (office) {
			officeInfo = {
				...office,
				name: `${office.name}, ${office.address}`,
			};
		}
	} else {
		// Если пользователь прикреплен к офису, то берем либо офис по приоритетности, либо другой офис, к которому пользователь прикреплен
		const userOfficeId = offices.find(office => office.name === PRIORITY_OFFICE_NAME)?.id || offices[0].id;
		const office = allOffices.find(office => office.id === userOfficeId);

		if (office) {
			officeInfo = {
				...office,
				name: `${office.name}, ${office.address}`,
			};
		}
	}

	return officeInfo;
};
