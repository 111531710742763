import React from 'react';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { ModalDelete } from 'src/pages/UserCardPage/ui/DeleteModal/DeleteModal';

interface Props {
	id: string;
	isActive: boolean;
	isVisible: boolean;
	onClose: () => void;
	actionAfterRequest?: () => void;
}

export const ArchiveModal: React.FC<Props> = props => {
	const {
		id, //
		isActive,
		isVisible,
		onClose,
		actionAfterRequest,
	} = props;

	// * API
	const [putUserEditactive, { isLoading: isArchiveLoading }] = user_serviceAPI.usePutUserEditactiveMutation();
	const [deletePendingRemove, { isLoading: isCancelLoading }] = user_serviceAPI.useDeletePendingRemoveMutation();

	// * Actions
	const onArchive = () => {
		putUserEditactive({
			editUserActiveRequest: {
				isActive: false,
				userId: id,
			},
		})
			.unwrap()
			.then(() => {
				actionAfterRequest && actionAfterRequest();
				onClose();
			})
			.catch(err => console.log(err));
	};

	const onCancel = () => {
		deletePendingRemove({
			userId: id,
		})
			.unwrap()
			.then(() => {
				actionAfterRequest && actionAfterRequest();
				onClose();
			})
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<ModalDelete
			isOpen={isVisible}
			onClose={onClose}
			title={isActive ? 'Архивирование пользователя' : 'Отменить приглашение'}
			description={isActive ? 'Вы действительно хотите архивировать этого пользователя?' : 'Вы действительно хотите отменить приглашение для этого\n пользователя?'}
			primaryText={isActive ? 'Архивировать' : 'Да, отменить'}
			primaryOnClick={isActive ? onArchive : onCancel}
			primaryIsLoading={isArchiveLoading || isCancelLoading}
			secondaryOnClick={onClose}
			secondaryIsLoading={isArchiveLoading || isCancelLoading}
		/>
	);
};
