import { autoUpdate, flip, offset, shift, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';
import React, { useId } from 'react';

export const useFloatingWrapper = (open: boolean, onOpenChange: React.Dispatch<React.SetStateAction<boolean>>) => {
	const { refs, floatingStyles, context } = useFloating({
		open,
		onOpenChange,
		middleware: [offset(4), flip({ fallbackAxisSideDirection: 'end' }), shift()],
		whileElementsMounted: autoUpdate,
	});

	const click = useClick(context, { keyboardHandlers: false });
	const dismiss = useDismiss(context);
	const role = useRole(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

	const headingId = useId();

	return { refs, floatingStyles, context, getReferenceProps, getFloatingProps, headingId };
};
