export const NAME_LIMIT = 300;
export const SHORT_NAME_LIMIT = 40;
export const STATUS_OPTIONS = [
	{
		id: 'true' as const,
		name: 'Активный' as const,
	},
	{
		id: 'false' as const,
		name: 'Архивированный' as const,
	},
];
