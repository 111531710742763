import { FC, ReactNode, useState } from 'react';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { AddUserList } from 'src/features/_card_lists/AddUserList';
import { matchItems } from 'src/shared/lib/filter';
import { Heading } from 'src/shared/ui/Heading';
import s from './AddUsersMC.module.scss';
import { UserListItem } from './types';
import { DepartmentsTreeInfo } from 'src/app/redux/queries/department-service/types/types';

interface Props {
	title: string;
	allUsers: UserListItem[];
	allDepartments: DepartmentsTreeInfo[];
	setSelectedUsers: (usersIds: string[]) => void;
	isLoading: boolean;
	toggleModal: () => void;
	ExtraComponent?: ReactNode;
}

export const AddUsersMC: FC<Props> = props => {
	const {
		title, //
		allUsers,
		allDepartments,
		setSelectedUsers,
		isLoading,
		toggleModal,
		ExtraComponent,
	} = props;

	// * Filters
	const [department, setDepartment] = useState<SelectValue>({ id: null, name: '' });
	const [userName, setUserName] = useState('');

	// - Фильтрация по департаменту
	const filteredUsersByDepartment = allUsers?.filter(user => {
		if (department.name) {
			return user.department === department.name;
		}
		return user;
	});

	// - Фильтрация по имени
	const filteredUsers = matchItems(filteredUsersByDepartment, userName);

	// * Temp users state
	const [tempSelectedUsersIds, setTempSelectedUsersIds] = useState<string[]>([]);

	// * Button actions
	const onApply = () => {
		setSelectedUsers(tempSelectedUsersIds);
	};

	const onCancel = () => {
		setTempSelectedUsersIds([]);
		toggleModal();
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading level={2}>{title}</Heading>

			{allDepartments && (
				<SelectStringSearchFilter
					selectPlaceholder="Департамент"
					selectValue={department}
					setSelect={(value: SelectValue) => setDepartment(value)}
					selectOptions={allDepartments}
					searchPlaceholder="Введите ФИО сотрудника"
					searchStringValue={userName}
					setSearchString={setUserName}
				/>
			)}

			{ExtraComponent}

			<AddUserList
				users={filteredUsers}
				selectedUsersIds={tempSelectedUsersIds}
				setSelectedUsers={setTempSelectedUsersIds}
			/>

			<ButtonPair
				primaryText="Добавить"
				primaryOnClick={onApply}
				primaryIsLoading={isLoading}
				secondaryText="Отменить"
				secondaryOnClick={onCancel}
				secondaryIsLoading={isLoading}
			/>
		</div>
	);
};
