import React from 'react';
import AvatarPlaceholder from 'src/shared/assets/img/AvatarPlaceholder.png';
import Background from 'src/shared/assets/img/newEmployeeBackground.jpg';
import { ReactComponent as CommaSVG } from 'src/shared/assets/svg/comma.svg';
import { ReactComponent as Logo } from 'src/shared/assets/svg/tercom-logo.svg';
import { InputTemplate } from '../../pages/NewEmployee/NewEmployee';
import s from './NewEmployeeTemplate.module.scss';
import cn from 'classnames';

interface NewEmployeeTemplateProps {
	image: string;
	data: InputTemplate[];
	refData: any;
}

export const NewEmployeeTemplate: React.FC<NewEmployeeTemplateProps> = ({ image, data, refData }) => {
	return (
		<div className={s.container}>
			<h2 className={s.title}>Результат</h2>
			<div
				className={s.card}
				ref={refData}
			>
				<div className={s.cardBody}>
					<div className={s.leftCol}>
						<div className={s.greetings}>
							ПРИВЕТСТВУЕМ <br /> НОВОГО СОТРУДНИКА <br /> В ЛАНИТ-ТЕРКОМ
						</div>
						<img
							src={image ? image : AvatarPlaceholder}
							alt="logo"
							className={s.image}
						/>
						<div className={s.name}>
							{data[0].value}
							<br /> {data[1].value}
						</div>
						<div className={s.job}>
							{data[2].value} <br /> {data[3].value}
						</div>
					</div>
					<div className={s.rightCol}>
						<div className={s.subtitle}>{data[4].value ? 'Образование' : ''}</div>
						<div className={s.textInfo}>{data[4].value}</div>
						<div className={cn(s.textInfo, s.quote)}>
							{data[5].value && (
								<p>
									<CommaSVG />
									{data[5].value}
									<CommaSVG />
								</p>
							)}
						</div>
						<div className={s.hobbies}>
							<div className={s.subtitle}>{data[6].value ? 'Увлечения' : ''}</div>
							<div className={`${s.textInfo}`}>{data[6].value}</div>
						</div>
					</div>
					<Logo className={s.logo} />
				</div>
				<img
					src={Background}
					className={s.background}
				/>
			</div>
		</div>
	);
};
