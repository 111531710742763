import { DateTime } from 'luxon';

interface Meta {
	month: number;
	year: number;
	holidays: string; // 1 - holiday, 0 - working day.
}

export const filterHolidaysByBinaryString = (meta: Meta[]): Date[] => {
	let dates: Date[] = [];

	for (const monthLimit of meta) {
		const monthHolidays = monthLimit.holidays
			.split('')
			.map((holiday, index) => {
				if (holiday === '1') {
					return DateTime.fromObject({
						year: monthLimit.year,
						month: monthLimit.month,
						day: index + 1,
					}).toJSDate();
				} else {
					return null;
				}
			})
			.filter(date => date !== null) as Date[]; // TS not infers removes null in his inference. That is the reason the type is hardcoded.
		dates = [...dates, ...monthHolidays];
	}

	return dates;
};
