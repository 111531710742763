import React from 'react';
import DatePicker from 'react-datepicker';
import { ModalHeader } from '../../../../../../shared/ui/_modals/ModalHeader/ModalHeader';
import { BookingInfoCard } from '../../../BookingInfoCard';
import { BookingInfoCardData } from '../../../BookingInfoCard/types';
import s from './AllBookingsMC.module.scss';

interface Props {
	orderedAllBookingInfo: BookingInfoCardData[];
}

export const AllBookingsMC: React.FC<Props> = props => {
	const { orderedAllBookingInfo } = props;

	const renderBookingInfoCards = () =>
		orderedAllBookingInfo.map(info => (
			<BookingInfoCard
				key={info.bookingId}
				{...info}
			/>
		));

	return (
		<>
			<ModalHeader title="Мои брони" />

			<div className={s.inner}>
				<div>
					<DatePicker
						locale={'ru'}
						selected={null}
						onChange={() => null}
						highlightDates={orderedAllBookingInfo.map(booking => booking.date)}
						inline
					/>
				</div>

				<div className={s.bookingList}>{orderedAllBookingInfo.length > 0 ? renderBookingInfoCards() : <div className={s.empty_text}>Нет броней</div>}</div>
			</div>
		</>
	);
};
