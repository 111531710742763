import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FC } from 'react';
import cn from 'classnames';
import s from './CardsTable.module.scss';

// TODO: Запилить возможность фиксации высоты таблицы с возможностью скролла.

interface Props extends React.ComponentPropsWithoutRef<'table'> {
	columns: ColumnDef<any, any>[];
	data: any[];
	onRowClick?: (rowId: number) => any;
}

export const CardsTable: FC<Props> = props => {
	const {
		columns, //
		data,
		onRowClick,
		className,
		...tableProps
	} = props;

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Render
	return (
		<table
			{...tableProps}
			className={cn(s.table, onRowClick && s.table_clickable, className)}
		>
			<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr
						key={headerGroup.id}
						// style={{ position: 'sticky', top: '-1rem' }}
					>
						{headerGroup.headers.map(header => (
							<th
								key={header.id}
								style={header.column.columnDef.meta?.style}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</thead>

			<tbody>
				{table.getRowModel().rows.map(row => (
					<tr
						key={row.id}
						onClick={event => {
							if (onRowClick) {
								event.stopPropagation();
								onRowClick(+row.id);
							}
						}}
					>
						{row.getVisibleCells().map(cell => {
							const hasMeta = cell.getContext().cell.column.columnDef.meta;

							return (
								<td
									key={cell.id}
									style={cell.column.columnDef.meta?.style}
									{...(hasMeta?.getCellContext && { ...hasMeta.getCellContext(cell.getContext()) })}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
};
