import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/app/api/apiSlice';
import { ServiceName } from 'src/app/api/api_types';
import {
	DeleteBookingRemoveApiArg,
	DeleteImageRemoveApiArg,
	DeleteTagRemoveApiArg,
	DeleteUserRemoveApiArg,
	GetBookingFindApiArg,
	GetImageGetApiArg,
	GetOfficeFindApiArg,
	GetOfficeGetApiArg,
	GetRoomFindApiArg,
	GetRoomGetApiArg,
	GetRoomTypeFindApiArg,
	GetRoomTypeGetApiArg,
	GetTagFindApiArg,
	GetWorkspaceFindApiArg,
	PatchOfficeEditApiArg,
	PatchRoomEditApiArg,
	PatchRoomTypeEditApiArg,
	PatchWorkspaceEditApiArg,
	PostBookingCreateApiArg,
	PostImageCreateApiArg,
	PostOfficeCreateApiArg,
	PostRoomCreateApiArg,
	PostRoomTypeCreateApiArg,
	PostTagCreateApiArg,
	PostUserCreateApiArg,
	PostWorkspaceCreateApiArg,
} from './types/regTypes';
import {
	DeleteBookingRemoveApiResponse,
	DeleteImageRemoveApiResponse,
	DeleteTagRemoveApiResponse,
	DeleteUserRemoveApiResponse,
	GetBookingFindApiResponse,
	GetImageGetApiResponse,
	GetOfficeFindApiResponse,
	GetOfficeGetApiResponse,
	GetRoomFindApiResponse,
	GetRoomGetApiResponse,
	GetRoomTypeFindApiResponse,
	GetRoomTypeGetApiResponse,
	GetTagFindApiResponse,
	GetWorkspaceFindApiResponse,
	PatchOfficeEditApiResponse,
	PatchRoomEditApiResponse,
	PatchRoomTypeEditApiResponse,
	PatchWorkspaceEditApiResponse,
	PostBookingCreateApiResponse,
	PostImageCreateApiResponse,
	PostOfficeCreateApiResponse,
	PostRoomCreateApiResponse,
	PostRoomTypeCreateApiResponse,
	PostTagCreateApiResponse,
	PostUserCreateApiResponse,
	PostWorkspaceCreateApiResponse,
} from './types/resTypes';

// !Полностью сгенерирован и обновлен 01.07.2024
const reducerPath = `${ServiceName.OFFICE_SERVICE}` as const;

export const office_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Booking', 'Image', 'Office', 'Room', 'RoomType', 'Tag', 'User', 'Workspace'] as const,
	endpoints: build => ({
		postBookingCreate: build.mutation<PostBookingCreateApiResponse, PostBookingCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Booking/create`,
				method: 'POST',
				body: queryArg.createBookingRequest,
			}),
			invalidatesTags: ['Booking'],
		}),
		deleteBookingRemove: build.mutation<DeleteBookingRemoveApiResponse, DeleteBookingRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Booking/remove`,
				method: 'DELETE',
				params: { BookingId: queryArg.bookingId },
			}),
			invalidatesTags: ['Booking'],
		}),
		getBookingFind: build.query<GetBookingFindApiResponse, GetBookingFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Booking/find`,
				params: {
					ParentId: queryArg.parentId,
					UserId: queryArg.userId,
					UpcomingOnly: queryArg.upcomingOnly,
					IncludeDeactivated: queryArg.includeDeactivated,
					IncludeBans: queryArg.includeBans,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Booking'],
		}),
		postImageCreate: build.mutation<PostImageCreateApiResponse, PostImageCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Image/create`,
				method: 'POST',
				body: queryArg.createImageRequest,
			}),
			invalidatesTags: ['Image'],
		}),
		getImageGet: build.query<GetImageGetApiResponse, GetImageGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Image/get`,
				params: { EntityId: queryArg.entityId },
			}),
			providesTags: ['Image'],
		}),
		deleteImageRemove: build.mutation<DeleteImageRemoveApiResponse, DeleteImageRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Image/remove`,
				method: 'DELETE',
				body: queryArg.removeImagesRequest,
			}),
			invalidatesTags: ['Image'],
		}),
		postOfficeCreate: build.mutation<PostOfficeCreateApiResponse, PostOfficeCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Office/create`,
				method: 'POST',
				body: queryArg.createOfficeRequest,
			}),
			invalidatesTags: ['Office'],
		}),
		getOfficeFind: build.query<GetOfficeFindApiResponse, GetOfficeFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Office/find`,
				params: {
					IsAscendingSort: queryArg.isAscendingSort,
					IncludeDeactivated: queryArg.includeDeactivated,
					NameIncludeSubstring: queryArg.nameIncludeSubstring,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Office'],
		}),
		patchOfficeEdit: build.mutation<PatchOfficeEditApiResponse, PatchOfficeEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Office/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { officeId: queryArg.officeId },
			}),
			invalidatesTags: ['Office'],
		}),
		getOfficeGet: build.query<GetOfficeGetApiResponse, GetOfficeGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Office/get`,
				params: { OfficeId: queryArg.officeId },
			}),
			providesTags: ['Office'],
		}),
		postRoomCreate: build.mutation<PostRoomCreateApiResponse, PostRoomCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Room/create`,
				method: 'POST',
				body: queryArg.createRoomRequest,
			}),
			invalidatesTags: ['Room'],
		}),
		patchRoomEdit: build.mutation<PatchRoomEditApiResponse, PatchRoomEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Room/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { roomId: queryArg.roomId },
			}),
			invalidatesTags: ['Room'],
		}),
		getRoomFind: build.query<GetRoomFindApiResponse, GetRoomFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Room/find`,
				params: {
					OfficeId: queryArg.officeId,
					RoomTypeId: queryArg.roomTypeId,
					BookingRule: queryArg.bookingRule,
					WholePeriodRequired: queryArg.wholePeriodRequired,
					StartTime: queryArg.startTime,
					EndTime: queryArg.endTime,
					MinSuitableTime: queryArg.minSuitableTime,
					Tags: queryArg.tags,
					IsAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Room'],
		}),
		getRoomGet: build.query<GetRoomGetApiResponse, GetRoomGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Room/get`,

				params: { RoomId: queryArg.roomId },
			}),
			providesTags: ['Room'],
		}),
		postRoomTypeCreate: build.mutation<PostRoomTypeCreateApiResponse, PostRoomTypeCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/RoomType/create`,
				method: 'POST',
				body: queryArg.createRoomTypeRequest,
			}),
			invalidatesTags: ['RoomType'],
		}),
		getRoomTypeFind: build.query<GetRoomTypeFindApiResponse, GetRoomTypeFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/RoomType/find`,
				params: {
					IncludeDeactivated: queryArg.includeDeactivated,
					OfficeId: queryArg.officeId,
					BookingRule: queryArg.bookingRule,
					StartTime: queryArg.startTime,
					EndTime: queryArg.endTime,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['RoomType'],
		}),
		getRoomTypeGet: build.query<GetRoomTypeGetApiResponse, GetRoomTypeGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/RoomType/get`,
				params: { RoomTypeId: queryArg.roomTypeId },
			}),
			providesTags: ['RoomType'],
		}),
		patchRoomTypeEdit: build.mutation<PatchRoomTypeEditApiResponse, PatchRoomTypeEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/RoomType/edit`,
				method: 'PATCH',
				body: queryArg.body,

				params: { roomTypeId: queryArg.roomTypeId },
			}),
			invalidatesTags: ['RoomType'],
		}),
		postTagCreate: build.mutation<PostTagCreateApiResponse, PostTagCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Tag/create`,
				method: 'POST',
				body: queryArg.createTagsRequest,
			}),
			invalidatesTags: ['Tag'],
		}),
		deleteTagRemove: build.mutation<DeleteTagRemoveApiResponse, DeleteTagRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Tag/remove`,
				method: 'DELETE',
				body: queryArg.removeTagsRequest,
			}),
			invalidatesTags: ['Tag'],
		}),
		getTagFind: build.query<GetTagFindApiResponse, GetTagFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Tag/find`,
				params: { NameIncludeSubstring: queryArg.nameIncludeSubstring, skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['Tag'],
		}),
		postUserCreate: build.mutation<PostUserCreateApiResponse, PostUserCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/create`,
				method: 'POST',
				body: queryArg.createOfficeUsersRequest,
			}),
			invalidatesTags: ['User'],
		}),
		deleteUserRemove: build.mutation<DeleteUserRemoveApiResponse, DeleteUserRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/remove`,
				method: 'DELETE',
				body: queryArg.removeOfficeUsersRequest,
			}),
			invalidatesTags: ['User'],
		}),
		postWorkspaceCreate: build.mutation<PostWorkspaceCreateApiResponse, PostWorkspaceCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Workspace/create`,
				method: 'POST',
				body: queryArg.createWorkspaceRequest,
			}),
			invalidatesTags: ['Workspace'],
		}),
		patchWorkspaceEdit: build.mutation<PatchWorkspaceEditApiResponse, PatchWorkspaceEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Workspace/edit`,
				method: 'PATCH',
				body: queryArg.body,

				params: { workspaceId: queryArg.workspaceId },
			}),
			invalidatesTags: ['Workspace'],
		}),
		getWorkspaceFind: build.query<GetWorkspaceFindApiResponse, GetWorkspaceFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Workspace/find`,
				params: {
					OfficeId: queryArg.officeId,
					RoomId: queryArg.roomId,
					WholePeriodRequired: queryArg.wholePeriodRequired,
					StartTime: queryArg.startTime,
					EndTime: queryArg.endTime,
					MinSuitableTime: queryArg.minSuitableTime,
					IncludeDeactivated: queryArg.includeDeactivated,
					Tags: queryArg.tags,
					IsAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Workspace'],
		}),
	}),
});

export const {
	usePostBookingCreateMutation,
	useDeleteBookingRemoveMutation,
	useGetBookingFindQuery,
	usePostImageCreateMutation,
	useGetImageGetQuery,
	useDeleteImageRemoveMutation,
	usePostOfficeCreateMutation,
	useGetOfficeFindQuery,
	usePatchOfficeEditMutation,
	useGetOfficeGetQuery,
	usePostRoomCreateMutation,
	usePatchRoomEditMutation,
	useGetRoomFindQuery,
	useGetRoomGetQuery,
	usePostRoomTypeCreateMutation,
	useGetRoomTypeFindQuery,
	useGetRoomTypeGetQuery,
	usePatchRoomTypeEditMutation,
	usePostTagCreateMutation,
	useDeleteTagRemoveMutation,
	useGetTagFindQuery,
	usePostUserCreateMutation,
	useDeleteUserRemoveMutation,
	usePostWorkspaceCreateMutation,
	usePatchWorkspaceEditMutation,
	useGetWorkspaceFindQuery,
} = office_serviceAPI;
