import React from 'react';
import s from './ListColumns.module.scss';

interface Column {
	title: string;
	width: number; // In percent
}

interface Props {
	margins: [number, number]; // [left-margin, right-margin] in rem.
	columns: Column[];
}

export const ListColumns: React.FC<Props> = props => {
	const { margins, columns } = props;

	const totalMarginsWidth = margins[0] + margins[1];
	const totalColumnsWidth = columns.reduce((previousValue, currentValue) => previousValue + currentValue.width, 0);
	const totalWidth = totalMarginsWidth + totalColumnsWidth;

	const marginLeft = (margins[0] / totalWidth) * 100 + '%';
	const marginRight = (margins[1] / totalWidth) * 100 + '%';

	// * Render
	return (
		<div
			className={s.container}
			style={{
				marginLeft,
				marginRight,
			}}
		>
			{columns &&
				columns.map(column => {
					const { width, title } = column;

					return (
						<div
							key={title}
							style={{
								flexBasis: (width / totalWidth) * 100 + '%',
							}}
						>
							{title}
						</div>
					);
				})}
		</div>
	);
};
