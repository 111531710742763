import React from 'react';
import { useParams } from 'react-router-dom';
import { survey_serviceAPI } from 'src/app/redux/queries/survey-service/survey_serviceAPI';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { getSummaryData } from 'src/pages/surveys/libs/getSummaryData/getSummaryData';
import { QuestionImage } from '../../../../components/QuestionImage/QuestionImage';
import { getInitialStateSurveyGroups } from '../../../../utils/utils';
import { QuestionItem } from '../../../NewSurveyPage/_types';
import s from './SummaryContent.module.scss';
import { HorizontalBar } from './ui/HorizontalBar/HorizontalBar';
import { Pie } from './ui/Pie/Pie';

interface Props {
	isAnonymous: boolean;
	usersCount: number;
}

export const SummaryContent: React.FC<Props> = props => {
	const { usersCount, isAnonymous } = props;

	// * Router
	const { surveyId } = useParams<'surveyId'>();

	// * API
	const { currentData: surveyIdData, isLoading } = survey_serviceAPI.useGetSurveysSurveyidQuery(
		{
			surveyid: surveyId as string,
			includeCategories: true,
			includeuseranswers: true,
			includeuserinfo: !isAnonymous,
			includeImages: true,
		},
		{ skip: !surveyId || isAnonymous === null },
	);

	// * Groups
	const groups = getInitialStateSurveyGroups('surveyId', surveyIdData?.groups);

	// * Questions
	const questions: QuestionItem[] = [];

	groups.map(group => {
		group.questions.map(question => questions.push(question));
	});

	const typeQuestion = (type: string, data?: any, keys?: string[]) => {
		switch (type) {
			case 'Base':
				return <Pie data={data} />;
			case 'MultiBase':
				return (
					<HorizontalBar
						indexBy="option"
						layout="horizontal"
						data={data}
					/>
				);
			case 'Scale':
				return (
					<HorizontalBar
						indexBy="scale"
						layout="vertical"
						data={data}
					/>
				);
			case 'Grid':
				return (
					<HorizontalBar
						keys={keys || ['value']}
						indexBy="row"
						layout="vertical"
						data={data}
					/>
				);
			case 'MultiGrid':
				return (
					<HorizontalBar
						keys={keys || ['value']}
						indexBy="row"
						layout="vertical"
						data={data}
					/>
				);
			default:
				return null;
		}
	};

	const summary = getSummaryData(questions);

	// * Render
	return (
		<div className={s.container}>
			<h5>Всего проголосовало: {usersCount}</h5>

			{isLoading && <LoaderCircle />}

			{!isLoading &&
				usersCount > 0 &&
				summary.map(question => (
					<div
						key={question.id}
						className={s.wrapper}
					>
						<div className={s.content}>
							{question.content && <div className={s.title}>{question.content}</div>}

							{question.isObligatory && <h3>*</h3>}
						</div>

						{question.imageId && <QuestionImage id={question.imageId} />}

						{question.data.length > 0 && typeQuestion(question.type, question.data, question?.keys)}

						{question.data.length === 0 && <div className={s.empty}>Ответов на этот вопрос пока нет</div>}
					</div>
				))}
		</div>
	);
};
