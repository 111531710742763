import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<any>();

export const COLUMNS = [
	columnHelper.accessor('avatar', {
		header: () => null,
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' }, // ! Работает как fit-content.
		},
	}),
	columnHelper.accessor('name', {
		header: 'ФИО',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('position', {
		header: 'ДОЛЖНОСТЬ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('extra', {
		header: () => null,
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' }, // ! Работает как fit-content.
		},
	}),
];
