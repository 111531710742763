import { routerPath } from 'src/app/router/paths';
import { useNavigate } from 'react-router-dom';

export const useRoomEdit = (arg: { officeId: string }) => {
	const { officeId } = arg;

	// * Router
	const navigate = useNavigate();

	const openRoomForm = (roomId: string | null) => {
		officeId && navigate(`${routerPath.offices.page}${routerPath.offices.roomForm}/${officeId}/${roomId}/1`);
	};

	return { openRoomForm };
};
