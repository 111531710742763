import { ActionCreatorsMapObject, bindActionCreators, createAsyncThunk } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { AppDispatch, RootState } from './store';

export const useActions = <T extends ActionCreatorsMapObject>(actions: T) => {
	const dispatch = useDispatch<AppDispatch>();

	return useMemo(() => {
		return bindActionCreators(actions, dispatch);
	}, [actions, dispatch]);
};

export const useAppDispatch = () => {
	return useDispatch<AppDispatch>();
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
	state: RootState;
	dispatch: AppDispatch;
}>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
