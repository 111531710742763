import { EducationInfo } from 'src/app/redux/queries/education-service/types/types';
import { CERTIFICATE_TYPE, EDUCATION_TYPE } from '../../const/type';

interface Result {
	education: EducationInfo[];
	certificate: EducationInfo[];
}

export const getEducationInfo = (info?: EducationInfo[] | null): Result => {
	if (!info)
		return {
			education: [],
			certificate: [],
		};

	const prepared = info.filter(item => item.isActive);

	const education = prepared.filter(item => item?.educationForm && EDUCATION_TYPE.includes(item.educationForm.name));
	const certificate = prepared.filter(item => item?.educationForm && CERTIFICATE_TYPE.includes(item.educationForm.name));

	return { education, certificate };
};
