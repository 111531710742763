import React from 'react';
import s from './ColorSection.module.scss';

export const ColorSection: React.FC<{ color: string; text?: string }> = ({ color, text }) => {
	return (
		<div
			className={s.colorSection}
			style={{ backgroundColor: color }}
		>
			<div className={s.colorSection__text}>{text ? text : 'Введите название'}</div>
		</div>
	);
};
