import { ReactNode } from 'react';
import { FeedbackType } from 'src/app/redux/queries/gateway-service/types/types';

export const feedbackCategoryOptions: Array<{ id: FeedbackType; name: string; Icon?: ReactNode }> = [
	{
		id: 'Bug',
		name: 'Сломалось',
	},
	{
		id: 'Wishes',
		name: 'Пожелание',
	},
	{
		id: 'Other',
		name: 'Другое',
	},
];

export const getFeedbackCategory = (feedbackCategory: FeedbackType) => {
	return feedbackCategoryOptions.find(feedbackCategoryOption => feedbackCategoryOption.id === feedbackCategory)?.id as 'Bug' | 'Wishes' | 'Other';
};
