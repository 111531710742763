import { DateTime } from 'luxon';
import { event_serviceAPI } from 'src/app/redux/queries/event-service/event_serviceAPI';
import { UserInfo } from 'src/pages/event_calendar/ui/EventCalendarPage/ui/DayEventsInfoList/ui/UserInfo';
import { dateToFullRuDataNameString } from 'src/shared/lib/date';
import { createFullName, formatToUpperFirstLetter } from 'src/shared/lib/string';
import { ConditionalRendering } from 'src/shared/providers';
import { Heading } from 'src/shared/ui/Heading';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import s from './Birthdays.module.scss';

export const Birthdays = () => {
	// * API
	const { data, isLoading } = event_serviceAPI.useGetUserBirthdayFindQuery({
		skipcount: 0,
		takecount: 100,
		startTime: new Date().toISOString().split('T')[0], // ! - По-другому бесконечная загрузка в RTQ Query идет
		endTime: new Date().toISOString().split('T')[0], // ! - По-другому бесконечная загрузка в RTQ Query идет
	});

	const allBirthdays = data?.body || [];

	//* Render
	return (
		<CardWrapper title="Дни рождения коллег">
			<Heading
				level={3}
				marginBottom="s"
			>
				{formatToUpperFirstLetter(DateTime.now().weekdayLong)}, {dateToFullRuDataNameString(new Date())}
			</Heading>

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={allBirthdays.length > 0}
				FailedResult={<span className={s.empty}>Сегодня дней рождений нет.</span>}
				LoadedResult={
					<>
						{allBirthdays.map(user => (
							<UserInfo
								key={user.userId}
								userId={user.userId}
								name={createFullName({
									firstName: user.name,
									lastName: user.lastName,
									middleName: user.middleName || '',
								})}
								job={user.positionName}
								imageId={user.imageId}
							/>
						))}
					</>
				}
			/>
		</CardWrapper>
	);
};
